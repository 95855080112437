import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

const StyledNamedAvatar = styled.div<{ deviceType?: DeviceTypeProps }>`


${({ deviceType }) => {
  if (deviceType?.type === 'mobile')
    return `
    display:none;
    `;
}}

  position: relative;
  padding: 1em;
  border-radius: 50%;
  ${({ theme }) => css`
    border: 1px solid ${theme.color.yellow.FFBF2E};

    p {
      color: ${theme.color.yellow.FFBF2E};
    }
  `}
`;

export { StyledNamedAvatar };
