/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from 'react'
import { Button } from "components/Button"
import { Selector } from "components/Selector"
import { Text } from "components/Text"
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik"
import ReCAPTCHA from "react-google-recaptcha"
import { TIPOS_SOLICITACAO, TIPO_DADO } from ".."
import { Parametro, Solicitacao } from "../Models/solicitacao"
import { UserDetails } from "../Models/userDetails"
import * as S from './style'
import { sentReport } from 'services/SuaConsulta'
import * as Yup from 'yup'
import useDeviceType from 'hooks/useDeviceType'

type MaisInformacoesForm = {
  firstRequest: boolean
  oposicaoCheck: boolean
  oposicao: DinamicForm[]
  bloqueioCheck: boolean
  bloqueio: DinamicForm[]
  eliminacaoCheck: boolean
  eliminacao: DinamicForm[]
  anonimizacaoCheck: boolean
  anonimizacao: DinamicForm[]
  revogacaoCheck: boolean
  revogacaoConfirm: boolean
  revogacao: string
  eliminacaoConsentimentoCheck: boolean
  eliminacaoConsentimentoConfirm: boolean
  eliminacaoConsentimento: string
  revisaoCheck: boolean
  revisao: string
}

type DinamicForm = {
  dataType: string
  justification: string
}

const MaisSolicitacoes = ({ detalheConsumidor, nome }: UserDetails) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const formSAERef = useRef<HTMLFormElement>(null)
  const [isFormReady, setIsFormReady] = useState(false)
  const [tokenRecapctha, setTokenRecaptcha] = useState('')
  const [formSAE, setFormSAE] = useState({
    retURL: '',
    name: '',
    email: '',
    cpf: '',
    phone: '',
    subject: '',
    description: '',
    type: '',
    motivo: '',
    submotivo: '',
    captcha_settings: '',
    recaptcha_response: '',
    orgId: ''
  })

  useEffect(() => {
    if (isFormReady && tokenRecapctha !== '') {
      formSAERef.current?.submit()
    }
  }, [isFormReady, formSAE, tokenRecapctha])

  const formik = useFormik<MaisInformacoesForm>({
    initialValues: {
      firstRequest: false,
      oposicaoCheck: false,
      oposicao: [
        {
          dataType: '',
          justification: ''
        }
      ],
      bloqueioCheck: false,
      bloqueio: [
        {
          dataType: '',
          justification: ''
        }
      ],
      eliminacaoCheck: false,
      eliminacao: [{
        dataType: '',
        justification: ''
      }],
      anonimizacaoCheck: false,
      anonimizacao: [{
        dataType: '',
        justification: ''
      }],
      revogacaoCheck: false,
      revogacaoConfirm: false,
      revogacao: '',
      eliminacaoConsentimentoCheck: false,
      eliminacaoConsentimentoConfirm: false,
      eliminacaoConsentimento: '',
      revisaoCheck: false,
      revisao: ''
    },
    validationSchema: Yup.object().shape({
      oposicao: Yup.array().when('oposicaoCheck', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          dataType: Yup.string().required("Campo obrigatório."),
          justification: Yup.string().required("Campo obrigatório.").max(70, 'Limite máximo de 70 caracteres.')
        }))
      }),
      bloqueio: Yup.array().when('bloqueioCheck', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          dataType: Yup.string().required("Campo obrigatório."),
          justification: Yup.string().required("Campo obrigatório.").max(70, 'Limite máximo de 70 caracteres.')
        }))
      }),
      eliminacao: Yup.array().when('eliminacaoCheck', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          dataType: Yup.string().required("Campo obrigatório."),
          justification: Yup.string().required("Campo obrigatório.").max(70, 'Limite máximo de 70 caracteres.')
        }))
      }),
      anonimizacao: Yup.array().when('anonimizacaoCheck', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          dataType: Yup.string().required("Campo obrigatório."),
          justification: Yup.string().required("Campo obrigatório.").max(70, 'Limite máximo de 70 caracteres.')
        }))
      }),
      revogacao: Yup.string().max(70, 'Limite máximo de 70 caracteres.').when('revogacaoCheck', {
        is: true,
        then: Yup.string().required("Campo obrigatório.")
      }),

      eliminacaoConsentimento: Yup.string().max(70, 'Limite máximo de 70 caracteres.').
        when('eliminacaoConsentimentoCheck',
          {
            is: true,
            then: Yup.string().required("Campo obrigatório.")
          }),
      revisao: Yup.string().max(70, 'Limite máximo de 70 caracteres.').
        when('revisaoCheck', {
          is: true,
          then: Yup.string().required("Campo obrigatório.")
        })
    }),
    onSubmit(values) {
      recaptchaRef.current?.executeAsync().then(async (resp) => {
        const token = resp
        const solicitacao = solicitacaoFormFactory(values.firstRequest)

        const parametros: Parametro[] = []
        const oposicaoArr = values.oposicao
        oposicaoArr.length > 0 && oposicaoArr.forEach(oposicao => {
          const oposicaoDataType = oposicao.dataType
          const oposicaoRestOfTheWord = oposicaoDataType.slice(1).toLocaleLowerCase()
          const formattedDateType = oposicaoDataType.charAt(0).toUpperCase() + oposicaoRestOfTheWord

          const dataType = {
            campo: 'Para qual dado de oposição?',
            valor: formattedDateType
          }
          const justification = {
            campo: 'Explicação sobre oposição / Justificativa',
            valor: oposicao.justification
          }

          parametros.push(dataType)
          parametros.push(justification)
        })

        const bloqueioArr = values.bloqueio
        bloqueioArr.length > 0 && bloqueioArr.forEach(bloqueio => {
          const bloqueioDataType = bloqueio.dataType
          const bloqueioRestOfTheWord = bloqueioDataType.slice(1).toLocaleLowerCase()
          const formattedDateType = bloqueioDataType.charAt(0).toUpperCase() + bloqueioRestOfTheWord

          const dataType = {
            campo: 'Para qual dado de bloqueio?',
            valor: formattedDateType
          }
          const justification = {
            campo: 'Explicação sobre bloqueio / Justificativa',
            valor: bloqueio.justification
          }

          parametros.push(dataType)
          parametros.push(justification)
        })

        const eliminacaoArr = values.eliminacao
        eliminacaoArr.length > 0 && eliminacaoArr.forEach(eliminacao => {
          const eliminacaoDataType = eliminacao.dataType
          const eliminacaoRestOfTheWord = eliminacaoDataType.slice(1).toLocaleLowerCase()
          const formattedDateType = eliminacaoDataType.charAt(0).toUpperCase() + eliminacaoRestOfTheWord

          const dataType = {
            campo: 'Para qual dado de eliminação?',
            valor: formattedDateType
          }
          const justification = {
            campo: 'Explicação sobre eliminação / Justificativa',
            valor: eliminacao.justification
          }

          parametros.push(dataType)
          parametros.push(justification)
        })

        const anonimizacaoArr = values.anonimizacao
        anonimizacaoArr.length > 0 && anonimizacaoArr.forEach(anonimizacao => {
          const anonimizacaoDataType = anonimizacao.dataType
          const anonimizacaoRestOfTheWord = anonimizacaoDataType.slice(1).toLocaleLowerCase()
          const formattedDateType = anonimizacaoDataType.charAt(0).toUpperCase() + anonimizacaoRestOfTheWord

          const dataType = {
            campo: 'Para qual dado de anonimização?',
            valor: formattedDateType
          }
          const justification = {
            campo: 'Explicação sobre anonimização / Justificativa',
            valor: anonimizacao.justification
          }

          parametros.push(dataType)
          parametros.push(justification)
        })

        const revogacao = values.revogacao
        parametros.push({
          campo: 'Explicação sobre revogação de consentimento / Justificativa',
          valor: revogacao
        })

        const eliminacaoConsentimento = values.eliminacaoConsentimento
        parametros.push({
          campo: 'Explicação sobre eliminação do consentimento / Justificativa',
          valor: eliminacaoConsentimento
        })

        const revisao = values.revisao
        parametros.push({
          campo: 'Explicação sobre revisão de decisões tomadas / Justificativa',
          valor: revisao
        })

        solicitacao.parametros = parametros

        try {
          await sentReport(solicitacao)
          setIsFormReady(true)
          setTokenRecaptcha(token!)
          submitFormSAE(token!, parametros!)
        } catch (e) {
          console.log(e)
        }
      })
    }
  })

  const isValidForm = () => {
    const { anonimizacaoCheck, bloqueioCheck, eliminacaoCheck,
      eliminacaoConsentimentoCheck, oposicaoCheck, revisaoCheck, revogacaoCheck } = formik.values
    if (anonimizacaoCheck || bloqueioCheck || eliminacaoCheck || eliminacaoConsentimentoCheck || oposicaoCheck || revisaoCheck || revogacaoCheck) {
      if (formik.isValid) {
        return true
      }
      return false
    }
    return false
  }

  const getOposicaoErrors = (index: number) => {
    if (formik?.errors?.oposicao && formik?.errors?.oposicao?.length > 0) {
      return formik?.errors?.oposicao[index] as FormikErrors<DinamicForm>
    }
    return {}
  }
  const getBloqueioErrors = (index: number) => {
    if (formik?.errors?.bloqueio && formik?.errors?.bloqueio?.length > 0) {
      return formik?.errors?.bloqueio[index] as FormikErrors<DinamicForm>
    }
    return {}
  }

  const getEliminacaoErrors = (index: number) => {
    if (formik?.errors?.eliminacao && formik?.errors?.eliminacao?.length > 0) {
      return formik?.errors?.eliminacao[index] as FormikErrors<DinamicForm>
    }
    return {}
  }

  const getAnonimizacaoErrors = (index: number) => {
    if (formik?.errors?.anonimizacao && formik?.errors?.anonimizacao?.length > 0) {
      return formik?.errors?.anonimizacao[index] as FormikErrors<DinamicForm>
    }
    return {}
  }
  const submitFormSAE = (token: string, parametros: Parametro[]) => {
    const description = formatDescription(parametros)
    const CAPTCHA_SETTINGS = {
      fallback: true,
      orgId: '00D1U000000FwcW',
      keyname: 'Captcha_V3',
      ts: new Date().getTime()

    }

    setFormSAE({
      retURL: `${window.location.href}?registroSAE=true`,
      name: nome,
      email: detalheConsumidor.email,
      cpf: detalheConsumidor.cpf,
      phone: detalheConsumidor.telefone,
      subject: `MAIS_SOLICITACOES - ${nome} - ${detalheConsumidor.cpf} -
      ${!formik.values.firstRequest ? 'Primeira solicitação' : ''}`,
      description,
      type: "Solicitação",
      motivo: 'MAIS_SOLICITACOES',
      captcha_settings: JSON.stringify(CAPTCHA_SETTINGS),
      recaptcha_response: token,
      orgId: '',
      submotivo: ''
    })
  }

  const formatDescription = (parametros: Parametro[]) => {
    const format = parametros.map(parametro => `${parametro.campo} - ${parametro.valor}.`)
    return format.join('')
  }

  const solicitacaoFormFactory = (firstRequest: boolean) => {
    const solicitacaoForm: Solicitacao = {
      anexos: [],
      email: detalheConsumidor.email,
      idTipoSolicitacao: TIPOS_SOLICITACAO.MAIS_SOLICITACOES,
      primeiraSolicitacao: !firstRequest,
      resumoSolicitacao:
        // eslint-disable-next-line max-len
        `${TIPOS_SOLICITACAO.MAIS_SOLICITACOES} - ${nome.toUpperCase()} - ${detalheConsumidor.cpf} - ${!firstRequest ? 'Primeira solicitação' : 'solicitação'}`,
      parametros: []
    }
    return solicitacaoForm
  }

  const { deviceType } = useDeviceType();

  return (
    <S.ContainerText>
      <Text tag='h4' size={3} weight={4}>Mais solicitações</Text>
      <Text color='text'>
        Informamos que o SPC BRASIL respeita a sua privacidade e a
        segurança dos seus dados pessoais. Portanto, todos os nossos
        processos de tratamento de dados pessoais estão em plena conformidade
        com a Lei nº. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).
        Para saber mais sobre nossas práticas de privacidade e proteção de dados pessoais, consulte aqui.
      </Text>
      <Text color='text'>
        Se após a leitura da nossa
        Política de Privacidade você ainda queira encaminhar a sua
        solicitação para avaliação do nosso Encarregado, por gentileza, nos informe:
      </Text>
      <FormikProvider value={formik}>
        <S.FieldSet>
          <form onSubmit={formik.handleSubmit}>
            <S.Checks>
              <input type="checkbox" id='firstRequest' name='firstRequest'
              />
              <Text size={0} >Esta não é minha primeira solicitação.</Text>
              <br />
            </S.Checks>
            <Text color='text'>
              Marque abaixo quais informações você deseja solicitar:
            </Text>

            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='oposicaoCheck' name='oposicaoCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Oposição</Text>
              </S.Checks>
              {
                formik.values.oposicaoCheck && (
                  <>
                    <Text size={0} weight={3}>Mais solicitações - Dados de Oposição</Text>
                    <FieldArray name="oposicao" render={
                      (arrayHelpers) => (
                        <div>
                          {
                            formik.values.oposicao.map((oposicao, index) => (
                              <S.DinamicForm key={index}>
                                <S.FormContainer>
                                  <S.FieldWrapper deviceType={{ type: deviceType }}>
                                    <Text size={0}>Para qual dado de oposição? (*)</Text>
                                    <Selector
                                      name={`oposicao[${index}].dataType`}
                                      options={[
                                        { label: 'Nome', value: TIPO_DADO.NOME },
                                        { label: 'Telefone', value: TIPO_DADO.TELEFONE },
                                        { label: 'Celular', value: TIPO_DADO.CELULAR },
                                        { label: 'Endereço', value: TIPO_DADO.ENDERECO },
                                        { label: 'Data de nascimento', value: TIPO_DADO.DATA_NASCIMENTO },
                                        { label: 'Email', value: TIPO_DADO.EMAIL },
                                        { label: 'CPF', value: TIPO_DADO.CPF },
                                      ]}
                                      value={formik.values.oposicao[index].dataType}
                                      onSelect={(e) => formik.setFieldValue(`oposicao.${index}.dataType`, e.value)}
                                    />
                                    <S.ErrorMessage>{getOposicaoErrors(index)?.dataType}</S.ErrorMessage>
                                  </S.FieldWrapper>
                                  <S.TextboxWrapper>
                                    <Text size={0}>Explicação sobre oposição / Justificativa (*)</Text>
                                    <S.TextAreaModal
                                      name={`oposicao[${index}].justification`}
                                      value={formik.values.oposicao[index].justification}
                                      onChange={formik.handleChange}
                                    />
                                    <S.ErrorMessage>{getOposicaoErrors(index)?.justification}</S.ErrorMessage>
                                  </S.TextboxWrapper>
                                </S.FormContainer>
                                <S.DeleteWrapper>
                                  <Button
                                    type="button"
                                    btnStatus='delete'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <S.IconDelete />
                                  </Button>
                                </S.DeleteWrapper>
                              </S.DinamicForm>
                            ))
                          }
                          <S.AddFormWrapper deviceType={{ type: deviceType }}>
                            <Button
                              type="button"
                              btnStatus='primary'
                              onClick={() => arrayHelpers.push({
                                dataType: '',
                                justification: ''
                              })}
                            >
                              Mais campos {<S.IconAdd />}
                            </Button>
                          </S.AddFormWrapper>
                        </div>
                      )
                    } />
                  </>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='bloqueioCheck' name='bloqueioCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Bloqueio</Text>
              </S.Checks>
              {
                formik.values.bloqueioCheck && (
                  <>
                    <Text size={0} weight={3}>Mais solicitações - Dados de Bloqueio</Text>
                    <FieldArray name="bloqueio" render={
                      (arrayHelpers) => (
                        <div>
                          {
                            formik.values.bloqueio.map((bloqueio, index) => (
                              <S.DinamicForm key={index}>
                                <S.FormContainer>
                                  <S.FieldWrapper deviceType={{ type: deviceType }}>
                                    <Text size={0}>Para qual dado de bloqueio? (*)</Text>
                                    <Selector
                                      name={`bloqueio[${index}].dataType`}
                                      options={[
                                        { label: 'Nome', value: TIPO_DADO.NOME },
                                        { label: 'Telefone', value: TIPO_DADO.TELEFONE },
                                        { label: 'Celular', value: TIPO_DADO.CELULAR },
                                        { label: 'Endereço', value: TIPO_DADO.ENDERECO },
                                        { label: 'Data de nascimento', value: TIPO_DADO.DATA_NASCIMENTO },
                                        { label: 'Email', value: TIPO_DADO.EMAIL },
                                        { label: 'CPF', value: TIPO_DADO.CPF },
                                      ]}
                                      value={formik.values.bloqueio[index].dataType}
                                      onSelect={(e) => formik.setFieldValue(`bloqueio.${index}.dataType`, e.value)}
                                    />
                                    <S.ErrorMessage>{getBloqueioErrors(index)?.dataType}</S.ErrorMessage>
                                  </S.FieldWrapper>
                                  <S.TextboxWrapper>
                                    <Text size={0}>Explicação sobre bloqueio / Justificativa (*)</Text>
                                    <S.TextAreaModal
                                      name={`bloqueio[${index}].justification`}
                                      value={formik.values.bloqueio[index].justification}
                                      onChange={formik.handleChange}
                                    />
                                    <S.ErrorMessage>{getBloqueioErrors(index)?.justification}</S.ErrorMessage>
                                  </S.TextboxWrapper>
                                </S.FormContainer>
                                <S.DeleteWrapper>
                                  <Button
                                    type="button"
                                    btnStatus='delete'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <S.IconDelete />
                                  </Button>
                                </S.DeleteWrapper>
                              </S.DinamicForm>
                            ))
                          }
                          <S.AddFormWrapper deviceType={{ type: deviceType }}>
                            <Button
                              type="button"
                              btnStatus='primary'
                              onClick={() => arrayHelpers.push({
                                dataType: '',
                                justification: ''
                              })}
                            >
                              Mais campos {<S.IconAdd />}
                            </Button>
                          </S.AddFormWrapper>
                        </div>
                      )
                    } />
                  </>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='eliminacaoCheck' name='eliminacaoCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Eliminação</Text>
              </S.Checks>
              {
                formik.values.eliminacaoCheck && (
                  <>
                    <Text size={0} weight={3}>Mais solicitações - Dados de Eliminação</Text>
                    <FieldArray name="eliminacao" render={
                      (arrayHelpers) => (
                        <div>
                          {
                            formik.values.eliminacao.map((eliminacao, index) => (
                              <S.DinamicForm key={index}>
                                <S.FormContainer>
                                  <S.FieldWrapper deviceType={{ type: deviceType }}>
                                    <Text size={0}>Para qual dado de eliminacao? (*)</Text>
                                    <Selector
                                      name={`eliminacao[${index}].dataType`}
                                      options={[
                                        { label: 'Nome', value: TIPO_DADO.NOME },
                                        { label: 'Telefone', value: TIPO_DADO.TELEFONE },
                                        { label: 'Celular', value: TIPO_DADO.CELULAR },
                                        { label: 'Endereço', value: TIPO_DADO.ENDERECO },
                                        { label: 'Data de nascimento', value: TIPO_DADO.DATA_NASCIMENTO },
                                        { label: 'Email', value: TIPO_DADO.EMAIL },
                                        { label: 'CPF', value: TIPO_DADO.CPF },
                                      ]}
                                      value={formik.values.eliminacao[index].dataType}
                                      onSelect={(e) => formik.setFieldValue(`eliminacao.${index}.dataType`, e.value)}
                                    />
                                    <S.ErrorMessage>{getEliminacaoErrors(index)?.dataType}</S.ErrorMessage>
                                  </S.FieldWrapper>
                                  <S.TextboxWrapper>
                                    <Text size={0}>Explicação sobre eliminação / Justificativa (*)</Text>
                                    <S.TextAreaModal
                                      name={`eliminacao[${index}].justification`}
                                      value={formik.values.eliminacao[index].justification}
                                      onChange={formik.handleChange}
                                    />
                                    <S.ErrorMessage>{getEliminacaoErrors(index)?.justification}</S.ErrorMessage>
                                  </S.TextboxWrapper>
                                </S.FormContainer>
                                <S.DeleteWrapper>
                                  <Button
                                    type="button"
                                    btnStatus='delete'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <S.IconDelete />
                                  </Button>
                                </S.DeleteWrapper>
                              </S.DinamicForm>
                            ))
                          }
                          <S.AddFormWrapper deviceType={{ type: deviceType }}>
                            <Button
                              type="button"
                              btnStatus='primary'
                              onClick={() => arrayHelpers.push({
                                dataType: '',
                                justification: ''
                              })}
                            >
                              Mais campos {<S.IconAdd />}
                            </Button>
                          </S.AddFormWrapper>
                        </div>
                      )
                    } />
                  </>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='anonimizacaoCheck' name='anonimizacaoCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Anonimização de dados tratados em desconformidade.</Text>
              </S.Checks>
              {
                formik.values.anonimizacaoCheck && (
                  <>
                    <Text size={0} weight={3}>Mais solicitações - Dados de Anonimização</Text>
                    <FieldArray name="anonimizacao" render={
                      (arrayHelpers) => (
                        <div>
                          {
                            formik.values.anonimizacao.map((anonimizacao, index) => (
                              <S.DinamicForm key={index}>
                                <S.FormContainer>
                                  <S.FieldWrapper deviceType={{ type: deviceType }}>
                                    <Text size={0}>Para qual dado de anonimizacao? (*)</Text>
                                    <Selector
                                      name={`anonimizacao[${index}].dataType`}
                                      options={[
                                        { label: 'Nome', value: TIPO_DADO.NOME },
                                        { label: 'Telefone', value: TIPO_DADO.TELEFONE },
                                        { label: 'Celular', value: TIPO_DADO.CELULAR },
                                        { label: 'Endereço', value: TIPO_DADO.ENDERECO },
                                        { label: 'Data de nascimento', value: TIPO_DADO.DATA_NASCIMENTO },
                                        { label: 'Email', value: TIPO_DADO.EMAIL },
                                        { label: 'CPF', value: TIPO_DADO.CPF },
                                      ]}
                                      value={formik.values.anonimizacao[index].dataType}
                                      onSelect={(e) => formik.setFieldValue(`anonimizacao.${index}.dataType`, e.value)}
                                    />
                                    <S.ErrorMessage>{getAnonimizacaoErrors(index)?.dataType}</S.ErrorMessage>
                                  </S.FieldWrapper>
                                  <S.TextboxWrapper>
                                    <Text size={0}>Explicação sobre anonimizacao / Justificativa (*)</Text>
                                    <S.TextAreaModal
                                      name={`anonimizacao[${index}].justification`}
                                      value={formik.values.anonimizacao[index].justification}
                                      onChange={formik.handleChange}
                                    />
                                    <S.ErrorMessage>{getAnonimizacaoErrors(index)?.justification}</S.ErrorMessage>
                                  </S.TextboxWrapper>
                                </S.FormContainer>
                                <S.DeleteWrapper>
                                  <Button
                                    type="button"
                                    btnStatus='delete'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    <S.IconDelete />
                                  </Button>
                                </S.DeleteWrapper>
                              </S.DinamicForm>
                            ))
                          }
                          <S.AddFormWrapper deviceType={{ type: deviceType }}>
                            <Button
                              type="button"
                              btnStatus='primary'
                              onClick={() => arrayHelpers.push({
                                dataType: '',
                                justification: ''
                              })}
                            >
                              Mais campos {<S.IconAdd />}
                            </Button>
                          </S.AddFormWrapper>
                        </div>
                      )
                    } />
                  </>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='revogacaoCheck' name='revogacaoCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Revogação de Consentimento.</Text>
              </S.Checks>
              {
                formik.values.revogacaoCheck && (
                  <>
                    <Text size={0} weight={3}>Mais solicitações - Revogação de Consentimento</Text>

                    <Text size={0}>Informamos que o SPC BRASIL respeita a sua privacidade e a segurança dos
                      seus dados pessoais. Portanto, todos os nossos processos de tratamento de dados pessoais
                      estão em plena conformidade com a Lei nº. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).
                      É importante que você compreenda que os seus dados não são tratados pelo SPC Brasil justificados
                      pelo seu consentimento, uma vez que a LGPD em seu art. 7º apresenta 09 (nove) outras hipóteses de
                      tratamento de dados pessoais, além do consentimento.
                      Para saber mais sobre nossas práticas de privacidade e proteção de dados pessoais, consulte aqui.</Text>

                    <Text size={0}>Se após a leitura da nossa Política de Privacidade você ainda queira encaminhar a
                      sua solicitação para avaliação do nosso Encarregado, por gentileza, nos informe:</Text>
                    <br />
                    <S.FormContainer>
                      <S.TextboxWrapper>
                        <Text size={0}>Explicação sobre revogação de consentimento / Justificativa (*)</Text>
                        <S.TextAreaModal
                          name={`revogacao`}
                          onChange={formik.handleChange}
                        />
                      </S.TextboxWrapper>
                    </S.FormContainer>

                  </>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='eliminacaoConsentimentoCheck' name='eliminacaoConsentimentoCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Eliminação de Consentimento.</Text>
              </S.Checks>
              {
                formik.values.eliminacaoConsentimentoCheck && (
                  <>
                    <Text size={0} weight={3}>Mais solicitações - Eliminação do Consentimento</Text>
                    <Text size={0}>Informamos que o SPC BRASIL respeita a sua privacidade e a
                      segurança dos seus dados pessoais. Portanto, todos os nossos
                      processos de tratamento de dados pessoais estão em plena conformidade com a
                      Lei nº. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).
                      É importante que você compreenda que os seus dados não são tratados pelo SPC Brasil
                      justificados pelo seu consentimento, uma vez que a LGPD em seu art. 7º apresenta 09 (nove)
                      outras hipóteses de tratamento de dados pessoais, além do consentimento.
                      Para saber mais sobre nossas práticas de privacidade e proteção de dados pessoais, consulte aqui.</Text>
                    <Text size={0} weight={3}>Se após a leitura da nossa Política de Privacidade você ainda queira encaminhar a sua
                      solicitação para avaliação do nosso Encarregado, por gentileza, nos informe:</Text>
                    <br />
                    <S.FormContainer>
                      <S.TextboxWrapper>
                        <Text size={0}>Explicação sobre eliminação do consentimento / Justificativa (*)</Text>
                        <S.TextAreaModal
                          name={`eliminacaoConsentimento`}
                          onChange={formik.handleChange}
                        />
                      </S.TextboxWrapper>
                    </S.FormContainer>
                  </>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='revisaoCheck' name='revisaoCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Revisão de Decisões tomadas com base em tratamento automatizado.</Text>
              </S.Checks>
              {
                formik.values.revisaoCheck && (
                  <>
                    <Text size={0} weight={3}>Mais solicitações - Revisão de Decisões tomadas com base em tratamento automatizado</Text>
                    <Text size={0}>É importante que você compreenda que o seu Score no SPC Brasil se baseia em informações sobre você.</Text>
                    <Text size={0}>Utilizamos os seus dados cadastrais e seu relacionamento com o mercado.
                      Tais informações são originadas da nossa base de dados que é alimentada por dados do mercado.</Text>
                    <Text size={0}>Dinâmico, o Score do SPC Brasil é apurado pontualmente, no momento em que é realizada a consulta.
                      Portanto, é esperado variações em sua pontuação.</Text>
                    <br />
                    <S.FormContainer>
                      <S.TextboxWrapper>
                        <Text size={0}>Explicação sobre revisão de decisões tomadas / Justificativa (*)</Text>
                        <S.TextAreaModal
                          name={`revisao`}
                          onChange={formik.handleChange}
                        />
                      </S.TextboxWrapper>
                    </S.FormContainer>
                  </>
                )
              }
            </S.GroupField>
            <S.AddFormWrapper deviceType={{ type: deviceType }}>
              <S.Submit disabled={!isValidForm()} type='submit'>Enviar</S.Submit>
            </S.AddFormWrapper>
          </form>
        </S.FieldSet>
      </FormikProvider>
      <ReCAPTCHA
        sitekey="6LcoYpkiAAAAAN5JHzikMmBCXXkUd9-gMxu_2VAu"
        size='invisible'

        ref={recaptchaRef}
      />
      <form name="formSAE" ref={formSAERef} id="formSAE"
        method="POST" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8">
        <input type="hidden" id="00N1U00000USpvb" name="00N1U00000USpvb" title="Area" value="SAE - Consumidor" />
        <input type="hidden" id="00N1U00000USpw6" name="00N1U00000USpw6" value="LGPD (Atendimento ao titular)" />
        <input value={formSAE.motivo} type="hidden" id="00N1U00000USpw4" name="00N1U00000USpw4" />
        <input value={formSAE.submotivo} type="hidden" id="00N1U00000USpw9" name="00N1U00000USpw9" />
        <input type="hidden" name="orgid" value="00D1U000000FwcW" />
        <input value={formSAE.retURL} type="hidden" name="retURL" />
        <input value={formSAE.cpf} type="hidden" id="00N1U00000USpvk" name="00N1U00000USpvk" />
        <input value={formSAE.name} type="hidden" id="name" name="name" />
        <input value={formSAE.email} type="hidden" id="email" name="email" />
        <input value={formSAE.phone} type="hidden" id="phone" name="phone" />
        <input value={formSAE.subject} type="hidden" id="subject" name="subject" />
        <input value={formSAE.description} type="hidden" id="description" name="description" />
        <input value={formSAE.type} type="hidden" id="type" name="type" />
        <input value={formSAE.captcha_settings} type="hidden" name="captcha_settings" id="captcha_settings" />
        <input value={formSAE.recaptcha_response} type="hidden" name="g-recaptcha-response" id="g-recaptcha-response" />
        <input type="hidden" id="external" name="external" value="1" />
      </form>
    </S.ContainerText>
  )
}

export default MaisSolicitacoes
