import { Text } from 'components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledStatusButton = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    text-decoration: none;

    > * {
      :last-child {
        margin-left: 24px;
        width: 28px;
        height: 28px;
        color: ${theme.color.neutral['8094BC']}
      }
    }
  `}
`;

export const Label = styled(Text)<{ status: string }>`
  ${({ theme, status }) => `
    color: ${theme.color.status[status]};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    & b {
      margin-right: 10px;
      font-weight: 900;
    }

    :before {
      content: ' ';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      position: absolute;
      left: -24px;
      top: 10px;
      background-color: ${theme.color.status[status]};
    }
  `}
`;
