import { FormField } from 'components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { BsCheck2, BsX } from 'react-icons/bs';
import { InputProps } from 'types';
import { formatDate } from 'utils/formatDate';


import { InputField, StyledDate, StyledInput, Wrapper } from './styles';
import useDeviceType from 'hooks/useDeviceType';

const Input = ({
  disabled,
  type = 'text',
  hasStatusIcon,
  helperText,
  id,
  label,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  status = 'default',
  value,
  inputText='',
  onKeyPress,
  ...rest
}: InputProps) => {
  const [inputValue, setInputValue] = useState(value);
  const [inputLabel, setInputLabel] = useState(inputText ?? value);
  const [fieldStatus, setFieldStatus] = useState(status);
  const [fieldHelper, setFieldHelper] = useState(helperText);

  useEffect(() => {
    setInputLabel(inputText);
    setInputValue(value);
    setFieldStatus(status);
    setFieldHelper(helperText);
  }, [value, inputText, status, helperText]);

  const handleChange = (v: string) => {
    setInputValue(v);
    setInputLabel(v);
    (onChange != null) && onChange(v);  
  };

  const handleDateChange = (dateValue: string) => {
    if (dateValue.length === 10) {
      const inputDate = moment(dateValue, 'DD/MM/YYYY').toString();

      if (inputDate != 'Invalid date') {
        handleChange(dateValue);
        setFieldStatus(status);
        setFieldHelper(helperText);
      } else {
        setFieldStatus('error');
        setFieldHelper('Data inválida');
      }
    } else {
      handleChange(dateValue);
      setFieldStatus(status);
      setFieldHelper(helperText);
      setInputValue('');
    }
  };
  const { deviceType } = useDeviceType();

  const showIcon =
    hasStatusIcon &&
    (status === 'error' || status === 'success' || status === 'warning');

  const StatusIcon =
    status === 'success' ?
      BsCheck2 :
      status === 'warning' ?
        AiOutlineWarning :
        BsX;

  return (
    <Wrapper status={fieldStatus} disabled={disabled}>
      <FormField label={label} status={fieldStatus} helperText={fieldHelper}>
        <InputField status={fieldStatus} disabled={disabled} role="group">
          <input type="hidden" value={inputValue} id={id} name={id} />
          {type === 'date' ?
          (
            <StyledDate
              type="text"
              id={`${id}_label`}
              name={`${id}_label`}
              value={inputLabel}
              status={fieldStatus}
              maxLength={10}
              disabled={disabled}
              placeholder={placeholder ?? 'dd/mm/aaaa'}
              onKeyDown={(e)=>{
                formatDate(e);
              }}
              onChange={(e) => {
                handleDateChange(e.target.value);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          ) :
          (<StyledInput
            deviceType={{ type: deviceType }}
            type={type === 'password' ? 'password' : 'text'}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={onFocus}
            id={`${id}_label`}
            name={`${id}_label`}
            onBlur={onBlur}
            status={fieldStatus}
            role="textbox"
            value={inputLabel}
            autoComplete='off'
            onKeyPress={(e) => { 
              onKeyPress && onKeyPress(e);
            }}
          />)}
          {showIcon && <StatusIcon role="img" aria-label={`Status: ${fieldStatus}`} />}
        </InputField>
      </FormField>
    </Wrapper>
  );
};

export { Input };
