import { FormField } from 'components';
import { TextAreaProps } from 'types';
import { StyledTextArea } from './styles';

const TextArea = ({
  helperText,
  label,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  status = 'default',
  value,
  ...rest
}: TextAreaProps) => {
  return (
    <FormField label={label} status={status} helperText={helperText}>
      <div style={{ display: "flex" , flexDirection: "column", alignItems: "start" }}>
      <StyledTextArea
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        status={status}
        value={value}
        {...rest}
        />
        </div>
    </FormField>
  );
};

export { TextArea };
