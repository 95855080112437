import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts';
import { Protocolo as protocolosService } from 'services/Cadastro';

const useQueriesProtocolos = () => {
  const { userData } = useContext(AuthContext);
  const [queriesProtocolos, setQueriesProtocolos] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);  

  const data: any = async () => {
    if (userData){
      setIsLoading(true);
      await protocolosService.getEvento({
        documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
      }).then((response) => {      
        setQueriesProtocolos(response.data);
        window.localStorage.setItem('Queries Protocolos', JSON.stringify(response));
        setIsLoading(false);
      }).catch((error) => {
        if ( error.response ) {
          console.log(error.response.data);
        }        
        setIsLoading(false);
      });
    }
  };

  const getEventos: any = async (filtro: any) => {
    if (userData){
      setIsLoading(true);

      const props = (filtro.dataInicio && filtro.dataFim) ? 
        {
          documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
          protocolo: filtro.protocolo,
          dataInicio: filtro.dataInicio,
          dataFim: filtro.dataFim,
        }
      : 
        {
          documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
          protocolo: filtro.protocolo         
        }
        ;

      await protocolosService.getEvento(props).then((response) => {      
        setQueriesProtocolos(response.data);
        window.localStorage.setItem('Queries Protocolos', JSON.stringify(response));
        setIsLoading(false);
      }).catch((error) => {
        if ( error.response ) {
          console.log(error.response.data);
        }        
        setIsLoading(false);
      });
    }
  };


  const queriesList = (data: any) => {    
    if (data && data.length > 0){
      return data.map((item: any) => {      

        const obj = Object.assign({}, item);       
        
        const dataEvento = obj['dataEvento'];      
        const dia = (dataEvento) ? Number(new Date(dataEvento).toLocaleDateString().split('/')[0]) : 0 ;
        const mes= (dataEvento) ? Number(new Date(dataEvento).toLocaleDateString().split('/')[1])-1 :  0 ;//date 1 posicao acima
        const ano = (dataEvento) ? Number(new Date(dataEvento).toLocaleDateString().split('/')[2]): 0 ;

        obj['dataEvento'] = (dataEvento) ? new Date(ano, mes, dia).toISOString()  : '';
        obj['title'] = 'Protocolo nº: ' + obj['protocoloAtendimento'];
        obj['description'] = 'Solicitação : ' + obj['tipoEvento'];
        obj['hasTime'] = false;
        obj['time'] = new Date(obj['dataEvento']).toLocaleTimeString('pt-BR', {
          hour: '2-digit',
          minute: '2-digit',
        });
        obj['status'] = (dataEvento) ? new Date(dataEvento).toLocaleDateString() : '';
        return obj;
      });
    }else{
      return []
    }
  };

  const getDayOfMonth = (date: any) =>{
    const dateIso = new Date(date).getDate() + 1;
    return dateIso;
  };

  const getMonthFromTimestamp = (date: any) =>{
    const dateIso = new Date(date);
    const month = dateIso.toLocaleString('pt-br', { month: 'short' });
    return month;
  };

  const groups = queriesList(queriesProtocolos).reduce((groups: any, queries: any) => {
    const date = queries.dataEvento.split('T')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(queries);
    return groups;
  }, {});

  const queriesProtocolosList = Object.keys(groups).map((date: any) => {
    return {
      day: getDayOfMonth([date]),
      month: getMonthFromTimestamp([date]),
      query: groups[date],
    };
  });

  const finalResults = window.localStorage.setItem('SPC_Protocolos_List', JSON.stringify(Object.values(queriesProtocolosList)));

  useEffect(() => {
    data();
    const list = window.localStorage.getItem('Queries Protocolos');
    if ( list !== null ) setQueriesProtocolos(JSON.parse(list));
  }, []);

  return { getEventos, queriesProtocolosList, isLoading }  
};

export default useQueriesProtocolos;
