export const listComoMelhorar = [
  {
    title: 'Mantenha o seu Cadastro Positivo ativo',
    description: 'O Cadastro Positivo funciona como um histórico do seu comportamento de crédito.',
  },
  {
    title: 'Pague as contas em dia',
    description: `Além de ajudar você a ter uma relação melhor com o seu dinheiro, acompanhar as 
    finanças de perto e manter o controle das despesas são maneiras de não se 
    perder nas datas de pagamento.`,
  },
  {
    title: 'Controle seus pedidos de crédito',
    description: `Ao solicitar ou realizar simulações em vários locais ao mesmo tempo, pode 
    representar risco de endividamento.`,
  },
];
