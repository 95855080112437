import { api } from './api';

export const vantagens = {

  getCarrossel: async () => {
    try {
      const res = await api().get(
        `/cadastropositivo-parametro-geral/carrossel`,
      );

      return res.data;
    } catch (err) {
      return { error: 'something went wrong' };
    }
  },

  getVantagens: async () => {
    try {
      const res = await api().get(
        `/cadastropositivo-parametro-geral/vantagens/`,
      );

      return res.data;
    } catch (err) {
      return { error: 'something went wrong' };
    }
  },
};

