import { Button, LoadingSpinner, Selector } from 'components';
import { AuthContext } from 'contexts';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { contestacao as contestService, history as historyService } from 'services/Cadastro';
import { formatDate } from 'utils/formatDate';
import { formatUnixTime } from 'utils/formatUnixTime';
import { ButtonContainer } from '../ContestForm/styles';
import moment from 'moment';
import { DateSelect, OperationInfo, ParcelInput, PriceInput, Title } from './styles';
import CurrencyInput from "react-intl-currency-input"
import { SourceContainer } from '../ContestClient/styles';
import useDeviceType from 'hooks/useDeviceType';

export interface Option {
  label: string
  value: string | number
}

const modalityDictionary: { [name: string]: string } = {
  CARTAO_CREDITO: 'C',
  CREDITO_PARCELADO: 'P',
  CREDITO_ROTATIVO: 'R',
  CONSORCIO: 'S',
  TELCOS: 'T',
  ENERGIAS: 'E',
};

const detailsFactory = {
  id: '',
  nomeComercialFonteOrigem: '',
  numeroContrato: '',
  dataContratacao: 0,
  modalidade: '',
  tipoDocumentoConsumidor: '',
  numeroDocumentoConsumidor: '',
  tipoFonteOrigem: '',
  idFonte: 0,
  codigoModalidade: ''
};

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const ContestOperation = ({ onSubmit }: { onSubmit: (data: any) => void }) => {
  const { userData, statusCadPos } = useContext(AuthContext);
  const [details, setDetails] = useState(detailsFactory);
  const [motive, setMotive] = useState<Option>({ label: '', value: 0 });
  const [motiveList, setMotiveList] = useState<Option[]>([]);
  const [dateRef, setDateRef] = useState<Option[]>([]);
  const [correctDate, setCorrectDate] = useState('');
  const [price, setPrice] = useState('');
  const [parcel, setParcel] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [formType, setFormType] = useState('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');
  const modality = urlParams.get('modality') ?? '';
  const invoiceID = urlParams.get('invoiceID') ?? '';

  const getUniqueValues: any = (data: any) => {
    const key = 'value';
    const unique = [...new Map(data.map((item: any) =>
      [item[key], item])).values()];
    return unique
  }

  useEffect(() => {
    setLoading(false);
  }, [statusCadPos]);

  useEffect(() => {
    if (userData && id) {
      setLoading(true);
      if (userData.cpf !== "" && userData.cpf !== undefined) {
        historyService.details[modality]('CPF', userData.cpf, id).then((res) => setDetails(res.data)).catch(() => { setLoading(false); });
      } else {
        historyService.details[modality]('CNPJ_COMPLETO', userData.cnpj, id).then((res) => setDetails(res.data)).catch(() => { setLoading(false); });
      }
    }
  }, [userData, id]);

  useEffect(() => {
    if (details.modalidade) {
      setLoading(true);
      contestService.getMotives(modalityDictionary[details.modalidade])
        .then((res) => {
          formatMotives(res.data)
            .then((res) => {
              setMotiveList(res.motives);
              setDateRef(getUniqueValues(res.dates));
              setLoading(false);
            });
        }).catch(() => { setLoading(false); });
    }
  }, [details]);

  useEffect(() => {
    setCorrectDate('');
    setPrice('');
    setParcel(0);
    setSelectedDate('');
  }, [motive]);

  useEffect(() => {
    // Agrupoando os possiveis IDs de motivos recebidos do back-end em formatos comuns de formulario
    if ([49, 55, 58, 64, 67, 73, 77, 85].includes(Number(motive.value))) {
      // Não Reconheco esse contrato | Quantidade de parcelas pagas divergentes
      setFormType('CLEAR_FORM');
    } else if ([51, 57, 60, 63, 66, 69, 75, 78, 81, 86, 89].includes(Number(motive.value))) {
      // Valor Pago Incorreto | Valor da parcela incorreto | Saldo utilizado incorreto | Valor da fatura incorreto
      setFormType('DATE_PRICE_FORM');
    } else if ([52, 61, 70, 79, 82, 87, 90].includes(Number(motive.value))) {
      // Data de pagamento incorreta | Data de vencimento incorreta
      setFormType('CORRECT_DATE_FORM');
    } else if ([53, 62, 71, 80, 88].includes(Number(motive.value))) {
      // Contrato pago consta parcela em aberto | Fatura paga consta em aberto
      setFormType('DATE_FORM');
    } else if ([54, 72].includes(Number(motive.value))) {
      // Quantidade de parcelas contratadas divergentes
      setFormType('PARCEL_FORM');
    } else if ([56, 74].includes(Number(motive.value))) {
      // Valor contratado incorreto
      setFormType('PRICE_FORM');
    }
  }, [motive]);

  const getMotive = (value: { label: string, value: string | number }) => {
    setMotive(value);
  };

  const getCodigoTipoFonte = (modality: string) => {
    if (modality.includes('ENERGIAS')) {
      return 'ENERGIAS';
    }
    if (modality.includes('TELCOS')) {
      return 'TELCOS';
    }
    return 'INSTITUICAO_FINANCEIRA';
  };


  const getDate = (value: { label: string, value: string | number }) => {
    setSelectedDate(value.label);
  };

  const formatMotives = (list: { id: number, codigoMotivo: string, descricaoMotivo: string, arvoreMotivos: any[] }[]) => {
    return new Promise<{ motives: Option[], dates: Option[] }>((resolve) => {
      const formattedList: Option[] = [];
      const dateRefList: Option[] = [];

      for (const motive of list) {
        if (!motive.codigoMotivo.includes('X')) {
          formattedList.push({
            label: motive.descricaoMotivo,
            value: motive.id,
          });
        }

        motive.arvoreMotivos.map((type) => {
          if (type.valor === 'DATA_REF') {
            for (const date of type.datasReferencia) {
              dateRefList.push({
                label: date,
                value: date,
              });
            }
          }
        });
      }

      resolve({ motives: formattedList, dates: dateRefList });
    });
  };

  const handleData = () => {
    return {
      arvoreMotivos: {
        contestacaoTipoData: formType === 'CORRECT_DATE_FORM' ? moment(correctDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
        contestacaoTipoValor: ['DATE_PRICE_FORM', 'PRICE_FORM'].includes(formType) ? price : null,
        contestacaoTipoDataReferencia: ['DATE_PRICE_FORM', 'CORRECT_DATE_FORM', 'DATE_FORM'].includes(formType) ? selectedDate : null,
        contestacaoTipoQuantidadeDeParcelas: formType === 'PARCEL_FORM' ? parcel : null,
      },
      cnpj: ['CPF', 'PF'].includes(details.tipoDocumentoConsumidor) ? null : details.numeroDocumentoConsumidor,
      codigoMotivoJornadaCliente: null,
      codigoTipoFonte: getCodigoTipoFonte(details.modalidade),
      cpf: ['CPF', 'PF'].includes(details.tipoDocumentoConsumidor) ? details.numeroDocumentoConsumidor : null,
      idFonte: details.idFonte,
      idMotivoContestacao: motive.value,
      idOperacao: details.id,
      modalidadeOperacao: modalityDictionary[details.modalidade],
      numeroContrato: details.numeroContrato,
      ...(invoiceID ? { numeroFatura: invoiceID } : {}),
      tipoConsumidor: ['CPF', 'PF'].includes(details.tipoDocumentoConsumidor) ? 'F' : 'J',
      tipoContestacao: 'C',
      codigoModalidade: details.codigoModalidade,
    };
  };

  const verifyCompletion = () => {
    return (
      formType === 'CLEAR_FORM' ||
      (formType === 'DATE_PRICE_FORM' && selectedDate !== '' && price !== '') ||
      (formType === 'CORRECT_DATE_FORM' && selectedDate !== '' && correctDate.length === 10) ||
      (formType === 'DATE_FORM' && selectedDate !== '') ||
      (formType === 'PARCEL_FORM' && parcel !== 0) ||
      (formType === 'PRICE_FORM' && price !== '')
    );
  };

  const handleChangePrice = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    event.preventDefault();

    setPrice(value);
  };

  const handleParcel = (value: string) => {
    if (value.length <= 3) {
      setParcel(Number(value));
    }
  };

  const { deviceType } = useDeviceType();

  return (
    <>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <Title>Você está contestando:</Title>
      <OperationInfo deviceType={{ type: deviceType }}>
        <h4>Resumo da operação:</h4>
        <p>Fonte: <b>{details.nomeComercialFonteOrigem}</b></p>
        <p>Nº do contrato: <b>{details.numeroContrato}</b></p>
        <p>Data da contratação: <b>{formatUnixTime(details.dataContratacao)}</b></p>
        {
          (details?.modalidade === "TELCOS" || details?.modalidade === "ENERGIAS") && invoiceID ? (
            <p>Nº da fatura: <b>{invoiceID}</b></p>
          ) : ("")
        }

      </OperationInfo>
      <SourceContainer deviceType={{ type: deviceType }}>
        <h3>Selecione o motivo da contestação:</h3>
        <Selector options={motiveList} onSelect={getMotive} />
      </SourceContainer>

      {['DATE_PRICE_FORM', 'CORRECT_DATE_FORM', 'DATE_FORM'].includes(formType) &&
        <SourceContainer deviceType={{ type: deviceType }}>
          <h3>Data de Referência:</h3>
          <DateSelect>
            <Selector options={dateRef} onSelect={getDate} />
          </DateSelect>
        </SourceContainer>
      }

      {['DATE_PRICE_FORM', 'PRICE_FORM'].includes(formType) &&
        <SourceContainer deviceType={{ type: deviceType }}>
          <h3>Valor Correto:</h3>
          <PriceInput>
            R$:
            <CurrencyInput
              currency='BRL'
              max={999999999.99}
              config={currencyConfig}
              onChange={handleChangePrice}
            />
          </PriceInput>
        </SourceContainer>
      }

      {formType === 'CORRECT_DATE_FORM' &&
        <SourceContainer deviceType={{ type: deviceType }}>
          <h3>Data Correta:</h3>
          <ParcelInput>
            <input
              id='dataCorreta'
              type='date'
              placeholder='dd/mm/aaaa'
              min="2022-01-01"
              max="2030-12-31"
              value={correctDate}
              maxLength={10}
              onKeyDown={(e: any) => formatDate(e)}
              onChange={(e: any) => setCorrectDate(e.target.value)}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </ParcelInput>
        </SourceContainer>
      }

      {formType === 'PARCEL_FORM' &&
        <SourceContainer deviceType={{ type: deviceType }}>
          <h3>Quantidade de Parcelas:</h3>
          <ParcelInput>
            <input
              id='parcel'
              type='number'
              min='1'
              maxLength={3}
              value={parcel}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleParcel(e.target.value)}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </ParcelInput>
        </SourceContainer>
      }

      {/*<ButtonContainer show={verifyCompletion()}>*/}
      {/*  <Button onClick={() => onSubmit(handleData())}>Contestar</Button>*/}
      {/*</ButtonContainer>*/}
    </>
  );
};
