import { Button, LoadingSpinner, PageSkeleton, Text } from 'components';
import MessageAlert from 'components/MessageAlert';
import { AuthContext, CadastroContext, RequestStatusContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filter } from './components/Filter';
import { HistoryList } from './components/HistoryList';
import { AlertContainer, Container, FilterContainer, Footer, Title } from './styles';
import useDeviceType from 'hooks/useDeviceType';

const Liberacao = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const { history, getPermissions, historyMsg } = useContext(CadastroContext);
  const [loading, setLoading] = useState(true);
  const { addRequest } = useContext(RequestStatusContext);
  const { deviceType } = useDeviceType();

  const permissionsList = async (userData: any) => {
    try {
      if (userData.cpf !== "" && userData.cpf !== undefined) {
        userData.cpf && getPermissions({ 'cpfConsumidor': userData.cpf || '', cnpjConsumidor: '' })
      } else {
        userData.cnpj && getPermissions({ 'cnpjConsumidor': userData.cnpj || '', cpfConsumidor: '' })
      }
      setLoading(false);
      return;
    } catch (err) {
      setLoading(false);
      return { error: 'Erro ao carregar os dados, favor repetir a requisição' };
    }
  }

  const loadPermissions = () => {
    setLoading(true);
    permissionsList(userData).catch((e) => {
      addRequest({
        statusCode: e?.response?.status,
        message: 'Erro ao carregar os dados, favor repetir a requisição',
      });
      navigate('/cadastro-positivo/liberacao-historico')
      setLoading(false);
    })
    setLoading(false);
  }


  useEffect(() => {
    loadPermissions()
  }, [userData]);

  
  return (
    <PageSkeleton
      title="Liberação aos Pagamentos"
      breadcrumb={{
        menuName: 'Início',
        submenuName: 'Cadastro Positivo',
        thirdLevel: 'Liberação aos Pagamentos',
      }}
      highlight={{
        title: `Aqui, você pode permitir que empresas específicas visualizem
        todo o seu histórico de pagamentos.`,
        description: `Ao liberar seus pagamentos para uma empresa, ela terá acesso à eles durante o tempo determinado na liberação.`,
        image: require('../../../assets/images/liberacao-pagamentos-1.png'),
      }}
    >
      <Container>
        <div>
          <LoadingSpinner state={loading} />
        </div>
        <Title deviceType={{ type: deviceType }}>
          <Text tag="h4" size={2} weight={3} lineHeight={1} color="text">
            Empresas liberadas
          </Text>
        </Title>

        <FilterContainer deviceType={{ type: deviceType }}>

        {history?.length == 0 && <Filter />}

        {history?.length > 0 ? (
          <>
            <Filter />
            <HistoryList historyList={history} onLoad={() => setLoading(false)} />
          </>
        ) : historyMsg == "" ? (<MessageAlert data={history} filter={false} />) : (
          <AlertContainer deviceType={{ type: deviceType }}>
            <Text size={1} lineHeight={1} weight={2}>{historyMsg}</Text>
          </AlertContainer>
        )
        }
      </FilterContainer>

      <Footer deviceType={{ type: deviceType }}
>
        <Text size={1} lineHeight={1} weight={2}>Deseja realizar uma nova liberação?</Text>
        <Button onClick={() => navigate('/cadastro-positivo/liberar-historico-pagamento')}>Liberar histórico de pagamentos</Button>
      </Footer>
    </Container>
    </PageSkeleton >
  );
};

export default Liberacao
