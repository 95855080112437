import { Container } from 'components';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function Cadastro() {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    path === '/cadastro-positivo' && navigate('/home/score');
  }, [path]);

  return <Container>
    <Outlet />
  </Container>;
}
