import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledListIcon = styled.div`
  ${({ theme }) => `
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 8px 0;
    
    & {
      :first-child {
        margin-top: 24px;
      }
      :not(:last-child) {
        border-bottom: 1px solid ${theme.color.lightGray['E5E5E5']};
      }
    }
  `}
`;

export const IconContainer = styled.div<{ deviceType?: DeviceTypeProps }>`

${({ theme, deviceType }) => `
    svg {
      width: ${deviceType?.type === 'mobile' || deviceType?.type === 'tablet' || deviceType?.type === 'mobile-landscape' ? '32px' : '48px'};
      height: ${deviceType?.type === 'mobile' || deviceType?.type === 'tablet' || deviceType?.type === 'mobile-landscape' ? '32px' : '48px'};
    }
    margin-right: 10px;
    color: ${theme.color.blue['03428D']};
  `}
`;

export const Content = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme, deviceType }) => `
    position: relative;
    flex: 1;

    p {
      :last-child {
        font-size: ${deviceType?.type === 'mobile' ? '14px' : '16px'};
        color: ${theme.color.darkGray['797979']};
      }
    }
`}
`;
