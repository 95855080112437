import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts';
import { bloqueio as bloqueioService } from 'services/Cadastro/bloqueio';

const useBlockedHistory = () => {
  const { userData } = useContext(AuthContext);
  const [companies, setCompanies] = useState<any>([]);
  const [isBloqued, setIsBloqued] = useState(Boolean);
  const [isLoading, setIsLoading] = useState<boolean>();
  
  const getCompanyData = useCallback(async (params?: any) => {    
    if (params){ 
      setIsLoading(true);
      params.documento = (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj;      
      await bloqueioService.companies(params)
        .then((response) => {
          setCompanies(response.data)
          setIsLoading(false);
        }).catch((error) => {
          setIsLoading(false);
          if (error.response) {
            console.log(error.response.data);
          }
        });
    }
  }, []);

  const getIsBloqued = useCallback(async () => {
      const documento = (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj;
      await bloqueioService.isBlocked(documento ?? '')
        .then((response) => {          
          setIsBloqued(response.data)
        }).catch((error) => {
          if (error.response) {
            console.log(error.response);
          }
        });
    }, []);

  useEffect(() => {
    if (userData) {
      getCompanyData()
    }
  }, [userData, getCompanyData, getIsBloqued]);

  return { isBloqued, isLoading, companies, getCompanyData, getIsBloqued }
};

export default useBlockedHistory
