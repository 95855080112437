import { Text } from 'components/Text';
import { useContext, useEffect, useState } from 'react';
import type { MenuType, SubmenuItem } from 'types';
import { StyledLinkItem, StyledListItem, StyledNavList } from '../styles';
import { SubmenuHeader } from './SubmenuHeader';
import useDeviceType from 'hooks/useDeviceType';

export const MenuHeader = ({
  menu,
  device,
  onSelectMenu
}: {
  menu?: MenuType;
  device?: string;
  onSelectMenu?: () => void;
}) => {
  const [menuName, setMenuName] = useState('');
  const [submenuName, setSubmenuName] = useState('');
  const [isMenuActive, setMenuActive] = useState(false);

  const handleMenuClick = (newItemOpen: number, menuName: string, menu?: Array<SubmenuItem>) => {

    if (device === 'mobile') {
      setMenuName(menuName);  
      setMenuActive(!isMenuActive);

      if(!menu) {
        if(onSelectMenu)
        onSelectMenu();
      }
    }

  };

  const handleSubmenuClick = (itemOpen: number, menuName: string) => {
    handlerMenuOut()
    setSubmenuName(menuName);
 
  };

  const handlerMenuOver = () => {
    if (device !== 'mobile') {
      setMenuActive(true);
    }
  };

  const handlerMenuOut = () => {
    setMenuActive(false);
  };

  const handleClickSubMenu = () => {
    if(onSelectMenu)
    onSelectMenu();
  }

  useEffect(() => {
    setSubmenuName('');
  }, [menuName]);

  const { deviceType } = useDeviceType();

  return (
    <>
      <StyledNavList deviceType={{ type: deviceType }}>
        {menu &&
          menu.map((item, index) => (
            <StyledLinkItem
              key={index}
              to={item.url}
              onMouseOver={() => handlerMenuOver()}
              onMouseOut={() => handlerMenuOut()}
              onClick={() => handleMenuClick(index, item.label, item.submenu)}
            >
              <StyledListItem deviceType={{ type: deviceType }}>
                {item.icon}
                <Text>{item.label}</Text>
              </StyledListItem>
              {item?.submenu && (
                <SubmenuHeader
                  device={'device'}
                  menuIndex={index}
                  submenuList={item.submenu}
                  onSubmenuClick={handleSubmenuClick}
                  isActive={isMenuActive}
                  onClick={handleClickSubMenu}
                />
              )}
            </StyledLinkItem>
          ))}
      </StyledNavList>
    </>
  );
};