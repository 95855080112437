import React from 'react'

const TermoReaberturaPJ = ({ data }: any) => {

    const { businessName, commercialName, cnpj, date, representativeName, representativeCPF,
    representativeRg } = data

  return (
    <>
      <h3 style={{ textAlign: 'center' }}>TERMO DE AUTORIZAÇÃO PARA REABERTURA DE CADASTRO POSITIVO</h3>
      <br />
      <div style={{ lineHeight: '1.3rem' }}>
        <strong>1 -</strong> Por este instrumento particular, <strong>{businessName}</strong>, inscrito no CNPJ n° {cnpj} solicito a <strong>REABERTURA</strong> de meu <i>cadastro positivo</i> aberto nos termos do artigo 5°, I da Lei no. 12.414/2011 e do Decreto no. 9936/2019 constituído pelo meu histórico de crédito relativo às obrigações assumidas por mim perante quaisquer pessoas jurídicas ou naturais com as quais eu mantenho/pretendo manter relação comercial ou creditícia, abrangendo os dados financeiros e de pagamentos relativos às operações de crédito e operações de pagamento adimplidas em seus respectivos vencimentos ou em atraso, e aquelas a vencer, no Banco de Dados abaixo indicado:
      </div>
      <br />
      <div style={{ lineHeight: '1.3rem' }}><strong>2 -</strong> Identificação do Representante Legal:</div>
      <div>
        Nome: {representativeName}
        <br />
        RG: {representativeRg}
        <br />
        CPF: {representativeCPF}
      </div>
      <br />
      <div style={{ lineHeight: '1.3rem' }}
      ><strong>3 -</strong> Identificação do(s) Banco(s) de Dados originário(s) indicado(s):</div>
      <div>
        SPC BRASIL
        <div>
          CNPJ/MF nº: 34.173.682/0003-18
          <br />
          Endereço: Rua Leôncio de Carvalho, nº 234 – 13º andar –Paraíso– São Paulo/SP – CEP 04.003-010
        </div>
      </div>
      <br />
      <div style={{ lineHeight: '1.3rem' }}
      ><strong>4 -</strong> Saliento, outrossim, nos termos da legislação aplicável em vigor, ter pleno conhecimento de que o presente requerimento ensejará o cancelamento de meu Cadastro Positivo referente ao meu histórico de crédito mantido junto ao Banco de Dados supracitado, bem como de outros Gestores de Banco de Dados do mercado, sendo compartilhado no prazo de até dois dias 2 uteis.</div>
      <br />
       <div style={{ lineHeight: '1.3rem' }}
      ><strong>5 -</strong> Estou ciente de que poderei realizar a reabertura do Cadastro Positivo, a qualquer tempo, esta solicitação, perante a entidade receptora desta autorização para o cancelamento do cadastro ou perante o gestor do banco de dados detentor das informações.
      </div>
      <br />
      <div style={{ lineHeight: '1.3rem' }}
      ><strong>6 -</strong> Por derradeiro, declaro ter lido e compreendido, integralmente, o sentido do presente requerimento, estando de pleno acordo com o pedido ora apresentado.</div>
      <br />
      <div>
        São Paulo, {date}.
      </div>
    </>
  )
}

export default TermoReaberturaPJ
