import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const AlertContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme, deviceType }) => `

    width: ${deviceType?.type === 'mobile' ? 'auto' : '94%'};
    padding: 0.5rem;
    border-radius: 16px;
    background: ${theme.color.lightGray['D5DDED']}3F;
    border: 1px solid ${theme.color.lightGray['BCBCBC']};
    display: flex;
    align-items: center;
    margin: 16px 0;
    justify-content: center;
    & p{
      text-align: center;
    }
  `}
`;
