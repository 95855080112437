import { createContext, useMemo, useState } from 'react';

import { RequestStatusProps, RequestStatusType } from './types';

export const RequestStatusContext = createContext<RequestStatusProps>({
  requestStack: [],
  addRequest: () => null,
});

export const RequestStatusContextProvider = ({ children }: any) => {
  const [requestStack, setRequestStack] = useState<Array<RequestStatusType>>([]);
  const getRequestStatus = (status: number) => {
    let statusColor = '';
    if ([200, 201].includes(status)) {
      statusColor = 'success';
    } else {
      statusColor = 'error';
    }
    return statusColor;
  };

  const addRequest = (requestData: RequestStatusType) => {
    const alertStatus = getRequestStatus(requestData.statusCode);
    setRequestStack([...requestStack, { ...requestData, statusColor: alertStatus }]);
  };
  // "Falha ao executar a sua solicitação, favor tentar novamente mais tarde" -- Erros 400 e pouco
  const value = useMemo(() => ({
    requestStack,
    addRequest,
  }), [requestStack]);


  return <RequestStatusContext.Provider value={value}>{children}</RequestStatusContext.Provider>;
};
