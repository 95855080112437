import { ButtonContainer, MessageButton, MessageContainer, StyledConfirmationBox } from './styles';
import { ConfirmationBoxProps } from 'types';
import { useState } from 'react';
import useDeviceType from 'hooks/useDeviceType';

const ConfirmationBox = ({ message, onDeny }: ConfirmationBoxProps) => {
  const [showBox, setShowBox] = useState(true);

  const handleConfirm = () => {
    setShowBox(false);
    window.location.reload();
  };

  const handleDeny = () => {
    if (onDeny) {
      onDeny();
    }
  };
  const { deviceType } = useDeviceType();

  return (
    <StyledConfirmationBox show={showBox} deviceType={{ type: deviceType }}    >
      <MessageContainer deviceType={{ type: deviceType }}>
        {message}
      </MessageContainer>
      <ButtonContainer deviceType={{ type: deviceType }} >
        <MessageButton deviceType={{ type: deviceType }}confirm={true} onClick={handleConfirm}>Sim</MessageButton>
        <MessageButton deviceType={{ type: deviceType }} confirm={false} onClick={handleDeny}>Não</MessageButton>
      </ButtonContainer>
    </StyledConfirmationBox>
  );
};

export { ConfirmationBox };
