import { QueryInfoItem, Text } from 'components';
import { QueryInfoListProps } from 'types';
import { DateContainer, StyledQueryInfoList } from './styles';
import useDeviceType from 'hooks/useDeviceType';

const QueryInfoList = ({ day, month, queryList }: QueryInfoListProps) => {
  const { deviceType } = useDeviceType();

  return (
    <StyledQueryInfoList deviceType={{ type: deviceType }}>
      { (queryList?.length > 0)? 
      (<DateContainer deviceType={{ type: deviceType }}>
        <Text tag='h3' size={10} weight={3} lineHeight={2} color='warning'>{day}</Text>
        <Text tag='h4' weight={1} color='title'>{month}</Text>
      </DateContainer> ) :
      (<>
      </>)
      }
      { queryList.map((item, index) => (

        <QueryInfoItem
          key={index}
          {...item}
        />
      ))}
    </StyledQueryInfoList>
  );
};

export { QueryInfoList };
