import useDeviceType from 'hooks/useDeviceType';
import { DetailBlock } from 'pages/Cadastro/HistoricoPagamentos/components/OperationDetails/style'
import { documentFormatter } from 'utils/documentFormatter'
import { formatUnixTime } from 'utils/formatUnixTime'

const Consorcio = ({ details }: any) => {
  const { deviceType } = useDeviceType();

  return (
    <>
      <DetailBlock deviceType={{ type: deviceType }}>
        <h1>Dados da Fonte de Informação</h1>
        <p>
          <b>Nome da fonte: </b>
          <span>{details?.nomeComercialFonteOrigem ?? ''}</span>
        </p>
        <p>
          <b>CNPJ da fonte: </b>
          <span>{details?.cnpjFonteOrigemFormatado ?? (documentFormatter(details?.cnpjFonteOrigem?.toString() ?? ''))}</span>
        </p>
      </DetailBlock>
      <DetailBlock deviceType={{ type: deviceType }}>
        <h1>Dados do Contrato</h1>
        <p>
          <b>Data da contratação: </b>
          <span>{formatUnixTime(details?.dataContratacao ?? '')}</span>
        </p>
        <p>
          <b>Número do grupo: </b>
        <span> {details?.numeroGrupo ?? ''}</span>
        </p>
        <p>
          <b>Código da cota: </b>
        <span> {details?.codigoCota ?? ''}</span>
        </p>
        <p>
          <b>Sequência da cota: </b>
          <span>{details?.sequenciaCota ?? ''}</span>
        </p>
        <p>
          <b>Situação da cota: </b>
          <span>{details?.situacaoCota ?? ''}</span>
        </p>

        <p>
          <b>Data da contemplação: </b>
          <span>{formatUnixTime(details?.dataContemplacaoCota ?? '')}</span>
        </p>
        <p>
          <b>Valor total do contrato: </b>
          <span>{details?.valorContratado?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }) ?? ''}</span>
        </p>
        <p>
          <b>Data do vencimento da última parcela: </b>
         <span>{formatUnixTime(details?.dataVencimentoUltimaParcela ?? '')}</span>
        </p>
        <p>
          <b>Quantidade das parcelas: </b>
          <span>{details?.quantidadeParcela ?? ''}</span>
        </p>
        <p>
          <b>Valor do saldo devedor: </b>
        <span> {details?.saldoDevedor ?? ''}</span>
        </p>
        <p>
          <b>Data da última atualização: </b>
          <span>{formatUnixTime(details?.dataAlteracao)}</span>
        </p>
      </DetailBlock>
    </>
  )
}

export default Consorcio


