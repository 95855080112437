import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts';
import { consultas as consultasService } from 'services/Cadastro';

const useQueriesAccomplished = () => {  
  const { userData } = useContext(AuthContext);
  const [queriesAccomplished, setQueriesAccomplished] = useState<any>([]);

  const data: any = async () => {
    await consultasService.getQueriesAccomplished(
      (userData.cpf !== "" && userData.cpf !== undefined)?
      { cpf: userData?.cpf }
      :
      { cnpj: userData?.cnpj }    
    ).then((response) => {
      setQueriesAccomplished(response);
      window.localStorage.setItem('Queries Accomplished', JSON.stringify(response));
    }).catch((error) => {
      if ( error.response ) {
        console.log(error.response.data);
      }
    });
  };

  const queriesList = (data: any) => {    
    return data.map((item: any) => {
      const obj = Object.assign({}, item);
      obj['dataConsulta'] = new Date(obj['dataConsulta']).toISOString();
      obj['title'] = obj['nomeAssociado'];
      obj['description'] =obj['cnpjFormatado'];
      obj['hasTime'] = true;
      obj['time'] = new Date(obj['dataConsulta']).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      });
      delete obj['nomeAssociado'];
      delete obj['cnpjFormatado'];
      return obj;
    });
  };

  const getDayOfMonth = (date: any) =>{
    const dateIso = new Date(date).getDate() + 1;
    return dateIso;
  };

  const getMonthFromTimestamp = (date: any) =>{
    const dateIso = new Date(date);
    const month = dateIso.toLocaleString('pt-br', { month: 'short' });
    return month;
  };

  const groups = queriesList(queriesAccomplished).reduce((groups: any, queries: any) => {
    const date = queries.dataConsulta.split('T')[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(queries);
    return groups;
  }, {});

  const queriesAccomplishedList = Object.keys(groups).map((date: any) => {
    return {
      day: getDayOfMonth([date]),
      month: getMonthFromTimestamp([date]),
      query: groups[date],
    };
  });

  const finalResults = window.localStorage.setItem('SPC_Historico_consulta_List', JSON.stringify(Object.values(queriesAccomplishedList)));

  useEffect(() => {
    {(userData.cpf || userData.cnpj) && data()}
    const list = window.localStorage.getItem('Queries Accomplished');
    if ( list !== null ) setQueriesAccomplished(JSON.parse(list));
  }, [userData]);

  return Object.values(queriesAccomplishedList);
};

export default useQueriesAccomplished;
