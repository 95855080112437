import { api } from './api';

export interface EventoProps {
  cpf?: string
  cnpj?: string
  tipoCliente: string
  tipoEvento: string
  descricaoInformacao?: string
}

export const Protocolo = {

  createEvento: async (params: EventoProps) => {
    const res = await api().post(
      '/cadastropositivo-eventos/eventos/v2/cria-evento/',
      params);
    return res.data;
  },

  getEvento: async (params: any) => await api()
    .get('/cadastropositivo-eventos/eventos/listar-eventos/',
    { params }),

};
