import { LoadingSpinner, PageSkeleton, Text } from 'components';
import { AuthContext, CadastroContext } from 'contexts';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { ContentContainer, KeyContainer, StyledKeyValue } from './styles';
import useDeviceType from 'hooks/useDeviceType';
import { DeviceTypeProps } from 'types/Responsive.types';

type KeyValueProps = {
  textKey: string
  textVal: string
  deviceType?: DeviceTypeProps
}
const KeyValue = ({ textKey, textVal, deviceType }: KeyValueProps) => (
  <StyledKeyValue deviceType={deviceType} >
    <Text weight={3} >{textKey}: {' '} </Text>
    <Text >{textVal}</Text>
  </StyledKeyValue>
);


export default function Comunicacao() {
  const { deviceType } = useDeviceType();
  const { userData } = useContext(AuthContext);
  const { communication, isLoading, getCommunication } = useContext(CadastroContext);
  const comDate = new Date(communication.dataComunicacao);
  const comStatus = `${communication.indicadorComunicacao == 'SIM' ? 'Comunicado' : 'Não Comunicado'}`;
  const formattedDate = moment(comDate).format('DD/MM/YYYY') == 'Data inválida' ?
    '' :
    moment(comDate).format('DD/MM/YYYY');

  useEffect(() => {
    if (userData.cpf !== "" && userData.cpf !== undefined) {
      communication.idFonte == 0 && getCommunication({
        tipoConsumidor: 'F',
        documento: userData?.cpf ?? '',
      });
    } else {
      communication.idFonte == 0 && getCommunication({
        tipoConsumidor: 'J',
        documento: userData?.cnpj ?? '',
      });
    }
  }, [communication]);

  return (
    <PageSkeleton
      title="Dados de Comunicação"
      breadcrumb={{
        menuName: 'Início',
        submenuName: 'Cadastro Positivo',
        thirdLevel: 'Dados de Comunicação',
      }}
      highlight={{
        title: 'Aqui, você pode visualizar os dados referentes as comunicações enviadas a você em relação à abertura do Cadastro Positivo.',
        description: 'Os seus dados de comunicação estão indicados abaixo e podem ' +
          'ser utilizados para te ajudar a consultar <strong>informações importantes</strong> sobre o seu cadastro.',
        image: require('../../../assets/images/dados-comunicacao-1.png'),
      }}
    >
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <ContentContainer deviceType={{ type: deviceType }}>
        <Text tag="h4" size={2} weight={3} lineHeight={1} color="text">
          Seus dados de comunicação
        </Text>
        <KeyContainer deviceType={{ type: deviceType }}>
          <KeyValue textKey="Status da Comunicação" textVal={comStatus} deviceType={{ type: deviceType }} />

          {true && (
            // {communication.indicadorComunicacao === 'SIM' && (
            <>
              <KeyValue textKey="Data da Comunicação" deviceType={{ type: deviceType }} textVal={formattedDate} />
              <KeyValue textKey="Meio da Comunicação" deviceType={{ type: deviceType }}
                textVal={communication.tipoMeioComunicacao ? communication.tipoMeioComunicacao + ' - ' + communication.valorMeioComunicacao : ''} />
            </>
          )}
        </KeyContainer>
      </ContentContainer>
    </PageSkeleton>
  );
}
