import useDeviceType from 'hooks/useDeviceType';
import { DetailBlock } from 'pages/Cadastro/HistoricoPagamentos/components/OperationDetails/style'
import { documentFormatter } from 'utils/documentFormatter'

interface ListTableMobile {
    title: string;
    topics: ListTopic[];
    originalPayment?: any
}

interface ListTopic {
    name: string;
    description: string;
}

interface ListTableMobileListProps {
    details?: ListTableMobile[];
    showPointer?: boolean;
}

const ListTableMobile = ({ details, onPaymentSelect, showPointer }: ListTableMobileListProps & { onPaymentSelect?: (payment: any) => void }) => {
    const { deviceType } = useDeviceType();

    const handleClick = (payment: any) => {
        if (onPaymentSelect) {
            onPaymentSelect(payment);
        }
    };

    return (
        <>
            {details?.map((item, index) => (
                <DetailBlock key={index} deviceType={{ type: deviceType }} onClick={() => handleClick(item)} showPointer={showPointer}>
                    <h1>{item.title}</h1>
                    {item.topics.map((topic, topicIndex) => (
                        <p key={topicIndex}>
                            <b>{topic.name}: </b>
                           <span>{topic.description}</span>
                        </p>
                    ))}
                </DetailBlock>
            ))}
        </>
    )
}


export default ListTableMobile


