import React from 'react';
import moment from 'moment';

const SignupTerm = () => {
  return (
    <>
      <div
        style={{
          textAlign: 'justify',
          lineHeight: '1.5rem',
          overflowX: 'hidden',
          marginTop: '20px'
        }}
      >
        <strong>1. Cadastro e Segurança</strong>
        <p>
          &emsp;<strong>1.1.</strong> Este Documento possui a finalidade de
          regular o acesso e/ou uso de qualquer dos conteúdos e/ou serviços
          constantes no Portal (“Site”) e/ou dos aplicativos (“Apps”)
          disponibilizados pelo SPC BRASIL.
        </p>
        <p>
          &emsp;<strong>1.2.</strong> Os termos e as condições propostas neste
          Documento refletem o engajamento do SPC BRASIL em prover segurança e
          privacidade no uso dos seus Sites e/ou Apps, nos termos da lei.
        </p>
        <p>
          &emsp;<strong>1.3.</strong> Ao acessar e/ou efetivar o seu cadastro, o
          Usuário está ciente e de acordo com todas as disposições contidas
          neste Documento e na Política de Coleta e Utilização de Dados pessoais
          disponibilizada no sítio eletrônico do SPC BRASIL –&nbsp;
          <a
            href="https://www.spcbrasil.org.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.spcbrasil.org.br
          </a>
          , bem como que poderão ser solicitados os seus dados pessoais e
          cadastrais ao Usuário (“Dados do Usuário/Informações”).
        </p>
        <p>
          &emsp;<strong>1.4.</strong> O acesso do Usuário ao Portal do SPC
          BRASIL será permitido mediante identificação única e pessoal, através
          do CPF e criação de senha intransferível, sendo de responsabilidade do
          Usuário a guarda e responsabilidade.
        </p>
        <p>
          &emsp;<strong>1.5.</strong> O Usuário, quando do referido cadastro,
          compromete-se a prestar informações verdadeiras, exatas, atuais e
          completas sobre si mesmo e, ainda, preservar e atualizar tais, a fim
          de mantê-las nos mesmos moldes, inexistindo qualquer responsabilidade
          do SPC BRASIL no caso de inclusão e/ou manutenção de dados falsos,
          inexatos ou desatualizados pelo Usuário.
        </p>
        <p>
          &emsp;<strong>1.6.</strong> O presente Documento possibilitará ao
          Usuário o acesso às informações coletadas, pontuação de crédito
          (score), histórico de pagamento e respectiva liberação de
          visualização, contestação, correção de informação, cancelamento de
          cadastro e reabertura de cadastro. A criação desse usuário é essencial
          para acessar ao Portal do Consumidor e suas funcionalidades.
        </p>
        <p>
          &emsp;<strong>1.7.</strong> O SPC BRASIL, em respeito aos direitos
          fundamentais de liberdade e de privacidade e o livre desenvolvimento
          da personalidade da pessoa natural, não irá monitorar, editar, acessar
          ou divulgar informações privativas dos Usuários, exceto, nos casos
          expressamente previstos no item 2 – Privacidade, abaixo, ou nos casos
          em que for compelido a fazê-lo por força de ordem legal.
        </p>
        <p>
          &emsp;<strong>1.8.</strong> O SPC BRASIL adota os melhores esforços na
          consecução de políticas internas consistentes na preservação do sigilo
          e integridade das informações que, porventura, venham a ser fornecidas
          pelo Usuário, bem como àquelas que, posteriormente, venham a ser
          registradas e armazenadas, tais como, ações relacionadas à manutenção
          dos registros de acesso e aplicações de internet, sob sigilo, em
          ambiente controlado e de segurança, entre outras, nos termos da lei.
        </p>

        <strong>2. Privacidade e Consentimento</strong>
        <p>
          &emsp;<strong>2.1.</strong> O Usuário está ciente e de acordo que, à
          medida que vier a acessar os Sites e/ou Apps do SPC BRASIL, poderão
          ser registradas e armazenadas, automaticamente, informações e dados do
          Usuário, bem como “Cookies” quando o navegador da internet é
          utilizado, com a finalidade principal de disponibilizar ao Usuário uma
          experiência segura, tranquila, eficiente e personalizada.
        </p>
        <p>
          &emsp;<strong>2.2.</strong> Fica, desde já, autorizado e conferido
          pelo Usuário, o direito do SPC BRASIL em compartilhar as informações
          fornecidas pelo Usuário com membros da Família Corporativa e da
          estrutura Confederativa, das quais o SPC BRASIL faz parte; parceiros e
          clientes; órgãos do Poder Público ou, ainda, outros terceiros, nessa
          hipótese, mediante consentimento do Usuário.
        </p>
        <p>
          &emsp;<strong>2.3.</strong> O Usuário está ciente e de acordo ainda
          que todos os seus dados, informações cadastrais e pessoais coletadas
          serão incorporadas, de forma definitiva, para finalidades comerciais e
          legais, à base de dados do SPC Brasil, sito à Rua Leôncio de Carvalho,
          no 234, 13o andar, Paraíso, São Paulo/SP, CEP 04003-010 em
          conformidade à legislação pertinente, sobretudo ao disposto na Lei n°
          12.414/2011 (Cadastro Positivo), Lei n° 12.965/14 (Marco Civil da
          Internet) e Lei n° 13.709/2018 (LGPD).
        </p>
        <p>
          &emsp;<strong>2.4.</strong> O Usuário está ciente e de acordo que o
          SPC BRASIL poderá utilizar os dados coletados, tais como, dados
          pessoais e dados cadastrais para as seguintes finalidades: (i) Avisar
          o Usuário sobre novos produtos e/ou serviços do SPC BRASIL e/ou
          parceiros, por correio eletrônico mala direta, SMS ou outros meios de
          comunicação existentes; (ii) utilizar os dados como componente no
          processo de criação e/ou atualização de produtos e/ou serviços do SPC
          BRASIL, os quais poderão ser disponibilizados à rede de relacionamento
          do SPC BRASIL e parceiros, a fim de auxiliá-los em todos os
          procedimentos do ciclo de negócios (prospecção, análise de crédito,
          relacionamento, identidade digital, gestão operacional, acompanhamento
          de carteira, cobrança e recuperação), segmentação e propensão,
          avaliação de riscos, prevenção à fraude, efetivação de negócios,
          marketing service, entre outros relacionados; (iii) Preservar
          atualizados os dados do Usuário para fins de contato por telefone,
          correio eletrônico mala direta, SMS ou outros meios de comunicação
          existentes para envio de materiais de marketing direcionados, ofertas
          promocionais, pesquisas, campanhas, entre outras ações; (iv) Prover
          garantia da prevenção à fraude e à segurança do Usuário, bem como a
          sua correta identificação. (v) Cumprimento de obrigação legal e/ou
          regulatória;
        </p>
        <p>
          &emsp;<strong>2.5.</strong> O Usuário está ciente e de acordo que o
          SPC BRASIL poderá, ainda, utilizar os seus dados biométricos, tais
          como a sua foto, entre outros, para as finalidades descritas no item
          2.4, acima.
        </p>
        <p>
          &emsp;<strong>2.6.</strong> O Usuário está ciente que os Sites e/ou
          Apps do SPC BRASIL poderão contemplar atalhos/acessos (“Links”) para
          outros Sites, com vistas a complementar ou operacionalizar algumas das
          suas funcionalidades. Assim, o Usuário se compromete a verificar as
          respectivas condições de uso e políticas de privacidade de tais Sites,
          caso venham a ser redirecionados, não tendo o SPC BRASIL qualquer
          responsabilidade nesse sentido.
        </p>
        <strong>3. Acesso e revogação</strong>
        <p>
          &emsp;<strong>3.1.</strong> O Usuário poderá ter acesso aos seus
          dados, a qualquer momento, bem como solicitar a correção de dados
          incompletos, inexatos ou desatualizados, de forma gratuita, através
          dos canais de atendimento informados no sítio eletrônico do SPC BRASIL
          –&nbsp;
          <a
            href="https://www.spcbrasil.org.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.spcbrasil.org.br
          </a>{' '}
          na homepage do Cadastro Positivo.
        </p>
        <p>
          &emsp;<strong>3.2.</strong> O Usuário poderá solicitar a revogação do
          consentimento/autorização conferida ao SPC Brasil, de acordo com as
          condições apresentadas neste Documento, de forma gratuita e
          facilitada, através dos canais de atendimento disponibilizados.
        </p>
        <p>
          &emsp;<strong>3.3.</strong> O Usuário está ciente que o SPC BRASIL
          poderá, entretanto, conservar os dados e informações do Usuário que
          solicitar a revogação do consentimento/autorização, especificamente,
          para cumprimento de obrigação legal ou regulatória, ou para o seu uso
          exclusivo, desde que anonimizados os dados.
        </p>
        <p>
          &emsp;<strong>3.4.</strong> O SPC BRASIL disponibiliza ao Usuário
          diversos canais de comunicação a fim de prover atendimento ágil e
          solução eficaz aos questionamentos apresentados.
        </p>

        <strong>4. Considerações Finais</strong>
        <p>
          &emsp;<strong>4.1.</strong> O SPC BRASIL se exime de qualquer
          responsabilidade decorrente de eventos de caso fortuito ou força
          maior, assim entendidas as circunstâncias imprevisíveis e inevitáveis
          que impeçam, total ou parcialmente, a execução das obrigações
          assumidas.
        </p>
        <p>
          &emsp;<strong>4.2.</strong> O SPC BRASIL se reserva ao direito de
          alterar, a qualquer momento, de forma unilateral e sem prévio aviso,
          os termos deste Documento, a fim de compatibilizá-lo a atual
          legislação e as suas práticas mercadológicas. O SPC BRASIL, sempre que
          possível, envidará todos os esforços no sentido de destacar tais
          alterações ao Usuário, quando do seu acesso aos produtos e/ou
          serviços.
        </p>
        <p>
          &emsp;<strong>4.3.</strong> O Usuário declara expressamente que tem
          ciência de que a utilização das informações a que tem acesso no Portal
          do SPC BRASIL é de sua inteira responsabilidade, respondendo
          individualmente por eventuais danos ocasionados decorrentes do uso
          indevido dos dados.
        </p>
        <p>
          &emsp;<strong>4.4. </strong> O Usuário declara expressamente, para os
          devidos fins e efeitos, que possui pleno conhecimento sobre o
          tratamento dos dados realizados pelo SPC BRASIL, o qual se destina às
          finalidades previstas neste Documento, abarcando todas as etapas do
          ciclo de negócios (prospecção, análise de crédito, relacionamento,
          identidade digital, gestão operacional, acompanhamento de carteira,
          cobrança e recuperação), o qual atende às suas legítimas expectativas,
          bem como está em conformidade com os seus direitos e liberdades
          fundamentais, nos termos da lei, responsabilizando-se a comunicar o
          SPC BRASIL caso seja constatado, pelo Usuário, qualquer
          desconformidade.
        </p>
        <p>
          &emsp;<strong>4.5.</strong> Este documento será regido e interpretado
          de acordo com a legislação brasileira, sendo eleito o Foro Central da
          Capital do Estado de São Paulo para dirimir quaisquer questões a ele
          relativas, com renúncia expressa a qualquer outro, por mais
          privilegiado que seja ou venha a ser.
        </p>
        <strong>5. Canais de Atendimento</strong>
        <p>
          &emsp;<strong>5.1.</strong> O SPC BRASIL disponibiliza canais de
          atendimento e de ouvidoria gratuitos e específicos para os assuntos
          relacionados ao Cadastro Positivo. Podendo o usuário entrar em contato
          através do atendimento de segunda à sexta, das 09h00 às 18h00, contato
          via e-mail{' '}
          <a
            href="mailto:falecomcadastropositivo@spcbrasil.org.br"
            target="_blank"
            rel="noreferrer"
          >
            falecomcadastropositivo@spcbrasil.org.br
          </a>
          , através do sítio eletrônico -{' '}
          <a
            href="https://www.spcbrasil.org.br/cadastropositivo/consumidor/sac-e-ouvidoria.html"
            target="_blank"
            rel="noreferrer"
          >
            https://www.spcbrasil.org.br/cadastropositivo/consumidor/sac-e-ouvidoria.html
          </a>
          &nbsp;ou do telefone <a href="tel:08008879105">0800-887-9105</a>.
        </p>
      </div>
    </>
  );
};

export default SignupTerm;
