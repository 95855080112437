import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledConfirmationBox = styled.div<{ show: boolean, deviceType?: DeviceTypeProps }>`
  position: relative;
  justify-content: space-between;
  align-items: center;
  border-radius: 11px;
  padding: 22px;
  margin-top: 32px;
  margin-bottom: 32px;
  
  ${({ theme, show }) => `
    display: ${show ? 'flex' : 'none'};
    background: ${theme.color.white['FFFFFF']};
    border: 1.4px solid ${theme.color.lightGray['EAEAEA']};
    `}
    
    ${({ deviceType }) => {
      if (deviceType?.type == 'mobile' || deviceType?.type == 'mobile-landscape' )
      return `
      padding: 18px;
    flex-direction: column;
      `;

  }}
`;

export const MessageContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  font-weight: 500;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
  line-height: 28px;

  ${({ theme }) => `
    color: ${theme.color.neutral['586682']};
  `}


`;

export const ButtonContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;

 ${({ deviceType }) => {
    if (deviceType?.type == 'mobile' || deviceType?.type == 'mobile-landscape' )
    return `
      margin-top: 10px;
      max-width: 80vw;
      `;
  }}
`;

export const MessageButton = styled.div<{ confirm: boolean, deviceType?: DeviceTypeProps }>`
  margin: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  height: 45px;
  border-radius: 70px;
  cursor: pointer;

  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile' || deviceType?.type == 'mobile-landscape' )
    return `
      height: 45px;
      `;
  }}

  ${({ theme, confirm }) => `
    background: ${confirm ? theme.color.blue['144695'] : theme.color.white['FFFFFF']};
    color: ${confirm ? theme.color.white['FFFFFF'] : theme.color.blue['144695']};
    border: ${confirm ? 'none' : '1.4px solid' + theme.color.blue['144695']};
  `}
`;
