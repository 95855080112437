import { AxiosResponse } from 'axios';
import { api } from './api';

interface HistoryServices {
  permissions: {
    search: (params: any) => Promise<AxiosResponse<any, any>>;
    get: {
      total: (params: any) => Promise<AxiosResponse<any, any>>;
    }
    include: (params: any) => Promise<AxiosResponse<any, any>>;
    delete: (params: any) => Promise<AxiosResponse<any, any>>;
  };
  get: {
    last: (params: any) => Promise<AxiosResponse<any, any>>;
    operations: (params: any) => Promise<AxiosResponse<any, any>>;
    telecom: (params: any) => Promise<AxiosResponse<any, any>>;
    energy: (params: any) => Promise<AxiosResponse<any, any>>;
    [key: string]: ((params: any) => Promise<AxiosResponse<any, any>>);
  };
  source: {
    companies: (docType: string, docNumber: string, name: string) => Promise<AxiosResponse<any, any>>;
  }
  details: {
    operations: (docType: string, docNumber: string, id: string) => Promise<AxiosResponse<any, any>>;
    telecom: (docType: string, docNumber: string, id: string) => Promise<AxiosResponse<any, any>>;
    energy: (docType: string, docNumber: string, id: string) => Promise<AxiosResponse<any, any>>;
    [key: string]: ((docType: string, docNumber: string, id: string) => Promise<AxiosResponse<any, any>>);
  },
  payments: {
    operations: (docType: string, docNumber: string, id: string, source: string, contract: string) => Promise<AxiosResponse<any, any>>;
    telecom: (docType: string, docNumber: string, id: string, source: string, contract: string) => Promise<AxiosResponse<any, any>>;
    energy: (docType: string, docNumber: string, id: string, source: string, contract: string) => Promise<AxiosResponse<any, any>>;
    [key: string]: ((docType: string, docNumber: string, id: string, source: string, contract: string) => Promise<AxiosResponse<any, any>>);
  }
}

export const history: HistoryServices = {
  permissions: {
    search: async (params: any) => await api()
      .post('/cadastropositivo-historico-pagamento-consumidor/permissao-historico/v2/busca-permissoes/',
        params),
    get: {
      total: async (params: any) => await api()
        .post('/cadastropositivo-historico-pagamento-consumidor/permissao-historico/v2/busca-total-registros/',
          params),
    },
    include: async (params: any) => await api()
      .post('/cadastropositivo-historico-pagamento-consumidor/permissao-historico/v2/inclui-permissao-consulente/',
        params),
    delete: async (params: any) => await api()
      .post('/cadastropositivo-historico-pagamento-consumidor/permissao-historico/exclui-permissao/',
        params),
  },
  get: {
    last: async (params: any) => await api()
      .post('/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-ultimas/',
        params),
    operations: async (params: any) => await api()
      .post('/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-operacoes/',
        params),
    telecom: async (params: any) => await api()
      .post('/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-operacoes-telcos/',
        params),
    energy: async (params: any) => await api()
      .post('/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-operacoes-energias/',
        params),

  },
  source: {
    companies: async (docType: string, docNumber: string, name: string) => await api()
      .get(`/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-fonte/
?tipoConsumidor=${docType}&documento=${docNumber}&nome=${name}`),
  },
  details: {
    operations: async (docType: string, docNumber: string, id: string) => await api()
      .get(`/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-operacao/
?tipoConsumidor=${docType}&documento=${docNumber}&idOperacao=${id}`),
    telecom: async (docType: string, docNumber: string, id: string) => await api()
      .get(`/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-operacao-telco/
?tipoConsumidor=${docType}&documento=${docNumber}&idOperacao=${id}`),
    energy: async (docType: string, docNumber: string, id: string) => await api()
      .get(`/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-operacao-energia/
?tipoConsumidor=${docType}&documento=${docNumber}&idOperacao=${id}`),
  },
  payments: {
    operations: async (docType: string, docNumber: string, id: string, source: string, contract: string) => await api()
      .get(`/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-movimentos-instfin/
?tipoConsumidor=${docType}&documento=${docNumber}&idOperacao=${id}&idFonte=${source}&numeroContrato=${contract}&pagina=1&totalItens=100`),
    telecom: async (docType: string, docNumber: string, id: string, source: string, contract: string) => await api()
      .get(`/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-movimentos-telcos/
?tipoConsumidor=${docType}&documento=${docNumber}&idOperacao=${id}&idFonte=${source}&numeroContrato=${contract}&pagina=1&totalItens=100`),
    energy: async (docType: string, docNumber: string, id: string, source: string, contract: string) => await api()
      .get(`/cadastropositivo-historico-pagamento-consumidor/historico-pagamento/pesquisa-movimentos-energias/
?tipoConsumidor=${docType}&documento=${docNumber}&idOperacao=${id}&idFonte=${source}&numeroContrato=${contract}&pagina=1&totalItens=100`),
  },
};
