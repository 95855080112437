import { api } from './api';

export const contestacao = {
  registros: {
    search: async (params: any) => await api().post('/cadastropositivo-contestacoes/contestacoes/busca-contestacoes/', params),
    get: {
      total: async (params: any) => await api()
          .post('/cadastropositivo-contestacoes/contestacoes/contestacoes-total-registros/',
              params),
    },
  },
  contest: {
    client: async (params: any) => await api()
        .post('/cadastropositivo-contestacoes/contestacoes/contestar-operacao-cliente/',
            params),            
    consumidor: async (params: any) => await api()
        .post('/cadastropositivo-contestacoes/contestacoes/contestar-operacao-consumidor/',
            params),
  },
  getMotives: async (category: string) => await api()
      .get(`/cadastropositivo-contestacoes/contestacoes/buscar-motivos-contestacao/${category}`),
  getSources: async (category: string) => await api()
      .get(`/cadastropositivo-contestacoes/contestacoes/fontes/${category}`),
};
