import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledCarousel = styled.div<{ deviceType?: DeviceTypeProps }>`
width: 60vw;
margin-top: 1rem;
padding-bottom: 1rem;
position: relative;

${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
    return `
    width: 70vw;
      `;
  }}
`


export const ContainerLink = styled.div<{ deviceType?: DeviceTypeProps }>`
`


export const StyledSwiper = styled(Swiper)`
border-radius: 20px;
width: 100%;
height: 100%;
border: 1px solid transparent;
`
export const StyledSwiperSlide = styled(SwiperSlide)`
text-align: center;
font-size: 18px;
background: #fff;

/* Center slide text vertically */
display: -webkit - box;
display: -ms - flexbox;
display: -webkit - flex;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
align-items: center;
`

export const StyledSwiperImage = styled.img`
display: block;
width: 100%;
height: 100%;
object-fit: cover;
`
