import { QueryInfoItemProps } from 'types';
import { Description, InfoContainer, StatusTimeContainer, StyledQueryInfoItem, TextContainer, TimeContainer, Title } from './styles';
import { MdAccessTime } from 'react-icons/md';
import useDeviceType from 'hooks/useDeviceType';
const QueryInfoItem = ({ title, description, status, hasTime, time, onClick }: QueryInfoItemProps) => {
  const { deviceType } = useDeviceType();

  return (
    
    <StyledQueryInfoItem onClick={onClick} deviceType={{ type: deviceType }}>
      <InfoContainer deviceType={{ type: deviceType }}> 
        <TextContainer>
          <Title size={1} weight={1}>
            {title}
          </Title>
          <Description deviceType={{ type: deviceType }}>
            {description}
          </Description>
        </TextContainer>
        <StatusTimeContainer deviceType={{ type: deviceType }}>
          {hasTime ? (
            <TimeContainer deviceType={{ type: deviceType }}>
              <MdAccessTime />
              <Description >
                {time}
              </Description>
            </TimeContainer>
          ) :
            (
              <Description deviceType={{ type: deviceType }}>
                {status}
              </Description>
            )}
        </StatusTimeContainer>
      </InfoContainer>
    </StyledQueryInfoItem>
  );
};

export { QueryInfoItem };
