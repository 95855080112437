import { Button, Text } from 'components';
import { BiTrash } from 'react-icons/bi';
import { Header, Status, StyledHistoryItem } from './styles';
import { HistoryItemProps } from './types';
import useDeviceType from 'hooks/useDeviceType';

export const HistoryItem = ({
  legalName,
  cnpj,
  releaseDate,
  expirationDate,
  status = 'Ativa',
  onDeleteHistory,
}: HistoryItemProps) => {
  const { deviceType } = useDeviceType();

  return (
    <StyledHistoryItem hasAlpha={status != 'Ativa'}>
      <Header deviceType={{ type: deviceType }} >
        <Text size={1} lineHeight={1} weight={2}>Nome Fantasia: {legalName}</Text>
        <Button btnStyle="action" onClick={onDeleteHistory} disabled={status != 'Ativa'}>
          <BiTrash />
        </Button>
      </Header>
      <Text size={1} lineHeight={1}>CNPJ: {cnpj}</Text>
      <Text size={1} lineHeight={1}>Data de liberação: {releaseDate}</Text>
      <Text size={1} lineHeight={1}>Data de expiração: {expirationDate}</Text>
      <Status status={status}><Text size={1} lineHeight={1}>{status}</Text></Status>
    </StyledHistoryItem>
  );
};
