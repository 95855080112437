import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const QueryContainer = styled.div`
  margin-top: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  `;
  
  export const QueryInfoConainter = styled.div<{ deviceType?: DeviceTypeProps }>`
  cursor: pointer;
  width: 100%;
  `;

  export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  `;
  
  export const FormFilter = styled.div`
  border: 1px solid blue;
  display: flex;
  flex-direction
  `;
