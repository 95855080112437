import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

const StyledResponsive = styled.div<{ deviceType?: DeviceTypeProps }>`

${({ deviceType }) => {
  if (deviceType?.type === 'mobile')
    return `
    margin:auto;
    width: 90vw;
    `;
}}
`;

export { StyledResponsive };
