import { createGlobalStyle, css } from 'styled-components';

import { StandardType } from './theme';

const GlobalStyle = createGlobalStyle<{ theme: StandardType }>`
  ${({ theme }) => css`
    body {
      width: calc(100vw - (100vw - 100%)) !important;
      height: 100vh;

      font-family: 'Montserrat';
      margin: 0 !important;
      padding: 0 !important;
      background: ${theme.color.white.F2F2F2};

      [disabled] {
        opacity: 0.55;
        cursor: not-allowed;
      }

      #root {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

    }
  `}
`;

export default GlobalStyle;
