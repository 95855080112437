import axios from 'axios';
import env from 'environments';

export const api = () => {
  const storedData = localStorage.getItem('SPC_SessionInfo') ?? '';
  const parsedData = JSON.parse(storedData);

  return axios.create({
    baseURL: `${env.API_URL}/apicadpos`,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${ parsedData['access_token'] ?? ''}`,
      'x-route': 'CONSUMIDOR',
    },
  });
};


