import { LoadingSpinner, PageSkeleton } from 'components';
import { AuthContext, CadastroContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const HomeLayout = ({ routes }: any) => {
  const { userData } = useContext(AuthContext)
  const { getScorePf, getScorePj, score } = useContext(CadastroContext);
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const detailsByScore: any = {
    low: {
      title: 'Seu score está Baixo',
      description: `Seu histórico de pagamentos pode ter contribuído para uma baixa pontuação de Score.<br/><br/>
        Evite dificuldades para conseguir crédito: acesse o Blog SPC Brasil, clique na seção Crédito
        e encontre dicas que podem te ajudar a aumentar sua pontuação do Score. <a href="https://www.spcbrasil.org.br/blog" target="_blank">Clique aqui</a>`,
    },
    medium: {
      title: 'Seu score está Regular',
      description: `As informações do seu histórico de pagamentos podem ter contribuído para isso.<br/><br/>
        Mantenha seus dados cadastrais atualizados e busque realizar todos os pagamentos em dia.
        Conte com as dicas financeiras do Blog SPC Brasil para isso! <a href="https://www.spcbrasil.org.br/blog" target="_blank">Clique aqui</a>`,
    },
    good: {
      title: 'Seu score está Bom',
      description: `Suas informações no mercado de crédito e histórico de pagamentos contribuíram para essa pontuação.<br/><br/>
        Mantenha suas finanças em dia para continuar assim. Quer aumentar ainda mais sua pontuação?
        Acesse o Blog SPC Brasil e conte com as dicas de quem entende de Score! <a href="https://www.spcbrasil.org.br/blog" target="_blank">Clique aqui</a>`,
    },
    veryGood: {
      title: 'Seu score está Excelente',
      description: `Parabéns! Seu score está excelente e as informações do seu histórico de pagamento contribuíram para isso.
        Mantenha suas finanças em dia para continuar com o seu score neste padrão.<br/><br/>
        Sabia que o Blog SPC Brasil tem dicas semanais sobre Score, saúde financeira, crédito e mais?
        Acesse e cuide das finanças com apoio do maior banco de dados da América Latina! <a href="https://www.spcbrasil.org.br/blog" target="_blank">Clique aqui</a>`,
    },
  };

  const getDetailsByScore = (score: any) => {
    if (score >= 0 && score < 400) {
      return {
        title: detailsByScore?.low.title,
        description: detailsByScore?.low.description
      }
    }
    else if (score >= 400 && score < 600) {
      return {
        title: detailsByScore?.medium.title,
        description: detailsByScore?.medium.description
      }
    }
    else if (score >= 600 && score < 800) {
      return {
        title: detailsByScore?.good.title,
        description: detailsByScore?.good.description
      }
    }
    else if (score >= 800 && score < 1001) {
      return {
        title: detailsByScore?.veryGood.title,
        description: detailsByScore?.veryGood.description
      }
    }
  }

  const scoreDetails: any = getDetailsByScore(score);

  const skeletonOptions = {
    score: score,
    highlight: {
      title: scoreDetails?.title,
      description: scoreDetails?.description,
    },
  };

  useEffect(() => {
    path === '/home' && navigate('/home/score');
  }, [path]);

  useEffect(() => {
    if (userData?.cpf !== "" && userData?.cpf !== undefined) {
      getScorePf(userData?.cpf)
    } else if (userData?.cnpj !== "" && userData?.cnpj !== undefined) {
      getScorePj(userData?.cnpj)
    }
  }, [userData]);

  useEffect(() => {
    if (userData?.cnpj || (userData?.cpf && score !== undefined)) {
      setLoading(false);
    }
  }, [score, userData]);

  // Remover Após Solucionar Problemas com a UI
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const displayedMsgUi: boolean = JSON.parse(sessionStorage.getItem("spcDisplayedMsgUi")!);

    if (!loading && !displayedMsgUi) {
      const helloPerson = {
        PF: `Olá, ${userData?.nome}`,
        PJ: "Olá"
      };

      timeoutId = setTimeout(() => {
        Swal.fire({
          icon: "warning",
          title: "Comunicado",
          html: `
            <h2 style="margin: 12px 0 24px 0;font-size: 22px;">${helloPerson[userData?.tipoConsumidor as keyof typeof helloPerson]}</h2>

            <p style="margin: 0 0 24px 0; font-size: 16px;">
              Pedimos desculpas caso você encontre dificuldades ou problemas em seu acesso,
              mas estamos melhorando o Portal do Consumidor para melhor atendê-lo.
            </p>

            <span style="display: block; margin: 0 0 18px 0;font-size: 16px;">Obrigado pela compreensão!</span>

            Atenciosamente Equipe SPC Brasil.
          `,
          confirmButtonColor: "#144695"
        });
      }, 1500);

      sessionStorage.setItem("spcDisplayedMsgUi", "true");
    }

    return () => clearTimeout(timeoutId);
  }, [loading]);

  return (
    <>
      <div>
        <LoadingSpinner state={loading} />
      </div>
      <PageSkeleton
        {...userData?.tipoConsumidor == "PJ" ? ("") : ({ ...skeletonOptions })}
        breadcrumb={path === '/home/entenda-seu-score' ? {
          menuName: 'Início',
          submenuName: 'Entenda seu Score',
        } : undefined}>
        {routes || <Outlet />}
      </PageSkeleton>
    </>
  );
};

export default HomeLayout;
