import { Input, Radio } from 'components';
import { AuthContext, CadastroContext } from 'contexts';
import moment from 'moment';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { DateRange, StyledForm } from './styles';
import useDeviceType from 'hooks/useDeviceType';


type FormProps = {
  onChangeForm?: Dispatch<SetStateAction<any>>
  selectedCompany?: any
  setIsSubmitDisabled: (state: boolean) => void
}

const Form = ({ onChangeForm, selectedCompany, setIsSubmitDisabled }: FormProps) => {
  const { userData } = useContext(AuthContext);
  const [showDateRange, setShowDateRange] = useState(false);

  const [company, setCompany] = useState<Array<{ label: string, value: string }>>([]);

  const [formData, setFormData] = useState({
    documentoConsumidor: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
    isConsultaUnica: false,
    tipoConsumidor: (userData.cpf !== "" && userData.cpf !== undefined) ? "PF" : "PJ",
    cnpjConsulente: selectedCompany?.cnpj.value,
    nomeCompletoConsulente: selectedCompany?.nomeFantasia,
    status: "Expirada",
    dataInicialPermissao: "",
    dataFinalPermissao: "",
    versaoTermo: "Termo Teste 1",
    termoHtml: ""
  });

  const [errorMessage, setErrorMessage] = useState('');

  const onChangeHandler = (field: any) => {
    const formattedData = field.companyInfo ? {
      nome: field.companyInfo.label.split(' - ')[0],
      cnpj: field.companyInfo.value,
    } : field;

    const allData = { ...formData, ...formattedData };
    setFormData(allData);
    onChangeForm && onChangeForm(allData);
  };

  const checkRadioValue = (field: any) => {
    if (field === moment().format('DD/MM/yyyy')) {
      setShowDateRange(true);
      setInitialDate('');
      setFinalDate('');
    } else {
      setShowDateRange(false);
      onChangeHandler({
        dataInicialPermissao: moment().format('DD/MM/yyyy'),
        dataFinalPermissao: field,
      });
    }
  };

  const convertToDate = (input: string) => {
    const [day, month, year] = input.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const isBefore = (date1: string, date2: string) => {
    const convertedDate1 = convertToDate(date1);
    const convertedDate2 = convertToDate(date2);
    return convertedDate1 < convertedDate2;
  };

  const setInitialDate = (date: string) => onChangeHandler({ dataInicialPermissao: date.length === 10 ? date : '' });
  const setFinalDate = (date: string) => {
    if (moment(date, 'DD/MM/YYYY').isBefore(moment(formData.dataInicialPermissao, 'DD/MM/YYYY'))) {
      setErrorMessage('A data final não pode ser anterior à data inicial');
      setIsSubmitDisabled(true);
      return;
    }

    setErrorMessage('');
    setIsSubmitDisabled(false);
    onChangeHandler({ dataFinalPermissao: date.length === 10 ? date : '' });
  };

  useEffect(() => {
    if (userData.cpf !== "" && userData.cpf !== undefined) {
      userData.cpf && setFormData({ ...formData, documentoConsumidor: userData.cpf });
    } else {
      userData.cnpj && setFormData({ ...formData, documentoConsumidor: userData.cnpj });
    }
  }, [userData.cpf, userData.cnpj]);

  useEffect(() => {
    const formattedList: any = {
      label: `${selectedCompany?.nomeFantasia} - CNPJ: ${selectedCompany?.cnpj.formattedValue
        }`,
      value: `${selectedCompany?.cnpj.formattedValue
        }`,
    }
    setCompany(formattedList);
  }, []);
  const { deviceType } = useDeviceType();

  return (
    <StyledForm deviceType={{ type: deviceType }}    >
      <input style={{ padding: "1rem" }} disabled type='text'
        value={`${selectedCompany?.nomeFantasia} - CNPJ: ${selectedCompany?.cnpj.formattedValue
          }`} />
      <Radio
        id='period'
        name='time'
        onCheck={(e: string) => checkRadioValue(e)}
        label='Selecione o período de liberação:'
        options={[
          { value: moment().add(1, 'day').format('DD/MM/yyyy'), label: '1 dia' },
          { value: moment().add(90, 'day').format('DD/MM/yyyy'), label: '3 meses' },
          { value: moment().add(365, 'day').format('DD/MM/yyyy'), label: '12 meses' },
          { value: moment().format('DD/MM/yyyy'), label: 'Personalizado' },
        ]} />
      {showDateRange && (
        <>
          <DateRange deviceType={{ type: deviceType }}>
            <Input type="date" onChange={setInitialDate} id='dataInicialPermissao' label='Data inicial:' />
            <Input type="date" onChange={setFinalDate} id='dataFinalPermissao' label='Data final:' />
          </DateRange>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </>
      )}
    </StyledForm>
  );
};

export default Form;
