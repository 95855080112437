import { Text } from 'components';
import { Percent, Range, StyledRangeItem } from 'components/ScoreRange/styles';
import { ScoreProps } from 'types';


const RangeItem = ({ isScoreHere, score, range, deviceType, percent, }: ScoreProps) => {
  return (
    <StyledRangeItem isActive={isScoreHere} score={score} deviceType={deviceType}>
      <Range>
        <Text size={1} align="center" color={isScoreHere ? 'buttonLabel' : 'default'}>
          {range} 
        </Text>
      </Range>
      {/*  <Percent><Text align="center" color={isScoreHere ? 'buttonLabel' : 'default'}>{percent}</Text></Percent> */}
      {isScoreHere && <Text size={1} color="primary">Seu score está aqui</Text>}
    </StyledRangeItem>
  );
};

export { RangeItem };
