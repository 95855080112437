import { useEffect, useState } from 'react'
import { PageSkeleton } from "components/PageSkeleton";
import { Text } from "components/Text";
import { Accordion } from "components/Accordion"
import * as S from './styles'
import { Button } from "components/Button";
import { Modal } from "components/Modal";
import { Selector } from 'components/Selector';
import { Option } from 'pages/Cadastro/HistoricoPagamentos/components/ContestOperation';
import Correcao from './Correcao';
import Informacoes from './Informacoes';
import { getUserDetails } from 'services/SuaConsulta';
import { UserDetails } from './Models/userDetails';
import MaisSolicitacoes from './MaisSolicitacoes';
import OutrasSolicitacoes from './OutrasSolicitacoes';
import { useLocation } from 'react-router-dom';
import { Container } from 'components/Container';
import useDeviceType from 'hooks/useDeviceType';

export enum TIPOS_SOLICITACAO {
  CORRECAO = 'CORRECAO',
  INFO_ORIGEM_FINALIDADE_COMPARTILHA = 'INFO_ORIGEM_FINALIDADE_COMPARTILHA',
  MAIS_SOLICITACOES = 'MAIS_SOLICITACOES',
  OUTRAS_SOLICITACAO = 'OUTRAS_SOLICITACAO'
}

export enum TIPO_DADO {
  NOME = 'NOME',
  TELEFONE = 'TELEFONE',
  CELULAR = 'CELULAR',
  ENDERECO = 'ENDERECO',
  DATA_NASCIMENTO = 'DATA_NASCIMENTO',
  EMAIL = 'EMAIL',
  CPF = 'CPF'
}

const SuaConsulta = () => {
  const [openModal, setOpenModal] = useState(false)
  const [solicitacao, setSolicitacao] = useState<Option>()
  const [userDetails, setUserDetails] = useState<UserDetails>({} as UserDetails)
  const { search } = useLocation()
  const [solicitacaoSucesso, setSolicitacaoSucesso] = useState(false)


  useEffect(() => {
    const getDetails = async () => {
      const resp = await getUserDetails()
      setUserDetails(resp);
    }
    getDetails()
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('registroSAE')) {
      setSolicitacaoSucesso(true)
    }
  }, [])


  const closeModal = () => {
    setOpenModal(false)
    setSolicitacao(undefined)
  }
  const getSolicitacao = (value: { label: string, value: string | number }) => {
    setSolicitacao(value);
  };

  const isCorrecao = () => solicitacao?.value === TIPOS_SOLICITACAO.CORRECAO
  const showCorrecao = () => isCorrecao() && !!solicitacao

  const isInformacao = () => solicitacao?.value == TIPOS_SOLICITACAO.INFO_ORIGEM_FINALIDADE_COMPARTILHA
  const showInformacao = () => isInformacao() && !!solicitacao

  const isMaisSolicitacoes = () => solicitacao?.value === TIPOS_SOLICITACAO.MAIS_SOLICITACOES
  const showMaisSolicitacoes = () => isMaisSolicitacoes() && !!solicitacao

  const isOutrasSolicitacoes = () => solicitacao?.value === TIPOS_SOLICITACAO.OUTRAS_SOLICITACAO
  const showOutrasSolicitacoes = () => isOutrasSolicitacoes() && !!solicitacao

  const { deviceType } = useDeviceType();

  return (
    <Container>
      <div>
        <PageSkeleton
          title="Sua consulta"
          breadcrumb={{
            menuName: 'Sua consulta',
            submenuName: 'consulta',
          }}
          highlight={{
            title: 'Nesta página, encontrará todos os dados de consulta.',
            description: `Confira seus dados cadastrais, informações de registros e direitos na LGPD.`,
            image: require('../../assets/images/sua-consulta.png'),
            reverse: true
          }}
        >
          <Text tag="h3" size={2} weight={3} lineHeight={1} color="text">
            Dados pessoais
          </Text>
          <S.ContentPersonalInfo>
            <S.ContainerPersonalInfo>
              <S.PersonalInfo>
                <Text weight={3} lineHeight={1} color="text">
                  Nome:
                </Text>
                <Text weight={1} lineHeight={1} color="text">
                  {(userDetails?.nome && !userDetails?.nome.trim().toUpperCase().includes('NULL')) ? userDetails?.nome : '-'}
                </Text>
              </S.PersonalInfo>
              <S.PersonalInfo>
                <Text weight={3} lineHeight={1} color="text">
                  CPF:
                </Text>
                <Text weight={1} lineHeight={1} color="text">
                  {(userDetails?.detalheConsumidor?.cpf && userDetails?.detalheConsumidor?.cpf.trim().toUpperCase() != 'NULL')
                    ? userDetails?.detalheConsumidor?.cpf : '-'}
                </Text>
              </S.PersonalInfo>
              <S.PersonalInfo>
                <Text weight={3} lineHeight={1} color="text">
                  Telefone:
                </Text>
                <Text weight={1} lineHeight={1} color="text">
                  {(userDetails?.detalheConsumidor?.telefone && !userDetails?.detalheConsumidor?.telefone.trim().toUpperCase().includes('NULL'))
                    ? userDetails?.detalheConsumidor?.telefone : '-'}
                </Text>
              </S.PersonalInfo>
              <S.PersonalInfo>
                <Text weight={3} lineHeight={1} color="text">
                  Celular:
                </Text>
                <Text weight={1} lineHeight={1} color="text">
                  {(userDetails?.detalheConsumidor?.celular && !userDetails?.detalheConsumidor?.celular.trim().toUpperCase().includes('NULL'))
                    ? userDetails?.detalheConsumidor?.celular : '-'}
                </Text>
              </S.PersonalInfo>
              <S.PersonalInfo>
                <Text weight={3} lineHeight={1} color="text">
                  Endereço:
                </Text>
                <Text weight={1} lineHeight={1} color="text">
                  {(userDetails?.detalheConsumidor?.endereco && !userDetails?.detalheConsumidor?.endereco.trim().toUpperCase().includes('NULL'))
                    ? userDetails?.detalheConsumidor?.endereco : '-'}
                </Text>
              </S.PersonalInfo>
              <S.PersonalInfo>
                <Text weight={3} lineHeight={1} color="text">
                  Email:
                </Text>
                <Text weight={1} lineHeight={1} color="text">
                  {(userDetails?.detalheConsumidor?.email && !userDetails?.detalheConsumidor?.email.trim().toUpperCase().includes('NULL'))
                    ? userDetails?.detalheConsumidor?.email : '-'}
                </Text>
              </S.PersonalInfo>
            </S.ContainerPersonalInfo>
            <S.ContainerRecords>
              <Text tag="h4" size={2} weight={3} lineHeight={1} color="text">
                Registro SPC
              </Text>
              <S.ContainerRecordsInformation>
                <Accordion contentHeight="8rem" title="Registros no SPC">
                  <Text weight={3} lineHeight={1} color="text">
                    Quantidade total: {userDetails?.qtdeRegistroSPC ?? '-'}
                  </Text>
                  <Text weight={3} lineHeight={1} color="text">
                    Valor total: {userDetails?.valorTotalRegistroSPC ?? '-'}
                  </Text>
                </Accordion>
              </S.ContainerRecordsInformation>
            </S.ContainerRecords>
            <S.ContainerRecords>
              <Text tag="h4" size={2} weight={3} lineHeight={1} color="text">
                Registro de Cheque Lojista
              </Text>
              <S.ContainerRecordsInformation>
                <Accordion contentHeight="8rem" title="Registros no SPC">
                  <Text weight={3} lineHeight={1} color="text">
                    Quantidade total: {userDetails?.qtdeRegistroCheque ?? '-'}
                  </Text>
                  <Text weight={3} lineHeight={1} color="text">
                    Valor total: {userDetails?.valorTotalRegistroCheque ?? '-'}
                  </Text>
                </Accordion>
              </S.ContainerRecordsInformation>
            </S.ContainerRecords>
            <S.ContainerRequest>
              <Text size={0} color="text">Deseja encaminhar sua solicitação?</Text>
              <Button onClick={() => setOpenModal(true)} btnStyle="normal">Fazer solicitação</Button>
            </S.ContainerRequest>
          </S.ContentPersonalInfo>

          <Modal isOpen={solicitacaoSucesso} onToggleModal={() => setSolicitacaoSucesso(false)}>
            <S.ModalSuccess>
              <Text tag='h4' size={2}>Solicitação enviada com sucesso!</Text>
              <Button onClick={() => {
                setOpenModal(true)
                setSolicitacaoSucesso(false)
              }} btnStyle="normal">Enviar nova solicitação</Button>
            </S.ModalSuccess>
          </Modal>
          <Modal isOpen={openModal} onToggleModal={closeModal} title="Nova solicitação">
            <S.ModalContentContainer deviceType={{ type: deviceType }}>
              {!solicitacao && <Selector onSelect={getSolicitacao} options={
                [
                  { label: 'Correção', value: 'CORRECAO' },
                  { label: 'Informações sobre origem, finalidade ou compartilhamento', value: 'INFO_ORIGEM_FINALIDADE_COMPARTILHA' },
                  { label: 'Mais solicitações', value: 'MAIS_SOLICITACOES' },
                  { label: 'Não achou o que procurava?', value: 'OUTRAS_SOLICITACAO' }
                ]
              }></Selector>}
              {
                showCorrecao() && <Correcao {...userDetails} />
              }
              {
                showInformacao() && <Informacoes {...userDetails} />
              }
              {
                showMaisSolicitacoes() && <MaisSolicitacoes {...userDetails} />
              }
              {
                showOutrasSolicitacoes() && <OutrasSolicitacoes {...userDetails} />
              }
            </S.ModalContentContainer>
          </Modal>
        </PageSkeleton>
      </div>
    </Container>
  )
}

export default SuaConsulta
