import { api } from './api';

export const consultas = {
  post: async (params: any) => {
    const res = await api().post(
      '/cadastropositivo-consulta-service/consulta/consulta-empresa/',
      params);
    return res.data;
  },

  getQueriesAccomplished: async (params: any) => {
    const res = await api().post(
      `/cadastropositivo-consulta-service/consulta/consultas-realizadas/`,
      params);
    return res.data;
  },
};
