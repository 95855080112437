import useDeviceType from 'hooks/useDeviceType';
import { DetailBlock, TableCell, TableRow } from 'pages/Cadastro/HistoricoPagamentos/components/OperationDetails/style'
import { documentFormatter } from 'utils/documentFormatter'
import { formatUnixTime } from 'utils/formatUnixTime'

const CreditoRotativo = ({ details, payments }: any) => {
  const { deviceType } = useDeviceType();
  return (
    <>
      <DetailBlock deviceType={{ type: deviceType }}>
        <h1>Dados da Fonte de Informação</h1>
        <p>
        <b>Nome da fonte: </b>
          <span>{details?.nomeComercialFonteOrigem ?? ''}</span>
        </p>
        <p>
          <b>CNPJ da fonte: </b>
          <span>{details?.cnpjFonteOrigemFormatado ?? (documentFormatter(details?.cnpjFonteOrigem?.toString() ?? ''))}</span>
        </p>
      </DetailBlock>
      <DetailBlock deviceType={{ type: deviceType }}>
        <h1>Dados do Contrato</h1>
        <p>
          <b>Natureza da operação: </b>
         <span> {details?.naturezaOperacao}</span>
        </p>
        <p>
          <b>Número do contrato: </b>
         <span> {details?.numeroContrato}</span>
        </p>
        <p>
          <b>Data da contratação: </b>
         <span> {formatUnixTime(details?.dataContratacao ?? '')}</span>
        </p>
        <p>
          <b>Data de vencimento do contrato: </b>
         <span> {formatUnixTime(details?.dataVencimento)}</span>
        </p>
        <p>
          <b>Data de fechamento atual: </b>
          <span>{(payments[0]?.dataProximoFechamento && (payments[0]?.tipoMovimento == "ATUAL" || payments[0]?.tipoMovimento == "ATU")) ?
            (formatUnixTime(payments[0]?.dataProximoFechamento))
            : ("")
          }</span>
        </p>
        <p>
          <b>Saldo utilizado até o fechamento atual: </b>
          <span>{(payments[0]?.saldoUtilizado && (payments[0]?.tipoMovimento == "ATUAL" || payments[0]?.tipoMovimento == "ATU")) ?
            (payments[0]?.saldoUtilizado?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            }))
              : (0
              .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }))
          }</span>
        </p>
        <p>
          <b>Data da última atualização: </b>
          <span>{formatUnixTime(details?.dataAlteracao)}</span>
        </p>
      </DetailBlock>
    </>
  )
}

export default CreditoRotativo
