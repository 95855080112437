import { Button, Checkbox, Input, Modal, Radio, Text } from 'components';
import { FilterIcon } from 'components/Icon';
import { AuthContext, CadastroContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import {
  FilterBar,
  InputGroup,
} from './styles';

export const Filter = () => {
  const FilterFactory = {
    nomeOuCnpjConsulente: '',
    dataInicial: '',
    dataFinal: '',
    statusList: [],
  };
  const [isModalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState(FilterFactory);
  const { getPermissions } = useContext(CadastroContext);
  const { userData } = useContext(AuthContext);
  const [checked, setChecked] = useState([]);
  const [isDateValid, setIsDateValid] = useState(true);
  const [dateErrorMessage, setDateErrorMessage] = useState('');


  useEffect(() => {
    if (!isModalOpen) {
      handleCleanForm();
    }
  }, [isModalOpen]);

  const handleFillterForm = () => {
    if (userData.cpf !== "" && userData.cpf !== undefined) {
      getPermissions({ cpfConsumidor: userData.cpf ?? '', ...formValues }, true);
    } else {
      getPermissions({ cnpjConsumidor: userData.cnpj ?? '', ...formValues }, true);
    }
    handleCleanForm();
  };

  const convertToDate = (input: string) => {
    const [day, month, year] = input.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const isEndDateValid = (startDate: string, endDate: string) => {
    if (startDate === '' || endDate === '') {
      return true;
    }

    const start = convertToDate(startDate);
    const end = convertToDate(endDate);

    return end >= start;
  };

  const handleFillForm = (fieldName: string, fieldValue: string) => {
    if (fieldName === 'dataFinal') {
      if (!isEndDateValid(formValues.dataInicial, fieldValue)) {
        setIsDateValid(false);
        setDateErrorMessage('A data final deve ser maior ou igual à data inicial');
      } else {
        setIsDateValid(true);
        setDateErrorMessage('');
      }
    }
    setFormValues({ ...formValues, [fieldName]: fieldValue });
  };


  const handleCleanForm = () => {
    setChecked([]);
    setFormValues(FilterFactory);
  };

  return (
    <FilterBar>
      <Button btnStyle='action' onClick={() => setModalOpen(!isModalOpen)}>
        <FilterIcon />
        Filtrar
      </Button>
      <Modal
        title="Filtros"
        isOpen={isModalOpen}
        reverseFooterButtons
        alignModalFooter='flex-start'
        onToggleModal={() => setModalOpen(!isModalOpen)}
        primaryActionButton={{
          label: 'Aplicar',
          action: () => {
            if (isDateValid) {
              handleFillterForm();
            }
          },
          closeOnclick: true,
          disabled: !isDateValid,
        }}
        secondaryActionButton={{
          label: 'Limpar todos',
          action: () => handleCleanForm(),
        }}>
        <Input
          id='nomeOuCnpjConsulente'
          inputText={formValues.nomeOuCnpjConsulente}
          label="Pesquisar por:"
          placeholder='Nome da empresa ou CNPJ'
          onChange={(e: any) => handleFillForm('nomeOuCnpjConsulente', e)}
        />
        <div style={{ marginTop: '2rem', marginBottom: '-1rem' }} >
          <Text weight={2} size={0.5}>Filtrar por data:</Text>
        </div>
        <InputGroup>
          <div style={{ marginBottom: '1rem' }} >
            <Input
              type="date"
              id='dataInicial'
              inputText={formValues.dataInicial}
              label='Data inicial:'
              placeholder='dd/mm/aaaa'
              onChange={(e: any) => handleFillForm('dataInicial', e)}
            />
            <Input
              type="date"
              id='dataFinal'
              inputText={formValues.dataFinal}
              label='Data final:'
              placeholder='dd/mm/aaaa'
              onChange={(e: any) => handleFillForm('dataFinal', e)}
            />
          </div>
          {dateErrorMessage && <p style={{ color: 'red' }}>{dateErrorMessage}</p>}
        </InputGroup>

        <Checkbox
          id="status"
          name="status"
          label='Filtrar por status:'
          value={formValues.statusList}
          checkdList={checked}
          onCheck={(e: any) => handleFillForm('statusList', e)}
          options={[
            { label: 'Ativa', value: 'ativa' },
            { label: 'Expirada', value: 'expirada' },
            { label: 'Excluída', value: 'excluída' },
          ]}
        />
      </Modal>
    </FilterBar >
  );
};
