import { Button, Text } from 'components';
import { BackToTop } from 'components/Icon';
import { Link, useNavigate } from 'react-router-dom';
import { FooterContainer, FooterLink, FooterLinks, StyledFooter } from './styles';
import { useContext } from 'react';
import { AuthContext } from 'contexts';
import useDeviceType from 'hooks/useDeviceType';

const Footer = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  const { deviceType } = useDeviceType();
  const shouldShowCancelButton = () => {
    const storedStatus = localStorage.getItem('SPC_StatusCadPos') || ''
    const objectData = storedStatus ? JSON.parse(storedStatus) : {};

    return isAuthenticated && (objectData !== 'CANCELADO' && objectData !== 'APTO');
  };

  return (
    <StyledFooter deviceType={{ type: deviceType }}  >
      <FooterContainer deviceType={{ type: deviceType }}>
        <div>
          <Text size={1} weight={2} color='buttonLabel'>Cadastro Positivo</Text>
          <FooterLink deviceType={{ type: deviceType }}
            href="https://spcbrasil.org.br/lgpd/politicas.html" target="_blank">
            Termo e condições de uso
          </FooterLink >
          {shouldShowCancelButton() && (
            <FooterLinks onClick={() => navigate('/cadastro-positivo/cancelamento')}>Cancelamento</FooterLinks>
          )}
        </div>
        <Button btnStyle='top' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <BackToTop />
        </Button>
      </FooterContainer>
    </StyledFooter>
  );
};

export { Footer };
