export const lightGray = {
  BCBCBC: '#BCBCBC',
  CACACA: '#CACACA',
  D5DDED: '#D5DDED',
  E4E4E4: '#E4E4E4',
  E5E5E5: '#E5E5E5',
  E6E6E6: '#E6E6E6',
  EAEAEA: '#EAEAEA',
  EBF5FF: '#EBF5FF',
  EEEEEE: '#EEEEEE',
  EFF3FE: '#EFF3FE',
  FDFDFD: '#FDFDFD',
};
