
import React from 'react'
import moment from 'moment'

const TermoLiberacaoPJ = ({ data }: any) => {

  const { name, cnpj, date, dataInicialPermissao, dataFinalPermissao, instituicaoFinanceira } = data

  const validate = () => {
    const tomorrow = moment(dataInicialPermissao, "DD-MM-YYYY").add(1, 'days').format('L')

    if (dataFinalPermissao == tomorrow) {
      return <div>
        ( X ) para consulta apenas nesta data;
        <br />
        (&nbsp; &nbsp;  ) até ___/___/______; ou
        <br />
        {instituicaoFinanceira ? ("( X )") : (<span>(&nbsp; &nbsp;)</span>)}
        &nbsp;pelo período em que vigorar a relação com a instituição financeira (somente em caso de consulente que
        seja instituição autorizada a funcionar pelo Banco Central do Brasil)
      </div>
    } else if (dataFinalPermissao !== tomorrow) {
      return <div>
        (&nbsp; &nbsp;  ) para consulta apenas nesta data;
        <br />
        ( X ) De {dataInicialPermissao} até {dataFinalPermissao}; ou
        <br />
        {instituicaoFinanceira ? ("( X )") : (<span>(&nbsp; &nbsp;)</span>)}
        &nbsp;pelo período em que vigorar a relação com a instituição financeira (somente em caso de consulente que
        seja instituição autorizada a funcionar pelo Banco Central do Brasil)
      </div>
    }
    else {
      return <div>
        (&nbsp; &nbsp;  ) para consulta apenas nesta data;
        <br />
        ( X ) De {dataInicialPermissao} até {dataFinalPermissao}; ou
        <br />
        {instituicaoFinanceira ? ("( X )") : (<span>(&nbsp; &nbsp;)</span>)}
        &nbsp;pelo período em que vigorar a relação com a instituição financeira (somente em caso de consulente que
        seja instituição autorizada a funcionar pelo Banco Central do Brasil)
      </div>
    }
  }

  return (
    <>
      <h3 style={{ textAlign: 'center' }}>AUTORIZAÇÃO PARA DISPONIBILIZAÇÃO DE HISTÓRICO DE CRÉDITO A CONSULENTES</h3>
      <div style={{ lineHeight: '1.3rem' }}>
        <div>
          <b>1 -</b> Por este instrumento particular, eu <b>{name}</b>, inscrito no CNPJ n° {cnpj}, AUTORIZO
          o GESTOR DE BANCO DE DADOS (GBD), SPC BRASIL, com o nº do CNPJ 34.173.682/0003-18 de que trata a Lei n° 12.414/2011 e
          do Decreto nº. 9936/2019, a disponibilizar à(ao) xxxxxxxxxxxxxxx, inscrita no CNPJ n° xxxxxxxxxxxxx, o meu histórico
          de crédito, o qual abrangerá os dados financeiros e de pagamentos relativos às operações de crédito e obrigações de
          pagamento adimplidas em seus respectivos vencimentos, e aquelas a vencer, constantes de banco(s) de dados, com a
          finalidade única e exclusiva de subsidiar a análise e a eventual concessão de crédito, a venda a prazo ou outras
          transações comerciais e empresariais que impliquem risco financeiro.
        </div>
        <br />
        <div><b>2 -</b> Validade da autorização:</div>
        <div>
          {validate()}
        </div>
        <br />
        <div>
          <b>3 -</b> Saliento, outrossim, nos termos da legislação aplicável em vigor, ter pleno conhecimento de que a
          presente autorização poderá ser revogada, a qualquer tempo, perante o Gestor de Banco de Dados.
        </div>
        <br />
        <div>
          <b>4 -</b> Por derradeiro, declaro ter lido e compreendido, integralmente, o sentido do presente requerimento,
          estando de pleno acordo com o pedido ora apresentado.
        </div>
        <br />
        <div>
          São Paulo, {date}.
          <br />
          <br />
          Assinatura: {name}
        </div>
      </div>
    </>
  )
}

export default TermoLiberacaoPJ
