import { DeviceTypeProps } from 'types/Responsive.types';
import styled from 'styled-components';

export const StyledFormFilter = styled.div`
  display: contents;
`;

export const FieldGroupContainer = styled.div`

  ${({ theme }) => `
    padding: 17px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.lightGray['D5DDED']};
    }
  `}
`;

export const DateRange = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  gap: 12px;
  width: 100%;
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
    return `
        flex-direction: column;
      `;
  }}

`;
