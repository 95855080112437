import { LoadingSpinner } from 'components/LoadingSpinner';
import { PageSkeleton } from 'components/PageSkeleton';
import { QueryInfoList } from 'components/QueryInfoList';
import { Text } from 'components/Text';
import { AuthContext } from 'contexts';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { capitalizeString } from 'utils/capitalizeString';
import { QueryContainer, SearchContainer } from '../Contestacao/styles';
import { Filters, FormFilter, factoryFilters } from './components/FormFilter';
import { ContentContainerBloqueio, Dot, ScoreStatus, StatusContainer } from './styles';
import useGetBlockedHistory from 'hooks/useGetBlockedHistory';
import MessageAlert from 'components/MessageAlert';
import useDeviceType from 'hooks/useDeviceType';
import { Container } from 'components/Container';


interface QueryList {
  statusBloqueio: string
  status: string
  nomeBiroOrigem: string
  dataInicial: number
}

interface FormattedQuery {
  day: number
  month: string
  data: QueryList[]
}

const initFilter: Filters = {
  documento: '',
  tipoData: '',
  listaStatus: '',
  dataInicial: '',
  dataFinal: '',
  maxResult: 10,
};

const factoryList: FormattedQuery[] = [
  {
    day: 0,
    month: '',
    data: [{
      statusBloqueio: '',
      status: '',
      nomeBiroOrigem: '',
      dataInicial: 0,
    }],
  },
];

export const BloqueioDesbloqueio = () => {
  const { userData } = useContext(AuthContext);
  const { isBloqued, isLoading, companies, getCompanyData, getIsBloqued } = useGetBlockedHistory();
  const [userName, setUserName] = useState('');
  const [filteredList, setFilteredList] = useState<FormattedQuery[]>(factoryList);
  const [filters, setFilters] = useState(factoryFilters);

  const updateStatusBloqueado = () => {
    return getIsBloqued();
  }

  const { dataInicial, dataFinal, listaStatus, tipoData } = filters;
  const isFilterActive = !!dataInicial || !!dataFinal || !!listaStatus || !!tipoData;

  useEffect(() => {
    if (userData) {
      setUserName(capitalizeString(
        userData?.tipoConsumidor === 'PF'
          ? userData?.nome.split(' ')[0] ?? ''
          : userData?.nomeEmpresarial.split(' ')[0] ?? ''
      )
      );
      setFilteredList(groupQueryByDate(companies));
      updateStatusBloqueado();
    }
  }, [userData, companies]);


  useEffect(() => {
    getCompanyData(filters);
  }, [filters]);

  const getModalFilters = (filters: Filters) => {
    setFilters(filters);
  };

  const handleStatus = (status: string) => {
    switch (status) {
      case 'A':
        return 'Ativo';
      case 'E':
        return 'Expirado';
      case 'X':
        return 'Excluído';
      case 'C':
        return 'Cancelado';
      default:
        return 'Sem informação de status';
    }
  }

  const groupQueryByDate = (query: QueryList[]) => {
    return query
      .reduce((group: FormattedQuery[], item: QueryList) => {
        const { dataInicial } = item;
        const existingDay = moment(dataInicial, 'DD/MM/YYYY HH:mm:ss').format('DD');
        const existingMonth = moment(dataInicial, 'DD/MM/YYYY HH:mm:ss').format('MMM');

        const hasItem = group.findIndex((item: FormattedQuery) =>
          item.day == Number(existingDay) && item.month == existingMonth);
        hasItem >= 0 ? group[hasItem].data.push(item) : group.push({
          day: Number(existingDay),
          month: existingMonth,
          data: [item],
        });
        return group;
      }, []);
  };

  const formatQueryList = (item: QueryList[]) => {
    const formattedList: {
      title: string
      description: string
      status: JSX.Element
    }[] = [];

    for (const query of item) {
      formattedList.push({
        title: query.statusBloqueio,
        description: query.nomeBiroOrigem,
        status: <ScoreStatus color={handleStatus(query.status) === 'Ativo' ? '#2E9C07' : '#ED2F48'}>
          <span><b>&#8226; </b>{handleStatus(query.status)}</span>
          <p>{moment(query.dataInicial, 'DD/MM/YYYY HH:mm:ss').format('DD/MM/YYYY')}</p>
        </ScoreStatus>,
      });
    }

    return formattedList;
  };

  const { deviceType } = useDeviceType();

  return (
    <Container>
      <div >
        <PageSkeleton
          title="Bloqueio e Desbloqueio do Score"
          breadcrumb={{
            menuName: 'Início',
            submenuName: 'Cadastro Positivo',
            thirdLevel: 'Bloqueio e Desbloqueio do Score',
          }}
          highlight={{
            title: ' Se desejar, você pode bloquear o seu score. ',
            description: `Ao bloquear o seu Score, sua pontuação <b>não</b> ficará
        mais visível para consultas feitas por terceiros.
        Para bloquear ou desbloquear o seu score, entre em
        contato com o <b>Serviço de Atendimento ao Cliente, entre 09h00 e às 18h00.</b>`,
            image: require('../../../../src/assets/images/bloqueio-desbloqueio.png'),
          }}
        >
          <div>
            <LoadingSpinner state={isLoading} />
          </div>
          <ContentContainerBloqueio deviceType={{ type: deviceType }}>
            <StatusContainer deviceType={{ type: deviceType }}>
              <Dot color={(isBloqued) ? '#B90000' : '#00B912'} deviceType={{ type: deviceType }} />
              <p>{userName}, seu <b>Score está {(isBloqued) ? 'bloqueado' : 'desbloqueado'} .</b></p>
            </StatusContainer>
            <Text size={2} weight={3} lineHeight={0} color={'text'}>
              Histórico de Bloqueio e Desbloqueio
            </Text>
          </ContentContainerBloqueio>
          <QueryContainer>
            <SearchContainer>
              <FormFilter sendFilters={getModalFilters} />
            </SearchContainer>
            {
              (filteredList.length > 0)
                ?
                (filteredList.map((item, index) => (
                  <QueryInfoList
                    key={index}
                    day={item?.day}
                    month={item?.month}
                    queryList={formatQueryList(item?.data)}
                  />
                )))
                :
                (
                  <div style={{ width: "100%" }}>
                    <MessageAlert data={filteredList} filter={isFilterActive && true} />
                  </div>
                )
            }
          </QueryContainer>
        </PageSkeleton>
      </div>
    </Container>
  );
};
