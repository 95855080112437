import {
  AlertCircleIcon,
  ChatIcon,
  ClipboardIcon,
  ExitIcon,
  KeyIcon,
  PaymentHistoryIcon,
  QueryHistoryIcon,
  UnlockIcon,
} from 'components/Icon';
import { AiOutlineHome, AiOutlineLike } from 'react-icons/ai';
import { BiExit } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { FiPieChart } from 'react-icons/fi';

export const getMenuItems = (documentType: string, deviceType?: string) => {
  const menuItems = [{
    url: '/home/score',
    label: 'Início',
    icon: <AiOutlineHome />,
  },
  {
    url: '/home/cadastro-positivo',
    label: 'Cadastro Positivo',
    icon: <FiPieChart />,
    submenu: [
      {
        title: 'Histórico de Pagamentos',
        description: 'Veja as informações consolidadas que compõem seu Cadastro Positivo',
        icon: <PaymentHistoryIcon />,
        url: '/cadastro-positivo/historico-pagamentos',
      },
      {
        title: 'Contestação',
        description: 'Veja as contestações realizadas de movimentações inadequadas do seu histórico de pagamentos.',
        icon: <AlertCircleIcon />,
        url: '/cadastro-positivo/contestacao',
      },
      {
        title: 'Histórico de Consulta',
        description: `Veja quem consultou seu ${documentType}.`,
        icon: <QueryHistoryIcon />,
        url: '/cadastro-positivo/historico-consulta',
      },
      {
        title: 'Liberação ao Histórico de Pagamentos',
        description: 'Autorize empresas à acessarem seu histórico de pagamentos.',
        icon: <UnlockIcon />,
        url: '/cadastro-positivo/liberacao-historico',
      },
      {
        title: 'Dados de Comunicação',
        description: 'Veja os dados de comunicação de abertura do seu Cadastro Positivo.',
        icon: <ChatIcon />,
        url: '/cadastro-positivo/dados-comunicacao',
      },
      {
        title: 'Cancelamento',
        description: 'Faça o cancelamento do seu Cadastro Positivo.',
        icon: <ExitIcon />,
        url: '/cadastro-positivo/cancelamento',
      },
      {
        title: 'Protocolos',
        description: `Veja os protocolos abertos no SPC para o seu ${documentType}.`,
        icon: <ClipboardIcon />,
        url: '/cadastro-positivo/protocolos',
      },
      {
        title: 'Bloqueio e Desbloqueio do Score',
        description: 'Escolha se seu Score ficará acessível ou não para as empresas.',
        icon: <KeyIcon />,
        url: '/cadastro-positivo/bloqueio-desbolqueio-score',
      },
    ],
  },
  {
    url: '/sua-consulta',
    label: 'Sua Consulta',
    icon: <BsSearch />,
  },
  {
    url: '/vantagens',
    label: 'Vantagens',
    icon: <AiOutlineLike />,
  },
  ];

  return menuItems;
};

export const getMenuItemsCancelado = (documentType: string, deviceType?: string) => {
  const menuItems = [{
    url: '/home/score',
    label: 'Início',
    icon: <AiOutlineHome />,
  },
  {
    url: '/home/cadastro-positivo',
    label: 'Cadastro Positivo',
    icon: <FiPieChart />,
    submenu: [
      {
        title: 'Histórico de Pagamentos',
        description: 'Veja as informações consolidadas que compõem seu Cadastro Positivo',
        icon: <PaymentHistoryIcon />,
      },
      {
        title: 'Contestação',
        description: 'Veja as contestações realizadas de movimentações inadequadas do seu histórico de pagamentos.',
        icon: <AlertCircleIcon />,
      },
      {
        title: 'Histórico de Consulta',
        description: `Veja quem consultou seu ${documentType}.`,
        icon: <QueryHistoryIcon />,
      },
      {
        title: 'Liberação ao Histórico de Pagamentos',
        description: 'Autorize empresas à acessarem seu histórico de pagamentos.',
        icon: <UnlockIcon />,
      },
      {
        title: 'Dados de Comunicação',
        description: 'Veja os dados de comunicação de abertura do seu Cadastro Positivo.',
        icon: <ChatIcon />,
      },
      {
        title: 'Cancelamento',
        description: 'Faça o cancelamento do seu Cadastro Positivo.',
        icon: <ExitIcon />,
      },
      {
        title: 'Protocolos',
        description: `Veja os protocolos abertos no SPC para o seu ${documentType}.`,
        icon: <ClipboardIcon />,
      },
      {
        title: 'Bloqueio e Desbloqueio do Score',
        description: 'Escolha se seu Score ficará acessível ou não para as empresas.',
        icon: <KeyIcon />,
      },
    ],
  },
  {
    url: '/sua-consulta',
    label: 'Sua Consulta',
    icon: <BsSearch />,
  },
  {
    url: '/vantagens',
    label: 'Vantagens',
    icon: <AiOutlineLike />,
  }
  ];

  return menuItems;
};

