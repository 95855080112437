import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const MotiveContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  padding: 16px;
  border-radius: 6px;
  margin: 16px 0px;

  & h3 {
    font-weight: 500;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
    margin-top: 10px;
  };

  ${({ theme }) => `
    background: ${theme.color.lightGray['FDFDFD']};
    border: 1.4px solid ${theme.color.lightGray['EAEAEA']};
    color: ${theme.color.neutral['586682']};
  `}
`;

export const SourceContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
${({ deviceType }) => `
  margin: 22px 0;
  width: ${deviceType?.type === "mobile" || deviceType?.type === "mobile-landscape" ? "100%" : "auto"};
  
  & h3 {
    font-weight: 500;
    font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
    margin-top: 10px;
  };

  

  & input {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
    padding: 14px 10px;
    z-index: 2;
    cursor: pointer;
    user-select: none;
    width: ${deviceType?.type === "mobile" || deviceType?.type === "mobile-landscape" ? "100%" : " 400px"};
`}
    
    &:focus {
      outline: none;
    }
    
    ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    border: 1.4px solid ${theme.color.lightGray['D5DDED']};
      color: ${theme.color.neutral['586682']};
    `}
  }

  ${({ theme }) => `
    color: ${theme.color.neutral['586682']};
  `}
`;

export const OperationContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  position: relative;
  padding: 20px;
  padding-top: 16px;
  margin-top: 34px;
  border-radius: 6px;
  z-index: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  & h3 {
    font-weight: 600;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 2)}` : 'inherit'};
    margin-top: 16px;
    margin-bottom: 50px;
  };

  ${({ theme }) => `
    background: ${theme.color.lightGray['FDFDFD']};
    border: 1.4px solid ${theme.color.lightGray['EAEAEA']};
    color: ${theme.color.neutral['586682']};
  `}
`;

export const Divider = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ deviceType }) => `
  position: absolute;
  width: 100%;
  height: 1.4px;
  top: ${
    deviceType?.type === "mobile" ? "110px" :
    deviceType?.type === "mobile-landscape" ? "78px" :
    deviceType?.type === "tablet" ? "80px" :
    deviceType?.type === "laptop" ? "73px" :
    deviceType?.type === "desktop" ? "80px" :
    "10%" 
  };
  transform: translateY(-50%);
  left: 0;
  `}

  ${({ theme }) => `
    background: ${theme.color.lightGray['D5DDED']};
  `}
`;


export const TitleOperation = styled.div`
  width: 100%;
`;

export const ContainerSelection = styled.div`
  margin: 0 10px;
`;

