import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const CardContainer = styled.div<{ device?: DeviceTypeProps }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  
  &.listCards{
    max-width: 780px;
  }
  

  &.highlightSpace {
    margin-top: 40px;

    
 ${({ device }) => {
  if (device?.type === 'mobile')
    return `
      margin-top: 4px;
    `;
}}


    p {
      margin-left: 14px;
    }
  }

`;

export const Dot = styled.span<{ color: string }>`
  display: inline-block;
  vertical-align: middle; 
  width: 16px;
  height: 16px;
  margin-right: 12px;
  border-radius: 50%;

  ${({ color }) => `
    background: ${color};
  `}
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const InactiveBanner = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: auto;

  margin: ${(props) => (props.deviceType?.type === 'mobile' ? '0px' : '24px 18px')};
  padding: 10px;
  padding-left: 48px;
  background: linear-gradient(114.7deg, #007BFF -3.91%, #005ABA 103.48%);
  border-radius: 24px;
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
      padding: 0;
      flex-direction: column;
      justify-content: center;
      height: 320px;
      width: 80vw;
      `;
  }}

  ${({ deviceType }) => `
  img {
    width: ${deviceType?.type === 'mobile' ? '150px' : '241px'};
    height: ${deviceType?.type === 'mobile' ? 'auto' : '111px'};
    margin-right: ${deviceType?.type === 'mobile' ? '0px' : '43px'};
  }
`}

  div {
    margin: 10px;
  }
`;

export const TextBanner = styled.div<{ deviceType?: DeviceTypeProps }>`
  
  p{
    font-size: 18px;
  }

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
      font-size: 14px;
      `;
  }}
`
export const InactiveDescription = styled.div<{ deviceType?: DeviceTypeProps }>`
  font-weight: 400;
  margin: 0 18px;

  ${({ theme }) => `
    color: ${theme.color.darkGray['797979']};
  `}

  b {
    font-weight: 500;
  }

  h1 {
    font-weight: 600;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 2)}` : 'inherit'};    ;
    line-height: 28px;
    margin-top: 20px;

    ${({ theme }) => `
      color: ${theme.color.darkGray['606060']};
    `}
  }
  p{
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};    ;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 12px;
`;

export const Cards = styled.div`
  display: flex;
  justify-content: center;
`;
