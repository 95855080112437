import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const CardContainer = styled.div<{ deviceType?: DeviceTypeProps, isCenter?: boolean, isColumn?: boolean }>`
  margin: 16px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction:  ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'space-between')}; /
  width: ${({ deviceType }) =>
    deviceType?.type === 'mobile' ? '80vw' :
      deviceType?.type === 'desktop' ? '40vw' :
        deviceType?.type === 'laptop' ? '60vw' :
          deviceType?.type === 'tablet' ? '75vw' :
            '70vw'};


  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
`;

export const ContentBlock = styled.div`
  ${({ theme }) => `
    border-bottom: 1px solid ${theme.color.lightGray['E5E5E5']};
    padding: 12px 0;

    h4 {
      color: ${theme.color.darkGray['606060']};
    }
    span {
      color: ${theme.color.darkGray['797979']};
    }

    &.sem-borda-bottom{
      border-bottom: 0px;
    }


  `}
`;


export const AboutLink = styled.h4`
  ${({ theme }) => `
    color: ${theme.color.darkGray['4E5157']};
    cursor: pointer;
    text-align: center;
    margin-top: 30px;

    &:hover {
      color: ${theme.color.blue['007BFF']};
    }
  `}
`;

export const AboutParagraph = styled.h3<{ deviceType?: DeviceTypeProps }>`
font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
 
${({ theme, deviceType }) => `
    font-weight: 400;

    line-height: ${
      deviceType?.type === 'mobile' ? '18px' : deviceType?.type === 'mobile-landscape' || deviceType?.type === "tablet" ? '20px' : "22px"};

    margin-top: 8px;
    color: ${theme.color.darkGray['4E5157']};
  `}
`;

export const AboutTopic = styled.h2<{ deviceType?: DeviceTypeProps }>`
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
  font-weight: 600;
`;

export const TopicDescription = styled.h1<{ deviceType?: DeviceTypeProps }>`
${({ theme, deviceType }) => `
  color: ${theme.color.darkGray['606060']};
  font-weight: 400;
  font-size: ${deviceType?.type === 'mobile' ? '14px' : "16px"} ;
  margin-left: 16px;
`}
`;
