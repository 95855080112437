import React from 'react'

const TermoReaberturaPF = ({ data }: any) => {

  const { name, cpf, date } = data

  return (
    <>
      <h3 style={{ textAlign: 'center' }}>TERMO DE AUTORIZAÇÃO PARA REABERTURA DE CADASTRO POSITIVO</h3>
      <div style={{ lineHeight: '1.3rem' }}>
        <b>1 -</b> Por este instrumento particular, eu <b>{name}</b>, inscrito no CPF n° {cpf} solicito
        a <b>REABERTURA</b> de meu <i>cadastro positivo</i> aberto nos termos do artigo 5º, I da Lei nº. 12.414/2011 e do Decreto nº. 9936/2019
        constituído pelo meu histórico de crédito relativo às obrigações assumidas por mim perante quaisquer pessoas jurídicas
        ou naturais com as quwais eu mantenho/pretendo manter relação comercial ou creditícia, abrangendo os dados financeiros
        e de pagamentos relativos às operações de crédito e operações de pagamento adimplidas em seus respectivos vencimentos
        ou em atraso, e aquelas a vencer, no Banco de Dados abaixo indicado:
      </div>
      <br />
      <div style={{ lineHeight: '1.3rem' }}><b>2 -</b> Identificação do(s) Banco(s) de Dados originário(s) indicado(s):</div>
      <div>
        SPC BRASIL
        <div>
          CNPJ/MF nº: 34.173.682/0003-18
          <br />
          Endereço: Rua Leôncio de Carvalho, nº 234 – 13º andar –Paraíso– São Paulo/SP – CEP 04.003-010
        </div>
      </div>
      <br />
      <div style={{ lineHeight: '1.3rem' }}
      ><b>3 -</b> Saliento, outrossim, nos termos da legislação aplicável em vigor, ter pleno conhecimento de que o
        presente requerimento ensejará o cancelamento de meu Cadastro Positivo referente ao meu histórico de crédito
        mantido junto ao Banco de Dados supracitado, bem como de outros Gestores de Banco de Dados do mercado, sendo
        compartilhado no prazo de até dois dias 2 uteis.</div>
      <br />
      <div style={{ lineHeight: '1.3rem' }}
      ><b>4 -</b> Estou ciente de que poderei realizar a reabertura do Cadastro Positivo, a qualquer tempo, esta solicitação,
        perante a entidade receptora desta autorização para o cancelamento do cadastro ou perante o gestor do banco de dados
        detentor das informações.</div>
      <br />
      <div style={{ lineHeight: '1.3rem' }}
      ><b>5 -</b> Por derradeiro, declaro ter lido e compreendido, integralmente, o sentido do presente requerimento,
        estando de pleno acordo com o pedido ora apresentado.</div>
      <br />
      <div>
        São Paulo, {date}.
        <br />
        <br />
        {name}
      </div>
    </>
  )
}

export default TermoReaberturaPF
