import type { TextProps } from 'types';

import { StyledText } from './styles';
import useDeviceType from 'hooks/useDeviceType';

const Text = ({
  id,
  children,
  tag = 'p',
  color = 'default',
  size = 0,
  lineHeight = 0,
  weight = 1,
  align = 'left',
  ...rest
}: TextProps) => {
  const { deviceType } = useDeviceType();
  
  return (
    <StyledText
      id={id}
      as={tag}
      color={color}
      size={size}
      weight={weight}
      align={align}
      deviceType={{ type: deviceType }}
      {...rest}
    >
     {children}
    </StyledText>
  );
};

export { Text };
