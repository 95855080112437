import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledGaugeChart = styled.div<{ deviceType?: DeviceTypeProps }>`


svg {
    height: 188px;
    .needle {
      display: none;
    }
  }

  margin: 10px;
  p {
    position: relative;
    margin-top: 80px;
    ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
      margin-top: 68px;
        `;
  }}
  
  
  }
  
`;
