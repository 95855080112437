import { Container, LinkCard } from 'components';
import { useContext, useEffect, useState } from 'react';
import type { SubmenuItem, SubmenuList } from 'types';
import { StyledSubmenuHeader, SubmenuGrid } from '../styles';
import '../styles';
import { AuthContext } from 'contexts';
import useDeviceType from 'hooks/useDeviceType';

const SubmenuHeader = ({ submenuList, isActive, menuIndex, onClick }: SubmenuList) => {
  const { userData } = useContext(AuthContext);
  const [isMenuActive, setMenuActive] = useState(true);
  const { deviceType } = useDeviceType();

  const submenuCards = submenuList;

  const handleCloseSubmenu = (submenuName: string) => {
    setMenuActive(false);
    onClick()
  };

  useEffect(() => {
    setMenuActive(isActive);
  }, [isActive]);

  return (
    <StyledSubmenuHeader className={isMenuActive ? 'submenu-ativo' : 'submenu-inativo'}>
      <Container  center={true}>
        <SubmenuGrid>
          {
            submenuCards.map((item: SubmenuItem, index: number) => (
              <LinkCard isSidebar={deviceType === "mobile"} key={index} {...item} onClick={() => handleCloseSubmenu(item.title)} inactive={
                (item.title === "Bloqueio e Desbloqueio do Score" && userData?.tipoConsumidor === "PJ") ||
                userData?.statusSenha !== 'ATIVO'
              } />
            ))
          }
        </SubmenuGrid>
      </Container>
    </StyledSubmenuHeader>
  );
};

export { SubmenuHeader };

