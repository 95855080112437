import { Container, LoadingSpinner, PageSkeleton, QueryInfoList, } from 'components';
import { MessageAlertContainer, QueryContainer, SearchField } from './styles';
import { useEffect, useState } from 'react';
import useQueriesProtocolos from 'hooks/useQueriesProtocolos';
import { SearchContainer } from '../Contestacao/styles';
import { Filters, FormFilter, factoryFilters } from './components/FormFilter';
import moment from 'moment';
import MessageAlert from 'components/MessageAlert';
import useDeviceType from 'hooks/useDeviceType';

interface QueryList {
  day: number
  month: string
  query: FormattedQuery[]
}

interface FormattedQuery {
  title: string
  description: string
}

const Protocolos = () => {
  const { isLoading, queriesProtocolosList, getEventos } = useQueriesProtocolos();
  const [filteredList, setFilteredList] = useState<QueryList[]>([]);
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = useState(factoryFilters);

  const list = window.localStorage.getItem('SPC_Protocolos_List');

  const { protocolo, dataInicio, dataFim } = filters;
  const isFilterActive = !!protocolo || !!dataInicio || !!dataFim;

  useEffect(() => {
    filterResults();
    if (list !== null) setFilteredList(JSON.parse(list));
  }, [search, list]);

  useEffect(() => {
    getEventos(filters);
  }, [filters]);

  const getModalFilters = (filters: Filters) => {
    setFilters({
      ...filters,
      dataInicio:
        filters.dataInicio === '' ? filters.dataInicio : (moment(filters.dataInicio, 'DD/MM/YYYY').format('YYYY/MM/DD 00:00:00')),
      dataFim:
        filters.dataFim === '' ? filters.dataFim : (moment(filters.dataFim, 'DD/MM/YYYY').format('YYYY/MM/DD 23:59:59'))
    })
  };

  const filterResults = async () => {
    const finalFilter: QueryList[] = [];

    await Promise.all(
      queriesProtocolosList?.map(async (item: any) => {
        const filtered = await item.query.filter(
          (consulta: FormattedQuery) => consulta.title.toUpperCase().includes(search.toUpperCase()),
        );

        if (filtered.length > 0) {
          finalFilter.push({ ...item, query: filtered });
        }
      },
      ),
    ).then(() => setFilteredList(finalFilter));
  };

  const { deviceType } = useDeviceType();
  return (

    <Container>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <div style={{
        'marginLeft': deviceType !== "mobile" ? '-3rem' : '-1rem',
        'marginRight': deviceType !== "mobile" ? '-3rem' : '-1rem'
      }}>
        <PageSkeleton
          title="Protocolos"
          breadcrumb={{
            menuName: 'Início',
            submenuName: 'Cadastro Positivo',
            thirdLevel: 'Protocolos',
          }}
          highlight={{
            title: 'Nesta página, você pode visualizar todos os protocolos de atendimentos solicitados',
            description: `Se desejar um novo atendimento, você pode entrar em contato com o <b>Serviço de
            Atendimento ao Cliente, entre 09h00 e às 18h00.</b>`,
            image: require('../../../assets/images/protocolos.png'),
            reverse: true,
          }}
        >
          <QueryContainer>
            <SearchContainer>
              <FormFilter sendFilters={getModalFilters} />
            </SearchContainer>
            {(filteredList?.length > 0)
              ?
              (filteredList?.map((item, index) => (
                <QueryInfoList
                  key={index}
                  day={item.day}
                  month={item.month}
                  queryList={item.query}
                />
              ))
              )
              :
              (
                <MessageAlertContainer deviceType={{ type: deviceType }}
                >
                  <MessageAlert data={filteredList} filter={isFilterActive && true} />
                </MessageAlertContainer>
              )}
          </QueryContainer>
        </PageSkeleton>
      </div>
    </Container>
  );
}

export default Protocolos
