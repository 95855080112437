import { ScoreRangeProps } from 'types';
import { StyledScoreRange } from './styles';
import { RangeItem } from './_partials/RangeItem';
import useDeviceType from 'hooks/useDeviceType';

const ScoreRange = ({ score }: ScoreRangeProps) => {
  const rangeList = [
    { range: [0, 399], percent: '100%' },
    { range: [400, 599], percent: '31.9%' },
    { range: [600, 799], percent: '15.5%' },
    { range: [800, 1000], percent: '3.5%' },
  ];

  const { deviceType } = useDeviceType();
 
  return (
    <StyledScoreRange deviceType={{ type: deviceType }} >
      {
        rangeList.map((item) => (
          <RangeItem
            isScoreHere={score >= item.range[0] && score <= item.range[1]}
            score={item.range[0]}
            range={`${item.range[0]} a ${item.range[1]}`}
            percent={item.percent}
            deviceType={{ type: deviceType }}
          />
        ))
      }
    </StyledScoreRange>
  );
};

export { ScoreRange };
