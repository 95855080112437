import { Button, Modal, PageSkeleton, Text } from 'components';
import { AuthContext, RequestStatusContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { MdAccessTime } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Signature as signatureService } from 'services/Cadastro/signature';
import { Protocolo as protocoloService } from 'services/Cadastro/protocolo';
import { CancelDateTime, CheckContainer, ConfirmContainer, DateContainer, FooterContainer, InputTerm, ListCancelamento, ModalTerm, Paragraph, StyledCancelationForm, TermContainer } from './styles';
import TermoCancelamentoPF from './TermoCancelamentoPF';
import TermoCancelamentoPJ from './TermoCancelamentoPJ';
import moment from 'moment';
import { documentFormatter } from 'utils/documentFormatter';
import { LoadingSpinner } from 'components';
import useDeviceType from 'hooks/useDeviceType';

export const Cancelamento = () => {
  const { userData, getRepresentativesInfo } = useContext(AuthContext);
  const { addRequest } = useContext(RequestStatusContext);
  const [page, setPage] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(0);
  const [representatives, setRepresentatives] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.cnpj !== "" && userData?.cnpj !== undefined) {
      const fetchData = async () => {
        const reps = await getRepresentativesInfo({ document: userData?.cnpj });
        setRepresentatives(reps);
      };

      fetchData();
    }
  }, [userData, getRepresentativesInfo]);

  const findValidRepresentative = (representatives: any) => {
    if (!representatives || representatives.length === 0) {
      return null;
    }

    const today = moment();

    const validRepresentative = representatives.find((representative: { dataFimVigencia: moment.MomentInput; }) => {
      if (representative.dataFimVigencia === null) {
        return true;
      }
      const endDate = moment(representative.dataFimVigencia);

      return endDate.isSameOrAfter(today);
    });

    return validRepresentative;
  }

  const validRepresentative = findValidRepresentative(representatives);

  const handleButton = () => {
    if (page === 0) {
      setPage(1);
      setDisabled(true);
    } else {
      handleModal(2);
    }
  };

  const handleModal = (modal: number) => {
    setShowModal(modal);
  };

  const setCancelado = () => {
    localStorage.removeItem('SPC_StatusCadPos');
    localStorage.setItem('SPC_StatusCadPos', JSON.stringify('CANCELADO'));
  };


  const handleCancelation = () => {
    if (userData) {
      setLoading(true);
      signatureService.cancel({
        tipoConsumidor: ['CPF', 'PF'].includes(userData.tipoConsumidor ?? '') ? 'F' : 'J',
        documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
        justificativa: '',
        dataAcolhimento: getCurrentDate().dateCancel,
        ipOrigem: ''
      }).then(() => {
        setLoading(false);
        setCancelado();
        addRequest({
          statusCode: 200,
          message: 'Cancelamento realizado com sucesso!',
        });

        protocoloService.createEvento({
          cpf: userData.cpf,
          tipoCliente: 'CPF',
          tipoEvento: 'CANCELAR_ADESAO'
        })
        navigate('/home/cadastro-positivo?page=canceled');
      });
    }
  };

  const getCurrentDate = () => {
    const date = new Date();

    const day = '0' + date.getDate();
    const month = '0' + (date.getMonth() + 1);
    const year = date.getFullYear();
    const formattedDate = day.slice(-2) + '/' + month.slice(-2) + '/' + year;

    const hour = '0' + date.getHours();
    const minute = '0' + date.getMinutes();
    const formattedTime = hour.slice(-2) + ':' + minute.slice(-2);
    const formattedDateCancel = year + '-' + month.slice(-2) + '-' + day.slice(-2);
    return {
      dateCancel: formattedDateCancel,
      date: formattedDate,
      time: formattedTime,
    };
  };

  const { deviceType } = useDeviceType();

  return (

    <>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <PageSkeleton
        breadcrumb={{
          menuName: 'Início',
          submenuName: 'Cadastro Positivo',
          thirdLevel: 'Cancelamento',
        }}
      >
        <StyledCancelationForm deviceType={{ type: deviceType }}>
          <h1>Cancelamento</h1>
          {page === 0 ? (
            <>
              <Paragraph deviceType={{ type: deviceType }}>
                <p>Se desejar, você pode cancelar o seu Cadastro Positivo.</p>
                <p>Ao fazer isso, seu Cadastro Positivo é cancelado em todos os bureaus de crédito, e não somente no SPC.</p>
              </Paragraph>
              <Paragraph deviceType={{ type: deviceType }}>
                <p>
                  Ao realizar o cancelamento,
                  <b> todas as informações do seu Cadastro Positivo ficarão indisponíveis </b>
                  dentro do Portal do Consumidor, tais como:
                </p>
              </Paragraph>
              <ListCancelamento deviceType={{ type: deviceType }}>
                <li>O seu histórico de pagamentos realizados;</li>
                <li>A opção de realizar contestações sobre os pagamentos registrados;</li>
                <li>A visualização do histórico de consulta do seu score pelas empresas;</li>
                <li>A opção de liberação do seu histórico de pagamentos para as empresas;</li>
                <li>O detalhamento do bloqueio e desbloqueio do seu Score;</li>
                <li>Além de não constar as informações do seu Cadastro Positivo no seu Score;</li>
              </ListCancelamento>
              <Paragraph deviceType={{ type: deviceType }}>
                <p>Após realizar o cancelamento, você poderá retornar ao Cadastro Positivo quando desejar.</p>
              </Paragraph>
            </>
          ) : (
            <>
              <h4>Leia e concorde com o Termo do Cadastro Positivo, disponível abaixo para cancelar:</h4>
              <TermContainer>
                {userData?.tipoConsumidor == 'PF' ? (
                  <TermoCancelamentoPF data={{
                    name: userData?.nome,
                    cpf: userData?.cpf,
                    date: moment().locale('PT-BR').format('LL'),
                  }} />
                ) : (
                  <TermoCancelamentoPJ data={{
                    representativeName: validRepresentative?.nome,
                    representativeCPF: validRepresentative?.cpf,
                    representativeRg: validRepresentative?.rg,
                    businessName: userData?.nomeEmpresarial,
                    commercialName: userData?.nomeFantasia,
                    cnpj: documentFormatter(userData?.cnpj),
                    date: moment().locale('PT-BR').format('LL'),
                  }} />
                )
                }
              </TermContainer>
              <InputTerm>
                <CheckContainer deviceType={{ type: deviceType }} whitespace={true}>
                  <input type="checkbox" id="confirm" checked={!disabled} onChange={() => setDisabled(!disabled)} />
                  <span>Li e concordo com o 
                    <b onClick={() => handleModal(1)}> Termo do Cadastro Positivo</b>
                  </span>
                </CheckContainer>

              </InputTerm>
            </>
          )}
          <FooterContainer>
            <Button onClick={handleButton} disabled={disabled}>Cancelar Cadastro Positivo</Button>
          </FooterContainer>

          <Modal
            title="Termo de cancelamento do Cadastro Positivo"
            isOpen={showModal === 1}
            onToggleModal={() => handleModal(0)}
            primaryActionButton={{
              label: 'Li e aceito',
              action: () => setDisabled(false),
              closeOnclick: true,
            }}
          >
            <ModalTerm deviceType={{ type: deviceType }}>
              {userData?.tipoConsumidor == 'PF' ? (
                <TermoCancelamentoPF data={{
                  name: userData?.nome,
                  cpf: userData?.cpf,
                  date: moment().locale('PT-BR').format('LL'),
                }} />
              ) : (
                <TermoCancelamentoPJ data={{
                  representativeName: validRepresentative?.nome,
                  representativeCPF: validRepresentative?.cpf,
                  representativeRg: validRepresentative?.rg,
                  businessName: userData?.nomeEmpresarial,
                  commercialName: userData?.nomeFantasia,
                  cnpj: documentFormatter(userData?.cnpj),
                  date: moment().locale('PT-BR').format('LL'),
                }} />
              )
              }
            </ModalTerm>
          </Modal>

          <Modal
            title="Cancelamento do Cadastro Positivo"
            isOpen={showModal === 2}
            onToggleModal={() => handleModal(0)}
            primaryActionButton={{
              label: 'Confirmar',
              action: handleCancelation,
              closeOnclick: true,
            }}
            secondaryActionButton={{
              label: 'Voltar',
              action: () => null,
              closeOnclick: true,
            }}
            modalHeight={
              deviceType === 'mobile' ? '90vh' :
                deviceType === 'tablet' ? '80vh' :
                  deviceType === 'laptop' ? '60vh' :
                    deviceType === 'desktop' ? '30vh' : '70vh'
            }
          >
            <ConfirmContainer>
              <Text size={1} lineHeight={1} color='text'>
                Você está cancelando o Cadastro Positivo. Tem certeza que deseja confirmar?
              </Text>
              <CancelDateTime deviceType={{ type: deviceType }}>
                Data do cancelamento:
                <DateContainer >
                  <b>{getCurrentDate().date}</b>
                  <MdAccessTime />
                  {getCurrentDate().time}
                </DateContainer>
              </CancelDateTime>
            </ConfirmContainer>
          </Modal>
        </StyledCancelationForm>
      </PageSkeleton>
    </>
  );
};
