import { DecreaseIcon } from 'components/Icon/DecreaseIcon';
import { IncreaseIcon } from 'components/Icon/IncreaseIcon';
import { useContext, useEffect, useState } from 'react';
import {
  Motive,
  MotiveDescription,
  MotiveTag,
  MotiveTags,
  SelectedTag,
  StyledScoreChangeMotives,
} from './styles';
import { Text } from 'components';
import { score as scoreService } from 'services/Cadastro';
import { CadastroContext } from 'contexts';
import useDeviceType from 'hooks/useDeviceType';

const ScoreChangeMotives = () => {
  const { score } = useContext(CadastroContext);
  const [currentTab, setCurrentTab] = useState(0);
  const [scoreFactors, setScoreFactors] = useState([]);

  const getFactors = () => {
    const data: any = scoreService.getFactors(score).then((result) => {
      setScoreFactors(result);
    });
  };

  useEffect(() => {
    getFactors()
  }, []);

  const increaseScoreFactors = scoreFactors.filter((factors: any) => {
    return factors.fator === 'A';
  });

  const decreaseScoreFactors = scoreFactors.filter((factors: any) => {
    return factors.fator === 'D';
  });

  const { deviceType } = useDeviceType();

  return (
    <StyledScoreChangeMotives>
      <Text tag="h4" size={2} weight={3} lineHeight={1}>
        Motivos das alterações
      </Text>
      <Text color="text" size={1} lineHeight={1} tag="span">
        Veja o que aumenta ou diminui seu score.
      </Text>
      <MotiveTags deviceType={{ type: deviceType }} >
        <MotiveTag deviceType={{ type: deviceType }} selected={currentTab === 0} onClick={() => setCurrentTab(0)}>
          Fatores que aumentam
        </MotiveTag >
        <MotiveTag deviceType={{ type: deviceType }} selected={currentTab === 1} onClick={() => setCurrentTab(1)}>
          Fatores que diminuem
        </MotiveTag>
        <SelectedTag tag={currentTab} deviceType={{ type: deviceType }} />
      </MotiveTags>
      {currentTab === 0 ? (
        increaseScoreFactors.map((scoreFactor: any, index) => (
          <Motive key={index} deviceType={{ type: deviceType }}>
            <MotiveDescription deviceType={{ type: deviceType }}>
              <div>
                <IncreaseIcon />
              </div>
              <p>
                {scoreFactor.descricao}
              </p>
            </MotiveDescription>
          </Motive>
        ),
        )
      ) : (
        decreaseScoreFactors.map((scoreFactor: any, index) => (
          <Motive key={index}>
            <MotiveDescription  deviceType={{ type: deviceType }}>
              <div>
                <DecreaseIcon />
              </div>
              <p>
                {scoreFactor.descricao}
              </p>
            </MotiveDescription>
          </Motive>
        ),
        )
      )
      }
    </StyledScoreChangeMotives>
  );
};

export { ScoreChangeMotives };
