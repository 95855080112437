import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { PageSkeleton } from 'components/PageSkeleton';
import { LoadingSpinner, Text } from 'components';
import { RequestStatusContext } from 'contexts';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { contestacao as contestService } from 'services/Cadastro';
import { ContestClient } from '../ContestClient';
import { ContestOperation } from '../ContestOperation';
import { ConfirmMessage } from './styles';
import { useLocation } from 'react-router-dom';
import useDeviceType from 'hooks/useDeviceType';

const factoryFormData = {
  arvoreMotivos: {},
  cnpj: '',
  codigoMotivoJornadaCliente: '',
  codigoTipoFonte: '',
  cpf: '',
  idFonte: 0,
  idOperacao: null,
  modalidadeOperacao: '',
  numeroContrato: '',
  tipoConsumidor: '',
  tipoContestacao: '',
  codigoModalidade: '',
};

export const ContestForm = () => {
  const { addRequest } = useContext(RequestStatusContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);
  const [formData, setFormData] = useState(factoryFormData);
  const [loadingStatus, setLoagingStatus] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');

  const goBack = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);
    }
  };

  const handleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleSubmit = () => {
    if (type === 'client') {
      setLoagingStatus(true);
      contestService.contest.client(formData).then(() => {
        setComplete(true);
        setLoagingStatus(false);
      }).catch((error) => {
        if (error.response.status === 400) {
          addRequest({
            statusCode: error.response.status,
            message: error.response.data.message,
          });
        } else {
          addRequest({
            statusCode: error.response.status,
            message: 'Falha ao contestar a operação',
          });
        }
        setLoagingStatus(false);
      });
    }else if (type === 'operation') {
      setLoagingStatus(true);

      contestService.contest.consumidor(formData).then(() => {
        setComplete(true);
        setLoagingStatus(false);
      }).catch((error) => {
        if (error.response.status === 400) {
          addRequest({
            statusCode: error.response.status,
            message: error.response.data.message,
          });
        } else {
          addRequest({
            statusCode: error.response.status,
            message: 'Falha ao contestar a operação',
          });
        }
        setLoagingStatus(false);
      });
    }
  };

  const handleFormData = (data: any) => {
    handleModal();
    setFormData(data);
  };
  const { deviceType } = useDeviceType();

  if (complete) {
    return (
      <PageSkeleton>
        <ConfirmMessage deviceType={{ type: deviceType }}>
          <img src={require('../../../../../assets/images/contestacao-3.png')} />
          <h2>Sua contestação foi gerada com sucesso.</h2>
          <h3>Para acompanhar o andamento do processo de contestação, clique no botão abaixo.</h3>
          <Button onClick={() => navigate('../historico-pagamentos')}>Ir para o histórico de pagamentos</Button>
          <h4 onClick={() => navigate('../contestacao')}>Ver contestações</h4>
        </ConfirmMessage>
      </PageSkeleton>
    );
  }

  return (
    <PageSkeleton
      title='Realizar Contestação'
      highlight={{
        title: 'Nesta página, você está realizando uma \ncontestação.',
        description: 'Nessa etapa, você precisa selecionar algumas informações \nantes de realizar a contestação.',
        image: require('../../../../../assets/images/contestacao-1.png'),
        reverse: true,
      }}
      paddingSize='sm'
      backButton={{
        render: true,
        action: goBack,
      }}
      breadcrumb={{
        menuName: 'Cadastro Positivo',
        submenuName: 'Histórico de Pagamentos',
        thirdLevel: 'Realizar Contestação',
      }}
    >

      <div>
        <LoadingSpinner state={loadingStatus} />
      </div>
        {type === 'client' ?
          (
            <ContestClient onSubmit={handleFormData} />
          ) :
          (
            <ContestOperation onSubmit={handleFormData} />
          )}

      <Modal
        title={`Confirmação`}
        isOpen={isModalOpen}
        onToggleModal={handleModal}
        primaryActionButton={{
          label: 'Finalizar contestação',
          action: handleSubmit,
          closeOnclick: true,
        }}
        secondaryActionButton={{
          action: () => null,
          closeOnclick: true,
        }}
      >
        <Text size={1}>
          Deseja mesmo contestar essa informação?
        </Text>
      </Modal>
    </PageSkeleton>
  );
};
