import axios, { AxiosInstance } from 'axios';
import env from 'environments';

export const api: AxiosInstance = axios.create({
  baseURL: env.API_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Authorization': 'Basic N2RjMTdmYjgtYWM2MS00NmIyLWJiNGYtZDY2YTJmMjMwY2VjOmQ2MmYzNjhkLWY5YWUtNDllMC1hNzZiLTI3MDliNjc0ZjU3OA==',
  },
});


export const apiLogin: AxiosInstance = axios.create({
  baseURL: `${env.API_URL}/apicadpos`,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*',
    'Authorization': 'Basic Q0FEUE9TX0JBU0lDOmU0MWIyYzYzLTEzOTItNDAwNy05MTlhLTI1OTMzMDRhMzUyMA==',
  },
});
