import { Text } from 'components';
import { useEffect, useState } from 'react';
import { StatusAlertProps } from 'types';
import { StyledStatusAlert } from './styles';
import useDeviceType from 'hooks/useDeviceType';

const StatusAlert = ({ label, status = 'success', showAlert }: StatusAlertProps) => {
  const [toggleAlert, setToggleAlert] = useState(showAlert);
  const { deviceType } = useDeviceType();

  const handleToggle = (status?: boolean) => {
    setToggleAlert(status);
    setTimeout(() => {
      setToggleAlert(false);
    }, 4000);
  };

  useEffect(() => {
    handleToggle(showAlert);
  }, [showAlert]);

  return (
    <StyledStatusAlert status={status} showAlert={toggleAlert} onClick={() => setToggleAlert(false)} deviceType={{ type: deviceType }} >
      <Text color='title'>{label}</Text>
    </StyledStatusAlert>
  );
};

export { StatusAlert };
