import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

const ErrorStatus = css`
  ${({ theme }) => `
    border-color: ${theme.color.red['B90000']};
    background: #f3dada;
  `}
`;

const WarningStatus = css`
  ${({ theme }) => `
    border-color: ${theme.color.yellow['FFBC29']};
    background: ${theme.color.yellow['FFBC29']}25;
  `}
`;

const SuccessStatus = css`
  ${({ theme }) => `
    border-color: ${theme.color.green['3AC853']};
    background: #b6ebc0;
  `}
`;

export const StyledStatusAlert = styled.div<{ status?: string, showAlert?: boolean, deviceType?: DeviceTypeProps }>`
  ${({ status, showAlert, deviceType }) => css`
    align-items: center;
    border-radius: 16px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    padding: 10px 30px;
    position: fixed;
    transition: all 500ms ease-in-out;
    z-index: 28;
    width: ${deviceType?.type === 'mobile' ? '75vw;' : 'auto;'}

    left: 50%;
    transform: translateX(-50%);

    ${status === 'success' ?
      SuccessStatus : status === 'warning' ? WarningStatus : ErrorStatus}
    ${showAlert ?
     `bottom: ${deviceType?.type === 'mobile' ? '10px;' : '20px;'}` : `
      bottom: -500px;
`}

    
  `}
`;

