import { calculateFontSize } from 'components/Text/styles';
import { GoSearch } from 'react-icons/go';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DeviceTypeProps, HeaderProps } from 'types';

export const StyledHeader = styled.header<HeaderProps>`
  padding: 5px;
  border-bottom: 1px solid transparent;
  display: flex;
  align-items: center;
  
  @media (max-width: 767px) {
    border-bottom: 1px solid transparent;
  }
  
  ${({ loggedIn, theme }) =>
    loggedIn &&
    css`
    background: ${theme.color.white.FFFFFF};
      border-color: ${theme.color.lightGray.E5E5E5};
      `}

`;

export const LogoContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  margin-left: 24px;
  margin-top: 5px;
  
  img {
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      max-width: 90px;
      `;
    else if (deviceType?.type == 'tablet')
      return `
      max-width: 100px;
      `;
    else if (deviceType?.type == 'laptop')
      return `
      max-width: 110px;
      `;
    else if (deviceType?.type == 'desktop')
      return `
      max-width: 140px;
      `;
  }}

  }
`;

export const SearchContainer = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.color.white.F3F8FE};
    width: 494px;
    height: 56px;
    border-radius: 16px;
    display: flex;
    align-items: center;

    input {
      background: transparent;
      border: none;
      outline: transparent;
      height: 100%;
      padding-left: 24px;
      width: calc(100% - 64px);
      ::placeholder {
        font-size: ${theme.font.size[0]};
      }
    }
  `}
`;

export const SubmenuGrid = styled.div`

@media (min-width: 767px) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center
}
`;

export const SearchIcon = styled(GoSearch)`
  ${({ theme }) => css`
    color: ${theme.color.darkGray['4E5157']};
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  `}
`;

export const Button = styled.button`
    background: black;
`;

export const AccountContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    &:last-of-type {
      margin-right: 24px;
    }
  }

  
  ${({ deviceType }) => {
    if (deviceType?.type !== 'mobile')
      return `
      button {
        display: none
      }
      `;
  }}


`;

export const GridHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DropdownMobile = styled.div`
  margin-top: 20px;
`;

export const StyledSubmenuHeader = styled.div`
  ${({ theme }) => css`
  position: absolute;
  padding: 6px;
  left: 0;
  display:none;
  background: ${theme.color.white['FFFFFF']};
  `}
  z-index: 11;

  @media (max-width: 767px) {
    position: static;
    padding: 0px;
  }
`;

export const StyledMenu = styled.nav<{ deviceType?: DeviceTypeProps }>`
  position: relative;

  ${({ theme }) => css`
    background: ${theme.color.white['FFFFFF']};

    > * {
      align-items: stretch;
      height: 100%;
    }
  `}
`;

export const StyledNavList = styled.ul<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  list-style: none;
  margin: 0;
  padding: 0;
  background: white;
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
        flex-direction: column;
        position: absolute;
        right: 0;
        top: -90px;
        display: block; 
        z-index: 99;
      `;
  }}

`;

export const StyledListItem = styled.li<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0px;
  margin: 0px 14px;
  
  p {
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
  }

  ${({ deviceType }) => {
    if (deviceType?.type == 'laptop')
      return `
        margin: 0px 40px;
        padding: 18px 0px;
        `;
        else if (deviceType?.type == 'desktop')
        return `
        margin: 0px 60px;
        padding: 20px 0px;
      `;
  }}

  svg {
    height: 30px;
    width: 30px;
    margin-right: 16px;
    ${({ theme }) => `stroke: ${theme.color.blue['007BFF']};`}
    &[fill="currentColor"] {
      ${({ theme }) => `fill: ${theme.color.blue['007BFF']};`}
    }
  }
  ${({ theme }) => css`
  @media (max-width: 767px) {
    background: ${theme.color.white['FFFFFF']};
      
    `}
`;

export const StyledLinkItem = styled(NavLink)`

  ${({ theme }) => css`
    text-decoration: none;
    border-bottom: 4px solid transparent;
    
    &.active {
      border-bottom-color: ${theme.color.blue['007BFF']};
    }

    :hover {
     .submenu-ativo {
        display: block;
      }
    }
  `}
`;

export const BreadcrumbContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;
  justify-content:start;
  margin: 20px 50px;

  p {
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 2)}` : 'inherit'};
  }

  & > * {
    margin-right: 6px;
  }


  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile' || deviceType?.type == 'mobile-landscape')
      return `
      display: none;
      `;
   else  if (deviceType?.type == 'tablet')
      return `
      margin: 20px 0px;
      `;
    else if (deviceType?.type == 'laptop')
      return `
      margin: 20px 90px;
      `;
    else if (deviceType?.type == 'desktop')
      return `
      margin: 20px 120px;
      `;
  }}
`;
export const UserName = styled.div<{ deviceType?: DeviceTypeProps }>`
  margin: 20px 30px 0px;
  
  display: flex;
  align-items: end;
  flex-direction: column;

  ${({ deviceType }) => {
    if (deviceType?.type !== 'mobile')
      return `
      display:none;
      `;
  }}
  }
`