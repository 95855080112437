import { useState } from "react";

import { IconType } from "react-icons/lib";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { InputButton, InputWrapper, StyledInputPassword } from "../styles";

type InputType = "password" | "text";

const icon: Record<InputType, IconType> = {
    password: AiFillEyeInvisible,
    text: AiFillEye
}

export function InputPassword({ buttonStyle, valid, error, ...props }: any) {
    const [inputType, setInputType] = useState<InputType>("password");
    const ButtonIcon = icon[inputType];

    const showOrHidePassword = () => {
        const _inputType: Record<InputType, InputType> = {
            password: "text",
            text: "password"
        };

        setInputType(_inputType[inputType]);
    };

    return (
        <InputWrapper>
            <StyledInputPassword {...props} valid={valid} error={error} type={inputType} />

            <InputButton valid={valid} error={error} type="button" style={buttonStyle} onClick={showOrHidePassword} isPassword={true}>
                <ButtonIcon color="#494E58" size="20px" />
            </InputButton>
        </InputWrapper>
    );
}
