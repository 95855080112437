import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';


export const Title = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  margin: 15px 0px;

  ${({ theme }) => `
    color: ${theme.color.neutral['586682']};
  `}
`;

export const OperationInfo = styled.div<{ deviceType?: DeviceTypeProps }>`
  padding: 5px 16px;
  border-radius: 6px;
  font-weight: 400;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
  line-height: 20px;

  & h4 {
    font-weight: 500;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};    
    line-height: 25px;
  }

  & b {
    font-weight: 500;
  }

  ${({ theme }) => `
    background: ${theme.color.lightGray['FDFDFD']};
    border: 1.4px solid ${theme.color.lightGray['EAEAEA']};
    color: ${theme.color.neutral['586682']};
  `}
`;

export const DateSelect = styled.div`
  & select {
    width: 457px;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    border-radius: 6px;
    padding: 16px;

    &:focus {
      outline: none;
    }

    ${({ theme }) => `
      border: 1.4px solid ${theme.color.lightGray['D5DDED']};
      color: ${theme.color.neutral['586682']};
    `}
  }
`;

export const PriceInput = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items:center;
  padding-left: 10px;
  gap: 5px;
  font-weight: 400;
  font-size: 18px;
  border-radius: 6px;

  & input {
    border: none;
    font-weight: 400;
    font-size: 18px;
    ${({ theme }) => `
      color: ${theme.color.neutral['586682']};
    `}
    &:focus {
      outline: none;
    }
  }

  ${({ theme }) => `
    border: 1.4px solid ${theme.color.lightGray['D5DDED']};
    color: ${theme.color.neutral['586682']};
  `}
`;

export const ParcelInput = styled.div`
  & input {
    box-sizing: border-box;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    line-height: 31px;
    border-radius: 6px;
    padding: 10px;

    &:focus {
      outline: none;
    }

    ${({ theme }) => `
      border: 1.4px solid ${theme.color.lightGray['D5DDED']};
      color: ${theme.color.neutral['586682']};
    `}
  }
`;
