import { LoadingSpinner, Modal, Text } from 'components';
import { AuthContext, CadastroContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import Form from './Form';
import { TermContainer } from './styles';
import TermoLiberacaoPF from './TermoLiberacaoPF';
import TermoLiberacaoPJ from './TermoLiberacaoPJ';
import moment from 'moment';
import { renderToString } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import useDeviceType from 'hooks/useDeviceType';

type ModalLiberacaoProps = {
  isModalOpen?: boolean
  onToggleModal: () => void
  selectedCompany?: any
}

export const ModalLiberacao = ({ isModalOpen, onToggleModal, selectedCompany }: ModalLiberacaoProps,) => {
  const { deviceType } = useDeviceType();
  const { userData } = useContext(AuthContext);
  const { includePermission, history } = useContext(CadastroContext);
  const { getPermissions } = useContext(CadastroContext);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [legalOpen, setLegalOpen] = useState(false);
  const [isInformationEmpty, setInformationEmpty] = useState(true);
  const [modalFormInfo, setModalFormInfo] = useState({
    documentoConsumidor: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
    isConsultaUnica: false,
    tipoConsumidor: (userData.cpf !== "" && userData.cpf !== undefined) ? "PF" : "PJ",
    cnpj: "",
    nomeFantasia: "",
    status: "Expirada",
    dataInicialPermissao: "",
    dataFinalPermissao: "",
    versaoTermo: "",
    termoHtml: ""
  });
  const navigate = useNavigate();
  const [isLoadingLocal, setLoadingLocal] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<any>(false);

  const term = () => {
    if (userData.cpf !== "" && userData.cpf !== undefined) {
      setModalFormInfo({
        ...modalFormInfo,
        termoHtml: renderToString(<TermoLiberacaoPF data={{
          name: userData?.nome,
          cpf: userData?.cpf,
          nomeCompletoConsulente: modalFormInfo?.nomeFantasia,
          cnpjConsulente: modalFormInfo?.cnpj,
          dataInicialPermissao: modalFormInfo?.dataInicialPermissao,
          dataFinalPermissao: modalFormInfo?.dataFinalPermissao,
          date: moment().locale('PT-BR').format('LL'),
          protocol: ""
        }} />)
      })
    } else {
      setModalFormInfo({
        ...modalFormInfo,
        termoHtml: renderToString(<TermoLiberacaoPF data={{
          name: userData?.nome,
          cnpj: userData?.cnpj,
          nomeCompletoConsulente: modalFormInfo?.nomeFantasia,
          cnpjConsulente: modalFormInfo?.cnpj,
          dataInicialPermissao: modalFormInfo?.dataInicialPermissao,
          dataFinalPermissao: modalFormInfo?.dataFinalPermissao,
          date: moment().locale('PT-BR').format('LL'),
          protocol: ""
        }} />)
      })
    }
  }

  useEffect(() => {
    const areTheseValuesEmpty =
      !modalFormInfo.dataInicialPermissao ||
      !modalFormInfo.dataFinalPermissao;
    setInformationEmpty(areTheseValuesEmpty);
    if (modalFormInfo.termoHtml === "") {
      term()
    }
  }, [modalFormInfo]);

  const handleLegal = () => {
    setConfirmationOpen(false);
    setLegalOpen(false);
    term()
    setLoadingLocal(true);
    Promise.resolve(includePermission(modalFormInfo)).then(() => {
      if (userData.cpf !== "" && userData.cpf !== undefined) {
        getPermissions({ cpfConsumidor: userData.cpf ?? '' }, true);
      } else {
        getPermissions({ cnpjConsumidor: userData.cnpj ?? '' }, true);
      }
      onToggleModal();
    }).then(() => {
      navigate('/cadastro-positivo/liberacao-historico')
      setLoadingLocal(false);
    });
  };

  return (
    <>
      <div>
        <LoadingSpinner state={isLoadingLocal} />
      </div>
      <Modal
        alignModalFooter='center'
        isOpen={isModalOpen}
        onToggleModal={onToggleModal}
        title="Liberar histórico de pagamentos"
        secondaryActionButton={{
          label: 'Realizar liberação',
          action: () => setConfirmationOpen(true),
          disabled: isInformationEmpty || isSubmitDisabled,
        }}
      >
        <Form onChangeForm={setModalFormInfo} selectedCompany={selectedCompany} setIsSubmitDisabled={setIsSubmitDisabled} />
        <Modal
          title="Confirmação"
          isOpen={confirmationOpen}
          onToggleModal={() => setConfirmationOpen(!confirmationOpen)}
          primaryActionButton={{
            action: () => setLegalOpen(true),
          }}
          secondaryActionButton={{
            action: () => setConfirmationOpen(false),
          }}
        >

          {selectedCompany?.instituicaoFinanceira ? (
            <Text size={1}>
              Deseja mesmo liberar seu histórico de pagamento para a empresa
              <br /> <b>{selectedCompany?.nomeFantasia}</b> no período informado?
              <br /> Lembrando que para Instituições financeiras, você poderá liberar o seu histórico
              <br /> enquanto vigorar sua relação com a mesma.
            </Text>
          ) : (
            <Text size={1}>
              Deseja mesmo liberar seu histórico de pagamento <br />
              para a empresa {selectedCompany?.nomeFantasia}?
            </Text>
          )
          }

          <Modal
            title="Termo de consentimento para a  Liberação aos Pagamentos"
            isOpen={legalOpen}
            onToggleModal={() => onToggleModal()}
            primaryActionButton={{
              label: 'Li e aceito',
              action: () => handleLegal(),
            }}
          >
            <TermContainer deviceType={{ type: deviceType }}>
              {userData?.tipoConsumidor == 'PF' ? (
                <TermoLiberacaoPF data={{
                  name: userData?.nome,
                  cpf: userData?.cpf,
                  nomeCompletoConsulente: selectedCompany?.nomeFantasia,
                  cnpjConsulente: selectedCompany?.cnpj.
                    formattedValue,
                  dataInicialPermissao: modalFormInfo?.dataInicialPermissao,
                  dataFinalPermissao: modalFormInfo?.dataFinalPermissao,
                  date: moment().locale('PT-BR').format('LL'),
                  instituicaoFinanceira: selectedCompany?.instituicaoFinanceira
                }} />
              ) : (
                <TermoLiberacaoPJ data={{
                  name: userData?.nome,
                  rg: userData?.rg == null ? ("NÃO TEM") : (userData?.rg),
                  cpf: userData?.cnpj,
                  nomeCompletoConsulente: modalFormInfo?.nomeFantasia,
                  cnpjConsulente: modalFormInfo?.cnpj,
                  cnpj: userData?.cnpj == null ? ("NÃO TEM") : (userData?.cnpj),
                  dataInicialPermissao: modalFormInfo?.dataInicialPermissao,
                  dataFinalPermissao: modalFormInfo?.dataFinalPermissao,
                  date: moment().locale('PT-BR').format('LL'),
                  instituicaoFinanceira: selectedCompany?.instituicaoFinanceira
                }} />
              )
              }
            </TermContainer>
          </Modal>
        </Modal>
      </Modal>
    </>
  );
};
