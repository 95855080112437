import { CadastroFactory } from 'factories/cadastro';
import moment from 'moment';
import { createContext, useContext, useMemo, useState } from 'react';
import {
  communication as communicationService,
  consultas as consultasService,
  contestacao as contestationService,
  history as historyService,
  score as scoreService,
} from 'services/Cadastro';
import { RequestStatusContext } from './RequestStatusContext';
import { CadastroProps, CommunicationProps, PaymentHistoryFilter, SearchParams } from './types';

export const CadastroContext = createContext<CadastroProps>(CadastroFactory);

export const CadastroContextProvider = ({ children }: any) => {
  const { addRequest, requestStack } = useContext(RequestStatusContext);
  const [history, setHistory] = useState(CadastroFactory.history);
  const [contestation, setContestation] = useState([]);
  const [companies, setCompanies] = useState(CadastroFactory.companies);
  const [historyMsg, setHistoryMsg] = useState(CadastroFactory.historyMsg);
  const [score, setScore] = useState();
  const [communication, setCommunication] = useState(CadastroFactory.communication);
  const [requestStatus, setRequestStatus] = useState(CadastroFactory.requestStatus);
  const [paymentHistory, setPaymentHistory] = useState(CadastroFactory.paymentHistory);
  const [isLoading, setIsLoading] = useState<boolean>();

  const getCommunication = (params: CommunicationProps) => {
    setIsLoading(true);
    communicationService.get(params).then((res) => {
      res.data && setCommunication(res.data);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const getPermissions = async (params: SearchParams, isFilter?: boolean) => {
    setIsLoading(true);
    await historyService.permissions.search(params)
      .then((res) => {
        setHistory(res.data);
        if (isFilter) {
          if (res.data.length === 0) {
            setHistoryMsg('Não encontrado resultado para o filtro informado');
          }
        }
        setIsLoading(false);
      })
      .catch((e) => {
        const { data, status } = e.response;
        if (data.error == 'invalid_token' && status === 401) {
          localStorage.removeItem('SPC_SessionInfo');
          window.location.replace('/');
        }
        setIsLoading(false);
      });
  };

  const removePermission = async (params: any) => await historyService
    .permissions
    .delete(params).then((res) => {
      addRequest({
        statusCode: res.status,
        message: 'Cancelamento realizado com sucesso',
      });
    })
    .catch((e) => {
      addRequest({
        statusCode: e.response.status,
        message: 'Erro ao remover permissão',
      });
    });

  const includePermission = async (params: any) => {
    resetRequestStatus();
    return await historyService
      .permissions
      .include(params).then((res) => {
        addRequest({ statusCode: res.status, message: 'Liberação realizada com sucesso' });
      })
      .catch((e) => {
        addRequest({ statusCode: e.response.status, message: e.response.data.message });
      });
  };

  const getCompanies = async (name: string) => await consultasService
    .post(name)
    .then((res) => {
      setCompanies(res?.data)
    })
    .catch((e) => {
      addRequest({ statusCode: 500, message: e.response.data.message });
    });

  const getScorePf = async (cpf: any) => await scoreService
    .getPf(cpf)
    .then((res) => setScore(res.data?.score));
  
  const getScorePj = async (cnpj: any) => await scoreService
    .getPj(cnpj)
    .then((res) => setScore(res.data?.score));

  const resetRequestStatus = () => setRequestStatus(CadastroFactory.requestStatus);

  const getContestation = async (params: any) => await contestationService.registros
    .search(params)
    .then((res: any) => {
      const groupByDt = res.data
        .reduce((group: any, item: any) => {
          const { dataContestacao } = item;
          const existingDay = moment(dataContestacao).format('DD');
          const existingMonth = moment(dataContestacao).format('MMM');
          const hasItem = group.findIndex((item: any) =>
            item.day == existingDay && item.month == existingMonth);
          hasItem >= 0 ? group[hasItem].data.push(item) : group.push({
            day: existingDay,
            month: existingMonth,
            data: [item],
          });
          return group;
        }, []);
      setContestation(groupByDt);
      setIsLoading(false);
    }).catch((e: any) => {
      addRequest({
        statusCode: e.response.status,
        message: 'Erro ao carregar contestações',
      })
      setIsLoading(false);
    }
    );

  const getPaymentHistory = async (type: string, params: PaymentHistoryFilter) => {
    await historyService.get[type](params)
      .then((res) => {
        setIsLoading(false);
        let list = [];
        let total = 0;
        if (res.data) {
          list = res.data.itens;
          total = res.data.numeroTotalItens;
          if (!list || total === 0) {
            addRequest({
              statusCode: 404,
              message: 'Não encontrado resultado para o filtro informado.',
            });
          }
        }

        setPaymentHistory({
          itens: list,
          numeroTotalItens: total,
        });
      })
      .catch((e) => {
        const { data, status } = e.response;
        if (data.error == 'invalid_token' && status === 401) {
          localStorage.removeItem('SPC_SessionInfo');
          window.location.replace('/');
        }
        setIsLoading(false);
      });
  };

  const setLoading = (param: boolean) => {
    setIsLoading(param);
  }

  const value = useMemo(() => ({
    communication,
    companies,
    getCommunication,
    getCompanies,
    getContestation,
    getPermissions,
    getPaymentHistory,
    getScorePf,
    getScorePj,
    history,
    paymentHistory,
    contestation,
    historyMsg,
    includePermission,
    removePermission,
    requestStatus,
    resetRequestStatus,
    score,
    setLoading,
    isLoading,
  }), [
    communication,
    companies,
    contestation,
    history,
    paymentHistory,
    historyMsg,
    requestStack,
    requestStatus,
    score,
    isLoading
  ]);

  return <CadastroContext.Provider value={value}>{children}</CadastroContext.Provider>;
};
