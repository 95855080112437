export const formatUnixTime = (code: number) => {
  
  if (!code){ return '' }

  const date = new Date(code);

  const day = '0' + date.getDate();

  const month = '0' + (date.getMonth() + 1);

  const year = date.getFullYear();

  const formattedTime = day.slice(-2) + '/' + month.slice(-2) + '/' + year;

  return formattedTime;
};
