import { BsX } from 'react-icons/bs';
import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const FilterButton = styled.div<{ deviceType?: DeviceTypeProps }>`
${({ deviceType }) => `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 11.24px;
  border: 1px solid #D5DDED;
  border-radius: 4px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
  padding: ${
    deviceType?.type === 'mobile' ? '6px 14px' :
    (deviceType?.type === 'mobile-landscape' || deviceType?.type === 'tablet') ? '10px 18px' :
    '14px 22px'
  }; 
  font-size: ${
    deviceType?.type === 'mobile' ? '14px' :
    (deviceType?.type === 'mobile-landscape' || deviceType?.type === 'tablet') ? '16px' :
    '18px'
  };

  svg{
    height: 19px;
    width: 19px;
  }
  `}  

  ${({ theme }) => `
    color: ${theme.color.neutral['8094BC']};
  `}
`;

export const FilterBar = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ deviceType }) => `
    margin:${
      deviceType?.type === 'mobile' ? '8px 0 18px auto' :
      (deviceType?.type === 'mobile-landscape' || deviceType?.type === 'tablet') ? '24px 0 24px auto' :
      '24px 0 24px auto'
    };
    position: relative;
  `}
`;

export const InputGroup = styled.div`
${({ theme }) => css`
    justify-content: flex-start;
    margin: 24px 0;

    &:not(:last-child) {
      padding-bottom: 24px;

    }

    &:last-child {
      margin-bottom: 0;
    }
    > * {
      display: flex;
      gap: 32px;
      width: 100%;
    }

    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.color.lightGray['D5DDED']};
  `}
`;

export const Divider = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 30px;

  ${({ theme }) => `
    border-bottom: 1px solid ${theme.color.lightGray['D5DDED']};
  `}
`;

export const NameContainer = styled.div`
  position: relative;

  div:first-child {
    z-index: 2;
  }
`;

export const CompanyList = styled.div`
  position: absolute;
  width: 520px;
  border-radius: 0 0 6px 6px;
  z-index: 1;
  margin-top: -10px;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: clip;
  max-height: 200px;

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 18px;
    margin: 0;
    cursor: default;
    text-align: center;
    ${({ theme }) => `
      color: ${theme.color.neutral['B9C6E0']};
    `}
  }

  &::-webkit-scrollbar {
    width: 8px;
  };

  &::-webkit-scrollbar-thumb {
    background: #D9D9DB;
    border-radius: 4px;
  };

  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    border: 1.4px solid ${theme.color.lightGray['D5DDED']};
  `}
`;

export const Company = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 12px 18px;
  cursor: pointer;

  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    border-bottom: 1.4px solid ${theme.color.lightGray['D5DDED']};
    color: ${theme.color.neutral['586682']};

    &:hover {
      background: ${theme.color.lightGray['EBF5FF']};
    }
  `}
`;
