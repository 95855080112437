import { BsX } from 'react-icons/bs';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';


export const StyledModal = styled.div<{ isOpen?: boolean, deviceType?: DeviceTypeProps }>`
  ${({ theme, isOpen }) => `
    position: fixed;
    display: ${isOpen ? 'flex' : 'none'};
    justify-content: center;
    align-items: start;
    background: ${theme.color.darkGray['2B2F38']}80;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    max-height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    `}
 
    `;

export const ModalWindow = styled.div<{ deviceType?: DeviceTypeProps }>`
${({ theme, deviceType }) => `
  position: relative;
  height: auto; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${theme.color.white.FFFFFF};
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  `}

  margin: auto;
  max-width: 85vw;

  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      width: 88vw;
    `;
  }}
`;

export const ModalContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
    margin: 10px 20px;

    ${({ deviceType }) => {
      if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape' || deviceType?.type === 'tablet' )
        return `
        margin: 10px 16px;
        `;
    }}

`;

export const ModalHeader = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme, deviceType }) => `
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    `}
    & > :nth-child(2) {
      margin-left: auto;
    }
  
    p{
      line-height: 26px;
    }
`;

export const ModalFooter = styled.div<{ align?: string, reverseButtons?: boolean, deviceType?: DeviceTypeProps }>`
  ${({ theme, align, reverseButtons }) => `
    align-items: center;
    background: ${theme.color.white.FFFFFF};
    bottom: 0;
    display: flex;
    flex-direction: ${reverseButtons ? 'row-reverse' : 'row'};
    justify-content: ${align};
    left: 0;
    position: relative;
    padding: 24px;
    border-radius: 10px;
    gap: 24px;
    align-text: center;
    `}
    
    ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
        padding: 0;
        margin: 10px 10px;    
        justify-content: center;
      `;
  }}

`;
export const ModalContent = styled.div<{ deviceType?: DeviceTypeProps }>`
  position: relative;

`;

export const ModalClose = styled(BsX)`
  ${({ theme }) => `
    width: 32px;
    height: 32px;
    color: ${theme.color.neutral['8094BC']};
    cursor: pointer;
  `}
`;
