import { Button, Checkbox, FormField, Input, Modal, Radio } from 'components';
import { FilterIcon } from 'components/Icon';
import { useState } from 'react';
import { DateRange, FieldGroupContainer, StyledFormFilter } from './styles';
import useDeviceType from 'hooks/useDeviceType';

interface FormFilterProps {
  sendFilters: (filters: Filters) => void
}

export interface Filters {
  nomeEmpresa: string
  dataInicial: string
  dataFinal: string
  listaSegmento: []
  listaStatus:[]
  tipoContestacao: string
}

export const factoryFilters: Filters = {
  nomeEmpresa: '',
  dataInicial: '',
  dataFinal: '',
  listaSegmento: [],
  listaStatus: [],
  tipoContestacao: 'CONSUMIDOR',
};

export const FormFilter = ({ sendFilters }: FormFilterProps) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(factoryFilters);
  const [segChecked, setSegChecked] = useState([]);
  const [statusChecked, setStatusChecked] = useState([]);

  const { deviceType } = useDeviceType();
  
  const handleFillForm = (fieldName: string, fieldValue: any) => {
    if (fieldName === 'segmento'){
      const segList = [];
        if (fieldValue && fieldValue.length > 0){
          segList.push(fieldValue.map((segmento : string) => {
              return { "segmento": segmento };
            })
        );
      }
      fieldName = "listaSegmento";
      fieldValue = segList[0];
    }
    if (fieldName === 'status'){
      const statusList = [];
        if (fieldValue && fieldValue.length > 0){
          statusList.push(fieldValue.map((status : string) => {
              return { "status": status };
            })
        );
      }
      fieldName = "listaStatus";
      fieldValue = statusList[0];
    }
    setFilters({ ...filters, [fieldName]: fieldValue });
  };


  const handleCleanForm = () => {
    setSegChecked([]);
    setStatusChecked([]);
    setFilters(factoryFilters);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    handleCleanForm();
  };

  return (
    <StyledFormFilter>
      <Button btnStyle='action' onClick={() => setShowFilter(true)}>
        <FilterIcon />
        Filtrar
      </Button>
      <Modal
        alignModalFooter='flex-start'
        isOpen={showFilter}
        onToggleModal={() => handleShowFilter()}
        title="Filtros"
        reverseFooterButtons
        primaryActionButton={{
          label: 'Aplicar',
          action: () => sendFilters(filters),
          closeOnclick: true,
        }}
        secondaryActionButton={{
          label: 'Limpar todos',
          action: () => handleCleanForm(),
        }}
      >
        <FieldGroupContainer>
          <Input
            placeholder="Nome da fonte"
            inputText={filters.nomeEmpresa}
            label="Pesquisar por:"
            onChange={(e: any) => handleFillForm('nomeEmpresa', e)}
          />
          <FormField label='Filtrar por data:'>
            <DateRange deviceType={{ type: deviceType }}>
              <Input
                type="date"
                inputText={filters.dataInicial}
                onChange={(e: any) => handleFillForm('dataInicial', e)}
                id='dataInicialPermissao'
                label='Data inicial:'
              />
              <Input
                type="date"
                inputText={filters.dataFinal}
                onChange={(e: any) => handleFillForm('dataFinal', e)}
                id='dataFinalPermissao'
                label='Data final:'
              />
            </DateRange>
          </FormField>
        </FieldGroupContainer>
        <FieldGroupContainer>
          <Checkbox
            label='Filtrar por segmento:'
            id='segmento'
            name='segmento'
            checkdList={segChecked}
            onCheck={(e: any) => handleFillForm('segmento', e)}
            options={[
              { label: 'Instituições financeiras', value: 'IF' },
              { label: 'Telecomunicações', value: 'T' },
              { label: 'Energias', value: 'E' },
            ]}
          />
        </FieldGroupContainer>
        <FieldGroupContainer>
          <Checkbox
            label='Filtrar por status:'
            id='status'
            name='status'
            checkdList={statusChecked}
            onCheck={(e: any) => handleFillForm('status', e)}
            options={[
              { label: 'Em análise', value: 'EM ANÁLISE' },
              { label: 'Concluída', value: 'CONCLUÍDA' },
              { label: 'Enviada', value: 'ENVIADA' },
              { label: 'Gerada', value: 'GERADA' },
            ]}
          />
        </FieldGroupContainer>
      </Modal>
    </StyledFormFilter>
  );
};
