import React, { useContext, useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Content,
  ImageContainer,
  Input,
  InputContainer,
  Label,
  StyledInlineErrorMessage,
  Submit,
  WelcomeContainer,
} from './styles';
import { Container } from 'components/Container';
import { AuthContext } from 'contexts';
import { useNavigate } from 'react-router-dom';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { Responsive } from 'components/Responsive';
import useDeviceType from 'hooks/useDeviceType';

const validationSchema = Yup.object().shape({
  document: Yup.string()
    .required('Campo obrigatório')
    .matches(/^[0-9]+$/, 'Somente números são permitidos')
    .max(14, 'O documento deve ter no máximo 14 dígitos')
    .min(11, 'O documento deve ter no mínimo 11 dígitos')
    .test(
      'len',
      'O documento deve ter 11 ou 14 dígitos',
      (val) => val?.length !== 12 && val?.length !== 13
    ),
});

const Login = () => {
  const { verifyDocument, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const [messagePopUp, setMessagePopUp] = useState(true);

  const { deviceType } = useDeviceType();
  const [widthLeft, setWidthLeft] = useState<number | string>();
  const [widthRight, setWidthRight] = useState<number | string>();

  useEffect(() => {
    if (isAuthenticated == true) {
      navigate('/home/score');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (deviceType == 'laptop') {
      setWidthLeft('calc(50%)');
      setWidthRight('calc(50%)');
    }

    if (deviceType == 'tablet' || deviceType == 'mobile') {
      setWidthLeft('calc(100%)');
      setWidthRight('calc(85%)');
    }
  }, [deviceType]);

  // Remover Após Solucionar Problemas com a UI
  useEffect(() => {
    sessionStorage.removeItem('spcDisplayedMsgUi');
    setWidthLeft('calc(50%)');
    setWidthRight('calc(50%)');
  }, []);

  return (
    <Container>
      <Content
        deviceType={{
          type: deviceType,
        }}
      >
        {messagePopUp ? (
          <WelcomeContainer deviceType={{ type: deviceType }}>
            <Text tag="h3" size={4} weight={2} color="black ">
              Olá!
            </Text>
            <Text tag="h4" size={4} weight={2} color="black">
              Temos uma novidade para acessar o Portal do Consumidor SPC
              Brasil!
            </Text>
            <br />
            <Text color="text" size={1}>
              Mudamos a maneira de você se autenticar!!
              <br />
            </Text>
            <Text color="text" size={1}>
              Agora, você vai receber um TOKEN para autenticar seu acesso e
              garantir ainda mais segurança, não sendo mais necessário o
              aplicativo do Google Authenticator.
            </Text>
            <br />
            <Text color="text" size={1}>Mas olha, não se preocupe!</Text>
            <br />
            <Text color="text" size={1}>
              Seus dados continuam os mesmos. Por isso, é muito importante
              manter o e-mail sempre atualizado, combinado?
            </Text>
            <br />
            <Submit  deviceType={{ type: deviceType }} onClick={() => setMessagePopUp(false)}>
              <Text size={1} color='buttonLabel'> Avançar </Text>
              </Submit>
          </WelcomeContainer>
        ) : (
          <WelcomeContainer deviceType={{ type: deviceType }}>
            <Text tag="h4" size={4} weight={2} color="black ">
              Seja Bem-vindo ao seu
            Portal do Consumidor
            </Text>
            <Text color="text" size={1}>
              Nele você terá acesso ao seu Score de Crédito, Consulta
              <br /> de CPF, Cadastro Positivo e muito mais.
            </Text>
            <Formik
              initialValues={{
                document: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                verifyDocument({
                  document: values,
                })
                  .then((res: any) => {
                    if (res === 'LOGIN_NAO_ENCONTRADO') {
                      if (values?.document?.length === 11) {
                        navigate('/cadastro-pf', {
                          state: { document: values.document },
                        });
                      } else {
                        navigate('/cadastro-pj', {
                          state: { document: values.document },
                        });
                      }
                    } else if (res === 'LOGIN_EXISTE') {
                      navigate('/entrar', {
                        state: { document: values.document },
                      });
                    } else if (res === 'SEM_PRE_CADASTRO') {
                      actions.setFieldError(
                        'document',
                        `Não conseguimos seguir com a sua solicitação por divergência de informações.
                  Por favor, procure nossa Central de Atendimento ao Cliente.olá
                  O atendimento ocorre entre às 09h00 e às 18h00.`
                      );
                    } else {
                      actions.setFieldError('document', res.message);
                    }
                    actions.setSubmitting(false);
                  })
                  .catch((e: any) => {
                    actions.setFieldError(
                      'document',
                      e.response.data.message
                    );
                    actions.setSubmitting(false);
                  });
                const timeOut = setTimeout(() => {
                  actions.setSubmitting(false);
                  clearTimeout(timeOut);
                }, 1000);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                isValidating,
                isValid,
              }) => {
                return (
                  <>
                    <Form
                      style={{ paddingTop: '1rem', }}
                      name="contact"
                      method="post"
                      onSubmit={handleSubmit}
                    >
                      <Label htmlFor="document">
                        <div style={{ marginBottom: "10px" }}>
                          <Text size={1}>
                            Para começar sua jornada e verificar suas
                            informações
                            <br /> do Cadastro Positivo, insira seu CPF ou CNPJ
                            para
                            <br /> validarmos suas informações.
                          </Text>
                        </div>
                        <InputContainer deviceType={{ type: deviceType }}>
                          <Input
                            maxLength="14"
                            type="text"
                            name="document"
                            autoCorrect="off"
                            autoComplete="name"
                            placeholder="Digite seu CPF ou CNPJ"
                            valid={touched.document && !errors.document}
                            error={touched.document && errors.document}
                          />
                          {errors.document && touched.document && (
                            <StyledInlineErrorMessage
                              dangerouslySetInnerHTML={{
                                __html: errors.document,
                              }}
                            />
                          )}
                        </InputContainer>
                      </Label>
                      <Submit deviceType={{ type: deviceType }}
                        style={{ marginTop: '2rem' }}
                        type="submit"
                        disabled={!isValid || isSubmitting}
                      >
                       <Text size={1} color='buttonLabel'> {isSubmitting ? `Avançando...` : `Avançar`}</Text>
                      </Submit>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </WelcomeContainer>
        )}
        <ImageContainer deviceType={{ type: deviceType }} >
          <img
            src={require('../../assets/images/Group151.png')}
            alt="Group"
          />
        </ImageContainer>
      </Content>
    </Container>
  );
};

export default Login;
