import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const CardContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  row-gap: 16px;
  
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'laptop')
      return `
      // margin-bottom: 150px;
      `;
    if (deviceType?.type === 'desktop')
      return `
      margin-top: 50px;
      margin-bottom: 200px;
    `;
    else if (deviceType?.type === 'mobile')
      return `
      row-gap: 12px;
      margin-bottom: 20px;
      `;
  }}
`;
