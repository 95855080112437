import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { authFactory } from 'factories';
import { auth } from 'services/Auth';
import { RequestStatusContext } from './RequestStatusContext';
import { UserDataType } from './types';
import { Signature as signatureService } from 'services/Cadastro/signature';

export const AuthContext = createContext<any>({
  isAuthenticated: false,
  userData: authFactory,
  setLogin: () => null,
  setLogout: () => null,
});

export const AuthContextProvider = ({ children }: any) => {
  const [isAuthenticated, setAuthentication] = useState(false);
  const [userData, setUserData] = useState(authFactory);
  const { addRequest } = useContext(RequestStatusContext);
  const [statusCadPos, setStatusCadPos] = useState<string>("");

  const updateCadposStatus = (userData: UserDataType) => {
    {
      if (userData.cpf !== undefined) {
        signatureService.situacao('F', userData.cpf)
          .then((res: any) => {
            localStorage.setItem('SPC_StatusCadPos', JSON.stringify(res.data));
            setStatusCadPos(res.data);
          }).catch((e: any) => {
            addRequest({
              statusCode: e.response.status,
              message: 'Erro ao consultar status no cadastro positivo',
            })
            setLogout()
          })
      }
      if (userData.cnpj !== undefined) {
        signatureService.situacao('J', userData.cnpj)
          .then((res: any) => {
            localStorage.setItem('SPC_StatusCadPos', JSON.stringify(res.data));
            setStatusCadPos(res.data);
          }).catch((e: any) => {
            addRequest({
              statusCode: e.response.status,
              message: 'Erro ao consultar status no cadastro positivo',
            })
            setLogout()
          })
      }
    }

  }

  const updateLocalData = async () => {
    const localData = localStorage.getItem('SPC_SessionInfo') || '';
    const objectData = localData ? JSON.parse(localData) : {};
    const hasObjectData = Object.keys(objectData).length > 0;
    await updateCadposStatus(objectData);
    setUserData(objectData);
    setAuthentication(hasObjectData);
  };

  const verifyDocument = async (params?: any) => {
    const { document } = params
    if (document && document.document.length === 11) {
      return auth.verifyDocumentPf(document).then((res) => {
        return res.data.status
      })
    } else {
      return auth.verifyDocumentPj(document).then((res) => {
        return res.data.status
      })
    }
  };

  const getUserInfoByHash = async (params?: any) => {
    return auth.getUserInfoByHash(params).then((res) => {
      return res.data
    })
  };

  const getRepresentativesInfo = async (params?: any) => {
    return auth.getRepresentativesInfo(params).then((res) => {
      return res?.data
    })
  };

  const recoverPassword = async (params?: any) => {
    const formData: any = new FormData();
    formData.append('documento', params.document);

    return auth.recoverPassword(formData).then((res) => {
      return res
    })
  };

  const updatePassword = async (params?: any) => {
    const { consomerID, personType, password, hash } = params;

    const data = {
      idConsumidor: consomerID,
      tipoPessoa: personType,
      senha: password,
      hash: hash
    };
    const payload = JSON.stringify(data)

    return auth.updatePassword(payload).then((res) => {
      if (res.data.hash !== "") {
        addRequest({
          statusCode: 200,
          message: 'Senha alterada com sucesso',
        });
        return res.data;
      }
      return res
    })
  };

  const updatePasswordPJ = async (params?: any) => {
    const { consomerID, personType, password, hash } = params;

    const data = {
      idConsumidor: consomerID,
      tipoPessoa: personType,
      senha: password,
      hash: hash
    };
    const payload = JSON.stringify(data)

    return auth.updatePasswordPJ(payload).then((res) => {
      if (res.data.hash !== "") {
        addRequest({
          statusCode: 200,
          message: 'Senha alterada com sucesso',
        });
        return res.data;
      }
      return res
    })
  };

  const registerUser = async (params?: any) => {
    const { document, firstName, lastName, birth, firstMomName, lastMomName, dddTelefone, telefone, email, password } = params;
    const data = {
      cpf: document,
      nome: [firstName, lastName],
      dataNascimento: birth,
      nomeMae: [firstMomName, lastMomName],
      dddTelefone: dddTelefone,
      telefone: telefone,
      email: email,
      consumidorSenhaDTO: { senha: password },
      grant_type: 'password'
    };
    const payload = JSON.stringify(data)
    try {
      const res: any = await auth.registerPf(payload)
      if (res.data.status === "SUCESSO") {
        addRequest({
          statusCode: 200,
          message: 'Cadastro realizado com sucesso',
        });
        return res.data;
      }
      else {
        addRequest({
          statusCode: 500,
          message: 'Erro ao realizar o cadastro',
        });
        return res.data;
      }
    } catch (e: any) {
      addRequest({
        statusCode: e.response.status,
        message: 'Erro ao realizar o cadastro',
      });
      throw e;
    }
  }

  const registerUserPj = async (params?: any) => {
    const { document, nomeEmpresa, nomeFantasia, dddTelefone, telefone, primeiroNomeRepresentante, ultimoNomeRepresentante, cpfRepresentante, rgRepresentante, emailRepresentante, dataFundacao, email, password } = params;

    const data = {
      cnpj: document,
      nomeEmpresarial: nomeEmpresa,
      nomeFantasia: nomeFantasia,
      nomeRepresentante: primeiroNomeRepresentante + ' ' + ultimoNomeRepresentante,
      dataFundacao: dataFundacao,
      cpfRepresentante: cpfRepresentante,
      rgRepresentante: rgRepresentante,
      dddTelefone: dddTelefone,
      telefone: telefone,
      emailRepresentante: emailRepresentante,
      email: email,
      consumidorSenhaDTO: { senha: password },
      grant_type: 'password'
    };
    const payload = JSON.stringify(data)

    try {
      const res: any = await auth.registerPj(payload);
      if (res.data.status === "SUCESSO") {
        addRequest({
          statusCode: 200,
          message: 'Cadastro realizado com sucesso',
        });
        return res.data;
      }
      else {
        addRequest({
          statusCode: 500,
          message: 'Erro ao realizar o cadastro',
        });
        return res.data;
      }
    } catch (e: any) {
      addRequest({
        statusCode: e.response.status,
        message: e.response.message
      });
      throw e;
    }
  }


  const setLogin = async (params?: any) => {
    const formData: any = new FormData();
    formData.append('username', params.document);
    formData.append('password', params.password);
    formData.append('version', 'v2');
    formData.append('grant_type', 'password');

    if (params.otp == null) {
      await auth.set(formData).then((res) => {
        localStorage.setItem('SPC_SessionInfo', JSON.stringify(res.data));
        const localData = localStorage.getItem('SPC_SessionInfo') || '';
        const objectData = localData ? JSON.parse(localData) : {};
        setUserData(objectData);
        localStorage.removeItem('SPC_SessionInfo');
      })
    }
    else {
      formData.append('otp', params.otp);
      const documentType = params.document?.length === 11 ? 'CPF' : 'CNPJ'
      localStorage.setItem('SPC_DocumentType', documentType);
      await auth.set(formData).then((res) => {
        localStorage.setItem('SPC_SessionInfo', JSON.stringify(res.data));
        updateLocalData();
        setStatusCadPos(localStorage.getItem('SPC_StatusCadPos') ?? '');
        localStorage.setItem('isAuthenticated', JSON.stringify(true));
      }).then((res: any) => {
        addRequest({
          statusCode: 200,
          message: 'Login realizado com sucesso',
        });
        return
      })
    }
  }

  const setLogout = () => {
    localStorage.removeItem('SPC_SessionInfo');
    localStorage.removeItem('SPC_StatusCadPos');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('SPC_DocumentType');
    setUserData(authFactory);
    setAuthentication(userData.access_token == '');
    window.location.replace('/');
  };

  useEffect(() => {
    updateLocalData();
  }, []);

  const getToken = async () => {
    const sessionRefreshToken: any | null = localStorage.getItem('SPC_SessionInfo');
    const refreshToken: any = JSON.parse(sessionRefreshToken).refresh_token;

    if (!refreshToken) {
      window.location.replace('/');
    }

    try {
      const formData: any = new FormData();
      formData.append('refresh_token', refreshToken);
      formData.append('grant_type', 'refresh_token');

      const response = await auth.set(formData);
      localStorage.setItem('SPC_SessionInfo', JSON.stringify(response.data));
      updateLocalData();
      setStatusCadPos(localStorage.getItem('SPC_StatusCadPos') ?? '');

      return response.data.access_token;
    } catch (e: any) {
      addRequest({
        statusCode: e.status,
        message: 'Não foi possível realizar o LOGIN',
      });
    }
  };

  const value = useMemo(() => ({
    registerUser,
    registerUserPj,
    verifyDocument,
    recoverPassword,
    getUserInfoByHash,
    getRepresentativesInfo,
    updatePassword,
    updatePasswordPJ,
    setLogin,
    isAuthenticated,
    userData,
    getToken,
    token: userData.access_token,
    cpf: userData.cpf,
    cnpj: userData.cnpj,
    setLogout,
    statusCadPos,
    setStatusCadPos
  }), [isAuthenticated, userData]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
