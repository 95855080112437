import useDeviceType from 'hooks/useDeviceType';
import * as S from './style';

const InputModal = (props: React.InputHTMLAttributes<HTMLInputElement>) => {
  const { deviceType } = useDeviceType();

  return <S.Input {...props} deviceType={{ type: deviceType }} />;
};

export default InputModal;
