import { StyledCarousel, StyledSwiper, StyledSwiperImage, StyledSwiperSlide } from './styles';
/* import { CarouselProps } from 'types'; */
import { Autoplay, Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import "./styles.css";
import useDeviceType from 'hooks/useDeviceType';

const Carousel = ({ data }: any) => {

  const pagination = {
    clickable: true,
    renderBullet: function (index: number, className: string) {
      return '<span class="' + className + '">' + '</span>' + '&nbsp &nbsp &nbsp &nbsp &nbsp'
    },
  };

  const { deviceType } = useDeviceType();

  return (
    <StyledCarousel deviceType={{ type: deviceType }}>
      <StyledSwiper
        pagination={pagination}
        modules={[Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
      >
        {data?.map((data: any) => {
          return <StyledSwiperSlide>
            {data?.url.startsWith("h", 0) === true ?
              (<a href={data?.linkDirecionamento} target="_blank" rel="noopener noreferrer" role="item" id={`vantagens-banner-${data.id}`}>
                <StyledSwiperImage src={data?.url} />
              </a>)
              :
              (<a href={data?.linkDirecionamento} target="_blank" rel="noopener noreferrer" role="item" id={`vantagens-banner-${data.id}`}>
                <StyledSwiperImage src={require('../../assets' + data?.url)} />
              </a>)
            }
          </StyledSwiperSlide>
        })
        }
      </StyledSwiper>
    </StyledCarousel >
  )
};

export default Carousel

