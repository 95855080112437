import { Input, Modal, Radio, Text } from 'components';
import { FilterIcon } from 'components/Icon';
import { AuthContext } from 'contexts';
import { PaymentHistoryFilter } from 'contexts/types';
import { useContext, useEffect, useState } from 'react';
import {
  Company,
  CompanyList,
  Divider,
  FilterBar,
  FilterButton,
  InputGroup,
  NameContainer,
} from './styles';

import useGetSourceCompanies from 'hooks/useGetSourceCompanies';
import moment from 'moment';
import useDeviceType from 'hooks/useDeviceType';

const filterFactory = {
  nome: '',
  dataInicioFiltro: '',
  dataFimFiltro: '',
  documento: '',
  tipoCliente: '',
  apenasContestadas: false,
  tipoOperacao: null,
  maximoPorPagina: 4,
  numeroPagina: 1,
  permiteContestadas: true,
};

const mockCompanies: string[] = [
  'Banco Bradesco',
  'Banco Santander',
  'Banco Moneo S/A',
  'Tim Sa',
  'ENEL CEARA',
  'BANCO DE DESENVOLVIMENTO DE MINAS GERAIS S.A – BDMG',
];

export const Filter = ({ sendFilters, resetNotFilter }: { sendFilters: (modalType: string, modalFilters: PaymentHistoryFilter) => void, resetNotFilter: () => void }) => {
  const { companies, getCompanyData } = useGetSourceCompanies();
  const [isModalOpen, setModalOpen] = useState(false);
  const { userData } = useContext(AuthContext);
  const [type, setType] = useState('');
  const [filters, setFilters] = useState<PaymentHistoryFilter>(filterFactory);
  const [companyList, setCompanyList] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [showList, setShowList] = useState(false);
  const [isDateValid, setIsDateValid] = useState(true);
  const [dateErrorMessage, setDateErrorMessage] = useState('');
  const { deviceType } = useDeviceType();

  useEffect(() => {
    if (userData) {
      setFilters({
        ...filters,
        documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
        tipoCliente: userData.tipoConsumidor,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (search.length < 3) {
      setShowList(false);
    }
  }, [search]);

  const convertToDate = (input: string) => {
    const [day, month, year] = input.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const isEndDateValid = (startDate: string, endDate: string) => {
    if (startDate === '' || endDate === '') {
      return true;
    }

    const start = convertToDate(startDate);
    const end = convertToDate(endDate);

    return end >= start;
  };

  const handleFillForm = (fieldName: string, fieldValue: string) => {
    if (fieldName === 'dataFimFiltro') {
      if (!isEndDateValid(filters.dataInicioFiltro, fieldValue)) {
        setIsDateValid(false);
        setDateErrorMessage('A data final deve ser maior ou igual à data inicial');
      } else {
        setIsDateValid(true);
        setDateErrorMessage('');
      }
    }
    setFilters({ ...filters, [fieldName]: fieldValue });
  };

  const handleSubmit = () => {
    sendFilters(type, filters);
    resetNotFilter();
  };

  const handleModal = () => {
    clearFilters();
    setModalOpen(!isModalOpen);
  };

  const clearFilters = () => {
    if (userData) {
      setFilters({
        ...filterFactory,
        documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
        tipoCliente: userData.tipoConsumidor,
      });
    }
    setType('');
    setSearch('');
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    const companyNameList = companies.map((company: any) => company.nome)

    if (value.length >= 3) {
      Promise.resolve(getCompanyData(search)).then(() => {
        const filteredList = companyNameList.filter((company: any) => company.toLowerCase().includes(value.toLowerCase()));

        setCompanyList(filteredList);
        setShowList(true);
      });

    }
  };

  return (
    <FilterBar  deviceType={{ type: deviceType }}>
      <FilterButton onClick={handleModal} deviceType={{ type: deviceType }}>
        <FilterIcon />
        Filtrar
      </FilterButton>
      <Modal
      
        title="Filtros"
        isOpen={isModalOpen}
        reverseFooterButtons
        alignModalFooter='flex-start'
        onToggleModal={handleModal}
        primaryActionButton={{
          label: 'Aplicar',
          action: handleSubmit,
          closeOnclick: true,
          disabled: !isDateValid,
        }}
        secondaryActionButton={{
          label: 'Limpar todos',
          action: clearFilters,
        }}>
        <NameContainer>
          <Input
            id='nome'
            inputText={search}
            label="Pesquisar por:"
            placeholder='Nome da fonte'
            onChange={(e: any) => handleSearch(e)}
          />
          {showList &&
            <CompanyList>
              {companyList.length === 0 ? (
                <p>Nenhuma fonte encontrada...</p>
              ) : (
                <>
                  {companyList.map((company) => (
                    <Company
                      key={company}
                      onClick={() => {
                        handleFillForm('nome', company);
                        setSearch(company);
                        setShowList(false);
                      }}
                    >
                      {company}
                    </Company>
                  ))}
                </>
              )}
            </CompanyList>
          }
        </NameContainer>
        <InputGroup>
          <Text weight={2} size={1}>Filtrar por data:</Text>
          <div>
            <Input
              type="date"
              id='dataInicial'
              inputText={filters.dataInicioFiltro}
              label='Data inicial:'
              onChange={(e: any) => handleFillForm('dataInicioFiltro', e)}
            />
            <Input
              type="date"
              id='dataFinal'
              inputText={filters.dataFimFiltro}
              label='Data final:'
              onChange={(e: any) => handleFillForm('dataFimFiltro', e)}
            />

          </div>
          {dateErrorMessage && <p style={{ color: 'red' }}>{dateErrorMessage}</p>}
          <br />
        </InputGroup>
        <Radio
          id="segment"
          name="segment"
          label='Filtrar por segmento:'
          value={type}
          onCheck={(e: any) => setType(e)}
          options={[
            { label: 'Instituições financeiras', value: 'operations' },
            { label: 'Telecomunicações', value: 'telecom' },
            { label: 'Energia', value: 'energy' },
          ]}
        />
        {type === 'operations' && (
          <>
            <Divider />
            <Radio
              id="modality"
              name="modality"
              label='Filtrar por modalidade:'
              value={filters.tipoOperacao ?? ''}
              onCheck={(e: any) => handleFillForm('tipoOperacao', e === 'TODOS' ? null : e)}
              options={[
                { label: 'Todos', value: 'TODOS' },
                { label: 'Cartão de crédito', value: 'CARTAO DE CREDITO' },
                { label: 'Consórcio', value: 'CONSORCIO' },
                { label: 'Crédito parcelado', value: 'CREDITO PARCELADO' },
                { label: 'Crédito rotativo', value: 'CREDITO ROTATIVO' },
              ]}
            />
          </>
        )}
      </Modal>
    </FilterBar>
  );
};
