import { Button, FormField, Input, Modal } from 'components';
import { FilterIcon } from 'components/Icon';
import { useEffect, useState } from 'react';
import { DateRange, FieldGroupContainer, StyledFormFilter } from './styles';
import { Text } from 'components';
import useDeviceType from 'hooks/useDeviceType';


interface FormFilterProps {
  sendFilters: (filters: Filters) => void
}

export interface Filters {
  protocolo: string
  dataInicio: string
  dataFim: string
}

export const factoryFilters: Filters = {
  protocolo: '',
  dataInicio: '',
  dataFim: ''
};

export const FormFilter = ({ sendFilters }: FormFilterProps) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(factoryFilters);
  const [statusBotao, setStatusBotao] = useState(false);

  const handleFillForm = (fieldName: string, fieldValue: string) => {
    setFilters({ ...filters, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if ((!filters.dataInicio && filters.dataFim) || (filters.dataInicio && !filters.dataFim)) {
      setStatusBotao(true);
    }else{
      setStatusBotao(false);
    }
  }, [filters]);

  const getModalFilters = (filters: Filters) => {    
    setFilters(filters);
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    setFilters(factoryFilters);
  };
  
  const { deviceType } = useDeviceType();

  return (
    <StyledFormFilter>
      <Button btnStyle='action' onClick={() => setShowFilter(true)}>
        <FilterIcon />
        Filtrar
      </Button>
      <Modal
        alignModalFooter='flex-start'
        isOpen={showFilter}
        onToggleModal={() => handleShowFilter()}
        title="Filtros"
        reverseFooterButtons
        primaryActionButton={{
          label: 'Aplicar',
          action: () => sendFilters(filters),
          closeOnclick: true,
          disabled: statusBotao,
        }}
        secondaryActionButton={{
          label: 'Limpar todos',
          action: () => setFilters(factoryFilters),
        }}
      >
        <FieldGroupContainer>
          <Input
            placeholder="Protocolo"
            inputText={filters.protocolo}
            label="Pesquisar por:"
            onChange={(e: any) => handleFillForm('protocolo', e)}
          />
          <FormField label='Filtrar por data:'>
            <DateRange deviceType={{ type: deviceType }}>
              {((!filters.dataInicio && filters.dataFim) || (filters.dataInicio && !filters.dataFim)) && (
                <span style={{ position: 'absolute', marginLeft: "6rem", color: 'red' }}>*</span>
              )}
              <Input
                type="date"
                inputText={filters.dataInicio}
                onChange={(e: any) => handleFillForm('dataInicio', e)}
                id='dataInicioEvento'
                label='Data inicial:'
              />
              {((!filters.dataInicio && filters.dataFim) || (filters.dataInicio && !filters.dataFim)) && (
                <span style={{ position: 'absolute', marginLeft: "17.5rem", color: 'red' }}>*</span>
              )}
              <Input
                type="date"
                inputText={filters.dataFim}
                onChange={(e: any) => handleFillForm('dataFim', e)}
                id='dataFimEvento'
                label='Data final:'
              />
            </DateRange>
          </FormField>
        </FieldGroupContainer>
        <br />
        {((!filters.dataInicio && filters.dataFim) || (filters.dataInicio && !filters.dataFim)) && (
          <Text size={1} lineHeight={2}>
            Campos obrigatórios ({<span style={{ color: 'red' }}>*</span>})
          </Text>
        )}
        <br />
      </Modal>
    </StyledFormFilter>
  );
};
