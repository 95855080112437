import { Anexos } from 'pages/SuaConsulta/Models/anexos';
import { Solicitacao } from 'pages/SuaConsulta/Models/solicitacao';
import { UserDetails } from 'pages/SuaConsulta/Models/userDetails'
import { api } from 'services/Cadastro/api'



export const getUserDetails = async (): Promise<UserDetails> =>
  await (await api().get(`/cadastropositivo-consulta-service/protecao-dados/minha/consulta`)).data

export const sentFile = async (file: File): Promise<Anexos> => {
    const formData: FormData = new FormData();

    if (file !== null) {
      formData.append('anexos', file, file.name);
    }

    return await (await api().post(`/cadastropositivo-consulta-service/protecao-dados/upload-email`, formData)).data
}

export const sentReport = async (data: Solicitacao):
Promise<void> => await api().post(`/cadastropositivo-consulta-service/protecao-dados/solicitacao`, data)
