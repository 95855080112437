export const getScoreColor = (level: number) => {
  const levelColors = ['#EE1D25', '#FFBC29', '#7DCC8B', '#00BD1E'];
  let color = '';
  if (level <= 399) {
    color = levelColors[0];
  } else if (level >= 400 && level <= 599) {
    color = levelColors[1];
  } else if (level >= 600 && level <= 799) {
    color = levelColors[2];
  } else {
    color = levelColors[3];
  }
  return color;
};
