import useDeviceType from 'hooks/useDeviceType';
import { DetailBlock } from 'pages/Cadastro/HistoricoPagamentos/components/OperationDetails/style'
import { documentFormatter } from 'utils/documentFormatter'
import { formatUnixTime } from 'utils/formatUnixTime'

const Consignado = ({ details, openParcels }: any) => {
  const { deviceType } = useDeviceType();
  
  return (
    <>
      <DetailBlock deviceType={{ type: deviceType }}>
        <h1>Dados da Fonte de Informação</h1>
        <p>
          <b>Nome da fonte: </b>
          <span>{details?.nomeComercialFonteOrigem ?? ''}</span>
        </p>
        <p>
          <b>CNPJ da fonte: </b>
          <span>{details?.cnpjFonteOrigemFormatado ?? (documentFormatter(details?.cnpjFonteOrigem?.toString() ?? ''))}</span>
        </p>
      </DetailBlock>
      <DetailBlock deviceType={{ type: deviceType }}>
        <h1>Dados do Contrato</h1>
        <p>
          <b>Natureza da operação: </b>
          <span>{details?.naturezaOperacao}</span>
        </p>
        <p>
          <b>Data da contratação: </b>
          <span>{formatUnixTime(details?.dataContratacao ?? '')}</span>
        </p>
        <p>
          <b>Indicador: </b>
          <span>{details?.prefixado === 'SIM' ? 'Pré-Fixado' : 'Pós-Fixado'}</span>
        </p>
        <p>
          <b>Data de vencimento da última parcela: </b>
          <span>{formatUnixTime(details?.dataVencimentoUltimaParcela)}</span>
        </p>
        <p>
          <b>Valor contratado futuro: </b>
          <span>{details?.valorContratadoFuturo?.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}</span>
        </p>
        <p>
          <b>Quantidade total de parcelas do contrato:  </b>
          <span>{details?.quantidadeParcela}x</span>
        </p>
        <p>
          <b>Quantidade de parcelas em aberto: </b>
          <span>{openParcels}x</span>
        </p>
        <p>
          <b>Data da última atualização: </b>
          <span>{formatUnixTime(details?.dataAlteracao)}</span>
        </p>
      </DetailBlock>
    </>
  )
}

export default Consignado
