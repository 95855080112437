import { AuthContext, RequestStatusContext } from 'contexts';
import { Input, LoadingSpinner } from 'components';
import { useContext, useEffect, useState } from 'react';
import { Selector } from 'components/Selector';
import { contestacao as contestService, history as historicoService } from 'services/Cadastro';
import {
  FilterContainer,
  PaymentCard,
  PaymentInfo,
  PaymentNumber,
  PaymentSubtypes,
  PaymentTypes,
  SubtypeButton,
  TypeButton
} from '../PaymentHistory/styles';
import { ContainerSelection, Divider, MotiveContainer, OperationContainer, SourceContainer, TitleOperation } from './styles';
import { ButtonContainer } from '../ContestForm/styles';
import { Button } from 'components/Button';
import useDeviceType from 'hooks/useDeviceType';
import { Dropdown, DropdownItem } from 'components/Dropdown';

interface Option {
  label: string
  value: string | number
}

interface Operation {
  modalidadeTexto: string
  nomeFonteOrigem: string
  numeroContrato: string
  idFonte: number
}

const types = [
  { label: 'Instituições Financeiras', value: 'IF' },
  { label: 'Telecomunicações', value: 'TEL' },
  { label: 'Energias', value: 'ENERGIAS' },
];

const subTypes = [
  { label: 'Cartão de crédito', value: 'CARTAO_CREDITO' },
  { label: 'Consórcio', value: 'CONSORCIO' },
  { label: 'Crédito parcelado', value: 'CREDITO_PARCELADO' },
  { label: 'Crédito rotativo', value: 'CREDITO_ROTATIVO' },
];

const categoryDictionary: { [name: string]: string } = {
  IF: 'INSTITUICAO_FINANCEIRA',
  TEL: 'TELCOS',
  ENERGIAS: 'ENERGIAS',
};

const modalityDictionary: { [name: string]: string } = {
  CARTAO_CREDITO: 'C',
  CREDITO_PARCELADO: 'P',
  CREDITO_ROTATIVO: 'R',
  CONSORCIO: 'S',
  TELCOS: 'T',
  ENERGIA: 'E',
};

export const  ContestClient = ({ onSubmit }: { onSubmit: (data: any) => void }) => {
  const { userData, statusCadPos } = useContext(AuthContext);
  const [motive, setMotive] = useState<Option>({ label: 'Ausência de informações', value: 'CT01' });
  const [source, setSource] = useState<Option>({ label: '', value: 0 });
  const [sourceList, setSourceList] = useState<Option[]>([]);
  const [category, setCategory] = useState({ type: 'IF', subtype: 'CARTAO_CREDITO' });
  const [operationList, setOperationList] = useState<Operation[]>([]);
  const [operationNumber, setOperationNumber] = useState('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const { addRequest, requestStack } = useContext(RequestStatusContext);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<DropdownItem | null>(null);

  useEffect(() => {
    setLoading(false);
  }, [statusCadPos]);

  useEffect(() => {
    setLoading(true);
    if (motive?.value === 'CT01') {
      updateSourceList();
    } else {
      updateSourceListByOperations();
    }
    setOperationList([]);
  }, [motive]);

  useEffect(() => {
    setOperationList([]);
    if (motive && motive?.value === 'CT02') {
      if (source?.value > 0) {
        updateOperationList();
      } else {
        updateSourceListByOperations();
      }
    } else {
      updateSourceList();
    }
  }, [source]);

  useEffect(() => {
    if (motive && motive?.value === 'CT02' && source?.value == 0) {
      updateSourceListByOperations();
      setOperationList([]);
    } else {
      setSource({ label: '', value: 0 })
    }
  }, [category]);

  const updateSourceList = () => {
    setLoading(true);
    contestService.getSources(category.type).then((res) => {
      const formattedList: Option[] = [];
      for (const source of res.data) {
        formattedList.push({
          label: source.nomeFonte,
          value: Number(source.codigoFonte),
        });
      }
      setSourceList(formattedList);
      setLoading(false);
    });
  }

  const updateSourceListByOperations = () => {
    setLoading(true);
    getOperations(category.type).then((res) => {
      filterSourceList(res).then((res) => {
        setSourceList(res);
        setLoading(false);
      });
      setLoading(false);
    });
  }
  
  const handleDropdownSelect = (item: DropdownItem) => {
    setSelectedDropdownItem(item);
    handleClickMenu(item.value);
  };

  const updateOperationList = () => {
    setLoading(true);
    getOperations(category.type).then((res) => {
      setOperationList(res)
      setLoading(false);
    });
  }

  const filterSourceList = (list: any[]) => {
    return new Promise<Option[]>((resolve) => {
      const newSourceList: Option[] = [];
      if (list && list.length > 0) {

        for (const operation of list) {
          const newSource = {
            label: operation.nomeFonteOrigem,
            value: operation.idFonte,
          };

          const verifyDuplicates = newSourceList.filter((source) => source.value === operation.idFonte);

          if (verifyDuplicates.length === 0) {
            newSourceList.push(newSource);
          }
        }
      }
      resolve(newSourceList);
    });
  };

  const getOperations = (type: string) => {
    return new Promise<Operation[]>((resolve) => {

      const filters = {
        nome: source?.label ?? '',
        dataInicioFiltro: '',
        dataFimFiltro: '',
        documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
        tipoCliente: userData?.tipoConsumidor ?? '',
        apenasContestadas: false,
        tipoOperacao: null,
        maximoPorPagina: null,
        numeroPagina: 1,
        permiteContestadas: true,
        idFonte: source?.value,
        validaContestacaoCliente: true
      };

      switch (type) {
        case 'IF':
          historicoService.get['operations'](filters).then((res) => {
            resolve(res.data.itens);
          }).catch((e) => {
            addRequest({
              statusCode: e?.response?.status,
              message: e?.response?.data?.message,
            });
            setLoading(false);
          });
          break;
        case 'TEL':
          historicoService.get['telecom'](filters).then((res) => {
            resolve(res.data.itens);
          }).catch((e) => {
            addRequest({
              statusCode: e?.response?.status,
              message: e?.response?.data?.message,
            });
            setLoading(false);
          });
          break;
        case 'ENERGIAS':
          historicoService.get['energy'](filters).then((res) => {
            resolve(res.data.itens);
          }).catch((e) => {
            addRequest({
              statusCode: e?.response?.status,
              message: e?.response?.data?.message,
            });
            setLoading(false);
          });
          break;
        default:
          resolve([]);
      }
    });
  };

  const handleData = () => {
  let contestacaoModalidade = modalityDictionary[category.subtype];
  if (category.type === 'TEL') {
    contestacaoModalidade = modalityDictionary.TELCOS;
  } else if (category.type === 'ENERGIAS') {
    contestacaoModalidade = modalityDictionary.ENERGIA;
  }

  const modalidadeOperacao = contestacaoModalidade;


  if (motive?.value === 'CT01') {
    return {
      arvoreMotivos: {
        contestacaoModalidade,
        contestacaoNumeroContrato: operationNumber,
      },
      cnpj: ['CPF', 'PF'].includes(userData?.tipoConsumidor ?? '') ? null : userData?.cnpj,
      codigoMotivoJornadaCliente: motive.value,
      codigoTipoFonte: categoryDictionary[category.type],
      cpf: ['CPF', 'PF'].includes(userData?.tipoConsumidor ?? '') ? userData?.cpf : null,
      idFonte: source.value,
      idOperacao: null,
      modalidadeOperacao,
      numeroContrato: operationNumber,
      tipoConsumidor: ['CPF', 'PF'].includes(userData?.tipoConsumidor ?? '') ? 'F' : 'J',
      tipoContestacao: 'C',
    };
  } else {
    return {
      arvoreMotivos: {},
      cnpj: ['CPF', 'PF'].includes(userData?.tipoConsumidor ?? '') ? null : userData?.cnpj,
      codigoMotivoJornadaCliente: motive.value,
      codigoTipoFonte: categoryDictionary[category.type],
      cpf: ['CPF', 'PF'].includes(userData?.tipoConsumidor ?? '') ? userData?.cpf : null,
      idFonte: source.value,
      idOperacao: null,
      modalidadeOperacao,
      numeroContrato: '',
      tipoConsumidor: ['CPF', 'PF'].includes(userData?.tipoConsumidor ?? '') ? 'F' : 'J',
      tipoContestacao: 'C',
    };
  }
};


  const getMotive = (value: { label: string, value: string | number }) => {
    setSource({ label: '', value: 0 });
    setMotive(value);
  };

  const getSource = (value: { label: string, value: string | number }) => {
    setSource(value);
  };

  const handleClickMenu = (value: string) => {
    const formattedList: Option[] = [];
    formattedList.push({ label: '', value: 0 })
    setSourceList(formattedList);
    if (value && value.includes('IF')) {
      setCategory({ ...category, type: value ?? '', subtype: 'CARTAO_CREDITO' });
    } else {
      setCategory({ ...category, type: value ?? '' });
    }
  };
  const { deviceType } = useDeviceType();

  return (
    <>     
    <div>
      <LoadingSpinner state={isLoading} />
    </div>
    <div style={{  width: "100%" }}>
      <MotiveContainer deviceType={{ type: deviceType }}>
        <h3>Selecione o motivo da contestação:</h3>
        <Selector
          options={[
            { label: 'Ausência de informações', value: 'CT01' },
            { label: 'Sem relacionamento com a fonte', value: 'CT02' },
          ]}
          selectedOption={motive.label}
          onSelect={getMotive} />
      </MotiveContainer>
      {deviceType !== 'mobile' ? (
        <PaymentTypes>
          {types.map((item, index) => (
            <TypeButton
              key={index}
              selected={item.value === category.type}
              onClick={() => handleClickMenu(item.value)}
            >
              {item.label}
            </TypeButton>
          ))}
        </PaymentTypes>
      ) : (
        <Dropdown items={types} selectedItem={selectedDropdownItem} onSelect={handleDropdownSelect} />
      )}
      <FilterContainer deviceType={{ type: deviceType }}>
        <PaymentSubtypes deviceType={{ type: deviceType }}>
          {category.type === 'IF' && motive?.value === 'CT01' && subTypes.map((item) => (
            <SubtypeButton deviceType={{ type: deviceType }}
              selected={item.value === category.subtype}
              onClick={() => setCategory({ ...category, subtype: item.value ?? '' })}
            >
              {item.label}
            </SubtypeButton>),
          )}
        </PaymentSubtypes>
      </FilterContainer>
      <ContainerSelection>
      <SourceContainer deviceType={{ type: deviceType }}>
        <h3>Selecione a fonte que deseja contestar:</h3>
        <Selector options={sourceList} selectedOption={source.label} onSelect={getSource} />
      </SourceContainer>
      {motive?.value === 'CT01' &&
        <SourceContainer deviceType={{ type: deviceType }}>
          <h3>Número de Contrato:</h3>
          <input
            id='numeroContestacao'
            value={operationNumber}
            onChange={(e) => setOperationNumber(e.target.value)}
            onKeyPress={(event) => {
              if (!/[a-zA-Z0-9]/.test(event.key) || operationNumber.length >= 40) {
                event.preventDefault();
              }
            }}
          />

        </SourceContainer>
      }
      </ContainerSelection>

      {source.value !== '' && operationList && operationList?.length > 0 && motive?.value === 'CT02' && (
        <OperationContainer>
          <TitleOperation>
          <h3>Consumidor, você possui histórico de crédito com a fonte selecionada:</h3>
          <Divider deviceType={{ type: deviceType }} />
          </TitleOperation>
          {operationList.map((operation) => (
            <PaymentCard>
              <PaymentInfo>
                <h3>{operation.modalidadeTexto}</h3>
                <h4>{operation.nomeFonteOrigem}</h4>
              </PaymentInfo>
              <PaymentNumber>
                <h4>Número do contrato: <b>{operation.numeroContrato}</b></h4>
              </PaymentNumber>
            </PaymentCard>
          ))}
        </OperationContainer>
      )}

      <ButtonContainer
        show={
          motive?.value === 'CT01' ? (operationNumber !== '' && source?.value !== 0) : (operationList?.length > 0)
        }
        >
        <Button onClick={() => onSubmit(handleData())}>Contestar</Button>
      </ButtonContainer>
    </div>
    </>
  );
};
