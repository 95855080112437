import { NamedAvatar, Text } from 'components';
import { AuthContext } from 'contexts';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvatarDropdownProps } from 'types';

import {
  Icon,
  IconTitle,
  LabelContainer,
  MenuItem,
  MenuList,
  StyledAvatarDropdown,
} from './styles';
import useDeviceType from 'hooks/useDeviceType';

const AvatarDropdown = ({ title, subtitle, menu }: AvatarDropdownProps) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { setLogout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    setLogout()
    navigate("/")
  }
  const { deviceType } = useDeviceType();

  return (
    <StyledAvatarDropdown deviceType={{ type: deviceType }}    >
      <NamedAvatar name={title} />
      <LabelContainer
        id="menuButton"
        role="button"
        aria-haspopup="true"
        aria-controls="menu"
        onClick={() => setToggleMenu(!toggleMenu)}
      >
        <IconTitle>
          <Text size={1} lineHeight={1}>
            {title}
          </Text>
          <Icon $isFlip={toggleMenu} />
        </IconTitle>
        <div style={{ marginRight: deviceType === "mobile" ? "20px" : "" }} >
          <Text align={deviceType === "mobile" ? "right" : "left"}>{subtitle}</Text>
        </div>
      </LabelContainer>
      {(menu != null) && toggleMenu && (
        <MenuList
          id="menu"
          aria-labelledby="menuButton"
          aria-orientation="vertical"
          role="menu"
        >
          {menu.map((item, index) => (
            <MenuItem
              key={index}
              role="presentation"
              onClick={() => setToggleMenu(false)}
            >
              <a href={item.url} role="menuitem">
                <Text>{item.label}</Text>
              </a>
            </MenuItem>
          ))}
          <MenuItem role="presentation" onClick={() => setToggleMenu(false)}>
            <a onClick={() => handleLogout()} role="menuitem">
              <Text>Sair</Text>
            </a>
          </MenuItem>
        </MenuList>
      )}
    </StyledAvatarDropdown>
  );
};

export { AvatarDropdown };
