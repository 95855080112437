import React, { useContext, useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  CodeWrapper,
  FormContainer,
  Input,
  Label,
  PageWrapper,
  StyledInlineErrorMessage,
  Submit,
  Title,
} from './styles';
import { Container } from 'components/Container';
import { CheckContainer, ModalTerm } from 'pages/Cadastro/Cancelamento/styles';
import { Modal } from 'components/Modal';
import { LoadingSpinner } from 'components';
import { AuthContext } from 'contexts';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import SignupTerm from 'pages/Login/SignupPf/SignupTerm';
import { documentFormatter } from 'utils/documentFormatter';
import { InputPassword } from '../InputPassword/InputPassword';
import { Content, ImageContainer } from '../styles';
import useDeviceType from 'hooks/useDeviceType';
import { CSSProperties } from 'styled-components';
import { ContainerInputPassword, LabelContainer } from '../SignupPj/styles';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Digite seu primeiro nome.')
    .matches(/^[\p{L} \-'’]+$/u, 'Apenas letras são permitidas'),
  lastName: Yup.string()
    .required('Digite seu último nome.')
    .matches(/^[\p{L} \-'’]+$/u, 'Apenas letras são permitidas'),
  birth: Yup.string()
    .required('Digite sua data de nascimento.')
    .matches(
      /^(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/,
      'Insira a data no formato dd/mm/aaaa'
    ),
  firstMomName: Yup.string()
    .required('Digite o primeiro nome da sua mãe.')
    .matches(/^[\p{L} \-'’]+$/u, 'Apenas letras são permitidas'),
  lastMomName: Yup.string()
    .required('Digite o último nome da sua mãe.')
    .matches(/^[\p{L} \-'’]+$/u, 'Apenas letras são permitidas'),
  telefone: Yup.string()
    .max(8, 'O telefone deverá ter entere 8 e 9')
    .max(9, 'O telefone deverá ter entere 8 e 9')
    .matches(/^[0-9]+$/, 'Somente números são permitidos'),
  dddTelefone: Yup.string()
    .min(3, 'O ddd tem que ter 3 caracteres')
    .matches(/^[0-9]+$/, 'Somente números são permitidos'),
  email: Yup.string()
    .email('O e-mail está incorreto')
    .required('Digite seu e-mail'),
  confirmEmail: Yup.string()
    .email('O e-mail está incorreto')
    .oneOf([Yup.ref('email'), null], 'Email precisa ser igual')
    .required('Digite seu e-mail novamente'),
  password: Yup.string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
    .matches(/\d/, 'A senha deve conter pelo menos um número')
    .matches(
      /^(?=.*[@$!%*?&#^(){}[\]\\/|<>,.:;'"`~+=_-])/,
      'A senha deve conter pelo menos um caractere especial'
    )
    .required('Digite uma senha'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
    .required('Digite a senha novamente'),
  term: Yup.bool()
    .oneOf([true], 'Você deve aceitar o Termo de adesão.')
    .required('Você deve aceitar o Termo de adesão.'),
});

const SignupPf = () => {
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [signupError, setSignupError] = useState(false);
  const { userData, registerUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const userDocument: any = state.document;
  const { deviceType } = useDeviceType();


  const handleModal = (modal: number) => {
    setShowModal(modal);
  };

  const formattedDocument = documentFormatter(userDocument);
  const unformattedDocument = state.document;

  const handleBirthKeyPress = (e: any) => {
    const keyCode = e.charCode;
    const value = e.target.value;

    if (keyCode >= 48 && keyCode <= 57) {
      return;
    }

    if (keyCode === 47 && (value.length === 2 || value.length === 5)) {
      return;
    }

    e.preventDefault();
  };

  const handleBirthInput = (e: any) => {
    const regex = /^(\d{0,2})([/]?)(\d{0,2})([/]?)(\d{0,4})$/;
    const matched = e.target.value.match(regex);

    if (matched) {
      const [, day, daySep, month, monthSep, year] = matched;
      e.target.value = `${day}${daySep}${month}${monthSep}${year}`;
    } else {
      e.target.value = '';
    }
  };

  
  const displayInput: CSSProperties = {
    display: "flex",
    alignItems: deviceType === "mobile" || deviceType === "mobile-landscape" || deviceType === "tablet" ? "" : "center",
    flexDirection: deviceType === "mobile" || deviceType === "mobile-landscape" || deviceType === "tablet" ? "column" : "row",
    width: "100%",
    marginBottom: "6px",
  };

  
  const rowInput: CSSProperties = {
    flex: 1,
    marginRight: deviceType === "mobile" || deviceType === "mobile-landscape" || deviceType === "tablet" ? "0px" : "1rem",
  };

  const rowSecondInput: CSSProperties = {
    flex: 1,
    marginRight: deviceType === "mobile" || deviceType === "mobile-landscape" || deviceType === "tablet" ? "0px" : "auto",
  };

  return (
    <>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <Container>
        <Content
          deviceType={{
            type: deviceType,
          }}>

        <div style={{ margin: "10px" }} >

            <Title>
              <AiOutlineArrowLeft
                style={{ marginRight: '1rem' }}
                onClick={() => {
                  navigate('/');
                }}
              />
              Realize seu cadastro
            </Title>
            <p>
              Verificamos que você ainda não possui um cadastro no Portal
              Consumidor. Para continuar, insira as informações abaixo e
              realize seu cadastro.
            </p>
            <Formik
              initialValues={{
                document: formattedDocument,
                firstName: '',
                lastName: '',
                birth: '',
                firstMomName: '',
                lastMomName: '',
                telefone: '',
                dddTelefone: '',
                email: '',
                confirmEmail: '',
                password: '',
                confirmPassword: '',
                term: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                const {
                  firstName,
                  lastName,
                  birth,
                  telefone,
                  dddTelefone,
                  firstMomName,
                  lastMomName,
                  email,
                  password,
                } = values;
                setLoading(true);
                registerUser({
                  document: unformattedDocument,
                  firstName: firstName,
                  lastName: lastName,
                  dddTelefone: dddTelefone,
                  telefone: telefone,
                  birth: birth,
                  firstMomName: firstMomName,
                  lastMomName: lastMomName,
                  email: email,
                  password: password,
                })
                  .then((res: any) => {
                    if (res.status === 'SUCESSO') {
                      navigate('/entrar', {
                        state: {
                          document: unformattedDocument,
                          password: values.password,
                          email: values.email,
                          signupLastStep: true,
                        },
                      });
                      setLoading(false);
                    } else if (res.status === 'DIVERGENTE') {
                      setLoading(false);
                      setSignupError(true);
                    }
                  })
                  .catch((e: any) => {
                    setLoading(false);
                    actions.setFieldError('', e.response.data.message);
                    actions.setSubmitting(false);
                    setSignupError(true);
                  });
                actions.setSubmitting(true);
                const timeOut = setTimeout(() => {
                  actions.setSubmitting(false);
                  clearTimeout(timeOut);
                }, 1000);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                handleChange,
                handleBlur,
                isValid,
                setFieldValue,
              }) => {
                return (
                  <>
                    <Form
                      name="contact"
                      method="post"
                      onSubmit={handleSubmit}
                    >
                      <Label htmlFor="document">
                        CPF:
                        <Input
                          type="text"
                          name="document"
                          autoCorrect="off"
                          autoComplete="name"
                          disabled
                          valid={touched.document && !errors.document}
                          error={touched.document && errors.document}
                          style={{ boxSizing: 'border-box' }}
                        />
                      </Label>

                      <div style={displayInput}>
                        <div style={rowInput}>
                          <LabelContainer>

                          <Label htmlFor="firstName">
                            Primeiro nome:
                            
                            <Input
                              type="text"
                              name="firstName"
                              autoCorrect="off"
                              autoComplete="name"
                              placeholder="Insira seu primeiro nome"
                              valid={touched.firstName && !errors.firstName}
                              error={touched.firstName && errors.firstName}
                              style={{ boxSizing: 'border-box' }}
                              />
                            </Label>
                          </LabelContainer>
                          {errors.firstName && touched.firstName && (
                            <StyledInlineErrorMessage>
                              {errors.firstName}
                            </StyledInlineErrorMessage>
                          )}
                        </div>
                        <div style={rowSecondInput}>
                          <LabelContainer>
                          <Label htmlFor="lastName">
                            Último nome:
                            <Input
                              type="text"
                              name="lastName"
                              autoCorrect="off"
                              autoComplete="name"
                              placeholder="Insira seu último nome"
                              valid={touched.lastName && !errors.lastName}
                              error={touched.lastName && errors.lastName}
                              style={{ boxSizing: 'border-box' }}
                            />
                          </Label>
                          </LabelContainer>
                          {errors.lastName && touched.lastName && (
                            <StyledInlineErrorMessage>
                              {errors.lastName}
                            </StyledInlineErrorMessage>
                          )}
                        </div>
                      </div>
                    <LabelContainer>

                      <Label htmlFor="birth">
                        Data de nascimento:
                        <Input
                          type="text"
                          name="birth"
                          autoCorrect="off"
                          placeholder="Insira no formato dd/mm/aaaa"
                          valid={touched.birth && !errors.birth}
                          error={touched.birth && errors.birth}
                          maxLength={10}
                          onKeyPress={handleBirthKeyPress}
                          onInput={handleBirthInput}
                          style={{ boxSizing: 'border-box' }}
                        />
                      </Label>
                    </LabelContainer>
                      {errors.birth && touched.birth && (
                        <StyledInlineErrorMessage>
                          {errors.birth}
                        </StyledInlineErrorMessage>
                      )}
                      <div style={displayInput}>
                        <div style={rowInput}>
                        <LabelContainer>
                          <Label htmlFor="firstMomName">
                            Primeiro nome da mãe:
                            <Input
                              type="text"
                              name="firstMomName"
                              autoCorrect="off"
                              autoComplete="name"
                              placeholder="Insira primeiro nome da mãe"
                              valid={
                                touched.firstMomName && !errors.firstMomName
                              }
                              error={
                                touched.firstMomName && errors.firstMomName
                              }
                              style={{ boxSizing: 'border-box' }}
                            />
                          </Label>
                          </LabelContainer>
                          {errors.firstMomName && touched.firstMomName && (
                            <StyledInlineErrorMessage>
                              {errors.firstMomName}
                            </StyledInlineErrorMessage>
                          )}
                        </div>

                        <div style={rowSecondInput}>
                        <LabelContainer>
                          <Label htmlFor="lastMomName">
                            Último nome da mãe:
                            <Input
                              type="text"
                              name="lastMomName"
                              autoCorrect="off"
                              autoComplete="name"
                              placeholder="Insira último nome da mãe"
                              valid={
                                touched.lastMomName && !errors.lastMomName
                              }
                              error={
                                touched.lastMomName && errors.lastMomName
                              }
                              style={{ boxSizing: 'border-box' }}
                            />
                          </Label>
                        </LabelContainer>
                          {errors.lastMomName && touched.lastMomName && (
                            <StyledInlineErrorMessage>
                              {errors.lastMomName}
                            </StyledInlineErrorMessage>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'inline-flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <div style={{ flex: 1 }}>
                        <LabelContainer>
                          <Label htmlFor="dddTelefone">DDD + Telefone:</Label>
                          </LabelContainer>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            flexDirection: 'row',
                          }}
                        >
                          <Input
                            type="text"
                            name="dddTelefone"
                            autoCorrect="off"
                            autoComplete="name"
                            placeholder="000"
                            maxLength="3"
                            valid={touched.dddTelefone && !errors.dddTelefone}
                            error={touched.dddTelefone && errors.dddTelefone}
                            style={{
                              boxSizing: 'border-box',
                              width: 60,
                            }}
                          />

                          <Input
                            type="text"
                            name="telefone"
                            autoCorrect="off"
                            autoComplete="name"
                            maxLength="9"
                            placeholder="00000000"
                            valid={touched.telefone && !errors.telefone}
                            error={touched.telefone && errors.telefone}
                            style={{
                              marginLeft: '2rem',
                              boxSizing: 'border-box',
                              width: 'calc(100% - 60px - 2rem)',
                            }}
                          />
                          {errors.dddTelefone && touched.dddTelefone && (
                            <StyledInlineErrorMessage>
                              {errors.dddTelefone}
                            </StyledInlineErrorMessage>
                          )}
                          {errors.telefone && touched.telefone && (
                            <StyledInlineErrorMessage>
                              {errors.telefone}
                            </StyledInlineErrorMessage>
                          )}
                        </div>
                      </div>
                        <LabelContainer>
                      <Label htmlFor="email">

                        Endereço de e-mail:
                        <Input
                          type="email"
                          name="email"
                          autoCapitalize="off"
                          autoCorrect="off"
                          autoComplete="email"
                          placeholder="Insira seu melhor endereço de e-mail"
                          valid={touched.email && !errors.email}
                          error={touched.email && errors.email}
                          style={{ boxSizing: 'border-box' }}
                          />
                        </Label>
                      </LabelContainer>
                      <ErrorMessage name="email">
                        {(msg) => (
                          <StyledInlineErrorMessage>
                            {msg}
                          </StyledInlineErrorMessage>
                        )}
                      </ErrorMessage>
                      <LabelContainer>

                      <Label htmlFor="confirmEmail">
                        Confirmação de e-mail:
                        <Input
                          type="email"
                          name="confirmEmail"
                          placeholder="Digite novamente o endereço indicado acima"
                          valid={touched.confirmEmail && !errors.confirmEmail}
                          error={touched.confirmEmail && errors.confirmEmail}
                          style={{ boxSizing: 'border-box' }}
                          />
                      </Label>
                    </LabelContainer>
                      <ErrorMessage name="confirmEmail">
                        {(msg) => (
                          <StyledInlineErrorMessage>
                            {msg}
                          </StyledInlineErrorMessage>
                        )}
                      </ErrorMessage>
                      <LabelContainer>
                      
                      <Label htmlFor="password">
                        Senha:
                        {/* <Input
                        type="password"
                        name="password"
                        autoCorrect="off"
                        autoComplete="name"
                        placeholder="Crie uma senha de acesso"
                        valid={touched.password && !errors.password}
                        error={touched.password && errors.password}
                      /> */}
                      <ContainerInputPassword>
                        <InputPassword
                          name="password"
                          autoCorrect="off"
                          autoComplete="name"
                          placeholder="Crie uma senha de acesso"
                          valid={touched.password && !errors.password}
                          error={touched.password && errors.password}
                          style={{ flex: '1 1 0', boxSizing: 'border-box' }}

                        />
                        </ContainerInputPassword>
                      </Label>
                      </LabelContainer>
                      {errors.password && touched.password && (
                        <StyledInlineErrorMessage>
                          {errors.password}
                        </StyledInlineErrorMessage>
                      )}
                      <LabelContainer>

                      <Label htmlFor="confirmPassword">
                        Confirmação de senha:
                        {/* <Input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirme sua senha de acesso"
                        valid={touched.confirmPassword && !errors.confirmPassword}
                        error={touched.confirmPassword && errors.confirmPassword}
                      /> */}
                      <ContainerInputPassword>
                        <InputPassword
                          name="confirmPassword"
                          placeholder="Confirme sua senha de acesso"
                          valid={
                            touched.confirmPassword && !errors.confirmPassword
                          }
                          error={
                            touched.confirmPassword && errors.confirmPassword
                          }
                          style={{ flex: '1 1 0', boxSizing: 'border-box' }}
                        />
                      </ContainerInputPassword>

                      </Label>
                      </LabelContainer>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <StyledInlineErrorMessage>
                          {errors.confirmPassword}
                        </StyledInlineErrorMessage>
                      )}
                      <CheckContainer
                        deviceType={{
                          type: deviceType,
                        }}
                      >
                        
                        <Input
                          type="checkbox"
                          name="term"
                          checked={values.term}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        Li e concordo com o
                        <strong
                          onClick={() => handleModal(1)}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          Termo de adesão
                        </strong>
                        .
                      </CheckContainer>
                      {errors.term && touched.term && (
                        <StyledInlineErrorMessage>
                          {errors.term}
                        </StyledInlineErrorMessage>
                      )}
                      <Submit
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        deviceType={{
                          type: deviceType,
                        }}
                      >
                        {isSubmitting ? `Avançando...` : `Avançar`}
                      </Submit>
                      {signupError && (
                        <StyledInlineErrorMessage>
                          Não conseguimos seguir com a sua solicitação por
                          divergência de informações.
                          <br />
                          Por favor, procure nossa Central de Atendimento ao
                          Cliente.
                          <br />O atendimento ocorre entre às 09h00 e às
                          18h00.
                        </StyledInlineErrorMessage>
                      )}
                    </Form>
                    <Modal
                      title="TERMO DE USO PARA ACESSO AO PORTAL DO SPC BRASIL"
                      isOpen={showModal === 1}
                      onToggleModal={() => handleModal(0)}
                      primaryActionButton={{
                        label: 'Li e aceito',
                        action: () => {
                          setFieldValue('term', true);
                          setDisabled(false);
                        },
                        closeOnclick: true,
                      }}
                    >
                      <ModalTerm deviceType={{ type: deviceType }}>
                        <SignupTerm />
                      </ModalTerm>
                    </Modal>
                    <Modal
                      title="TERMO DE USO PARA ACESSO AO PORTAL DO SPC BRASIL"
                      isOpen={showModal === 1}
                      onToggleModal={() => handleModal(0)}
                      primaryActionButton={{
                        label: 'Li e aceito',
                        action: () => {
                          setFieldValue('term', true);
                          setDisabled(false);
                        },
                        closeOnclick: true,
                      }}
                    >
                      <ModalTerm deviceType={{ type: deviceType }}>
                        <SignupTerm />
                      </ModalTerm>
                    </Modal>
                  </>
                );
              }}
            </Formik>
          </div>
          <div style={{ display: deviceType === "mobile" ? "none" : "" }}>
            <Container>
              <ImageContainer deviceType={{ type: deviceType }}>
                <img style={{ width: "40vw" }} src={require('../../../assets/images/Group151.png')} alt="Group" />
              </ImageContainer>
            </Container>
          </div>
        </Content>
      </Container>
    </>
  );
};

export default SignupPf;
