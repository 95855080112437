import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledPageBody = styled.div<{ hasScore?: boolean, padding?: 'sm' | 'lg', deviceType?: DeviceTypeProps }>`
  ${({ theme, hasScore, padding, deviceType }) => `
    min-height: ${deviceType?.type === 'mobile' ? '65vh' :
      deviceType?.type === 'mobile-landscape' ? 'auto' :
        deviceType?.type === 'tablet' ? '78vh' :
            deviceType?.type === 'laptop' ? '75.2vh' : ''
    };
    
    width: ${deviceType?.type === 'mobile' ? '90vw' :
      deviceType?.type === 'mobile-landscape' ? '80vw' :
        deviceType?.type === 'tablet' ? '90vw' :
          deviceType?.type === 'desktop' ? '70vw' :
            deviceType?.type === 'laptop' ? '75vw' : ''
    };

    background-color: ${theme.color.white['FFFFFF']};
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    z-index: 0;
    
    > h3 {
      margin: 25px 10px 0px 10px;

    }
    
  `}
  
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile' || deviceType?.type == 'tablet')
      return `
       justify-content:start;
      `;
  }}

`;

export const ContentPageBody = styled.div<{ deviceType?: DeviceTypeProps }>`
margin-bottom: 20px;
width: 90%;
`;

export const BoxHighlight = styled.div<{ reverse?: boolean, hasScore?: boolean, deviceType?: DeviceTypeProps }>`
  ${({ theme, reverse, hasScore, deviceType }) => `
  display: flex;
  flex-direction: row;
  flex-direction: ${deviceType?.type === 'mobile'
      || deviceType?.type === 'mobile-landscape'
      || deviceType?.type === 'tablet'
      ? 'column' : 'row'};
      
  align-items: center;
  margin-top:${deviceType?.type === "desktop" || deviceType?.type === "laptop" ? "30px" : "20px"};
  margin-bottom: 20px;
  
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: left;
  }
  
  ${hasScore ?
      `
      padding: ${deviceType?.type === 'mobile'
        ? '16px 49px'
        : deviceType?.type === 'tablet'
          ? '20px 40px'
          : deviceType?.type === 'laptop' || deviceType?.type === 'desktop'
            ? '50px 40px'
            : '40px 54px'
      };

    border-radius: 14px;
    box-sizing: content-box;

    position: relative;
    transform: translateX(-50%);
    left: 50%;
    
    
    width: ${deviceType?.type === 'mobile' ? '60vw' :
        deviceType?.type === 'mobile-landscape' ? '70vw' :
          deviceType?.type === 'tablet' ? '70vw' :
            deviceType?.type === 'desktop' ? '75vw' :
              deviceType?.type === 'laptop' ? '80vw' : ''
      };

    // Link Border Generator -> https://unused-css.com/tools/border-gradient-generator
    background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat,
    linear-gradient(90deg, #2296f3 0%, #ffbf2e 52%, #00b912 100%);
    border-radius: 8px;
      h4 {
        font-size ${deviceType?.type === 'mobile' ? '20px' :
        deviceType?.type === 'mobile-landscape' ? '20px' :
          deviceType?.type === 'tablet' ? '20px' :
            deviceType?.type === 'desktop' ? '28px' :
              deviceType?.type === 'laptop' ? '28px' : ''
      };
        margin-bottom: 14px;
      }
      
      ` :
      `
        background: ${theme.color.white['F4F7FC']};
        border-radius: 25.53px;
        padding: 24px;

        > * {
          flex: 1;
        }

        img {
          max-width: 150px;
        }
      `}
  `}
`;

export const TextContent = styled.div<{ hasScore?: boolean, deviceType?: DeviceTypeProps }>`
${({ deviceType }) => `
margin-top: 10px;
margin: ${deviceType?.type === 'mobile' ? '30px -10px 5px -10px' :
      deviceType?.type === 'mobile-landscape' ? 'auto' :
        deviceType?.type === 'tablet' ? '' :
          deviceType?.type === 'desktop' ? '0 7vw 0 1vw' :
            deviceType?.type === 'laptop' ? '12px 38px' : ''
    };
        `}

    b {
      font-weight: 500;
  }

  button{
    margin-top: 10px;
  }
`;

export const ButtonScore = styled.div<{ deviceType?: DeviceTypeProps }>`
  margin-top: 20px;
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape' || deviceType?.type === 'tablet')
      return `
    button{
      width: 100%;
    }
    
     `;
  }}
`

export const BackButton = styled.div<{ deviceType?: DeviceTypeProps }>`
  cursor: pointer;
  display: flex;
  gap: 22px;
  align-items: center;
  svg{
    width: 20px;
    height: 20px;
  }
 
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
    return `
        gap: 10px;
        margin-left: 12px;
      `;
  }}

`;

export const Title = styled.div<{ deviceType?: DeviceTypeProps }>`
      width: 100%;
      display:flex;
      margin-top: 24px;

      ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
            justify-content:center;
            margin:left:0px;
          `;
  }}
 `;

