import { DeviceTypeProps } from 'types/Responsive.types';
import { lightGray } from './../../../../../utils/colors/lightGray';
import styled from 'styled-components';

export const StyledFormFilter = styled.div`
  display: contents;
`;

export const FieldGroupContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 16px;
    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.lightGray['D5DDED']};
    }
  `}
`;

export const DateRange = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  gap: 12px;
  width: 360px;
  
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
    return `
      width: auto;
      flex-direction: column;
      `;
  }}

`;
