import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledLinkCard = styled(Link) <{ $alternative?: boolean, $inactive?: boolean, deviceType?: DeviceTypeProps  }>`
  ${({ theme, $alternative, $inactive, deviceType }) => css`
    text-decoration: none;
    align-items: center;
    border-radius: 16px;
    border: 1px solid ${theme.color.lightGray['E5E5E5']};
    cursor: ${$inactive ? 'none' : 'pointer'};
    pointer-events: ${$inactive ? 'none' : 'auto'};
    display: flex;
    justify-content:${deviceType?.type === "mobile"  || deviceType?.type === "mobile-landscape" ?
    "center" : "space-between"};
  flex-direction :${deviceType?.type === "mobile"  || deviceType?.type === "mobile-landscape" ?
    "column" : "row"};
  padding: ${deviceType?.type === "mobile"  || deviceType?.type === "mobile-landscape" ?
    "16px 0" : "16px 14px"};
    position: relative;
    margin: 2rem auto;
/*     margin-bottom: 2rem; */

    &:hover {
      background-color: ${theme.color.blue['007BFF']}16;
    }

    ${$alternative && `
      &:hover {
        background: linear-gradient(114.7deg, #007BFF -3.91%, #005ABA 103.48%);

        p, svg {
          color: ${theme.color.white['FFFFFF']};
        }
      }
    `}
  `}
`;
export const Content = styled.div`
  margin-left: 32px;
  flex: 1;
`;
