import { Text } from 'components';
import Gauge from 'react-gauge-chart';
import { GaugeChartProps } from 'types';
import { StyledGaugeChart } from './styles';
import useDeviceType from 'hooks/useDeviceType';
import { useEffect, useState } from 'react';

const GaugeChart = ({ level }: GaugeChartProps) => {
  const { deviceType } = useDeviceType();
  
  const [styleChart, setChartStyle] = useState({ height: 0, width: "300px" });

  const textLevelColors = ['#EE1D25', '#FFBC29', '#7DCC8B', '#00BD1E'];

  const levelColors = (level: number) => {
    let color: any = '';
    if (level < 400) {
      color = ['#EE1D25', 'transparent', 'transparent', 'transparent'];
    } else if (level >= 400 && level < 600) {
      color = ['#EE1D25', '#FFBC29', 'transparent', 'transparent'];
    } else if (level >= 600 && level < 800) {
      color = ['#EE1D25', '#FFBC29', '#7DCC8B', 'transparent'];
    } else {
      color = ['#EE1D25', '#FFBC29', '#7DCC8B', '#00BD1E'];
    }
    return color;
  };

  const getTextColor = (level: number) => {
    let color = '';
    if (level < 400) {
      color = textLevelColors[0];
    } else if (level >= 400 && level < 600) {
      color = textLevelColors[1];
    } else if (level >= 600 && level < 800) {
      color = textLevelColors[2];
    } else {
      color = textLevelColors[3];
    }
    return color;
  };

  useEffect(() => {
  const newChartStyle = {
    height: 0,
    width: deviceType !== 'mobile' ? '300px' : '220px',
  };
  setChartStyle(newChartStyle);
  
}, [deviceType]);

  return (
    <StyledGaugeChart deviceType={{ type: deviceType }}>
      <Gauge
        style={styleChart}
        cornerRadius={0}
        arcWidth={0.35}
        nrOfLevels={1}
        marginInPercent={0}
        colors={['#EFF3FE']} />
      <Gauge
        style={styleChart}
        id="score"
        cornerRadius={0}
        colors={levelColors(level)}
        arcWidth={0.35}
        nrOfLevels={4}
        percent={level / 100}
        arcPadding={0.014}
        marginInPercent={0}
        hideText={true}
        textColor={getTextColor(level)}
        formatTextValue={(level) => `${parseInt(level)}`}
      />
      <Text>
        <h1 style={{
          fontSize: deviceType === "mobile" ? "24px" : "34px",
          marginBottom: "0.15rem",
          textAlign: "center",
          color: `${getTextColor(level)}`
        }}>
          {level}
        </h1>
        <div style={{ textAlign: "center" }}>de 1000.</div>
      </Text>
    </StyledGaugeChart >
  );
};

export { GaugeChart };
