import { api as apiAuth, apiLogin } from './api';
import { api } from 'services/Cadastro/api';

export const auth = {
  set: async (params: any) => {
    const auth = await apiAuth.post('/cadastropositivo-consumidor-oauth/oauth/token', params);

    const response = {
      data: auth.data,
      status: auth.status,
      statusText: auth.statusText,
    };

    return response;
  },


  registerPf: async (params: any) => {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    const res = await apiLogin.post('/cadastropositivo-consumidor-login-service/senha-consumidor/v2/valida-consumidor-pf', params, options);

    const response = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
    };

    return response
  },

  registerPj: async (params: any) => {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    const res = await apiLogin.post('/cadastropositivo-consumidor-login-service/senha-consumidor/v2/valida-consumidor-pj', params, options);

    const response = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
    };

    return response
  },

  verifyDocumentPf: async (params: any) => {
    const res = await apiLogin.get(`/cadastropositivo-consumidor-login-service/senha-consumidor/pf-login/${params.document}`);

    const response = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
    };

    return response;
  },
  verifyDocumentPj: async (params: any) => {
    const res = await apiLogin.get(`/cadastropositivo-consumidor-login-service/senha-consumidor/pj-login/${params.document}`);

    const response = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
    };

    return response;
  },
  recoverPassword: async (params: any) => {
    const res = await apiLogin.post('/cadastropositivo-consumidor-login-service/senha-consumidor/email-recupera-senha-v2', params);

    const response = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
    };

    return response
  },

  getUserInfoByHash: async (params: any) => {
    const res = await apiLogin.get(`/cadastropositivo-consumidor-login-service/senha-consumidor/recadastro-senha-valido/${params.hash}`);

    const response = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
    };

    return response;
  },

  updatePassword: async (params: any) => {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    const res = await apiLogin.post('/cadastropositivo-consumidor-login-service/senha-consumidor/pf-recadastro', params, options);

    const response = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
    };

    return response
  },

  updatePasswordPJ: async (params: any) => {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    const res = await apiLogin.post('/cadastropositivo-consumidor-login-service/senha-consumidor/pj-recadastro', params, options);

    const response = {
      data: res.data,
      status: res.status,
      statusText: res.statusText,
    };

    return response
  },

  getRepresentativesInfo: async (params: any) => {
    if (params?.document !== "" && params?.document !== undefined) {
      const res = await api().get(`cadastropositivo-autorizacao/consumidor/pj/${params.document}`);

      return {
        data: res?.data?.representantes || [],
        status: res?.status,
        statusText: res?.statusText,
      };
    }

    return { data:[], status: "", statusText: "" }
  }
};


