import { LoadingSpinner, PageSkeleton, QueryInfoList } from 'components';
import { AuthContext, CadastroContext } from 'contexts';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { ContestDetails } from './components/ContestDetails';
import { Filters, FormFilter, factoryFilters } from './components/FormFilter';
import { StatusButton } from './components/StatusButton';
import { QueryContainer, QueryInfoConainter, SearchContainer  } from './styles';
import MessageAlert from 'components/MessageAlert';
import useDeviceType from 'hooks/useDeviceType';

export interface Details {
  numeroProtocolo: string
  dataContestacao: number
  justificativaInclusao: string
  textoStatus: string
  numeroContrato: string
  dataContratacao: number
  nomeEmpresa: string
  descricaoAnaliseFonte: string
  cnpjEmpresa: string
  status: string
  numeroFatura: number
  nomeBiroOrigem: string
  cnpjBiroOrigem: string
  codigoStatusTela: string
  categoriaContestacao: string
}

const factoryDetails: Details = {
  numeroProtocolo: '',
  dataContestacao: 0,
  justificativaInclusao: '',
  textoStatus: '',
  numeroContrato: '',
  dataContratacao: 0,
  nomeEmpresa: '',
  descricaoAnaliseFonte: '',
  cnpjEmpresa: '',
  status: '',
  numeroFatura: 0,
  nomeBiroOrigem: '',
  cnpjBiroOrigem: '',
  codigoStatusTela: '',
  categoriaContestacao: '',
};

export default function Contestacao() {
  const [page, setPage] = useState(0);
  const [details, setDetails] = useState(factoryDetails);
  const [filter, setFilter] = useState(factoryFilters);
  const { contestation, getContestation, isLoading, setLoading } = useContext(CadastroContext);
  const [loadingStatus, setLoagingStatus] = useState(Boolean);
  const { userData } = useContext(AuthContext);

  const { dataInicial, dataFinal, nomeEmpresa, listaSegmento, listaStatus } = filter;
  const isFilterActive = !!dataInicial || !!dataFinal || !!nomeEmpresa || listaSegmento?.length > 0 || listaStatus?.length > 0;


  useEffect(() => {
    setLoagingStatus(isLoading ?? false);
  }, [isLoading]);

  useEffect(() => {
    setLoading(true);


    if (userData.cpf !== "" && userData.cpf !== undefined){
        getContestation({
          ...filter,
          cpfBusca: userData.cpf,
          tipoClienteAutorizado: 'CPF',
          numeroPagina: 0,
        });
      }else{
        getContestation({
          ...filter,
          cnpjRaizBusca: userData.cnpj,
          tipoClienteAutorizado: 'CNPJ_COMPLETO',
          numeroPagina: 0,
        });
      }

  }, [filter]);

  const handleDetails = (info: Details) => {
    setPage(1);
    setDetails(info);
  };

  const handleTranslateStatus = (status: string) => {
    switch (status) {
      case 'AGUARDANDO_ENVIO_FONTE':
      case 'NAO_ENVIAR_FONTE':
        return 'Gerada';
      case 'ENVIADA_FONTE_SEM_CONFIRMACAO_RECEBIMENTO':
        return 'Enviada';
      case 'PROCESSADA_FONTE':
        return 'Concluída';
      case 'RECEBIDA_FONTE':
      case 'PENDENTE_RETORNO_FONTE':
      case 'ERRO_RETORNO_CONCENTRADOR':
        return 'Em Análise';
      default:
        return 'Sem informação de status';
    }
  };

  const { deviceType } = useDeviceType();

  const getModalFilters = (filter: Filters) => {
    setFilter({
      ...filter,
      dataInicial:
        filter.dataInicial == '' ? ("") : (moment(filter.dataInicial).format('YYYY-MM-DD')),
      dataFinal:
        filter.dataFinal == '' ? ("") : (moment(filter.dataFinal).format('YYYY-MM-DD'))
    })
  };

  if (page === 1) {
    return <ContestDetails info={details} goBack={() => setPage(0)} />;
  }

  return (
    <PageSkeleton
      title="Contestações"
      breadcrumb={{
        menuName: 'Início',
        submenuName: 'Cadastro Positivo',
        thirdLevel: 'Contestação',
      }}
      highlight={{
        title: 'Nesta página, você encontrará o histórico de todas as suas contestações.',
        description: `Aqui ficam listadas todas as contestações geradas. Para buscar
        uma contestação em específico, basta você filtrar de acordo com os status.`,
        image: require('../../../assets/images/contestacao-2.png'),
        reverse: true,
      }}
      paddingSize='sm'
    >
      <div>
        <LoadingSpinner state={loadingStatus} />
      </div>
      <QueryContainer>
        <SearchContainer>
          <FormFilter sendFilters={getModalFilters} />
        </SearchContainer>
        {(contestation?.length > 0)
          ?
          (contestation.map((item, index) => {
            return (
              <QueryInfoConainter deviceType={{ type: deviceType }}              >
                <QueryInfoList
                  key={index}
                  day={item.day}
                  month={item.month}
                  queryList={item.data.map((detail: Details) => {
                    const checkStatus = handleTranslateStatus(detail.status) === 'Concluída' ? 'success' :
                      (handleTranslateStatus(detail.status) === 'Enviada' ||
                        handleTranslateStatus(detail.status) === 'Gerada' ||
                        handleTranslateStatus(detail.status) === 'Em Análise') ? 'warning' : 'error';
                    return (
                      {
                        title: detail.nomeEmpresa,
                        description: detail.cnpjEmpresa,
                        status: <StatusButton
                          label={handleTranslateStatus(detail.status)}
                          status={checkStatus}
                        />,
                        onClick: () => handleDetails(detail),
                      }
                    );
                  })
                  }
                /></QueryInfoConainter>
            );
          }))
          :
          (<div style={{ width: "80%" }} >
            <MessageAlert data={contestation} filter={isFilterActive && true} />
          </div>
          )
        }


      </QueryContainer>
    </PageSkeleton>
  );
}
