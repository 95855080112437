import { calculateFontSize } from 'components/Text/styles';
import styled, { css } from 'styled-components'
import { DeviceTypeProps } from 'types/Responsive.types'

export const Input = styled.input<{ deviceType?: DeviceTypeProps }>`

font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};  ;

${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
      width: 260px;
      `;
      else if(deviceType?.type === 'mobile-landscape')
      return `
      width: 95%;
      `;
  }}

  ${({ theme }) => css`
      border-radius: 4px;
      color: ${theme.color.neutral['586682']};
      height: 1rem;
      background: ${theme.color.white['FFFFFF']};
      border: 1.4px solid ${theme.color.lightGray['D5DDED']};
      font-style: normal;
      font-weight: 400;
      padding: 22px 4px;
      ::placeholder {
        color: ${theme.color.neutral['586682']};
        opacity: .65;
      }

    :focus {
      outline: transparent;
    }
    `}


`
