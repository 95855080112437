import { Button, Input, LoadingSpinner, PageSkeleton, Text } from 'components';
import { AuthContext, CadastroContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { ModalLiberacao } from './components/ModalLiberacao';
import { AlertContainer, CompaniesContainer, PaginateResponsive, SearchContainer, StyledCard, StyledCardContainer } from './styles';
import './pagination.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { CloseIcon } from 'components/Icon/CloseIcon';
import { useNavigate } from 'react-router-dom';

import { consultas as consultasService } from 'services/Cadastro';
import useDeviceType from 'hooks/useDeviceType';

const LiberarHistoricoPagamento = () => {
  const { userData } = useContext(AuthContext);
  const { historyMsg, getPermissions, requestStatus } = useContext(CadastroContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const [formValues, setFormValues] = useState<any>("");
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [showCompanies, setShowCompanies] = useState(false);
  const [companies, setCompanies] = useState<any>([]);
  const [checked, setChecked] = useState("");
  const navigate = useNavigate();

  const Items = ({ currentItems }: any) => {
    if (!currentItems || currentItems.length === 0) {
      return null;
    }

    return (
      <>
        <div style={{ marginBottom: "2rem" }}>
          {currentItems.map((item: any, index: any) => (
            <StyledCardContainer key={index}>
              <StyledCard  >
                <label>
                  <Text size={1} lineHeight={1} weight={2}>{item?.nomeFantasia}</Text>
                  <Text size={1} lineHeight={1}>CNPJ: {item?.cnpj.formattedValue}</Text>
                  <Text size={1} lineHeight={1}>Endereço: {item?.endereco.logradouro}, {item?.endereco.numero}  - CEP: {item?.endereco.cep} </Text>
                </label>
                <input style={{ width: "24px" }}
                  checked={checked == item?.id}
                  onChange={e => handleSelectedCompany(e)}
                  type="radio" name="group" value={item?.id} />
              </StyledCard>
            </StyledCardContainer>
          ))}
        </div>
      </>
    );
  };

  const handleFillterForm = async () => {
    await consultasService.post({ ...formValues }).then((response) => {
      setCompanies(response);
    }).catch((error) => {
      setCompanies([])
    });

    if (userData.cpf !== "" && userData.cpf !== undefined) {
      getPermissions({
        'cpfConsumidor': userData.cpf || '',
        cnpjConsumidor: ''
      })
    } else {
      getPermissions({
        'cnpjConsumidor': userData.cnpj || '',
        cpfConsumidor: ''
      })
    }

    setShowCompanies(true)
  };

  const handleFillForm = (fieldName: string, fieldValue: string) => {
    setFormValues({ [fieldName]: fieldValue });
  };

  const handleSelectedCompany = (event: any) => {
    setChecked(event.target.value);
    setSelectedCompany(event.target.value)
  }

  const handleEraseButton = () => {
    setChecked("")
    setFormValues("")
    setShowCompanies(false)
  };

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * 3) % companies.length;
    setItemOffset(newOffset);
  };

  const startsWithNumber = (str: any) => {
    return /^\d/.test(str);
  }

  const companyData = companies.find((obj: any) => obj.id == selectedCompany)

  const endOffset = itemOffset + 3;

  const currentItems = companies.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(companies.length / 3);

  const goBack = () => navigate('/cadastro-positivo/liberacao-historico')



  useEffect(() => {
    if (userData.cpf !== "" && userData.cpf !== undefined) {
      getPermissions({
        'cpfConsumidor': userData.cpf || '',
        cnpjConsumidor: ''
      })
    } else {
      getPermissions({
        'cnpjConsumidor': userData.cnpj || '',
        cpfConsumidor: ''
      })
    }
  }, [userData.cpf, userData.cnpj, isModalOpen, requestStatus, companies]);


  const { deviceType } = useDeviceType();
  return (

    <PageSkeleton
      title="Liberar Histórico de Pagamentos"
      backButton={{
        render: true,
        action: goBack,
      }}
      breadcrumb={{
        menuName: 'Início',
        submenuName: 'Cadastro Positivo',
        thirdLevel: 'Liberação ao Histórico de Pagamentos',
      }}
      highlight={{
        title: 'Aqui você autoriza que empresas especificas tenham acesso a todos os dados detalhados do histórico do seu Cadastro Positivo.',
        description: 'Ao liberar seu Histórico para uma empresa, ela terá acesso a eles durante o tempo determinado por você na liberação.',
        image: require('../../../assets/images/liberacao-pagamentos-1.png'),
      }}
    >
      <div>
        <LoadingSpinner state={history === null ? true : false} />
      </div>

      <SearchContainer deviceType={{ type: deviceType }}>
        <Text tag="h4" size={1} weight={3} lineHeight={1} color="text">
          Pesquise pelo nome ou CNPJ da empresa:
        </Text>
        <br />
        <div style={{ position: "relative" }}>
          <Input
            id='nomeOuCnpjConsulente'
            inputText={formValues.nome ? (formValues.nome) : (formValues.cnpj)}
            placeholder='Pesquise pelo nome completo ou CNPJ da empresa'
            onChange={(e: any) => {
              startsWithNumber(e) === true ? (handleFillForm('cnpj', e)) : (handleFillForm('nome', e))
            }
            }
          />
          <div style={{
            position: "absolute",
            right: "1rem",
            top: "0.7rem"
          }}>
            {formValues == "" /* || formValues.nome == "" || formValues.cnpj == "" */ ? ("")
              : (<button style={{ border: "none", background: "transparent" }}
                onClick={handleEraseButton}>
                <CloseIcon />
              </button>)}
          </div>
        </div>
        <br />
        <Button disabled={formValues == "" || formValues.nome == "" || formValues.cnpj == "" ? (true) : (false)} onClick={() => handleFillterForm()}>
          Buscar
        </Button>
      </SearchContainer>
      {
        showCompanies === true ? (
          <div>
            {companies?.length > 0 ? (
              <>
                <Items currentItems={currentItems} />
              </>
            ) :
              <AlertContainer  deviceType={{ type: deviceType }}>
                <Text size={1} lineHeight={1} weight={2}>
                  Nenhuma empresa encontrada.
                </Text>
              </AlertContainer>
            }
            <CompaniesContainer deviceType={{ type: deviceType }}>
              <PaginateResponsive deviceType={{ type: deviceType }}>
                <ReactPaginate
                  previousLabel={<FaAngleLeft
                  />}
                  nextLabel={<FaAngleRight />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={
                    deviceType === "mobile" ?  0 : 
                    deviceType === "tablet" || deviceType === "mobile-landscape" ? 1 :  2}
                  pageCount={pageCount}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel={deviceType === "mobile" ? "" : "..."}
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                  disableInitialCallback={false}

                />
              </PaginateResponsive>
              <Button disabled={selectedCompany === null ? (true) : (false)} onClick={() => setModalOpen(true)}>Avançar</Button>
            </CompaniesContainer>
          </div>
        ) : ("")
      }


      {isModalOpen && <ModalLiberacao selectedCompany={companyData} isModalOpen={isModalOpen} onToggleModal={() => setModalOpen(!isModalOpen)} />}
    </PageSkeleton >
  );
};

export default LiberarHistoricoPagamento
