import { calculateFontSize } from 'components/Text/styles';
import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledFooter = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme }) => css` 
    bottom: 0;
    display: flex;
    align-items: center;
    background: ${theme.color.blue['154695']};
    color: ${theme.color.white['FFFFFF']};
    width: 100%;
    height: 160px;
    `}  
    
    ${({ deviceType }) => {
     if (deviceType?.type == 'tablet') {
      return `
          height: 110px;
          `;
    }
    else if (deviceType?.type == 'laptop') {
      return `
          height: 120px;
          
          `;
    }
  }}

`;

export const FooterContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
margin: 0 84px;

${({ deviceType }) => {
  if (deviceType?.type === 'mobile')
  return `
  margin: 0 24px;
    `;
}}

`;

export const FooterLinks = styled.div<{ deviceType?: DeviceTypeProps }>`
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
  font-weight: 300;
  text-align: left;
  cursor: pointer;
`;

export const FooterLink = styled.a<{ deviceType?: DeviceTypeProps }>`
  color: #FFF;

  display: block;

  font-weight: 300;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};

  
  line-height: 36px;
  
  text-decoration: none;
 
`;
