import { FormField, Text } from 'components';
import { useEffect, useState } from 'react';
import { CheckboxProps } from 'types';
import { RadioOption, RadioOptionsContainer, StyledCheckbox } from './styles';

const Checkbox = ({
  helperText,
  horizontal,
  label,
  name,
  onCheck,
  options,
  status,
  checkdList,
}: CheckboxProps) => {
  const [checkValue, setCheckValue] = useState<Array<string>>([]);

  const handleCheck = (value: string) => {
    if (checkValue.includes(value)) {
      setCheckValue(checkValue.filter((item) => item !== value));
    } else {
      setCheckValue([...checkValue, value]);
    }
  };

  useEffect(() => {
    onCheck(checkdList);
    setCheckValue(checkdList ?? []);
  }, [checkdList]);

  useEffect(() => {
    onCheck(checkValue);
  }, [checkValue]);

  return (
    <StyledCheckbox>
      <FormField label={label} status={status} helperText={helperText}>
        <RadioOptionsContainer horizontal={horizontal}>
          {
            options.map((option, index) => (
              <RadioOption key={index} htmlFor={option.value ?? ''}>
                <input
                  key={option.value}
                  type="checkbox"
                  checked={checkValue.includes(option.value ?? '')}
                  onChange={() => handleCheck(option.value ?? '')}
                  id={option.value}
                  name={name} />
                <Text size={1}>{option.label}</Text>
              </RadioOption>
            ))
          }
        </RadioOptionsContainer>
      </FormField>
    </StyledCheckbox>
  );
};

export { Checkbox };
