import { ConfirmationBox, LoadingSpinner, Modal } from 'components';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { history as historyService } from 'services/Cadastro';
import { formatUnixTime } from 'utils/formatUnixTime';
import { OperationInfo } from '../..';
import { DetailBlock, HistoryContainer, PaymentTable, PaymentTableContainer, TableCell, TableRow } from './style';
import CartaoCredito from './ModalitiesDetails/CartaoCredito';
import Consorcio from './ModalitiesDetails/Consorcio';
import CreditoParcelado from './ModalitiesDetails/CreditoParcelado';
import CreditoRotativo from './ModalitiesDetails/CreditoRotativo';
import Consignado from './ModalitiesDetails/Consignado';
import Telecomunicacao from './ModalitiesDetails/Telecomunicacao';
import Energia from './ModalitiesDetails/Energia';
import { documentFormatter } from 'utils/documentFormatter';
import { RequestStatusContext } from 'contexts';
import useDeviceType from 'hooks/useDeviceType';
import ListTableMobile from './ListTableMobile';

interface DetailsProps {
  info: OperationInfo
}

interface Details {
  codigoModalidade: string;
  id: string
  nomeComercialFonteOrigem: string
  cnpjFonteContratacao: number
  dataContratacao: number
  prefixado: string
  valorContratadoFuturo: number
  quantidadeParcela: number
  dataVencimentoUltimaParcela: number
  dataAlteracao: number
  modalidade: string
  tipoFonteOrigem: string
  numeroContrato: string
  cnpjFonteOrigem: number
}

interface TableData {
  pagamentoDTO: {
    movimentoContestado?: any;
    dataVencimento: number
    valorPagamento?: number
    dataPagamento?: number
    codigoModalidade?: any
    tipoPagamento?: string
    saldoDevedor?: number
    statusFatura?: string
    saldoUtilizado?: number
  }
  valorProximaParcela?: number
  valorTotalProximaFatura?: number
  fatura: string
  status: string
  valorTransacao?: number
  valorMinimoProximaFatura?: number
  dataProximoFechamento?: number
  saldoUtilizado?: number
  tipoMovimento?: string
  dataProximoVencimento?: number
}

interface AlternateData {
  numeroFatura: string;
  movimentoContestado: any;
  dataVencimento: number
  valorFaturaMonetario: number
  dataPagamento?: number
  valorPagamento: number
  tipoPagamento?: string
  saldoDevedor?: number
  statusFatura?: string
  dataProximoFechamento?: number
  saldoUtilizado?: number
}

const DetailsFactory: Details = {
  id: '',
  nomeComercialFonteOrigem: '',
  cnpjFonteContratacao: 0,
  dataContratacao: 0,
  prefixado: '',
  valorContratadoFuturo: 1000.50,
  quantidadeParcela: 0,
  dataVencimentoUltimaParcela: 0,
  dataAlteracao: 0,
  modalidade: '',
  tipoFonteOrigem: '',
  numeroContrato: '',
  codigoModalidade: '',
  cnpjFonteOrigem: 0
};

interface Payment {
  statusFatura: string;
  fatura: string;
  valorTransacao: number;
  pagamentoDTO: {
    dataPagamento: number;
    dataVencimento: number;
    valorPagamento: number;
    statusFatura: string;
    movimentoContestado: boolean;
    saldoUtilizado: number;
  };
  status: string;
  dataProximoFechamento: number;
  saldoUtilizado: number;
}


const columns_cartao_credito = [
  'Data de vencimento:',
  'Valor total:',
  'Valor mínimo:',
  'Data do pagamento:',
  'Valor pago:'
];
const columns_parcelado_consorcio = [
  'Tipo de pagamento',
  'Data de vencimento:',
  'Valor:',
  'Data do pagamento:',
  'Valor pago:'
];
const columns_telco_energia = [
  'Número da fatura',
  'Data de vencimento:',
  'Valor fatura:',
  'Data do pagamento:',
  'Valor pago:',
  'Status fatura:'
];
const columns_rotativo = [
  'Data de fechamento:',
  '',
  '',
  '',
  '',
  'Saldo utilizado:'
];

const modalityDictionary: { [name: string]: string } = {
  CARTAO_CREDITO: 'Cartão de Crédito',
  CREDITO_PARCELADO: 'Crédito Parcelado',
  CREDITO_ROTATIVO: 'Crédito Rotativo',
  CONSORCIO: 'Consórcio',
  TELCOS: 'Telecomunicações',
  ENERGIAS: 'Energias',
};

export const OperationDetails = ({ info }: DetailsProps) => {
  const [details, setDetails] = useState(DetailsFactory);
  const [payments, setPayments] = useState<TableData[]>([]);
  const [openParcels, setOpenParcels] = useState(0);
  const [expiredParcels, setExpiredParcels] = useState(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { addRequest, requestStack } = useContext(RequestStatusContext);
  const [columns, setColumns] = useState<string[]>(columns_parcelado_consorcio);
  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    Promise.resolve(loadDetails()).then(() => {
      loadPayments();
    }).catch((e) => {
      addRequest({
        statusCode: e?.response?.status,
        message: 'Erro ao carregar dados do histórico, favor repetir a requisição',
      });
      setLoading(false);
    });
  }, []);

  const handleStatus = (status: string) => {
    switch (status) {
      case 'A':
        return 'Aberta';
      case 'F':
        return 'Fechada';
      case 'X':
        return 'Cancelada';
      case 'C':
        return 'Contestada';
      case 'N':
        return 'Negociada';
      default:
        return 'Sem informação de status';
    }
  }

  useEffect(() => {

    if (modalityDictionary[details?.modalidade] == "Cartão de Crédito") { setColumns(columns_cartao_credito) }
    if (modalityDictionary[details?.modalidade] == "Crédito Rotativo") { setColumns(columns_rotativo) }

    if (modalityDictionary[details?.modalidade] == "Consórcio"
      || modalityDictionary[details?.modalidade] == "Crédito Parcelado") { setColumns(columns_parcelado_consorcio) }

    if (modalityDictionary[details?.modalidade] == "Telecomunicações"
      || modalityDictionary[details?.modalidade] == "Energias") { setColumns(columns_telco_energia) }

  }, [details]);

  const loadDetails = () => {
    historyService.details[info.type](
      (info.docType === 'CPF' || info.docType === 'PF' || info.docType === 'F') ? info.docType : 'CNPJ_COMPLETO',
      info.document.toString(),
      info.id,
    ).then((res) => setDetails(res.data))
      .catch((e) => {
        addRequest({
          statusCode: e?.response?.status,
          message: 'Erro ao carregar detalhes do histórico, favor repetir a requisição',
        });
        navigate('/cadastro-positivo/historico-pagamentos')
        setLoading(false);
      });
  }

  const loadPayments = () => {
    historyService.payments[info.type](
      (info.docType === 'CPF' || info.docType === 'PF' || info.docType === 'F') ? info.docType : 'CNPJ_COMPLETO',
      info.document.toString(),
      info.id,
      info.source.toString(),
      info.contract
    ).then((res) => sortPayments(res.data.itens)
      .then((res) => {
        setPayments(sortData(res.data));
        setOpenParcels(res.open);
        setExpiredParcels(res.expired);
        setLoading(false);
      }).catch((e) => {
        addRequest({
          statusCode: e?.response?.status,
          message: 'Erro ao carregar dados de pagamento do histórico, favor repetir a requisição',
        });
        setLoading(false);
      })
    ).catch((e) => {
      addRequest({
        statusCode: e?.response?.status,
        message: 'Erro ao carregar dados de pagamento do histórico, favor repetir a requisição',
      });
      setLoading(false);
    });
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Realizado':
        return '#00B912';
      case 'Em aberto':
        return '#FFBF2E';
      case 'Vencido':
        return '#ED2F48'
      default:
        return '';
    }
  };

  const sortPayments = async (paymentsList: TableData[] | AlternateData[]) => {
    return new Promise<{ data: TableData[], open: number, expired: any }>((resolve) => {
      if (info.type !== 'operations') {
        const formattedList: TableData[] = []

        for (const item of paymentsList as AlternateData[]) {
          formattedList.push({
            pagamentoDTO: {
              dataVencimento: item?.dataVencimento ? Number(item?.dataVencimento) : 0,
              valorPagamento: item?.valorFaturaMonetario ? Number(item?.valorFaturaMonetario) : undefined,
              dataPagamento: item?.dataPagamento ? Number(item?.dataPagamento) : undefined,
              tipoPagamento: item?.tipoPagamento ? String(item?.tipoPagamento) : undefined,
              saldoDevedor: item?.statusFatura ? Number(item?.saldoDevedor) : 0,
              statusFatura: item?.statusFatura ? String(item?.statusFatura) : '',
              movimentoContestado: item?.movimentoContestado ? 'Sim' : 'Não',
              saldoUtilizado: item?.saldoUtilizado ? Number(item?.saldoUtilizado) : undefined
            },
            fatura: item?.numeroFatura,
            status: '',
            valorTransacao: item?.valorPagamento ? Number(item?.valorPagamento) : undefined,
          })
        }

        paymentsList = formattedList;
      }

      const sortedList: TableData[] = (paymentsList as TableData[]).sort((a, b) => (b?.pagamentoDTO?.dataVencimento > a?.pagamentoDTO?.dataVencimento ? 1
        : b.pagamentoDTO.dataVencimento === a.pagamentoDTO.dataVencimento ? 0 : -1))

      for (const item of sortedList) {
        const expirationDate = item.pagamentoDTO.dataVencimento;
        const paymantDate = item.pagamentoDTO.dataPagamento ?? 0

        if (item.valorTransacao && paymantDate > 0) {
          item.status = 'Realizado';
        } else if (!item.valorTransacao && (new Date(expirationDate) >= new Date())) {
          item.status = 'Pendente';
        } else {
          item.status = 'Vencido';
        }
      }

      const expiredPayments = sortedList.filter((payment) => payment.status === 'Vencido');
      const donePayments = sortedList.filter((payment) => payment.status === 'Realizado');
      const pendingPayments = sortedList.filter((payment) => payment.status === 'Pendente');
      const openPayment = [pendingPayments[0]] ?? [];
      pendingPayments.shift();

      if (openPayment.length > 0) {
        openPayment[0] && (openPayment[0].status = 'Em aberto');
      }

      const sortedPayments: TableData[] = expiredPayments.concat(openPayment.concat(donePayments.concat(pendingPayments)));

      resolve({ data: sortedPayments, open: openPayment.length + pendingPayments.length, expired: expiredPayments.length });
    });
  };

  const sortData = (list: TableData[]) => {
    if (info.type === 'operations') {

      const sortedList: TableData[] = (list as TableData[]).sort((a, b) => (((b?.dataProximoFechamento !== undefined && a?.dataProximoFechamento !== undefined)
        && a?.dataProximoFechamento < b?.dataProximoFechamento) ? 1 : ((b?.dataProximoFechamento !== undefined && a?.dataProximoFechamento !== undefined) && b?.dataProximoFechamento < a?.dataProximoFechamento) ? -1 : 0))

      return sortedList;
    }

    return list;
  };

  const handleContest = () => {
    const urlParams = `../realizar-contestacao?type=operation&modality=${info.type}&id=${details?.id}`;

    if (selectedPayment?.fatura) {
      navigate(`${urlParams}&invoiceID=${selectedPayment?.fatura}`, { state: { from: location.pathname }} );
    } else {
      navigate(urlParams, { state: { from: location.pathname }} );
    }
  };


  const handleModal = (isOpen: any) => {
    setModalOpen(isOpen);
  };

  const handlePaymentSelection = (payment: any) => {
    setSelectedPayment(payment);
  };

  const { deviceType } = useDeviceType();

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedPayment(null);
    }
  }, [isModalOpen]);

  const paymentsEnergiaAndTelecomunicacoes: ListTableMobile[] = payments
  .filter(payment => payment !== undefined)
  .map(payment => ({
    title: 'Fatura',
    topics: [
      { name: 'Número da fatura', description: payment?.fatura || '-' },
      { name: 'Data de vencimento', description: payment?.pagamentoDTO?.dataVencimento ? formatUnixTime(payment.pagamentoDTO.dataVencimento) : '-' },
      { name: 'Valor fatura', description: payment?.valorTransacao ? payment.valorTransacao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' },
      { name: 'Data do pagamento', description: payment?.pagamentoDTO?.dataPagamento ? formatUnixTime(payment.pagamentoDTO.dataPagamento) : '-' },
      { name: 'Valor pago', description: payment?.pagamentoDTO?.valorPagamento ? payment.pagamentoDTO.valorPagamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' },
      { name: 'Status fatura', description: handleStatus(payment?.pagamentoDTO?.statusFatura ?? '') }
    ],
   originalPayment: payment

  }));

  const paymentConsorcioAndCreditoParcelado: ListTableMobile[] = payments
 .filter(payment => payment !== undefined)
 .map(payment => ({
    title: 'Pagamento',
    topics: [
      { name: 'Tipo de pagamento', description: payment?.pagamentoDTO?.tipoPagamento || '-' },
      { name: 'Data de vencimento', description: payment?.pagamentoDTO?.dataVencimento ? formatUnixTime(payment?.pagamentoDTO?.dataVencimento) : (payment?.dataProximoVencimento ? formatUnixTime(payment?.dataProximoVencimento) : '-') },
      { name: 'Valor transação', description: payment?.valorTransacao ? payment.valorTransacao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' },
      { name: 'Data do pagamento', description: payment?.pagamentoDTO?.dataPagamento ? formatUnixTime(payment?.pagamentoDTO?.dataPagamento) : '-' },
      { name: 'Valor do pagamento', description: payment?.pagamentoDTO?.valorPagamento ? payment.pagamentoDTO.valorPagamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' },
    ]
  }));

  const paymentCartaoCredito: ListTableMobile[] = payments
  .filter(payment => payment !== undefined)
  .map(payment => ({
    title: 'Pagamento',
    topics: [
      { name: 'Data de vencimento', description: payment?.pagamentoDTO?.dataVencimento ? formatUnixTime(payment?.pagamentoDTO?.dataVencimento) : (payment?.dataProximoVencimento ? formatUnixTime(payment?.dataProximoVencimento) : '-') },
      { name: 'Valor transação', description: payment?.valorTransacao ? payment.valorTransacao.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' },
      { name: 'Valor mínimo próxima fatura', description: payment?.valorMinimoProximaFatura ? payment.valorMinimoProximaFatura.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' },
      { name: 'Data do pagamento', description: payment?.pagamentoDTO?.dataPagamento ? formatUnixTime(payment?.pagamentoDTO?.dataPagamento) : '-' },
      { name: 'Valor do pagamento', description: payment?.pagamentoDTO?.valorPagamento ? payment.pagamentoDTO.valorPagamento.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' },
    ]
  }
  ));

  const paymentCreditoRotativo: ListTableMobile[] = payments
  .filter(payment => payment !== undefined)
  .map(payment => (
    {
      title: 'Fechamento Anteriore - Saldo Utilizado',
      topics: [
        { name: 'Data do próximo fechamento', description: payment?.dataProximoFechamento ? formatUnixTime(payment?.dataProximoFechamento) : '-' },
        { name: 'Saldo utilizado', description: payment?.saldoUtilizado ? payment.saldoUtilizado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '-' },
      ]
    }
  ));

  const getPaymentsByModality = () => {

    if(payments && payments.length > 0) {
    const modality = modalityDictionary[details?.modalidade];
    switch (modality) {
      case "Telecomunicações":
      case "Energias":
        return paymentsEnergiaAndTelecomunicacoes;
      case "Consórcio":
      case "Crédito Parcelado":
        return paymentConsorcioAndCreditoParcelado;
      case "Cartão de Crédito":
        return paymentCartaoCredito;
      case "Crédito Rotativo":
        return paymentCreditoRotativo;
    }
  }

  };

  const handleListTable = (listTable: ListTableMobile) => {
    if(modalityDictionary[details?.modalidade] === "Energias" || modalityDictionary[details?.modalidade] === "Telecomunicações"){
    handlePaymentSelection(listTable.originalPayment)
    handleModal(true);
  }
 }


  return (
    <>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <HistoryContainer deviceType={{ type: deviceType }}>

        <div>
          {modalityDictionary[details?.modalidade] == "Cartão de Crédito" ? (
            <CartaoCredito details={details} payments={payments} openParcels={openParcels} expiredParcels={expiredParcels} modalityDictionary={'Cartão de Crédito'} />
          ) :
            modalityDictionary[details?.modalidade] == "Consórcio" ? (
              <Consorcio details={details} payments={payments} openParcels={openParcels} expiredParcels={expiredParcels} modalityDictionary={'Consorcio'} />
            ) :
              modalityDictionary[details?.modalidade] == "Crédito Parcelado" ? (
                <CreditoParcelado details={details} payments={payments} openParcels={openParcels} expiredParcels={expiredParcels} modalityDictionary={'Crédito Parcelado'} />
              ) :
                modalityDictionary[details?.modalidade] == "Crédito Rotativo" ? (
                  <CreditoRotativo details={details} payments={payments} openParcels={openParcels} expiredParcels={expiredParcels} modalityDictionary={'Crédito Rotativo'} />
                ) :
                  modalityDictionary[details?.codigoModalidade] == "A01" ? (
                    <Consignado details={details} payments={payments} openParcels={openParcels} expiredParcels={expiredParcels} modalityDictionary={'Consignado'} />
                  ) :
                    modalityDictionary[details?.modalidade] == "Telecomunicações" ? (
                      <Telecomunicacao details={details} payments={payments} openParcels={openParcels} expiredParcels={expiredParcels} modalityDictionary={'Telecomunicações'} />
                    ) :
                      modalityDictionary[details?.modalidade] == "Energias" ? (
                        <Energia details={details} payments={payments} openParcels={openParcels} expiredParcels={expiredParcels} modalityDictionary={'Energias'} />
                      ) : ("")
          }
        </div>
        <DetailBlock deviceType={{ type: deviceType }}>

          {deviceType === "mobile" || deviceType === "mobile-landscape" ? (
            <ListTableMobile
             details={getPaymentsByModality()}
              onPaymentSelect={handleListTable}
              showPointer={modalityDictionary[details?.modalidade] == "Telecomunicações" || modalityDictionary[details?.modalidade] == "Energias"}
              />
          ) : (
            <><h1>{details?.tipoFonteOrigem === 'INSTITUICAO_FINANCEIRA' ? modalityDictionary[details?.modalidade] == "Crédito Rotativo" ? 'Fechamentos Anteriores - Saldos Utilizados' : 'Pagamentos'
              : 'Faturas'}</h1>

              <PaymentTableContainer deviceType={{ type: deviceType }}>
                <PaymentTable>
                  <thead>
                    <TableRow deviceType={{ type: deviceType }}>
                      {columns.map((column) => (
                        <th>{column}</th>
                      ))}
                    </TableRow>
                  </thead>
                  <tbody>

                    {(modalityDictionary[details?.modalidade] == "Cartão de Crédito") && payments.length > 0 && payments.map((payment) => (
                      <>
                        {payment &&
                          <TableRow deviceType={{ type: deviceType }}>
                            <TableCell bold>
                              {(payment?.pagamentoDTO?.dataVencimento && payment?.pagamentoDTO?.dataVencimento !== 0) ? formatUnixTime(payment?.pagamentoDTO?.dataVencimento) : (payment?.dataProximoVencimento ? formatUnixTime(payment?.dataProximoVencimento) : '-')}
                            </TableCell>
                            <TableCell>
                              {payment?.valorTransacao ?
                                payment?.valorTransacao
                                  .toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })
                                :
                                '-'}
                            </TableCell>
                            <TableCell>
                              {payment?.valorMinimoProximaFatura ?
                                payment?.valorMinimoProximaFatura
                                  .toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })
                                :
                                '-'}
                            </TableCell>
                            <TableCell>
                              {payment?.pagamentoDTO?.dataPagamento ?
                                formatUnixTime(payment?.pagamentoDTO?.dataPagamento)
                                :
                                '-'}
                            </TableCell>
                            <TableCell>
                              {(payment?.pagamentoDTO && payment?.pagamentoDTO?.valorPagamento) ?
                                payment?.pagamentoDTO?.valorPagamento
                                  .toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })
                                :
                                '-'}
                            </TableCell>
                          </TableRow>}
                      </>
                    )
                    )}

                    {(modalityDictionary[details?.modalidade] == "Crédito Rotativo") && payments.length > 0 && payments.map((payment) => (
                      <>
                        {payment &&
                          <TableRow deviceType={{ type: deviceType }}>
                            <TableCell bold>
                              {payment?.dataProximoFechamento ? formatUnixTime(payment?.dataProximoFechamento) : '-'}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell lineText={true}>
                              {(payment?.saldoUtilizado) ?
                                payment?.saldoUtilizado
                                  .toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })
                                :
                                '-'}
                            </TableCell>
                          </TableRow>}
                      </>
                    )
                    )}


                    {(modalityDictionary[details?.modalidade] == "Consórcio" || modalityDictionary[details?.modalidade] == "Crédito Parcelado") &&
                      payments.length > 0 && payments.map((payment) => (
                        <>
                          {payment &&
                            <TableRow deviceType={{ type: deviceType }}>
                              <TableCell>
                                {payment?.pagamentoDTO?.tipoPagamento ? payment?.pagamentoDTO?.tipoPagamento : '-'}
                              </TableCell>
                              <TableCell bold>
                                {(payment?.pagamentoDTO?.dataVencimento && payment?.pagamentoDTO?.dataVencimento !== 0) ? formatUnixTime(payment?.pagamentoDTO?.dataVencimento) : (payment?.dataProximoVencimento ? formatUnixTime(payment?.dataProximoVencimento) : '-')}
                              </TableCell>
                              <TableCell>
                                {payment?.valorTransacao ?
                                  payment?.valorTransacao
                                    .toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    })
                                  :
                                  '-'}
                              </TableCell>
                              <TableCell>
                                {payment?.pagamentoDTO?.dataPagamento ?
                                  formatUnixTime(payment?.pagamentoDTO?.dataPagamento)
                                  :
                                  '-'}
                              </TableCell>
                              <TableCell>
                                {(payment?.pagamentoDTO && payment?.pagamentoDTO?.valorPagamento) ?
                                  payment?.pagamentoDTO?.valorPagamento
                                    .toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    })
                                  :
                                  '-'}
                              </TableCell>
                            </TableRow>}
                        </>
                      )
                      )}

                    {(modalityDictionary[details?.modalidade] == "Telecomunicações" || modalityDictionary[details?.modalidade] == "Energias")
                      && payments.length > 0 && payments.map((payment) => (
                        <>
                          {payment &&
                            <TableRow deviceType={{ type: deviceType }} style={{ cursor: "pointer" }} onClick={() => { handlePaymentSelection(payment); handleModal(true); }}>
                              <TableCell lineText={true}>
                                {payment?.fatura ? payment?.fatura : '-'}
                              </TableCell>
                              <TableCell bold>
                                {(payment?.pagamentoDTO?.dataVencimento && payment?.pagamentoDTO?.dataVencimento !== 0) ? formatUnixTime(payment?.pagamentoDTO?.dataVencimento) : '-'}
                              </TableCell>
                              <TableCell>
                                {(payment?.pagamentoDTO && payment?.pagamentoDTO?.valorPagamento) ?
                                  payment?.pagamentoDTO?.valorPagamento
                                    .toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    })
                                  :
                                  '-'}
                              </TableCell>
                              <TableCell>
                                {(payment?.valorTransacao && payment?.pagamentoDTO?.dataPagamento) ?
                                  formatUnixTime(payment?.pagamentoDTO?.dataPagamento)
                                  :
                                  '-'}
                              </TableCell>
                              <TableCell>
                                {payment?.valorTransacao ?
                                  payment?.valorTransacao
                                    .toLocaleString('pt-BR', {
                                      style: 'currency',
                                      currency: 'BRL',
                                    })
                                  :
                                  '-'}
                              </TableCell>
                              <TableCell color={getStatusColor(handleStatus(payment?.pagamentoDTO?.statusFatura ?? ''))} bold>
                                {handleStatus(payment?.pagamentoDTO?.statusFatura ?? '')}
                              </TableCell>
                            </TableRow>}
                        </>
                      )
                      )}

                  </tbody>
                </PaymentTable>
              </PaymentTableContainer></>
          )}

          <Modal
            title="Fatura"
            isOpen={isModalOpen}
            reverseFooterButtons
            alignModalFooter='flex-start'
            onToggleModal={() => handleModal(false)}
          >
            {selectedPayment && (
              <>
                <p>
                  <b>Nº do contrato: </b><br />
                  {details?.numeroContrato}
                </p>
                <p>
                  <b>CNPJ: </b><br />
                  {documentFormatter(JSON.stringify(details?.cnpjFonteOrigem))}
                </p>
                <p>
                  <b>Nº da Fatura: </b><br />
                  {selectedPayment?.fatura}
                </p>
                <p>
                  <b>Data de Vencimento: </b><br />
                  {formatUnixTime(selectedPayment?.pagamentoDTO?.dataVencimento)}
                </p>
                <p>
                  <b>Valor da fatura: </b><br />
                  {selectedPayment?.valorTransacao?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </p>
                <p>
                  <b>Data do Pagamento: </b><br />
                  {formatUnixTime(selectedPayment?.pagamentoDTO?.dataPagamento)}
                </p>
                <p>
                  <b>Valor pago: </b><br />
                  {(selectedPayment?.pagamentoDTO && selectedPayment?.pagamentoDTO?.valorPagamento) ?
                    selectedPayment?.pagamentoDTO?.valorPagamento
                      .toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })
                    :
                    '-'
                  }
                </p>
                <p>
                  <b>Status: </b><br />
                  {handleStatus(selectedPayment?.pagamentoDTO.statusFatura)}
                </p>
                <p>
                  <b>Fatura contestada: </b><br />
                  {selectedPayment?.pagamentoDTO.movimentoContestado}
                </p>
                {/*<ConfirmationBox*/}
                {/*  message="Os dados da fatura estão corretos?"*/}
                {/*  onDeny={handleContest}*/}
                {/*/>*/}
              </>
            )}
          </Modal>

          {/*{details?.id &&*/}
          {/*  <ConfirmationBox*/}
          {/*    message="Os dados da fatura estão corretos?"*/}
          {/*    onDeny={handleContest}*/}
          {/*  />*/}
          {/*}*/}
        </DetailBlock>
      </HistoryContainer>
    </>
  );
};
