import { Button, FormField, Input, Modal, Radio } from 'components';
import { FilterIcon } from 'components/Icon';
import { useEffect, useState } from 'react';
import { DateRange, FieldGroupContainer, StyledFormFilter } from './styles';
import { Text } from 'components';
import useDeviceType from 'hooks/useDeviceType';


interface FormFilterProps {
  sendFilters: (filters: Filters) => void
}

export interface Filters {
  documento: string
  tipoData: string
  listaStatus: string
  dataInicial: string
  dataFinal: string
  maxResult: number
}

export const factoryFilters: Filters = {
  documento: '',
  tipoData: '',
  listaStatus: '',
  dataInicial: '',
  dataFinal: '',
  maxResult: 10,
};

export const FormFilter = ({ sendFilters }: FormFilterProps) => {
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(factoryFilters);
  const [statusBotao, setStatusBotao] = useState(false);

  const handleFillForm = (fieldName: string, fieldValue: string) => {
    setFilters({ ...filters, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (filters.dataInicial && filters.dataFinal) {
      setStatusBotao(false);
    } else if (filters.listaStatus || filters.tipoData) {
      setStatusBotao(true);
    } else if (!filters.dataInicial && !filters.dataFinal && !filters.listaStatus && !filters.tipoData) {
      setStatusBotao(false);
    }
  }, [filters]);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    setFilters(factoryFilters);
  };

  const { deviceType } = useDeviceType();

  return (
    <StyledFormFilter>
      <Button btnStyle='action' onClick={() => setShowFilter(true)}>
        <FilterIcon />
        Filtrar
      </Button>
      <Modal
        alignModalFooter='flex-start'
        isOpen={showFilter}
        onToggleModal={() => handleShowFilter()}
        title="Filtros"
        reverseFooterButtons
        primaryActionButton={{
          label: 'Aplicar',
          action: () => sendFilters(filters),
          closeOnclick: true,
          disabled: statusBotao,
        }}
        secondaryActionButton={{
          label: 'Limpar todos',
          action: () => setFilters(factoryFilters),
        }}
      >
        <FieldGroupContainer>
          <FormField label='Filtrar por data:'>
            <DateRange deviceType={{ type: deviceType }}>
              {(filters.listaStatus || filters.tipoData) && (
                <span style={{ position: 'absolute', marginLeft: "6rem", color: 'red' }}>*</span>
              )}
              <Input
                type="date"
                inputText={filters.dataInicial}
                onChange={(e: any) => handleFillForm('dataInicial', e)}
                id='dataInicialPermissao'
                label='Data inicial:'
              />
              {(filters.listaStatus || filters.tipoData) && (
                <span style={{ position: 'absolute', marginLeft: "17.5rem", color: 'red' }}>*</span>
              )}
              <Input
                type="date"
                inputText={filters.dataFinal}
                onChange={(e: any) => handleFillForm('dataFinal', e)}
                id='dataFinalPermissao'
                label='Data final:'
              />
            </DateRange>
          </FormField>
        </FieldGroupContainer>
        <FieldGroupContainer>
          <Radio
            label='Filtrar por status:'
            id='listaStatus'
            name='listaStatus'
            value={filters.listaStatus}
            onCheck={(e: any) => handleFillForm('listaStatus', e)}
            options={[
              { label: 'Ativa', value: 'A' },
              { label: 'Expirada', value: 'E' },
              { label: 'Excluída', value: 'X' },
            ]}
          />
        </FieldGroupContainer>
        <FieldGroupContainer>
          <Radio
            label='Filtrar por tipo de contestação:'
            id='tipoData'
            name='tipoData'
            value={filters.tipoData}
            onCheck={(e: any) => handleFillForm('tipoData', e)}
            options={[
              { label: 'Determinado', value: 'D' },
              { label: 'Indeterminado', value: 'I' },
            ]}
          />
        </FieldGroupContainer>
        {(filters.listaStatus || filters.tipoData) && (
          <Text size={1} lineHeight={2}>
            Campos obrigatórios ({<span style={{ color: 'red' }}>*</span>})
          </Text>
        )}
      </Modal>
    </StyledFormFilter>
  );
};
