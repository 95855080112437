import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledScoreChangeMotives = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  `;

export const MotiveTags = styled.div<{deviceType: DeviceTypeProps }>`
  display: flex;
  position: relative;
  justify-content: center;
  ${({ theme }) => `
    background: ${theme.color.white['FEFEFE']};
  `}
`;

export const MotiveTag = styled.div<{ selected: boolean, deviceType: DeviceTypeProps }>`
  display: flex;
  margin-top: 8px;
  margin-left: 14px;
  justify-content: center;
  align-items: center;
  font-weight: 400;

  cursor: pointer;
  z-index: 1;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `width: 130px;`;
  }}

  ${({ theme, selected }) => `
    color: ${selected ? theme.color.blue['154695'] : theme.color.neutral['8094BC']};
  `}
`;

export const SelectedTag = styled.div<{ tag: number, deviceType?: DeviceTypeProps }>`
  position: absolute;
  width: 215px;
  height: 40px;
  border-radius: 3px 4px 4px 3px;
  transition: all 300ms ease-in-out;
  z-index: 0;

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
    return `
      height: 60px;
      width: 120px;
      `;
    else if (deviceType?.type === 'desktop')
    return `
      width: 230px;
      `;
  }}

  ${({ theme, tag, deviceType }) => `

    background: ${theme.color.white['F3F7FF']};
    ${deviceType?.type === 'mobile'
      ? 'left ' + (tag === 0 ? 'calc(50% - 150px)' : 'calc(50% - 5px)') + ';'
      : deviceType?.type === 'desktop'
      ? `left: ${tag === 0 ? 'calc(50% - 220px)' : 'calc(50% + 8px)'};`
      : 'left: ' + (tag === 0 ? 'calc(50% - 205px)' : 'calc(50% + 7px)') + ';'
    }

  `}

`;

export const Motive = styled.div<{ deviceType?: DeviceTypeProps }>`
  width: 100%;
  font-weight: 400;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
  line-height: 30px;
  margin-top: 18px;
  ${({ theme }) => `
  color: ${theme.color.darkGray['797979']};
  `}
  `;

  export const MotiveDescription = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 500;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};

 ${({ deviceType }) => {
   if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape' )
   return `
   line-height: 18px;
    `;
}}

`;
