import styled from 'styled-components';

export const StyledRadio = styled.div<{ horizontal?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
`;

export const OptionLabel = styled.label`
  cursor: pointer;
  display: flex;
  position: relative;

  p {
    margin-left: 8px;
    margin-top: 6px;
  }

  input {
    opacity: 0;
    width: 10px;
    // height: 10px;
  }
`;

export const Option = styled.span`
  font-size: 0;
  left: 0;
  ${({ theme }) => `border: 1px solid ${theme.color.darkGray['494E58']};`}
  border-radius: 100%;
  position: relative;
  margin-top: 6px;
  width: 21px;
  height: 21px;
  
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
  }

  & {
    :before {
      content: ' ';
      display: block;
      position: absolute;
    }

    input:checked + & {
      &:before {
        content: ' ';
        width: 14px;
        height: 14px;
        top: calc(50% - 7px);
        left: calc(50% - 7px);
        border-radius: 100%;
        ${({ theme }) => `background: ${theme.color.blue['03428D']};`}
      }
    }
  }

`;
