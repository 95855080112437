import styled, { css } from 'styled-components';
import FilteredPropsInputField from './SignupPf/FilteredPropsInputField';
import { DeviceTypeProps } from 'types/Responsive.types';
import { calculateFontSize } from 'components/Text/styles';

export const Content = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  min-height: 86.5vh;

  ${({ deviceType }) => {
    if (deviceType?.type == 'laptop')
      return `
        margin-right: 0;
      `;

    if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape')
      return `
        flex-direction: column-reverse;
        margin: 10px;
        overflow-y: auto;
      `;
  }}


`;

export const Label = styled.label`
  margin-top: 1rem;
  width: 100%;
  display:flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
`;

export const DocumentLabel = styled.div`
  margin: 12px 0px 10px 0px;
`;

export const SubmitContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;
  margin: 1rem 0px;
  `;

export const InputContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
    width: 18vw;
    ${({ deviceType }) => {
    if (deviceType?.type == 'mobile' || deviceType?.type == 'mobile-landscape')
      return `
          width: 100%;
          `;
          else if (deviceType?.type == 'laptop')
          return `
        width: 85%;
        `;
        else if (deviceType?.type == 'tablet')
        return `
        width: 100%;
        `;
        else if (deviceType?.type == 'desktop')
        return `
        width: 65%;
        `;
  }}

    `;

export const Input = styled(FilteredPropsInputField)`
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 400;
  width: 90%;
  padding: 0.75rem 0.75rem;

  &:focus,
  &:active {
    border: 1.333px solid black;
    outline: none;
  }

  /* Autocomplete styles in Chrome*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;

  box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  :autofill {
    background: #fff; /* or any other */
  }

  ${({ valid }) =>
    valid &&
    css`
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px,
          rgb(177, 247, 160) 0px 0px 0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
      
      &:-webkit-autofill:focus {
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px, rgb(177, 247, 160) 0px 0px 0px 3px;
        border: 1px solid rgb(0, 156, 38);
      }

    `}

  ${({ error }) =>
    error &&
    css`
      border: 1px solid rgb(191, 49, 12);
      outline: none;

      &:focus,
      &:active {
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
     
   
    `}

  ${({ deviceType }) => {
    if (deviceType?.type == 'tablet' || deviceType?.type == 'mobile')
      return `
        min-width: 210px;
      `;
  }}
`;

export const StyledInlineErrorMessage = styled.div`
  background-color: rgb(255, 245, 245);
  color: rgb(120, 27, 0);
  display: block;
  width: 90%;
  padding: 0.5rem 0.75rem;
  margin-top: 0.5rem;
  white-space: pre-line;
`;

export const Submit = styled.button<{ deviceType?: DeviceTypeProps }>`

  height: 64px;
  min-width: 140px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 50px;
  margin-right: 1.5rem;
  background-color: #1f3c75;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 700;
  white-space: nowrap;
  color: rgb(232, 243, 255) !important;

  &:active,
  &:focus,
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: pointer;
    background-color: rgb(163, 168, 173);
    box-shadow: none;
    color: rgb(255, 255, 255) !important;

    &:hover,
    &:focus {
      cursor: not-allowed;
    }
  }
`;

export const Link = styled.a`
  cursor: pointer;
  color: #000000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const InputWrapper = styled.div`

  display: flex;
  margin-top:0px !important;
  align-items: center;
  width: 100% !important;

  `;

export const InputButton = styled.button<{ valid: boolean; error: boolean, isPassword?: boolean, deviceType?: DeviceTypeProps }>`
  background-color: #fff;

  border: 1.333px solid lightgray;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: none;
  cursor: pointer;

  display: flex;
  align-items: center;  

  padding: 0px 15px;
  width: 50px;
  height: 50px;

  ${(props) =>
    props.valid &&
    css`
      border-color: #009c26;
    `}

  ${(props) =>
    props.error &&
    css`
      border-color: #bf310c;
    `}

`;

export const StyledInputPassword = styled(Input)`
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  display: flex;
  align-items: center;  
  ::placeholder {
    font-size: 14px;
  }
  &:focus {
    border-right: none;

    & + button {
      ${(props) => props.valid && css`
          box-shadow: #6aed61 0 0 2px 1px, #b1f7a0 0 0 0 3px;
        `}

      ${(props) => props.error && css`
      border-color: #bf310c;
        `}

      ${(props) =>!props.valid && !props.error && css`
          border-color: #1a212b;
          // box-shadow: #d2d5d9 0 0 2px 1px, #e3e6e8 0 0 0 3px;
        `}

      clip-path: inset(-10px -10px -10px 0);
    }
  }
`;

export const WelcomeContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  flex: 1;
  padding-bottom: 6rem;
  width: 45vw;
  margin: auto;
  padding-top: 20px;
  h4{
    line-height: 32px;
  }

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape')
      return `
      padding-top: 2.5rem;
      width: 90vw;
      `;
    else if (deviceType?.type === 'desktop')
      return `
      margin-left: 4.5rem;
      `;
    else if (deviceType?.type === 'laptop')
      return `
      margin-left: 1.5rem;
      `;

  }}


`;

export const ImageContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  width: 48vw;
  display:flex;
  justify-content: center;
  margin: auto;
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape' )
      return `
      width: 90vw;
      `;
    else if (deviceType?.type === 'desktop')
      return `
      width: 43vw;
      `;
    else if (deviceType?.type === 'tablet')
      return `
      width: 48vw;
      `;
      else{
        return `
        width: 55vw;
        `;
      }

  }}

  img{
    width: 90%;

    ${({ deviceType }) => {
    if (deviceType?.type === 'tablet')
      return `
        width: 80%;
        `;
      
  }}
    height: auto;  
  }
`;
