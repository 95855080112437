import {
  AvatarDropdown,
  Button,
  Container,
  Responsive,
  Text,
} from 'components';
import { useContext, useState } from 'react';
import { HeaderProps } from 'types';
import { MenuHeader } from './partials/MenuHeader';

import {
  AccountContainer,
  DropdownMobile,
  GridHeader,
  LogoContainer,
  StyledHeader,
  StyledMenu,
  UserName,
} from './styles';
import { AuthContext } from 'contexts';
import { documentFormatter } from 'utils/documentFormatter';
import useDeviceType from 'hooks/useDeviceType';

import { useEffect, useRef } from 'react';

const Header = ({ loggedIn, username, documentRef, menu }: HeaderProps) => {
  const { isAuthenticated } = useContext(AuthContext);

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const { deviceType } = useDeviceType();
  const menuRef: any = useRef(null);
  const toggleButtonRef: any = useRef(null);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        (menuRef.current && !menuRef.current.contains(event.target)) &&
        event.target !== toggleButtonRef.current
      ) {
        setSidebarVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, toggleButtonRef]);


  return (
    <>
      <Responsive
        deviceType={{
          type: deviceType,
        }}
      >
        <StyledHeader loggedIn={loggedIn} >
          <Container fluid>
            <GridHeader>
              <LogoContainer deviceType={{ type: deviceType }}>
                <a href={isAuthenticated === true ? '/home/score' : '/'}>
                  <img
                    src={require('../../assets/images/logo_spcbrasil.png')}
                    alt="Logo"
                  />
                </a>
              </LogoContainer>
              {loggedIn && (
                <>
                  <div />
                  <AccountContainer deviceType={{ type: deviceType }}>
                    {deviceType !== "mobile" && (
                      <AvatarDropdown
                        title={username ?? 'Convidado'}
                        subtitle={documentFormatter(documentRef) ?? ' '}
                        menu={[]}
                      />
                    )}
                    <div ref={toggleButtonRef} className='menu-'>
                      <Button btnStyle="action" onClick={toggleSidebar} >
                        ☰
                      </Button>
                    </div>
                  </AccountContainer>
                </>
              )}
            </GridHeader>
            {deviceType === "mobile" && loggedIn && (
              <DropdownMobile>
                <AvatarDropdown
                  title={username ?? 'Convidado'}
                  subtitle={documentFormatter(documentRef) ?? ' '}
                  menu={[]}
                />
              </DropdownMobile>
            )}
          </Container>
        </StyledHeader>

        {loggedIn && (sidebarVisible || deviceType !== 'mobile') && (
          <StyledMenu deviceType={{ type: deviceType }} ref={menuRef}>
            <Container fluid>
              <MenuHeader menu={menu} device={deviceType} onSelectMenu={toggleSidebar} />
            </Container>
          </StyledMenu>
        )}
      </Responsive>
    </>
  );
};


export { Header };