import type { SiginatureProps } from 'contexts/types';
import { api } from './api';

export const Signature = {
  cancel: async (params: SiginatureProps) => await api()
      .post('/cadastropositivo-autorizacao/consumidor/cancelar-adesao/',
           params ),
  reopen: async (params: SiginatureProps) => await api()
      .post('/cadastropositivo-autorizacao/consumidor/reabrir-adesao/',
           params ),
  situacao: async (tipoPessoa: string, documento: string) => await api()
      .get(`/cadastropositivo-autorizacao/consumidor/situacao-consulta-consumidor/${tipoPessoa}/${documento}`),

           
};
