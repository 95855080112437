export const authFactory = {
  access_token: '',
  cpf: '',
  rg: '',
  cnpj: '',
  dataNascimento: '',
  dataUltimaAutenticacao: '',
  email: '',
  expires_in: 0,
  id: 0,
  nome: '',
  qrCodToken: false,
  refresh_token: '',
  scope: '',
  statusSenha: '',
  tipoConsumidor: '',
  token_type: '',
  username: '',
};
