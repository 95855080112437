import styled from 'styled-components';
import { FieldStatusType } from 'types';
export const StyledTextArea = styled.textarea<{
  status: FieldStatusType
}>`
  ${({ theme, status }) => `
    width: 95% !important;
    border-radius: 4px;
    border: 1px solid ${theme.font.color[status]};
    color: ${theme.font.color[status]};
    font-family: 'Montserrat';
    font-size: ${theme.font.size[1]}px;
    padding: 12px 6px;
    min-height: 180px;
    position: relative;
    ::placeholder {
      color: ${theme.font.color[status]};
      opacity: .65;
      font-size: 16px;
    }
  `}
`;
