import React, { useContext, useEffect, useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";

import {
  CodeWrapper,
  ContainerInputPassword,
  FormContainer,
  Input,
  Label,
  LabelContainer,
  PageWrapper,
  StyledInlineErrorMessage,
  Submit,
  Title
} from "./styles";
import { Container } from 'components/Container';
import { CheckContainer, ModalTerm } from 'pages/Cadastro/Cancelamento/styles';
import { Modal } from 'components/Modal';
import { LoadingSpinner } from 'components';
import { AuthContext } from 'contexts';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import SignupTerm from 'pages/Login/SignupPf/SignupTerm';
import { documentFormatter } from 'utils/documentFormatter';
import { InputPassword } from "../InputPassword/InputPassword";
import useDeviceType from 'hooks/useDeviceType';
import { CSSProperties } from "styled-components";
import { Content, ImageContainer } from "../styles";

const validationSchema = Yup.object().shape({
  primeiroNomeRepresentante: Yup.string()
    .required("Digite o primeiro nome do representante.")
    .matches(/^[\p{L} \-'’]+$/u, 'Apenas letras são permitidas'),
  ultimoNomeRepresentante: Yup.string()
    .required("Digite o último nome do representante.")
    .matches(/^[\p{L} \-'’]+$/u, 'Apenas letras são permitidas'),
  cpfRepresentante: Yup.string()
    .required("Digite o CPF do representante.")
    .matches(/^[0-9]+$/, "Somente números são permitidos")
    .min(11, "O CPF deve conter 11 dígitos"),
  rgRepresentante: Yup.string()
    .required("Digite o RG do representante.")
    .matches(/^[0-9]+$/, "Somente números são permitidos")
    .min(7, "O RG deve conter no mínimo 7 dígitos"),
  nomeFantasia: Yup.string()
    .required("Digite o nome fantasia da empresa."),
  nomeEmpresa: Yup.string()
    .required("Digite a razão social da empresa."),
  dataFundacao: Yup.string()
    .required('Digite a data de fundação da empresa.')
    .matches(
      /^(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/,
      'Insira a data no formato dd/mm/aaaa'
    ),
  telefone: Yup.string()
    .required('Digite o número do telefone.')
    .max(8, "O telefone deverá ter entre 8 e 9")
    .max(9, "O telefone deverá ter entre 8 e 9")
    .matches(/^[0-9]+$/, "Somente números são permitidos"),
  dddTelefone: Yup.string()
    .required('Digite o DDD do número do telefone.')
    .min(3, "O ddd tem que ter 3 caracteres")
    .matches(/^[0-9]+$/, "Somente números são permitidos"),
  email: Yup.string()
    .email("O e-mail da empresa está incorreto")
    .required("Digite o e-mail da empresa"),
  confirmEmail: Yup.string()
    .email('O e-mail está incorreto')
    .oneOf([Yup.ref('email'), null], 'Email precisa ser igual')
    .required('Digite o e-mail da empresa novamente'),
  password: Yup.string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
    .matches(/\d/, 'A senha deve conter pelo menos um número')
    .matches(/[@$!%*?&#()]/, 'A senha deve conter pelo menos um caractere especial')
    .required('Digite uma senha'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
    .required('Digite a senha novamente'),
  term: Yup.bool()
    .oneOf([true], 'Você deve aceitar o Termo de adesão.')
    .required('Você deve aceitar o Termo de adesão.')
});

const SignupPj = () => {
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [signupError, setSignupError] = useState(false);
  const { userData, registerUserPj } = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const userDocument: any = state.document

  const handleModal = (modal: number) => {
    setShowModal(modal);
  };

  const formattedDocument = documentFormatter(userDocument)
  const unformattedDocument = state.document;

  const handleBirthKeyPress = (e: any) => {
    const keyCode = e.charCode;
    const value = e.target.value;

    if (keyCode >= 48 && keyCode <= 57) {
      return;
    }

    if (keyCode === 47 && (value.length === 2 || value.length === 5)) {
      return;
    }

    e.preventDefault();
  }

  const handleBirthInput = (e: any) => {
    const regex = /^(\d{0,2})([/]?)(\d{0,2})([/]?)(\d{0,4})$/;
    const matched = e.target.value.match(regex);

    if (matched) {
      const [, day, daySep, month, monthSep, year] = matched;
      e.target.value = `${day}${daySep}${month}${monthSep}${year}`;
    } else {
      e.target.value = '';
    }
  }

  const { deviceType } = useDeviceType();

  const displayInput: CSSProperties = {
    display: "flex",
    alignItems: deviceType === "mobile" || deviceType === "mobile-landscape" || deviceType === "tablet" ? "" : "center",
    flexDirection: deviceType === "mobile" || deviceType === "mobile-landscape" || deviceType === "tablet" ? "column" : "row",
    width: "100%",
    marginBottom: "6px",
  };

  const rowInput: CSSProperties = {
    flex: 1,
    marginRight: deviceType === "mobile" || deviceType === "mobile-landscape" || deviceType === "tablet" ? "0px" : "1rem",
  };

  const rowSecondInput: CSSProperties = {
    flex: 1,
    marginRight: deviceType === "mobile" || deviceType === "mobile-landscape" || deviceType === "tablet" ? "0px" : "auto",
  };

  return (<>
    <div>
      <LoadingSpinner state={isLoading} />
    </div>
    <Container>
      <Content deviceType={{ type: deviceType }}>
        <div style={{ margin: "10px" }} >
          <Title>
            <AiOutlineArrowLeft style={{ marginRight: "1rem" }} onClick={() => {
              navigate('/');
            }} />
            Realize seu cadastro
          </Title>
          <p>
            Verificamos que você ainda não possui um cadastro no Portal Consumidor.
            Para continuar, insira as informações abaixo e realize seu cadastro.
          </p>
          <Formik
            initialValues={{
              document: formattedDocument,
              nomeEmpresa: "",
              nomeFantasia: "",
              dataFundacao: "",
              primeiroNomeRepresentante: "",
              ultimoNomeRepresentante: "",
              telefone: "",
              dddTelefone: "",
              cpfRepresentante: "",
              rgRepresentante: "",
              email: "",
              confirmEmail: "",
              password: "",
              confirmPassword: "",
              term: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const { nomeEmpresa, nomeFantasia, primeiroNomeRepresentante, ultimoNomeRepresentante, telefone, dddTelefone, cpfRepresentante, rgRepresentante, dataFundacao, email, password } = values
              setLoading(true);
              registerUserPj({
                document: unformattedDocument,
                nomeEmpresa: nomeEmpresa,
                nomeFantasia: nomeFantasia,
                dataFundacao: dataFundacao,
                dddTelefone: dddTelefone,
                telefone: telefone,
                cpfRepresentante: cpfRepresentante,
                rgRepresentante: rgRepresentante,
                primeiroNomeRepresentante: primeiroNomeRepresentante,
                ultimoNomeRepresentante: ultimoNomeRepresentante,
                email: email,
                password: password
              })
                .then((res: any) => {
                  if (res.status === "SUCESSO") {
                    navigate("/entrar", { state: { document: unformattedDocument, password: values.password, signupLastStep: true }})
                    setLoading(false);
                  } else if (res.status === "DIVERGENTE") {
                    setLoading(false);
                    setSignupError(true);
                  }
                }).catch((e: any) => {
                  setLoading(false);
                  actions.setFieldError("", e.response.data.message);
                  actions.setSubmitting(false);
                  setSignupError(true);
                })
              actions.setSubmitting(true)
              const timeOut = setTimeout(() => {
                actions.setSubmitting(false);
                clearTimeout(timeOut);
              }, 1000);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              handleChange,
              handleBlur,
              isValid,
              setFieldValue
            }) => {
              return (
                <>
                  <Form name="contact" method="post" onSubmit={handleSubmit}>
                    <FormContainer deviceType={{ type: deviceType }}>
                    <LabelContainer>                    
                    <Label htmlFor="document">
                      CNPJ:
                      <Input
                        type="text"
                        name="document"
                        autoCorrect="off"
                        autoComplete="name"
                        disabled
                        valid={touched.document && !errors.document}
                        error={touched.document && errors.document}
                        style={{ boxSizing: "border-box" }}
                      />
                    </Label>
                    </LabelContainer>

                    <LabelContainer>                    
                    <Label htmlFor="nomeEmpresa">
                      Razão social da empresa:
                      <Input
                        type="text"
                        name="nomeEmpresa"
                        autoCapitalize="off"
                        autoComplete="name"
                        valid={touched.nomeEmpresa && !errors.nomeEmpresa}
                        error={touched.nomeEmpresa && errors.nomeEmpresa}
                        style={{ boxSizing: "border-box" }}
                      />

                    </Label>
                    </LabelContainer>                    
                    {errors.nomeEmpresa && touched.nomeEmpresa && (
                      <StyledInlineErrorMessage>
                        {errors.nomeEmpresa}
                      </StyledInlineErrorMessage>
                    )}

                    <LabelContainer>                    
                    <Label htmlFor="nomeFantasia">
                      Nome fantasia da empresa:
                      <Input
                        type="text"
                        name="nomeFantasia"
                        autoCorrect="off"
                        autoComplete="name"
                        valid={touched.nomeFantasia && !errors.nomeFantasia}
                        error={touched.nomeFantasia && errors.nomeFantasia}
                        style={{ boxSizing: "border-box" }}
                      />
                    </Label>
                    </LabelContainer>                    
                    {errors.nomeFantasia && touched.nomeFantasia && (
                      <StyledInlineErrorMessage>
                        {errors.nomeFantasia}
                      </StyledInlineErrorMessage>
                    )}
              
                  <LabelContainer>                    
                    <Label htmlFor="dataFundacao">
                      Data de fundação:
                      <Input
                        type="text"
                        name="dataFundacao"
                        autoCorrect="off"
                        placeholder="Insira no formato dd/mm/aaaa"
                        valid={touched.dataFundacao && !errors.dataFundacao}
                        error={touched.dataFundacao && errors.dataFundacao}
                        maxLength={10}
                        onKeyPress={handleBirthKeyPress}
                        onInput={handleBirthInput}
                        style={{ boxSizing: "border-box" }}
                      />
                    </Label>
                    </LabelContainer>                                          
                    {errors.dataFundacao && touched.dataFundacao && (
                      <StyledInlineErrorMessage>
                        {errors.dataFundacao}
                      </StyledInlineErrorMessage>
                    )}
                    <div style={displayInput}>
                      <div style={rowInput}>
                      <LabelContainer>                    
                    
                        <Label htmlFor="primeiroNomeRepresentante">
                          Primeiro nome do representante:
                          <Input
                            type="text"
                            name="primeiroNomeRepresentante"
                            autoCorrect="off"
                            autoComplete="name"
                            placeholder="Insira o primeiro nome do representante"
                            valid={touched.primeiroNomeRepresentante && !errors.primeiroNomeRepresentante}
                            error={touched.primeiroNomeRepresentante && errors.primeiroNomeRepresentante}
                            style={{ boxSizing: "border-box" }}
                          />
                        </Label>
                      </LabelContainer>
                        {errors.primeiroNomeRepresentante && touched.primeiroNomeRepresentante && (
                          <StyledInlineErrorMessage>
                            {errors.primeiroNomeRepresentante}
                          </StyledInlineErrorMessage>
                        )}
                      </div>
                      <div style={rowSecondInput}>
                    <LabelContainer>                    
                        <Label htmlFor="ultimoNomeRepresentante">
                           Último nome do representante:
                          <Input
                            type="text"
                            name="ultimoNomeRepresentante"
                            autoCorrect="off"
                            autoComplete="name"
                            placeholder="Insira o último nome do representante"
                            valid={touched.ultimoNomeRepresentante && !errors.ultimoNomeRepresentante}
                            error={touched.ultimoNomeRepresentante && errors.ultimoNomeRepresentante}
                            style={{ boxSizing: "border-box" }}
                          />
                        </Label>
                    </LabelContainer>
                        {errors.ultimoNomeRepresentante && touched.ultimoNomeRepresentante && (
                          <StyledInlineErrorMessage>
                            {errors.ultimoNomeRepresentante}
                          </StyledInlineErrorMessage>
                        )}
                      </div>
                    </div>
                    <LabelContainer>                    
                    
                    <Label htmlFor="cpfRepresentante">
                      CPF do representante legal:
                      <Input
                        type="text"
                        name="cpfRepresentante"
                        autoCorrect="off"
                        autoComplete="name"
                        placeholder="Insira o seu CPF"
                        valid={touched.cpfRepresentante && !errors.cpfRepresentante}
                        error={touched.cpfRepresentante && errors.cpfRepresentante}
                        style={{ boxSizing: "border-box" }}
                      />
                    </Label>
                    </LabelContainer>
                    {errors.cpfRepresentante && touched.cpfRepresentante && (
                      <StyledInlineErrorMessage>
                        {errors.cpfRepresentante}
                      </StyledInlineErrorMessage>
                    )}
                    
                    <LabelContainer>                    
                    <Label htmlFor="rgRepresentante">
                      RG do representante legal:
                      <Input
                        type="text"
                        name="rgRepresentante"
                        autoCorrect="off"
                        autoComplete="name"
                        placeholder="Insira o seu RG"
                        valid={touched.rgRepresentante && !errors.rgRepresentante}
                        error={touched.rgRepresentante && errors.rgRepresentante}
                        style={{ boxSizing: "border-box" }}
                      />
                    </Label>
                    </LabelContainer>
                    {errors.rgRepresentante && touched.rgRepresentante && (
                      <StyledInlineErrorMessage>
                        {errors.rgRepresentante}
                      </StyledInlineErrorMessage>
                    )}
                    <div
                          style={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            width: '100%',
                          }}
                        >
                          <div style={{ flex: 1 }}>
                    <LabelContainer>                    
                            <Label htmlFor="dddTelefone">DDD + Telefone:</Label>
                     </LabelContainer>
                          </div>
                          <div
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                            }}
                          >
                            <Input
                              type="text"
                              name="dddTelefone"
                              autoCorrect="off"
                              autoComplete="name"
                              placeholder="000"
                              maxLength="3"
                              valid={touched.dddTelefone && !errors.dddTelefone}
                              error={touched.dddTelefone && errors.dddTelefone}
                              style={{
                                boxSizing: 'border-box',
                                width: 60,
                              }}
                            />

                            <Input
                              type="text"
                              name="telefone"
                              autoCorrect="off"
                              autoComplete="name"
                              maxLength="9"
                              placeholder="00000000"
                              valid={touched.telefone && !errors.telefone}
                              error={touched.telefone && errors.telefone}
                              style={{
                                marginLeft: '2rem',
                                boxSizing: 'border-box',
                                width: 'calc(100% - 60px - 2rem)',
                              }}
                            />
                            {errors.dddTelefone && touched.dddTelefone && (
                              <StyledInlineErrorMessage>
                                {errors.dddTelefone}
                              </StyledInlineErrorMessage>
                            )}
                            {errors.telefone && touched.telefone && (
                              <StyledInlineErrorMessage>
                                {errors.telefone}
                              </StyledInlineErrorMessage>
                            )}
                          </div>
                        </div>
                        <LabelContainer>                    

                    <Label htmlFor="email">
                      Endereço de e-mail da Empresa:
                      <Input
                        type="email"
                        name="email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        placeholder="Insira o endereço de e-mail da empresa"
                        valid={touched.email && !errors.email}
                        error={touched.email && errors.email}
                        style={{ boxSizing: "border-box" }}
                      />
                    </Label>
                    </LabelContainer>
                    <ErrorMessage name="email">
                      {msg => (
                        <StyledInlineErrorMessage>{msg}</StyledInlineErrorMessage>
                      )}
                    </ErrorMessage>
                    
                    <LabelContainer>                    
                    <Label htmlFor="confirmEmail">
                      Confirmação de e-mail da Empresa:
                      <Input
                        type="email"
                        name="confirmEmail"
                        placeholder="Digite novamente o endereço indicado acima"
                        valid={touched.confirmEmail && !errors.confirmEmail}
                        error={touched.confirmEmail && errors.confirmEmail}
                        style={{ boxSizing: "border-box" }}
                      />
                    </Label>
                    </LabelContainer>
                    <ErrorMessage name="confirmEmail">
                      {msg => (
                        <StyledInlineErrorMessage>{msg}</StyledInlineErrorMessage>
                      )}
                    </ErrorMessage>
                    <LabelContainer>                    
                    
                    <Label htmlFor="password">
                      Senha:
                      {/* <Input
                        type="password"
                        name="password"
                        autoCorrect="off"
                        autoComplete="name"
                        placeholder="Crie uma senha de acesso"
                        valid={touched.password && !errors.password}
                        error={touched.password && errors.password}
                      /> */}
                      <ContainerInputPassword>
                        <InputPassword
                          name="password"
                          autoCorrect="off"
                          autoComplete="name"
                          placeholder="Crie uma senha de acesso"
                          valid={touched.password && !errors.password}
                          error={touched.password && errors.password}
                          
                          />
                      </ContainerInputPassword>
                    </Label>
                    </LabelContainer>
                    {errors.password && touched.password && (
                      <StyledInlineErrorMessage>
                        {errors.password}
                      </StyledInlineErrorMessage>
                    )}
                    <LabelContainer>                    

                    <Label htmlFor="confirmPassword">
                       Confirmação de senha:
                      {/* <Input
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirme sua senha de acesso"
                        valid={touched.confirmPassword && !errors.confirmPassword}
                        error={touched.confirmPassword && errors.confirmPassword}
                      /> */}
                      <ContainerInputPassword>
                        <InputPassword
                          name="confirmPassword"
                          placeholder="Confirme sua senha de acesso"
                          valid={touched.confirmPassword && !errors.confirmPassword}
                          error={touched.confirmPassword && errors.confirmPassword}
                          style={{ flex: "1 1 0", boxSizing: "border-box" }}
                          />
                        </ContainerInputPassword>
                    </Label>
                    </LabelContainer>                    

                    {errors.confirmPassword && touched.confirmPassword && (
                      <StyledInlineErrorMessage>
                        {errors.confirmPassword}
                      </StyledInlineErrorMessage>
                    )}
                    <CheckContainer  deviceType={{
                            type: deviceType,
                          }}>
                      <Input type="checkbox" name="term" checked={values.term} onChange={handleChange}
                        onBlur={handleBlur} />
                      Li e concordo com o
                      <strong onClick={() => handleModal(1)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>Termo de adesão</strong>.
                    </CheckContainer>
                    {errors.term && touched.term && (
                      <StyledInlineErrorMessage>
                        {errors.term}
                      </StyledInlineErrorMessage>
                    )}
                    <Submit type="submit" disabled={!isValid || isSubmitting}>
                      {isSubmitting ? `Avançando...` : `Avançar`}
                    </Submit>
                    <br />
                    {signupError && (
                      <StyledInlineErrorMessage>
                        Não conseguimos seguir com a sua solicitação por divergência de informações.
                        <br />
                        Por favor, procure nossa Central de Atendimento ao Cliente.
                        <br />
                        O atendimento ocorre entre às 09h00 e às 18h00.
                      </StyledInlineErrorMessage>
                    )}
                    </FormContainer>
                  </Form>
                  <Modal
                    title="TERMO DE USO PARA ACESSO AO PORTAL DO SPC BRASIL"
                    isOpen={showModal === 1}
                    onToggleModal={() => handleModal(0)}
                    primaryActionButton={{
                      label: 'Li e aceito',
                      action: () => {
                        setFieldValue('term', true)
                        setDisabled(false)
                      },
                      closeOnclick: true,
                    }}
                  >
                    <ModalTerm deviceType={{ type: deviceType }}>
                      <SignupTerm />
                    </ModalTerm>
                  </Modal>
                  {/* <hr /> <CodeWrapper> <strong>Errors:</strong> {JSON.stringify(errors, null, 2)} <strong>Touched:</strong> {JSON.stringify(touched, null, 2)} {formValues && <strong>Submitted values:</strong>} {JSON.stringify(formValues, null, 2)} </CodeWrapper> */}
                </>
              )
            }}
          </Formik>
        </div>
        <div style={{ display: deviceType === "mobile" ? "none" : "" }}>
        <Container>
          <ImageContainer deviceType={{ type: deviceType }}>
          <img style={{ width: "40vw" }} src={require('../../../assets/images/Group151.png')} alt="Group" />
          </ImageContainer>
        </Container>
        </div>
      </Content >
    </Container >
  </>
  );
}

export default SignupPj;
