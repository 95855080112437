import { ResponsiveProps } from 'types';
import { StyledResponsive } from './styles';

const Responsive = ({ children, deviceType, ...rest }: ResponsiveProps) => {
  return (
    <StyledResponsive deviceType={deviceType} {...rest}>
      {children}
    </StyledResponsive>
  );
};

export { Responsive };
