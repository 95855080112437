import React, { useContext, useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Input, Label, StyledInlineErrorMessage, Submit } from './styles';
import { Container } from 'components/Container';
import { LoadingSpinner, Responsive } from 'components';
import { AuthContext } from 'contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from 'components/Text';
import { InputPassword } from '../InputPassword/InputPassword';
import { Content, ImageContainer, WelcomeContainer } from '../styles';
import useDeviceType from 'hooks/useDeviceType';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'A senha deve ter pelo menos 8 caracteres')
    .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula')
    .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula')
    .matches(/\d/, 'A senha deve conter pelo menos um número')
    .matches(
      /^(?=.*[@$!%*?&#^(){}[\]\\/|<>,.:;'"`~+=_-])/,
      'A senha deve conter pelo menos um caractere especial'
    )
    .required('Digite uma senha'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem coincidir')
    .required('Digite a senha novamente'),
});

const RecoverPassword = () => {
  const {
    verifyDocument,
    isAuthenticated,
    getUserInfoByHash,
    updatePassword,
    updatePasswordPJ,
  } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const hash = location?.pathname?.match(
    /\/recadastro-senha-valido\/(\w+)/
  )?.[1];
  const [userInfo, setUserInfo]: any = useState();

  const { deviceType } = useDeviceType();

  const getUserInfo = async (hash: any) => {
    await getUserInfoByHash({
      hash: hash,
    })
      .then((res: any) => {
        setUserInfo(res);
      })
      .catch((e: any) => {
        console.log('erro', e);
      });
  };

  useEffect(() => {
    if (isAuthenticated == true) {
      navigate('/home/score');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    {
      hash && getUserInfo(hash);
    }
  }, [isAuthenticated]);



  return (
    <>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <Container>
        <Content
          deviceType={{
            type: deviceType,
          }}
        >
   
          <WelcomeContainer deviceType={{ type: deviceType }}>
     
              <Text tag="h4" size={3} weight={2} color="black ">
                Alterar senha
              </Text>
              <Text color="text" size={0}>
                Nele você terá acesso ao seu Score de Crédito, Consulta
                <br /> de CPF, Cadastro Positivo e muito mais.
              </Text>
              <br />
              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  setLoading(true);
                  const updatePasswordFunction =
                    userInfo.tipoPessoa === 'PJ'
                      ? updatePasswordPJ
                      : updatePassword;
                  updatePasswordFunction({
                    hash: userInfo?.hash,
                    password: values.password,
                    personType: userInfo.tipoPessoa,
                    consomerID: userInfo.id,
                  })
                    .then((res: any) => {
                      navigate('/entrar', {
                        state: { document: userInfo?.documento },
                      });
                      setLoading(false);
                    })
                    .catch((e: any) => {
                      setLoading(false);
                      actions.setFieldError(
                        'password',
                        JSON.stringify(e.response.data.message)
                      );
                    });
                  actions.setSubmitting(true);
                  const timeOut = setTimeout(() => {
                    actions.setSubmitting(false);
                    clearTimeout(timeOut);
                  }, 1000);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  isSubmitting,
                  isValidating,
                  isValid,
                }) => {
                  return (
                    <><div style={{ width: deviceType === "mobile" ? "100%" : "80%" , maxWidth: "450px" }}>
                      <Form
                        style={{ paddingTop: '0.3rem' }}
                        name="contact"
                        method="post"
                        onSubmit={handleSubmit}
                      >
                        <Label htmlFor="password">
                          Criar nova senha:
                          {/* <Input
                          type="password"
                          name="password"
                          autoCorrect="off"
                          placeholder="Criar nova senha"
                          valid={touched.password && !errors.password}
                          error={touched.password && errors.password}
                        /> */}
                        <div style={{ marginTop: "4px" }}></div>
                          <InputPassword
                            name="password"
                            autoCorrect="off"
                            placeholder="Criar nova senha"
                            valid={touched.password && !errors.password}
                            error={touched.password && errors.password}
                            buttonStyle={{ zIndex: 95 }}
                          />
                        </Label>
                        {errors.password && touched.password && (
                          <StyledInlineErrorMessage>
                            {errors.password}
                          </StyledInlineErrorMessage>
                        )}
                        <br/>
                        <Label htmlFor="confirmPassword">
                          Confirmação de senha:
                          {/* <Input
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirmar nova senha"
                          valid={touched.confirmPassword && !errors.confirmPassword}
                          error={touched.confirmPassword && errors.confirmPassword}
                        /> */}
                        <div style={{ marginTop: "4px" }}></div>
                          <InputPassword
                            name="confirmPassword"
                            placeholder="Confirmar nova senha"
                            valid={
                              touched.confirmPassword && !errors.confirmPassword
                            }
                            error={
                              touched.confirmPassword && errors.confirmPassword
                            }
                            buttonStyle={{ zIndex: 95 }}
                          />
                        </Label>
                        {errors.confirmPassword && touched.confirmPassword && (
                          <StyledInlineErrorMessage>
                            {errors.confirmPassword}
                          </StyledInlineErrorMessage>
                        )}

                        <Submit
                          style={{ marginTop: '2rem' }}
                          type="submit"
                          disabled={!isValid || isSubmitting}
                        >
                          {isSubmitting
                            ? `Alterando a senha...`
                            : `Alterar senha`}
                        </Submit>
                      </Form>
                      </div>
                       </>
                  );
                }}
              </Formik>
            </WelcomeContainer>
       
            <ImageContainer deviceType={{ type: deviceType }} >
                <img
                  src={require('../../../assets/images/Group151.png')}
                  alt="Group"
                />
            </ImageContainer>
        </Content>
      </Container>
    </>
  );
};

export default RecoverPassword;
