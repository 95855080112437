import { useEffect, useState } from 'react';

const useDeviceType = () => {
  const [deviceType, setDeviceType] =
    useState<'desktop' | 'laptop' | 'tablet' | 'mobile' | 'mobile-landscape'>();

  useEffect(() => {
    const updateDeviceType = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      if (width >= 1920) {
        setDeviceType('desktop');
      } else if (width >= 1100 && width < 1920) {
        setDeviceType('laptop');
      } else if (width >= 768 && width < 1100) {
        setDeviceType('tablet');
      } else {
        setDeviceType(height > width ? 'mobile' : 'mobile-landscape');
      }
    };

    // Execute a função inicialmente para definir o tipo de dispositivo
    updateDeviceType();

    // Adicione um ouvinte de redimensionamento para atualizar o tipo de dispositivo
    window.addEventListener('resize', updateDeviceType);

    // Remova o ouvinte de redimensionamento quando o componente é desmontado
    return () => {
      window.removeEventListener('resize', updateDeviceType);
    };
  }, []);

  return { deviceType };
};

export default useDeviceType;
