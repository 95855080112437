export const neutral = {
  '3F3D56': '#3F3D56',
  '444F64': '#444F64',
  '586682': '#586682',
  '8094BC': '#8094BC',
  '91A6B7': '#91A6B7',
  '929FBA': '#929FBA',
  '9DB3D4': '#9DB3D4',
  'B9C6E0': '#B9C6E0',
};
