import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const ContentContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  flex-direction: column;
  justify-content:start;
  margin: 24px 0px;
`;

export const KeyContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  margin-top: 16px;
  `;

export const StyledKeyValue = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ deviceType }) => `
    margin: 14px 0px;
   
    p {
      display: inline;
      margin-right: 8px;
      font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
    }
    
  `} 
`;
