import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

const MAX_WIDTH_CONTAINER = 2560;
const CONTAINER_PADDING = 96;

const StyledContainer = styled('section') <{
  fluid?: boolean;
  center?: boolean;
  deviceType?: DeviceTypeProps;
}>`
  ${({ fluid, deviceType, center }) => `box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: ${fluid
      ? `${MAX_WIDTH_CONTAINER}px`
      : `calc(${MAX_WIDTH_CONTAINER + CONTAINER_PADDING}px)`
    };
    
    width: 100%;
    padding: ${fluid
      ? '0'
      : deviceType?.type === 'desktop'
        ? '0 0'
        : deviceType?.type === 'laptop'
          ? '0 '
          : deviceType?.type === 'tablet'
            ? '0 2vw'
            : '0'
    };

    ${center ? ` 
      display:flex;
      justify-content:center;
    ` : ``
    }

`}
`;

export const StyledPageBody = styled.div<{ noBreadcrumbs?: boolean }>`
  margin: 0 auto;
  max-width: 898px;
  position: relative;
  padding: 72px 150px;
  z-index: 0;

  ${({ theme, noBreadcrumbs }) => `
    background: ${theme.color.white['FFFFFF']};
    ${noBreadcrumbs && `margin-top: -86px;`}
  `}
`;
export { StyledContainer };
