import styled from 'styled-components';

export const StyledComplete = styled.div`
  position: relative;
`;

export const ListResults = styled.ul`
  position: absolute;
  left: 0;
  top: 100%;
  padding: 0;
  width: 100%;
  margin: 1px 0;
  border-radius: 0 0 8px 8px;
  list-style: none;
  z-index: 9;

  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    border: 1px solid ${theme.color.neutral['929FBA']};
  `}
`;

export const ResultItem = styled.li`
  padding: 12px 16px;
  cursor: pointer;

  ${({ theme }) => `
    &:not(:first-of-type) {
      border-top: 1px solid ${theme.color.neutral['929FBA']};
    }

    &:hover {
      background: ${theme.color.lightGray['EBF5FF']};
    }
  `}
`;
