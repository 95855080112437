import styled, { css } from 'styled-components'
import { BsFillPlusCircleFill, BsFillTrashFill } from "react-icons/bs";
import { TextArea } from 'components/TextArea';
import { DeviceTypeProps } from 'types/Responsive.types';

export const ContainerText = styled.div`
  margin: auto;
`

export const FieldSet = styled.div`
  margin-top: 0.7rem;
`

export const FormContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  justify-content: space-between;

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape')
      return `
      flex-direction: column;

      `;
  }}

  `

export const DinamicForm = styled.div`
    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.color.lightGray['EAEAEA']};
      margin-top: 1rem;
      padding-bottom: 2rem;
    `}
`

export const FirstRequest = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  input {
    margin-right: 16px;
  }
  p{
    border: 1px solid transparent;
  }
`
export const FieldWrapper = styled.div<{ deviceType?: DeviceTypeProps }>`
  width: 40%;
  white-space:nowrap;
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape')
    return `
      width: 100%;
      `;
  }}

`

export const TextboxWrapper = styled.div`
  width: 100%;
`

export const TextAreaModal = styled(TextArea)`
  min-height: unset;
  width: 100%;
  resize: none;
  font-style: normal;
  outline: 0;
  font-weight: 400;
  font-size: 16px;
  padding: 22px 4px;
`

export const DeleteWrapper = styled.div`
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
`

export const IconDelete = styled(BsFillTrashFill)`
    height: 1.5rem;
    width: 1.5rem;
`

export const AddFormWrapper = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1rem;

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
      justify-content: center;
      `;
  }}

`

export const IconAdd = styled(BsFillPlusCircleFill)`
  position: relative;
  top: 3px;
`

export const Submit = styled('button')`
  ${({ theme }) => css`

  height: 64px;
  border-radius: 50px;
  min-width: 168px;
  padding: 14px 30px;
  cursor: pointer;
  border: 1px solid transparent;


    color: ${theme.button['default'].text};
    font-weight: 600;
    font-size: 16px;



  background: ${theme.button['default'].bg};
  &:hover {
    background: ${theme.button.primary.bg}
  }

  &:disabled {
    background-color: ${theme.button.disabled.bg};
    color: ${theme.button.disabled.text};
    border-color: ${theme.button.disabled.border};
  }
  `}
`

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    color: ${theme.color.red['B90000']};
    font-size: 14px;
  `}
`
