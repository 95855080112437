import { Text } from 'components';
import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledQueryInfoItem = styled.div<{ deviceType?: DeviceTypeProps }>`
${({ theme }) => `
  position: relative;
  display: flex;
  width: calc(100% - 65px);
  height: 105% ;  
  padding-left: 50px;
  margin-left: 6px;
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25'
  xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none'
  stroke='%23FFBF2EFF' strokeWidth='2' stroke-dasharray='8' stroke-dashoffset='3'
  strokeLinecap='square'/%3e%3c/svg%3e");
  background-size: 150px 300px;
  background-position: -130px -2px;
  background-repeat: no-repeat;
  

  &:before {
    content: '';
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: ${theme.color.yellow['FFBF2E']};
    position: absolute;
    left: 7px;
    top: 0;

    
  }
  
`}

${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      width: auto;
      background-image: none;
      padding: 0;
      margin: 0;
     
      &:before {
      display:none;
      }
    `;
  }}

`;

export const TextContainer = styled.div`
  flex: 1;
`;

export const Title = styled(Text)`
  font-weight: 500;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
  line-height: 25px;
  ${({ theme }) => `
    color: ${theme.color.darkGray['606060']};
  `}
`;

export const Description = styled.p<{ deviceType?: DeviceTypeProps }>`
  font-weight: 400;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};

  line-height: 26px;
  
  ${({ theme }) => `
    color: ${theme.color.darkGray['797979']};
  `}
`;

export const StatusTimeContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex; 
  justify-content: center;
  white-space: nowrap;

`;

export const TimeContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme, deviceType }) => `
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      color: ${theme.color.darkGray['797979']};
      font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
    }
  `}
`;

export const StatusContainer = styled.div`
`;

export const InfoContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme }) => `
    flex: 1;
    background: ${theme.color.white['FFFFFF']};
    padding: 22px;
    height: auto;
    border-radius: 12px;
    box-shadow: 0px 1.59567px 7.97834px rgba(88, 102, 130, 0.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    `}
    
    ${({ deviceType }) => {
      if (deviceType?.type == 'mobile')
      return `
      padding: 18px 22px;
      flex-direction: column;
      margin: 5px;
      align-items:start;
      max-width: 77vw;
      text-align: start;
      `;
  }}

`;
