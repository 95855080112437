import { PageSkeleton } from 'components/PageSkeleton';
import { formatUnixTime } from 'utils/formatUnixTime';
import { Details } from '../..';
import { DetailsContainer } from './styles';
import useDeviceType from 'hooks/useDeviceType';

interface ContestDetailsProps {
  info: Details
  goBack: () => void
}

export const ContestDetails = ({ info, goBack }: ContestDetailsProps) => {
  const { deviceType } = useDeviceType();


  const changeMotivo = (motivo: string) => {

    motivo = motivo.replace("modalidade C,", "modalidade de Cartão de Crédito,");
    motivo = motivo.replace("na modalidade T,", "no segmento de Telecomunicações,");
    motivo = motivo.replace("na modalidade E,", "no segmento de Energia,");
    motivo = motivo.replace("modalidade P,", "modalidade de Crédito Parcelado,");
    motivo = motivo.replace("modalidade R,", "modalidade de Crédito Rotativo,");
    motivo = motivo.replace("modalidade S,", "modalidade de Consórcio,");
    motivo = motivo.replace("identificação do contrato:", "através do contrato:");

    return motivo;
  }


  return (
    <PageSkeleton
      title="Detalhes da contestação"
      highlight={{
        title: 'Acompanhe aqui a atualização da sua contestação',
        description: '',
        image: require('../../../../../assets/images/contestacao-4.png'),
        reverse: true,
      }}
      paddingSize='sm'
      backButton={{
        render: true,
        action: goBack,
      }}
      breadcrumb={{
        menuName: 'Cadastro Positivo',
        submenuName: 'Contestação',
      }}
    >
      <DetailsContainer deviceType={{ type: deviceType }}>
        <h3>Detalhes da contestação</h3>
        <h4>Protocolo:</h4>
        <p>{info.numeroProtocolo}</p>
        <h4>Status:</h4>
        <p>{info.codigoStatusTela}</p>
        <h4>Fonte</h4>
        <p>{info.nomeEmpresa}</p>
        <h4>Realizada em:</h4>
        <p>{formatUnixTime(info.dataContestacao)}</p>
        <h4>Motivo:</h4>
        <p>{changeMotivo(info.justificativaInclusao)}</p>
        <h4>Origem da Contestação:</h4>
        <p>{info.nomeBiroOrigem}</p>
        <h4>CNPJ Origem Contestação:</h4>
        <p>{info.cnpjBiroOrigem}</p>
        <h4>Explicação:</h4>
        <p>{info.textoStatus}</p>
      </DetailsContainer>
      {info.categoriaContestacao !== 'CLIENTE' ? (
        <DetailsContainer deviceType={{ type: deviceType }}>
          {info.numeroFatura !== null || info.numeroContrato !== null ?
            <h3>Operação contestada:</h3>
            : ''}
          {info.numeroContrato !== null ?
            <h4>Contrato:</h4>
            : ''}
          {info.numeroContrato !== null ?
            <p>{info.numeroContrato}</p>
            : ''}
          {info.dataContratacao !== null ?
            <h4>Data do contrato:</h4>
            : ''}
          {info.dataContratacao !== null ?
            <p>{formatUnixTime(info.dataContratacao)}</p>
            : ''}
          {info.numeroFatura !== null ?
            <h4 >Fatura: </h4>
            : ''}
          {info.numeroFatura !== null ?
            <p> {info.numeroFatura} </p>
            : ''}
        </DetailsContainer>
      ) : ''}

    </PageSkeleton>
  );
};
