import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledPaymentHistory = styled.div<{ deviceType?: DeviceTypeProps }>`
  position: relative;
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
    return `
      margin-top: 20px;
      `;
  }}

  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    border-bottom: 2px solid ${theme.color.lightGray['EAEAEA']};
  `}
`;

export const MessageAlertContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const PaymentTypes = styled.div <{ deviceType?: DeviceTypeProps }>`
  display: flex;
  justify-content: start;
  width: 100%;
  margin-top 10px;
  ${({ theme }) => `
    border-bottom: 2px solid ${theme.color.lightGray['EAEAEA']};
  `}
`;

export const TypeButton = styled.div<{ selected: boolean }>`
  padding: 8px;
  margin-right: 15px;
  margin-bottom: -2px;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;

  ${({ theme, selected }) => `
    color: ${selected ? theme.color.blue['154695'] : theme.color.neutral['8094BC']};
    border-bottom: 3px solid ${selected ? theme.color.blue['154695'] : 'transparent'};
    font-weight: ${selected ? '600' : '300'};
  `}
`;

export const FilterContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;  
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile') {
      return `
      margin: 0;
      flex-direction: column;
      justify-content: end;
        `;
    }
  }}
  
`;

export const PaymentSubtypes = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
  ${({ deviceType }) => {
    if (deviceType?.type === 'tablet' || deviceType?.type === 'mobile-landscape' || deviceType?.type === 'mobile') {
      return `
      flex-wrap: wrap;
        `;
    }
  }}
`;

export const SubtypeButton = styled.div<{ selected: boolean, deviceType?: DeviceTypeProps }>`
  padding: 12px 8px;
  border-radius: 5px;
  font-size: 17px;
  line-height: 28px;
  cursor: pointer;  
  
  ${({ theme, selected }) => `
  color: ${selected ? theme.color.blue['154695'] : theme.color.neutral['8094BC']};
  background: ${selected ? theme.color.white['F3F7FF'] : 'transparent'};
  font-weight: ${selected ? '600' : '300'};
  `}
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape') {
      return `
      margin-left: 4px;
      padding: 10px 8px;
      display: flex;
      font-size: 15px;

      align-items: center;
      white-space: nowrap;
      `;
    }
  }}
`;

export const PaymentCard = styled.div<{ deviceType?: DeviceTypeProps }>`
  width: 100%;
  box-shadow: 0px 1.4px 7px rgba(88, 102, 130, 0.16);
  border-radius: 11px;
  cursor: pointer;
  margin-bottom: 11px;

  ${({ theme }) => `
    color: ${theme.color.neutral['586682']};
  `}
  
  & h3 {
    font-weight: 500 !important;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type,2)}` : 'inherit'};
    line-height: 25px !important;
    margin: 0 !important;
  }

  & h4 {
    font-weight: 400;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
    line-height: 28px;
    margin: 0;

    & b {
      font-weight: 500;
    }
  }
`;

export const PaymentInfo = styled.div`
  padding: 20px 22px;

  ${({ theme }) => `
    border-bottom: 2px solid ${theme.color.lightGray['EAEAEA']};
  `}
`;

export const PaymentNumber = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 11px 22px;
  word-break: break-all;

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
      h4 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      `;
  }}

`;

export const ShowMore = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  gap: 11.24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;

  ${({ theme }) => `
    color: ${theme.color.blue['154695']};
  `}
`;

export const AlertContainer = styled.div`
  ${({ theme }) => `
    width: 100%;
    height: 80px;
    border-radius: 16px;
    background: ${theme.color.lightGray['D5DDED']}3F;
    border: 1px solid ${theme.color.lightGray['BCBCBC']};
    display: flex;
    align-items: center;
    margin: 24px 0;
    justify-content: center;
    & p{
      text-align: center;
    }
  `}
`;
