import { Option, OptionList, SelectInput, StyledSelector } from './styles';
import { SelectorProps } from 'types';
import { SelectorIcon } from 'components/Icon/SelectorIcon';
import { useEffect, useState } from 'react';
import useDeviceType from 'hooks/useDeviceType';

const Selector = ({ options, selectedOption, onSelect, ...rest }: SelectorProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const [selected, setSelected] = useState(selectedOption ?? '');

  useEffect(() => {
    if (selectedOption != null) {
      setSelected(selectedOption);
    }
  }, [selectedOption]);

  const handleOptionList = () => {
    setShowOptions(!showOptions);
  };

  const handleSelected = (value: {label: string, value:string | number}) => {
    setSelected(value.label);
    handleOptionList();
    onSelect(value);
  };

  const getOptionToDisplay = () => {
    const result = options.filter(o => o.label === selected);

    return result.length > 0 ? selected : 'Selecione...';
  }
  const { deviceType } = useDeviceType();

  return (
    <StyledSelector>
      <SelectInput deviceType={{ type: deviceType }} open={showOptions} onClick={handleOptionList} value={selected} {...rest}>
        {getOptionToDisplay()}
        <SelectorIcon />
      </SelectInput>
      { showOptions && (
        <OptionList deviceType={{ type: deviceType }}>
          {options.map((option, index) => (
            <Option deviceType={{ type: deviceType }} key={`${option.label}-${index}`} 
            onClick={() => handleSelected(option)}>{option.label}</Option>
          ))}
        </OptionList>
      )}
    </StyledSelector>
  );
};

export { Selector };
