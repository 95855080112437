import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledSelector = styled.div`
  position: relative;
`;

export const SelectInput = styled.div<{ open: boolean, value: string, deviceType?: DeviceTypeProps }>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
  padding: 14px 10px;
  z-index: 2;
  cursor: pointer;
  user-select: none;

  ${({ theme, open, value }) => `
    background: ${theme.color.white['FFFFFF']};
    border: 1.4px solid ${theme.color.lightGray['D5DDED']};
    color: ${value === 'Selecione...' ? theme.color.neutral['B9C6E0'] : theme.color.neutral['586682']};

    & svg {
      transform: rotate(${open ? '180deg' : '0'});
    }
  `}
`;

export const OptionList = styled.div<{ deviceType?: DeviceTypeProps }>`
  position: relative;
  border-radius: 0 0 6px 6px;
  z-index: 1;
  margin-top: -10px;
  padding-top: 10px;
  overflow-y: auto;
  overflow-x: clip;
  max-height: 320px;

  &::-webkit-scrollbar {
    width: 8px;
  };

  &::-webkit-scrollbar-thumb {
    background: #D9D9DB;
    border-radius: 4px;
  };

  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    border: 1.4px solid ${theme.color.lightGray['D5DDED']};
  `}
`;

export const Option = styled.div<{ deviceType?: DeviceTypeProps }>`
  box-sizing: border-box;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
  padding: 20px 18px;
  cursor: pointer;

  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    border-bottom: 1.4px solid ${theme.color.lightGray['D5DDED']};
    color: ${theme.color.neutral['586682']};

    &:hover {
      background: ${theme.color.lightGray['EBF5FF']};
    }
  `}
`;
