import { PageSkeleton, Text } from 'components';
import Carousel from 'components/Carousel';
import { StarIcon } from 'components/Icon';
import { useEffect, useState } from 'react';
import { Content, StyledLinkCard } from './styles';
import { vantagens as vantagensService } from 'services/Cadastro';
import { ContainerLink } from 'components/Carousel/styles';
import useDeviceType from 'hooks/useDeviceType';
import { IconContainer } from 'components/LinkCard/styles';

const Vantagens = () => {
  const [carrosel, setCarrosel] = useState([]);
  const [images, setImages] = useState([]);
  const [vantagens, setVantagens] = useState([]);
  const { deviceType } = useDeviceType();

  const mock = [{
    title: "Vantagens 1",
    description: "Conheça as soluções e parcerias que o SPC Brasil tem para você",
    url: '/vantagens',
    /* onClick: window.location.assign('/vantagens'), */
    alternative: true
  },
  {
    title: "Vantagens 2",
    description: "Conheça as soluções e parcerias que o SPC Brasil tem para você",
    url: '/vantagens',
    /* onClick: window.location.assign('/vantagens'), */
    alternative: true
  },
  {
    title: "Vantagens 3",
    description: "Conheça as soluções e parcerias que o SPC Brasil tem para você",
    url: '/vantagens',
    /* onClick: window.location.assign('/vantagens'), */
    alternative: true,
    inative: false
  }]



  const formatUrl = () => {
    let urlPrefix = "/carrousel";
    if (deviceType === "mobile") {
      urlPrefix += "/mobile";
    } else {
      urlPrefix += "/desktop";
    }
    return urlPrefix
  }

  const getCarrosel = () => {
    const data: any = vantagensService.getCarrossel().then((result: any) => {
      setCarrosel(result);
      changePath();
    }).then();
  };


  const changePath = () => {
    setImages([])
    const newCarrosel: any = carrosel.map((image: any) => {
      return {
        ...image,
        url: formatUrl() + image.url
      };
    });
    setImages(newCarrosel)
  }

  const getVantagens = () => {
    const data: any = vantagensService.getVantagens().then((result: any) => {
      setVantagens(result);
    });
  };

  useEffect(() => {
    getCarrosel();
    getVantagens();

  }, []);

  useEffect(() => {
    changePath()

  }, [deviceType, carrosel]);
 

  return (
    <PageSkeleton
      title='Vantagens'
      breadcrumb={{
        menuName: 'Cadastro Positivo',
        submenuName: 'Vantagens',
      }}
    ><>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Carousel data={images} />
        </div>
        <ContainerLink deviceType={{ type: deviceType }}>
          {vantagens?.map((data: any) => {
            return <StyledLinkCard key={data.id} $alternative={data.alternative == true ? true : false}
              $inactive={data.inative == true ? true : false} to={data?.linkDirecionamento} deviceType={{ type: deviceType }}
              onClick={(event) => {
                event.preventDefault()
                window.open(data.linkDirecionamento, '_blank')
              }}>
              <IconContainer deviceType={{ type: deviceType }}>
                {<StarIcon />}
              </IconContainer>
              <Content>
                <Text size={1} weight={2} color='title'>{data.titulo}</Text>
                <Text color="text">{data.descricao}</Text>
              </Content>
            </StyledLinkCard>
          })
          }
        </ContainerLink>
      </>
    </PageSkeleton >
  );
}

export default Vantagens
