import styled, { css } from 'styled-components';
import { DeviceTypeProps, FieldStatusType } from 'types';

const commonStyles = css<{ status: FieldStatusType, deviceType?: DeviceTypeProps }>`
${({ theme, status, deviceType }) => `
    position: relative;
    width: 100%;
    height: calc(100% - 28px);
    margin: 14px 6px;
    border: none;
    color: ${theme.font.color[status]};

    ::placeholder {
      color: ${theme.font.color[status]};
      opacity: .65;
      ${deviceType?.type === "mobile" ? "font-size: 12.5px !important": ""} ;
    }

  `}
  :focus {
    outline: transparent;
  }
`;

export const StyledInput = styled.input<{ status: FieldStatusType, deviceType?: DeviceTypeProps }>`
  ${commonStyles}
`;

export const StyledDate = styled.input<{ status: FieldStatusType, deviceType?: DeviceTypeProps }>`
  ${commonStyles}
`;

export const Wrapper = styled.div<{
  status: FieldStatusType
  disabled?: boolean
}>`
  ${({ theme, status }) => `
    position: relative;
    width: 100%;
    color: ${theme.font.color[status]};
  `}
`;

export const InputField = styled.div<{
  status: FieldStatusType
  disabled?: boolean
}>`
  ${({ theme, status, disabled }) => `
    background: white;
    border-radius: 4px;
    border: 1px solid ${theme.font.color[status]};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    svg {
      width: 24px;
      height: 24px;
    }
  `}
`;
