import styled, { css } from 'styled-components';
import { ButtonProps } from 'types';

const BtnNormal = css<ButtonProps>`
  ${({ theme, btnStatus = 'default', btnStyle }) =>
    (btnStyle === 'normal' || !btnStyle) &&
    `
    background: ${theme.button[btnStatus].bg};

    p {
      color: ${theme.button[btnStatus].text};
    }

    &:hover {
      background: ${btnStatus !== 'delete' ? theme.button.primary.bg : theme.color.red.EE1D25};
    }
  `}
`;


const BtnOutline = css<ButtonProps>`
  ${({ theme, btnStatus = 'default', btnStyle }) =>
    btnStyle === 'outline' &&
    `
    background: transparent;
    border-color: ${theme.button[btnStatus].border};
    p {
      color: ${theme.button[btnStatus].bg};
    }

    &:not(:disabled):hover {
      border-color: ${theme.button.primary.bg};

      p {
        color: ${theme.button.primary.bg};
      }
    }
  `}
`;


const BtnLink = css<ButtonProps>`
  ${({ theme, btnStatus = 'default', btnStyle }) =>
    btnStyle === 'link' &&
    `
    background: transparent;
    color: ${theme.button[btnStatus].border};
    border-color: transparent;
  `}
`;

const BtnAction = css<ButtonProps>`
  ${({ theme, btnStyle }) =>
    btnStyle === 'action' &&
    `
    height: 48px;
    min-width: 48px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    overflow: visible;
    padding: 12px;
    border-color: ${theme.color.lightGray['D5DDED']};
    color: ${theme.color.darkGray['606060']};

    p {
      display: flex;
      align-items: center;
      color: ${theme.color.neutral['8094BC']};

      svg {
        margin-right: 8px;
        stroke: ${theme.color.neutral['8094BC']};
      }
    }
  `}
`;

const BtnTop = css<ButtonProps>`
  ${({ btnStyle }) =>
    btnStyle === 'top' &&
    `
    padding: 0;
    min-width: 0;
    width: 57px;
    height: 59px;
    background: transparent;
    border-color: transparent;
  `}
`;

const StyledButton = styled.button<ButtonProps>`
  border-radius: 50px;
  padding: 10px 28px;
  cursor: pointer;
  border: 1px solid transparent;
  
  @media (max-width: 767px) {
    height: 48px;
    padding: 0px 15px;

    p {
      font-size: 14px;
    }
  }    
  
  p {
    text-align: center;
  }

  ${BtnNormal}
  ${BtnOutline}
  ${BtnLink}
  ${BtnAction}
  ${BtnTop}
`;

export { StyledButton };
