import { api } from './api';

interface BloqueioProps {
  documento: string
  tipoData: string
  listaStatus: string
  dataInicial: string
  dataFinal: string
  maxResult: number
}

export const bloqueio = {  
  companies: async (params: BloqueioProps) => await api()    
    .get(`/cadastropositivo-bloqueio-desbloqueio-score/bloqueiodesbloqueio-score/pesquisar-bloqueio-score`,
      { params }),
  isBlocked: async (documento: string) => await api()
      .get(`/cadastropositivo-bloqueio-desbloqueio-score/bloqueiodesbloqueio-score/existe-bloqueio-ativo/${documento}`),
};
