import styled from 'styled-components';

const StyledFormField = styled.label`
  position: relative;
  width: 100%;
  
  .helperText {
    // position: absolute;
  }
  
  > * {
    &:first-child {
      margin: 6px 0px;
    }
    &:last-child {
      margin-top: 8px;
    }
  }
`;

export { StyledFormField };
