import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { PageSkeleton } from 'components/PageSkeleton';
import { Text } from 'components/Text';
import { AuthContext, RequestStatusContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Signature as signatureService } from 'services/Cadastro/signature';
import { CancelDateTime, CheckContainer, ConfirmContainer, InputTerm, ModalTerm, TermContainer } from '../Cancelamento/styles';
import TermoReaberturaPF from './TermoReaberturaPF';
import TermoReaberturaPJ from './TermoReaberturaPJ';
import moment from 'moment';
import { documentFormatter } from 'utils/documentFormatter';
import { LoadingSpinner } from 'components';
import { FooterTermContainer } from './styles';
import useDeviceType from 'hooks/useDeviceType';

export const Abertura = () => {
  const { userData, getRepresentativesInfo } = useContext(AuthContext);
  const { addRequest } = useContext(RequestStatusContext);
  const [disabled, setDisabled] = useState(true);
  const [showModal, setShowModal] = useState(0);
  const [representatives, setRepresentatives] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleModal = (modal: number) => {
    setShowModal(modal);
  };

  const setAtivo = () => {
    localStorage.removeItem('SPC_StatusCadPos');
    localStorage.setItem('SPC_StatusCadPos', JSON.stringify('ATIVO'));
  };

  const handleActivation = () => {
    if (userData) {
      setLoading(true);
      signatureService.reopen({
        tipoConsumidor: ['CPF', 'PF'].includes(userData.tipoConsumidor ?? '') ? 'F' : 'J',
        documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
        justificativa: '',
        dataAcolhimento: getCurrentDate(),
        ipOrigem: ''
      }).then(() => {
        setLoading(false);
        setAtivo();
        addRequest({
          statusCode: 200,
          message: 'Abertura realizada com sucesso!',
        });
        navigate('/home/cadastro-positivo');
      });
    }
  };

  const getCurrentDate = () => {
    const date = new Date();

    const day = '0' + date.getDate();
    const month = '0' + (date.getMonth() + 1);
    const year = date.getFullYear();
    const formattedDate = year + '-' + month.slice(-2) + '-' + day.slice(-2);

    return formattedDate;
  };

  useEffect(() => {
    if (userData?.cnpj !== "" && userData?.cnpj !== undefined) {
      const fetchData = async () => {
        const reps = await getRepresentativesInfo({ document: userData?.cnpj });
        setRepresentatives(reps);
      };

      fetchData();
    }
  }, [userData, getRepresentativesInfo]);

  const findValidRepresentative = (representatives: any) => {
    if (!representatives || representatives.length === 0) {
      return null;
    }

    const today = moment();

    const validRepresentative = representatives.find((representative: { dataFimVigencia: moment.MomentInput; }) => {
      if (representative.dataFimVigencia === null) {
        return true;
      }

      const endDate = moment(representative.dataFimVigencia);

      return endDate.isSameOrAfter(today);
    });

    return validRepresentative;
  }

  const validRepresentative = findValidRepresentative(representatives);

  const { deviceType } = useDeviceType();

  return (
    <>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <PageSkeleton
        title="Abertura do Cadastro Positivo"
        breadcrumb={{
          menuName: 'Cadastro Positivo',
          submenuName: 'Abertura do Cadastro Positivo',
        }}
        highlight={{
          title: `A abertura do Cadastro Positivo é gratuita e super simples.`,
          description: `A partir do momento em que realizar a reativação do seu Cadastro Positivo,
        você volta a contar com as informações dos seus pagamentos no seu Score, entre outros
        benefícios. Vale lembrar que a sua solicitação de abertura, será compartilhada com os
        demais Gestores de Bancos de Dados em até 2 dias úteis.`,
          image: require('../../../assets/images/abertura.png'),
          reverse: false,
        }}
      >
        <div style={{ margin: "5px" }}>
          <Text size={1} lineHeight={1} weight={2} color="text">
            Leia e concorde com o Termo do Cadastro Positivo, disponível abaixo:
          </Text>
        </div>
        <TermContainer>
          {userData?.tipoConsumidor == 'PF' ? (
            <TermoReaberturaPF data={{
              name: userData?.nome,
              cpf: userData?.cpf,
              date: moment().locale('PT-BR').format('LL'),
              protocol: "NÃO TEM NOS DADOS"
            }} />
          ) : (
            <TermoReaberturaPJ data={{
              representativeName: validRepresentative?.nome,
              representativeCPF: validRepresentative?.cpf,
              representativeRg: validRepresentative?.rg,
              businessName: userData?.nomeEmpresarial,
              commercialName: userData?.nomeFantasia,
              cnpj: documentFormatter(userData?.cnpj),
              date: moment().locale('PT-BR').format('LL'),
            }} />
          )
          }
        </TermContainer>
        <FooterTermContainer>
          <CheckContainer deviceType={{ type: deviceType }} whitespace={true}>
            <input type="checkbox" id="confirm" checked={!disabled} onChange={() => setDisabled(!disabled)} />
            <span>
              Li e concordo com o
              <b style={{ marginLeft: "4px" }} onClick={() => handleModal(1)}>Termo do Cadastro Positivo</b>
            </span>
          </CheckContainer>
          <Button onClick={() => handleModal(2)} disabled={disabled}>Realizar abertura do Cadastro Positivo</Button>
        </FooterTermContainer>
        <Modal
          title="Termo de autorização para reabertura de Cadastro Positivo"
          isOpen={showModal === 1}
          onToggleModal={() => handleModal(0)}
          primaryActionButton={{
            label: 'Li e aceito',
            action: () => setDisabled(false),
            closeOnclick: true,
          }}
        >
          <ModalTerm deviceType={{ type: deviceType }}>
            {userData?.tipoConsumidor == 'PF' ? (
              <TermoReaberturaPF data={{
                name: userData?.nome,
                cpf: userData?.cpf,
                date: moment().locale('PT-BR').format('LL'),
                protocol: "NÃO TEM NOS DADOS"
              }} />
            ) : (
              <TermoReaberturaPJ data={{
                representativeName: validRepresentative?.nome,
                representativeCPF: validRepresentative?.cpf,
                representativeRg: validRepresentative?.rg,
                businessName: userData?.nomeEmpresarial,
                commercialName: userData?.nomeFantasia,
                cnpj: documentFormatter(userData?.cnpj),
                date: moment().locale('PT-BR').format('LL'),
              }} />
            )
            }
          </ModalTerm>
        </Modal>

        <Modal
          title="Abertura do Cadastro Positivo"
          isOpen={showModal === 2}
          onToggleModal={() => handleModal(0)}
          primaryActionButton={{
            label: 'Confirmar',
            action: handleActivation,
            closeOnclick: true,
          }}
          secondaryActionButton={{
            label: 'Cancelar',
            action: () => null,
            closeOnclick: true,
          }}
        >
          <ConfirmContainer>
          <Text size={1}>Suas informações serão compartilhadas com os demais GBDs.</Text>
            <CancelDateTime>
              Data de abertura:
              <b>{moment().locale('PT-BR').format('L')}</b>
            </CancelDateTime>
          </ConfirmContainer>
        </Modal>
      </PageSkeleton>
    </>
  );
};
