import { Button, LinkCard, ListIcon, Text } from 'components';
import { ThumbsUpIcon } from 'components/Icon';
import { AuthContext, CadastroContext } from 'contexts';
import { getMenuItems } from 'pages/_components/menus';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { listComoMelhorar } from './como-melhorar-dicas';
import { ButtonContainer, CardContainer, Dot, InactiveBanner, InactiveDescription, StatusContainer, TextBanner } from './styles';
import useDeviceType from 'hooks/useDeviceType';

export default function CadastroPositivo() {
  const { deviceType } = useDeviceType();
  const { userData } = useContext(AuthContext)
  const menuItems = getMenuItems(localStorage.getItem('SPC_DocumentType') || "", deviceType);

  const ActiveCards = menuItems.filter((menu) => menu.label === 'Cadastro Positivo')[0].submenu;

  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const page = urlParams.get('page');
  const spcStatusCadPos = localStorage.getItem('SPC_StatusCadPos');

  // Verificação para desabilitar o botão de entrada, caso APTO/CANCELADO.
  const { communication, getCommunication } = useContext(CadastroContext);
  const comStatus = `${communication.indicadorComunicacao == 'SIM' ? 'Comunicado' : 'Não Comunicado'}`


  useEffect(() => {
    if (userData.cpf !== "" && userData.cpf !== undefined) {
      communication.idFonte == 0 && getCommunication({
        tipoConsumidor: 'F',
        documento: userData?.cpf ?? '',
      });
    } else {
      communication.idFonte == 0 && getCommunication({
        tipoConsumidor: 'J',
        documento: userData?.cnpj ?? '',
      });
    }
  }, [communication]);

  return (
    <>
      {spcStatusCadPos && (spcStatusCadPos.includes('ATIVO') || spcStatusCadPos.includes('APTO')) ? (
        spcStatusCadPos.includes('ATIVO') ? (
          <>
            <CardContainer device={{ type: deviceType }} className='highlightSpace' style={{ marginTop: userData.tipoConsumidor == "PJ" ? "20px" : "" }}>
              <StatusContainer>
                <Text size={1} lineHeight={1} color="text">
                <Dot color={'#00B912'} />
                  {userData?.tipoConsumidor === 'PF' ? userData?.nome : userData?.nomeEmpresarial}, seu <strong>Cadastro Positivo está ativo</strong> no SPC. Por isso,
                  você tem acesso às seguintes funcionalidades:
                </Text>
              </StatusContainer>
            </CardContainer>
            <CardContainer
            className='listCards'>
              {
                ActiveCards && ActiveCards.map((item, index) => (
                  <LinkCard

                    key={index}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                    url={item.url}
                    onClick={() => navigate(`${item.url}`)}
                    alternative
                    inactive={item.title === "Bloqueio e Desbloqueio do Score" && userData.tipoConsumidor === "PJ"}
                  />
                ))
              }
            </CardContainer>
          </>
        ) : (
          <>
            <CardContainer device={{ type: deviceType }} className='highlightSpace'
              style={{ marginTop: userData.tipoConsumidor == "PJ" ? "20px" : "" }}>
              <Text size={1} lineHeight={1} color="text">
                <>
                  <div style={{ padding: 2 }} >
                    <Dot color={'#B90000'} />
                    {userData?.tipoConsumidor === 'PF' ? userData?.nome : userData?.nomeEmpresarial}, você se cadastrou no Portal do Consumidor e está com os acessos às suas informações, mas o seu Cadastro Positivo ainda não foi aberto no SPC Brasil.
                    <br /><br />
                    Aguarde que iremos comunicá-lo quando a abertura dele ocorrer e assim você poderá usufruir também de todos os benefícios e vantagens desse serviço. Obrigado e contamos com a sua compreensão!
                  </div>
                </>
              </Text>
            </CardContainer>
            {
              userData && userData.cnpj ? (
                <CardContainer>
                  {
                    ActiveCards && ActiveCards.map((item, index) => (
                      <LinkCard
                        key={index}
                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                        url={item.url}
                        onClick={() => navigate(`${item.url}`)}
                        alternative
                        inactive
                      />
                    ))
                  }
                </CardContainer>
              ) : (
                <CardContainer>
                  {
                    ActiveCards && ActiveCards.map((item, index) => (
                      <LinkCard
                        key={index}
                        title={item.title}
                        description={item.description}
                        icon={item.icon}
                        url={item.url}
                        onClick={() => navigate(`${item.url}`)}
                        alternative
                        inactive
                      />
                    ))
                  }
                </CardContainer>
              )
            }

          </>
        )
      ) : (
        <>
          {page === 'canceled' ? (
            <CardContainer className='highlightSpace' style={{ marginTop: userData.tipoConsumidor == "PJ" ? "20px" : "" }}>
              {
                userData.tipoConsumidor == "PJ" ? "" :
                  <>
                    <Text size={2} lineHeight={0} weight={3} color="text">Como melhorar seu score</Text>
                    {
                      listComoMelhorar.map((tip) => (
                        <ListIcon
                          title={tip.title}
                          description={tip.description}
                          icon={<ThumbsUpIcon />}
                        />
                      ))
                    }
                  </>
              }
            </CardContainer>
          ) : (
            <CardContainer className='highlightSpace' style={{ marginTop: userData.tipoConsumidor == "PJ" ? "20px" : "" }}>
              <Text size={1} lineHeight={1} color="text">
              <Dot color={'#B90000'} />
                {userData?.tipoConsumidor === 'PF' ? userData?.nome : userData?.nomeEmpresarial}, seu <strong>Cadastro Positivo está cancelado</strong> no SPC. Por isso,
                algumas funcionalidades estão limitadas. Mas você pode ativá-lo rapidamente e sem nenhum custo:
              </Text>
            </CardContainer>
          )}
          <Text align='left' size={2} lineHeight={4} weight={3} color="text">Cadastro Positivo</Text>
          <InactiveBanner deviceType={{ type: deviceType }}>
            <img src={require('../../../assets/images/usuario-inativo.png')} />
            <TextBanner deviceType={{ type: deviceType }}>
              <Text size={2} lineHeight={1} weight={2} color="buttonLabel">
                Você está com seu 
                cadastro positivo cancelado
              </Text>
              <Text size={2} lineHeight={1} weight={0} color="buttonLabel">
                Portanto não possui acesso às várias funcionalidades do Cadastro Positivo ativo
              </Text>
            </TextBanner>
          </InactiveBanner>
          <InactiveDescription deviceType={{ type: deviceType }}>
            <p>
              O Cadastro Positivo é um sucesso em vários países e, agora, veio <b>revolucionar a
                análise de crédito</b> no Brasil. <br />
              Ele reúne, de maneira segura, as <b>informações de pagamento</b> que você fez ou está
              fazendo. Esses dados ficam guardados num histórico que leva em conta toda a sua
              vida financeira recente.<br />
              Com isso, você poderá <b>ter mais facilidade e menos burocracia</b> para conseguir crédito
              ou fazer compras a prazo, entre outros benefícios e vantagens.
            </p>
            <h1>Benefícios do Cadastro Positivo</h1>
            <p>
              Com o Cadastro Positivo do SPC, você tem <b>acesso gratuito</b> às seguintes funcionalidades:
            </p>
          </InactiveDescription>
          {
            userData && userData.cnpj ? (
              <CardContainer>
                {
                  ActiveCards && ActiveCards.map((item, index) => (
                    <LinkCard
                      key={index}
                      title={item.title}
                      description={item.description}
                      icon={item.icon}
                      url={item.url}
                      onClick={() => navigate(`${item.url}`)}
                      alternative
                      inactive
                    />
                  ))
                }
              </CardContainer>
            ) : (
              <CardContainer>
                {
                  ActiveCards && ActiveCards.map((item, index) => (
                    <LinkCard
                      key={index}
                      title={item.title}
                      description={item.description}
                      icon={item.icon}
                      url={item.url}
                      onClick={() => navigate(`${item.url}`)}
                      alternative
                      inactive
                    />
                  ))
                }
              </CardContainer>
            )
          }
          {comStatus == "Não Comunicado" ? "" : (<ButtonContainer>
            <Button onClick={() => navigate('/cadastro-positivo/abertura')}>Quero ativar o Cadastro Positivo</Button>
          </ButtonContainer>)}
        </>
      )}
    </>
  );
}
