import { Container, Text } from 'components';
import { BreadcrumbContainer } from '../styles';
import useDeviceType from 'hooks/useDeviceType';

type BreadcrumbsProps = {
  menu: string
  submenu?: string
  thirdLevel?: string
}

export const Breadcrumbs = ({ menu, submenu, thirdLevel }: BreadcrumbsProps) => {
  const { deviceType } = useDeviceType();
  
  return (
    <Container>
    <BreadcrumbContainer deviceType={{
          type: deviceType,
        }}>
        <Text >{`${menu}${submenu ? ' >' : ''} `}</Text>
        {submenu && <Text  weight={thirdLevel ? 1 : 2}>{`${submenu}${thirdLevel ? ' >' : ''} `}</Text>}
        {thirdLevel && <Text  weight={2}>{`${thirdLevel}`}</Text>}
      </BreadcrumbContainer>
    </Container>
  );
};
