import styled from 'styled-components';

export const DropdownButton = styled.button`
display: flex;
align-items: center;
${({ theme }) => `
color: ${theme.color.blue['154695']};
   `}
  
  font-size: 18px;
  background: transparent;
  font-weight: bold;
  padding: 10px;
  border: none;
  cursor: pointer;

  & > svg {
    margin-left: 12px;
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;


export const DropdownList = styled.ul`
  position: absolute;
  list-style: none;
  padding: 0;
  background: white;
  margin: 0;
  z-index: 20;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;

export const DropdownListItem = styled.li`
  padding: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;