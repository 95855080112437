/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from 'react'
import { Button } from 'components/Button'
import { Selector } from 'components/Selector'
import { Text } from 'components/Text'
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik'
import { TIPOS_SOLICITACAO, TIPO_DADO } from '..'
import InputModal from '../InputModal'
import { UserDetails } from '../Models/userDetails'
import * as S from './style'
import ReCAPTCHA from 'react-google-recaptcha';
import { sentFile, sentReport } from 'services/SuaConsulta'
import { Parametro, Solicitacao } from '../Models/solicitacao'
import * as Yup from 'yup'
import { LoadingSpinner } from 'components/LoadingSpinner'
import useDeviceType from 'hooks/useDeviceType'


type CorrecaoForm = {
  firstRequest: boolean
  formsRequest: DinamicForm[]
}

type DinamicForm = {
  dataType: string,
  outdateData: string,
  correctData: string,
  file: File | undefined,
  comments?: string
}

const Correcao = ({ dataNascimento, detalheConsumidor, nome }: UserDetails) => {
  const [anexos, setAnexos] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const formSAERef = useRef<HTMLFormElement>(null)
  const [isFormReady, setIsFormReady] = useState(false)
  const [tokenRecapctha, setTokenRecaptcha] = useState('')
  const [formSAE, setFormSAE] = useState({
    retURL: '',
    name: '',
    email: '',
    cpf: '',
    phone: '',
    subject: '',
    description: '',
    type: '',
    motivo: '',
    submotivo: '',
    captcha_settings: '',
    recaptcha_response: '',
    orgId: ''
  })


  useEffect(() => {
    if (isFormReady && tokenRecapctha !== '') {
      formSAERef.current?.submit()
    }
  }, [isFormReady, formSAE, tokenRecapctha])


  const formik = useFormik<CorrecaoForm>({
    initialValues: {
      firstRequest: false,
      formsRequest: [
        {
          dataType: '',
          outdateData: '',
          correctData: '',
          comments: '',
          file: undefined
        }
      ]
    },
    validationSchema: Yup.object().shape({
      formsRequest: Yup.array()
        .of(Yup.object().shape({
          dataType: Yup.string().required('Campo obrigatório'),
          outdateData: Yup.string().required('Campo obrigatório'),
          correctData: Yup.string().required('Campo obrigatório').max(70, 'Limite máximo de 70 caracteres.'),
          comments: Yup.string().max(70, 'Limite máximo de 70 caracteres.'),
          file: Yup.mixed().required('Arquivo obrigatório').
            test({
              message: 'Tipo de arquivo não suportado, por favor, utilize pdf, jpeg, jpg ou png.',
              test: (file, context) => {
                const fileToUpload = file as File

                const isValid = ["application/pdf", "image/jpeg", "image/png", "image/jpg"].includes(fileToUpload?.type)
                if (!isValid) context?.createError();
                return isValid;
              }
            })
            .test({
              message: `O tamanho do arquivo excede o limite de 5mb. Reduza seu tamanho e tente novamente.`,
              test: (file) => {
                const isValid = file?.size < 5138022;
                return isValid;
              }
            })
        }))

    }),
    onSubmit(values, formikHelpers) {
      recaptchaRef.current?.executeAsync().then(async (resp) => {

        const token = resp
        const formSolicitacao = solicitacaoFormFactory(values.firstRequest)
        const parametros: Parametro[] = []
        values.formsRequest.forEach(form => {
          const dataType = {
            campo: `[Correções] Tipo de dado`,
            valor: `${form.dataType}`
          }
          const correctData = {
            campo: `[Correções] Dado correto`,
            valor: `${form.correctData}`
          }
          const outdatedData = {
            campo: `[Correções] Dado desatualizado`,
            valor: `${form.outdateData}`
          }

          const commentsData = {
            campo: `[Correções] Observações`,
            valor: `${form.comments}`
          }
          parametros.push(dataType)
          parametros.push(correctData)
          parametros.push(outdatedData)
          parametros.push(commentsData)
        })
        formSolicitacao.parametros = parametros

        try {
          await sentReport(formSolicitacao)
          setIsFormReady(true)
          setTokenRecaptcha(token!)
          submitFormSAE(token!, parametros!)
        } catch (e) {
          console.log(e)
        }

      })
    },
  })



  const getFormRequestErrors = (index: number) => {
    if (formik?.errors?.formsRequest && formik?.errors?.formsRequest?.length > 0) {
      return formik?.errors?.formsRequest[index] as FormikErrors<DinamicForm>
    }
    return {}
  }

  const fillOutdatedField = (tipoDado: TIPO_DADO, formikField: string) => {
    const options = {
      [TIPO_DADO.NOME]: () => formik.setFieldValue(formikField, nome),
      [TIPO_DADO.TELEFONE]: () => formik.setFieldValue(formikField, detalheConsumidor.telefone),
      [TIPO_DADO.CELULAR]: () => formik.setFieldValue(formikField, detalheConsumidor.celular),
      [TIPO_DADO.ENDERECO]: () => formik.setFieldValue(formikField, detalheConsumidor.endereco),
      [TIPO_DADO.DATA_NASCIMENTO]: () => formik.setFieldValue(formikField, dataNascimento),
      [TIPO_DADO.EMAIL]: () => formik.setFieldValue(formikField, detalheConsumidor.email),
      [TIPO_DADO.CPF]: () => formik.setFieldValue(formikField, detalheConsumidor.cpf),
    }
    return options[tipoDado]()
  }

  /*
  const handleInputFile = async (file: File, index: number) => {
    setLoading(true)
    if(formik.errors.formsRequest && formik.errors.formsRequest[index]) {
      const fileValidationError = (formik?.errors?.formsRequest![index] as FormikErrors<DinamicForm>).file
      if(fileValidationError) {
        return
      }
    }
    try {
      const resp = await sentFile(file)
      setAnexos((oldState) => [...oldState, resp?.anexos[0]])
    } catch(e) {
      console.log('error', e)
    } finally {
      setLoading(false)
    }
  }
  */

  /* New Function */
  const attachFile = async (file: File, index: number) => {
    setLoading(true);

    const result = await formik.setFieldValue(`formsRequest[${index}].file`, file);
    const formikErrors = result as FormikErrors<CorrecaoForm>;
    const isNotValid = formikErrors.formsRequest &&
      (formikErrors.formsRequest[index] as FormikErrors<DinamicForm>).file;

    if (isNotValid) {
      setLoading(false);
      return;
    }

    try {
      const rsp = await sentFile(file);
      setAnexos((oldState) => [...oldState, rsp?.anexos[0]]);
    } catch (error) {
      // TODO: Tratar Erro
      console.log('Erro ao tentar enviar o e-mail!', error);
    } finally {
      setLoading(false);
    }
  }

  const solicitacaoFormFactory = (firstRequest: boolean) => {
    const solicitacaoForm: Solicitacao = {
      anexos,
      email: detalheConsumidor.email,
      idTipoSolicitacao: TIPOS_SOLICITACAO.CORRECAO,
      primeiraSolicitacao: firstRequest,
      resumoSolicitacao:
        `${TIPOS_SOLICITACAO.CORRECAO} - ${nome} - ${detalheConsumidor.cpf} - ${!firstRequest ? 'Primeira solicitação' : 'solicitação'}`,
      parametros: []
    }
    return solicitacaoForm
  }

  const submitFormSAE = (token: string, parametros: Parametro[]) => {
    const description = formatDescription(parametros)
    const CAPTCHA_SETTINGS = {
      fallback: true,
      orgId: '00D1U000000FwcW',
      keyname: 'Captcha_V3',
      ts: new Date().getTime()

    }
    setFormSAE({
      retURL: `${window.location.href}?registroSAE=true`,
      name: nome,
      email: detalheConsumidor.email,
      cpf: detalheConsumidor.cpf,
      phone: detalheConsumidor.telefone,
      subject: `CORREÇÃO - ${nome} - ${detalheConsumidor.cpf} -
      ${!formik.values.firstRequest ? 'Primeira solicitação' : ''}`,
      description,
      type: "Solicitação",
      motivo: 'CORREÇÃO',
      captcha_settings: JSON.stringify(CAPTCHA_SETTINGS),
      recaptcha_response: token,
      orgId: '',
      submotivo: ''
    })
  }

  const formatDescription = (parametros: Parametro[]) => {
    const format = parametros.map(parametro => `${parametro.campo} - ${parametro.valor}.`)
    return format.join('')
  }
  const { deviceType } = useDeviceType();

  return (
    <>
      <S.ContainerText>
        <Text tag='h4' size={2} weight={4}>Solicitação para correção</Text>
        <Text color='text'>
          O SPC BRASIL respeita a sua privacidade e a segurança dos seus dados pessoais.
          Com isso, todos os nossos processos de tratamento de dados pessoais estão em plena conformidade
          com a Lei nº. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais – LGPD).
          Para saber mais sobre as nossas práticas de privacidade e proteção de dados clique aqui.
        </Text>
        <Text color='text'>
          Para que o seu pedido de correção seja analisado,
          é imprescindível que você nos indique por quais motivos os seus dados estão incompletos,
          incorretos ou desatualizados na base do SPC Brasil. Além disso,
          é obrigatório que os seguintes documentos sejam enviados,
          para realizarmos as devidas correções:
        </Text>
        <ul>
          <li>
            <Text>
              RG, CNH, Passaporte ou a sua Carteira de Trabalho –
              para atualizações relacionadas ao seu nome, data de nascimento e número do CPF.
            </Text>
          </li>
          <li>
            <Text>
              Comprovante de residência em seu nome e com emissão de no máximo 06 (seis) meses –
              para atualizações relacionadas a informações de endereço.</Text>
          </li>
        </ul>
        <Text>
          Utilize o campo “Anexar arquivo” para incluir os documentos necessários. Campos com (*) são obrigatórios.
        </Text>
        <Text>
          Caso você tenha identificado que os seus dados pessoais estão incompletos, inexatos ou desatualizados, por gentileza, nos informe:
        </Text>
        <FormikProvider value={formik}>
          <S.FieldSet>
            <form onSubmit={formik.handleSubmit}>
              <S.FirstRequest>
                <Text size={0} >Esta não é minha primeira solicitação.</Text>
                <input type="checkbox" id='firstRequest' name='firstRequest'
                  onChange={formik.handleChange} />
              </S.FirstRequest>
              <FieldArray name="formsRequest" render={(arrayHelpers) => (
                <div>
                  {
                    formik.values.formsRequest?.map((formRequest, index) => {

                      return (
                        <S.DinamicForm key={index}>
                          <S.FormContainer deviceType={{ type: deviceType }}>
                            <S.FieldWrapper deviceType={{ type: deviceType }}>
                              <Text size={0}>Tipo de dado</Text>
                              <Selector
                                name={`formsRequest[${index}].dataType`}
                                options={[
                                  { label: 'Nome', value: TIPO_DADO.NOME },
                                  { label: 'Telefone', value: TIPO_DADO.TELEFONE },
                                  { label: 'Celular', value: TIPO_DADO.CELULAR },
                                  { label: 'Endereço', value: TIPO_DADO.ENDERECO },
                                  { label: 'Data de nascimento', value: TIPO_DADO.DATA_NASCIMENTO },
                                  { label: 'Email', value: TIPO_DADO.EMAIL },
                                  { label: 'CPF', value: TIPO_DADO.CPF },
                                ]}
                                value={formik.values.formsRequest[index].dataType}
                                onSelect={(e) => {
                                  formik.setFieldValue(`formsRequest.${index}.dataType`, e.value)
                                  fillOutdatedField(e.value as TIPO_DADO, `formsRequest[${index}].outdateData`)
                                }}
                              />
                              <S.ErrorMessage>{getFormRequestErrors(index)?.dataType}</S.ErrorMessage>
                            </S.FieldWrapper>
                            <S.FieldWrapper deviceType={{ type: deviceType }}>
                              <Text size={0}>Dado desatualizado</Text>
                              <InputModal
                                name={`formsRequest[${index}].outdateData`}
                                value={formik.values.formsRequest[index].outdateData}
                                disabled={true}
                                onChange={formik.handleChange}
                              />

                              <S.ErrorMessage>{getFormRequestErrors(index)?.outdateData}</S.ErrorMessage>

                            </S.FieldWrapper>
                            <S.FieldWrapper deviceType={{ type: deviceType }}>
                              <Text size={0}>Dado correto</Text>
                              <InputModal
                                name={`formsRequest[${index}].correctData`}
                                value={formik.values.formsRequest[index].correctData}
                                onChange={formik.handleChange}
                              />

                              <S.ErrorMessage>{getFormRequestErrors(index)?.correctData}</S.ErrorMessage>
                            </S.FieldWrapper>
                            <S.TextboxWrapper>
                              <Text size={0}>Observações</Text>
                              <S.TextAreaModal
                                name={`formsRequest[${index}].comments`}
                                value={formik.values.formsRequest[index].comments}
                                onChange={formik.handleChange}
                              />

                              <S.ErrorMessage>{getFormRequestErrors(index)?.comments}</S.ErrorMessage>
                            </S.TextboxWrapper>
                            <S.FieldWrapper deviceType={{ type: deviceType }}>
                              <Text size={0}>Anexar arquivo</Text>
                              <InputModal
                                type='file'
                                accept='.pdf, .jpg, .jpeg, .png'
                                name={`formsRequest[${index}].file`}
                                //value={formik.values.formsRequest[index].file}
                                onChange={(event) => attachFile(event.currentTarget.files![0], index)}
                              /*
                              onChange={async (event) => {
                                  formik.setFieldValue(`formsRequest.${index}.file`, event.currentTarget.files![0])
                                  handleInputFile(event.currentTarget.files![0], index)
                                }
                              
                              } */
                              />

                              <S.ErrorMessage>{getFormRequestErrors(index)?.file}</S.ErrorMessage>
                            </S.FieldWrapper>
                          </S.FormContainer>
                          <S.DeleteWrapper>
                            <Button
                              type='button'
                              btnStatus='delete'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <S.IconDelete />
                            </Button>
                          </S.DeleteWrapper>
                        </S.DinamicForm>
                      )
                    })
                  }
                  <S.AddFormWrapper deviceType={{ type: deviceType }}>
                    <Button
                      btnStatus='primary'
                      type='button'
                      onClick={() => arrayHelpers.push({
                        dataType: '',
                        outdateData: '',
                        correctData: '',
                        file: undefined,
                        comments: ''
                      })}
                    >
                      Mais campos {<S.IconAdd />}
                    </Button>
                  </S.AddFormWrapper>
                </div>
              )} />
              <S.AddFormWrapper deviceType={{ type: deviceType }}>
                <S.Submit disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting} type='submit'>Enviar</S.Submit>
              </S.AddFormWrapper>
            </form>
          </S.FieldSet>
        </FormikProvider>
        <ReCAPTCHA
          sitekey="6LcoYpkiAAAAAN5JHzikMmBCXXkUd9-gMxu_2VAu"
          size='invisible'

          ref={recaptchaRef}
        />

        <form name="formSAE" ref={formSAERef} id="formSAE"
          method="POST" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8">
          <input type="hidden" id="00N1U00000USpvb" name="00N1U00000USpvb" title="Area" value="SAE - Consumidor" />
          <input type="hidden" id="00N1U00000USpw6" name="00N1U00000USpw6" value="LGPD (Atendimento ao titular)" />
          <input value={formSAE.motivo} type="hidden" id="00N1U00000USpw4" name="00N1U00000USpw4" />
          <input value={formSAE.submotivo} type="hidden" id="00N1U00000USpw9" name="00N1U00000USpw9" />
          <input type="hidden" name="orgid" value="00D1U000000FwcW" />
          <input value={formSAE.retURL} type="hidden" name="retURL" />
          <input value={formSAE.cpf} type="hidden" id="00N1U00000USpvk" name="00N1U00000USpvk" />
          <input value={formSAE.name} type="hidden" id="name" name="name" />
          <input value={formSAE.email} type="hidden" id="email" name="email" />
          <input value={formSAE.phone} type="hidden" id="phone" name="phone" />
          <input value={formSAE.subject} type="hidden" id="subject" name="subject" />
          <input value={formSAE.description} type="hidden" id="description" name="description" />
          <input value={formSAE.type} type="hidden" id="type" name="type" />
          <input value={formSAE.captcha_settings} type="hidden" name="captcha_settings" id="captcha_settings" />
          <input value={formSAE.recaptcha_response} type="hidden" name="g-recaptcha-response" id="g-recaptcha-response" />
          <input type="hidden" id="external" name="external" value="1" />
        </form>

      </S.ContainerText>
      <LoadingSpinner state={loading} />
    </>
  )
}
export default Correcao
