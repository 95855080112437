import { Button, Text } from 'components';
import { useEffect, useState } from 'react';
import { ModalProps } from 'types';

import {
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  StyledModal,
} from './styles';
import useDeviceType from 'hooks/useDeviceType';

const Modal = ({
  alignModalFooter = 'flex-end',
  reverseFooterButtons,
  children,
  isOpen = false,
  onToggleModal,
  primaryActionButton,
  secondaryActionButton,
  title,
}: ModalProps) => {
  const [statusModal, setStatusModal] = useState(isOpen);

  const handleToggleModal = (modalStatus: boolean) => {
    onToggleModal();
    setStatusModal(modalStatus);
  };

  const handlePrimaryAction = () => {
    primaryActionButton?.action && primaryActionButton.action();
    primaryActionButton?.closeOnclick && handleToggleModal(false);
  };

  const handleSecondaryAction = () => {
    secondaryActionButton?.action && secondaryActionButton?.action();
    secondaryActionButton?.closeOnclick && handleToggleModal(false);
  };
  const { deviceType } = useDeviceType();

  useEffect(() => {
    setStatusModal(isOpen);
  }, [isOpen]);

  return (<StyledModal isOpen={statusModal} deviceType={{ type: deviceType }}>
    <ModalWindow role="dialog" aria-labelledby="modalTitle" deviceType={{ type: deviceType }} >
      <ModalContainer deviceType={{ type: deviceType }}>
        <ModalHeader deviceType={{ type: deviceType }}>
          <Text id="modalTitle" size={2} weight={3}>
            {title}
          </Text>
          <ModalClose role="button" aria-label="Fechar modal" onClick={() => handleToggleModal(false)} />
        </ModalHeader>
        <ModalContent deviceType={{ type: deviceType }}>{children}</ModalContent>
        <ModalFooter deviceType={{ type: deviceType }} align={alignModalFooter} reverseButtons={reverseFooterButtons}>
          {((primaryActionButton?.action) != null) && (
            <Button disabled={primaryActionButton.disabled} onClick={handlePrimaryAction}>
              {primaryActionButton?.label ?? 'Confirmar'}
            </Button>
          )}
          {((secondaryActionButton?.action) != null) && (
            <Button btnStyle="outline" disabled={secondaryActionButton.disabled} onClick={handleSecondaryAction}>
              {secondaryActionButton?.label ?? 'Cancelar'}
            </Button>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalWindow>
  </StyledModal>);
};

export { Modal };
