import { Container, LinkCard, LoadingSpinner, PageSkeleton, QueryInfoList } from 'components';
import { ActionContainer, QueryContainer, SearchContainer, SearchField, Title, TitleContainer } from './styles';
import { UnlockIcon } from 'components/Icon';
import { useEffect, useState } from 'react';
import { SearchIcon } from 'components/Header/styles';
import useQueriesAccomplished from 'hooks/useQueriesAccomplished';
import MessageAlert from 'components/MessageAlert';
import useDeviceType from 'hooks/useDeviceType';

interface Consulta {
  title: string
  description: string
  hasTime: boolean
  time: string
}
interface QueryList {
  day: number
  month: string
  query: Consulta[]
}

export default function Historico() {

  const queriesList = useQueriesAccomplished();
  const [filteredList, setFilteredList] = useState<QueryList[]>([]);
  const [search, setSearch] = useState<string>('');

  const list = window.localStorage.getItem('SPC_Historico_consulta_List');

  useEffect(() => {
    filterResults();
    if (list !== null) setFilteredList(JSON.parse(list));
  }, [search, list]);

  const filterResults = async () => {
    const finalFilter: QueryList[] = [];

    await Promise.all(
      queriesList?.map(async (item: any) => {
        const filtered = await item.query.filter(
          (consulta: Consulta) => consulta.title.toUpperCase().includes(search.toUpperCase()),
        );

        const filteredCnpj = await item.query.filter(
          (consulta: Consulta) => (consulta.description) ? consulta.description.replace('.', '').replace('/', '').replace('-', '').includes(search.replace('.', '').replace('/', '').replace('-', '')) : '',
        );

        if (filtered.length > 0) {
          finalFilter.push({ ...item, query: filtered });
        } else if (filteredCnpj.length > 0) {
          finalFilter.push({ ...item, query: filteredCnpj });
        }
        setFilteredList(finalFilter);
      },
      ),
    ).then(() => setFilteredList(finalFilter));
  };

  const isFilterActive = !!search;
  const { deviceType } = useDeviceType();

  return (
    <Container>
      <div>
        <LoadingSpinner state={filteredList === null ? true : false} />
      </div>
      <div >
        <PageSkeleton
          title="Histórico de Consulta"
          breadcrumb={{
            menuName: 'Início',
            submenuName: 'Cadastro Positivo',
            thirdLevel: 'Histórico de Consulta',
          }}
          highlight={{
            title: ` Aqui você pode visualizar as informações de todas as empresas que consultaram os
            seus dados positivos nos últimos 6 meses. `,
            description: ` Acompanhe os nomes e CNPJ das empresas que consultaram, além das datas e dos horários das consultas. `,
            image: require('../../../assets/images/historico-1.png'),
          }}
        >
          <QueryContainer>
            <Title tag="h4" size={2} weight={3} >
              Consultas realizadas
            </Title>
            <SearchContainer>
              <SearchField deviceType={{ type: deviceType }}>
                <SearchIcon />
                <input value={search} placeholder='Buscar por nome ou CNPJ' onChange={(e) => setSearch(e.target.value)} />
              </SearchField>
            </SearchContainer>
            {(filteredList?.length > 0)
              ?
              (filteredList?.map((item, index) => (
                <QueryInfoList
                  key={index}
                  day={item.day}
                  month={item.month}
                  queryList={item.query}
                />
              ))
              )
              :
              (<div style={{ width: deviceType === "mobile" ? "100%" : "80%" }}>
                <MessageAlert data={filteredList} filter={isFilterActive && true} />
              </div>
              )}
          </QueryContainer>
          <ActionContainer deviceType={{ type: deviceType }}>
            <TitleContainer deviceType={{ type: deviceType }}>
              <Title size={2} tag="h4" weight={3} lineHeight={1}>
                Deseja que uma empresa possa ver seus pagamentos?
              </Title>
            </TitleContainer>
            <LinkCard
              title='Liberação aos Pagamentos'
              description='Autorize empresas à acessarem seu histórico de pagamentos.'
              url='/cadastro-positivo/liberacao-historico'
              icon={<UnlockIcon />}
              alternative
            />

          </ActionContainer>
        </PageSkeleton>
      </div>
    </Container>
  );
}
