import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StatusContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;
  gap: 12px;
  
  p {
    font-weight: 400;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 2)}` : 'inherit'};

    b {
      font-weight: 500;
    }
  }

  ${({ theme }) => `
    color: ${theme.color.darkGray['797979']};
  `}
`;

export const ContentContainerBloqueio = styled.div<{ deviceType?: DeviceTypeProps }>`
    margin: 8px 16px;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};

`;

export const Dot = styled.div<{ color: string, deviceType?: DeviceTypeProps  }>`
  width: 18px;
  height: 18px;

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
        width: 24px;
      `;
  }}
  border-radius: 50%;
  ${({ color }) => `
    background: ${color};
  `}
`;

export const ScoreStatus = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: end;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    ${({ color }) => `
      color: ${color};
    `}
  }

  p {
    margin: 0;
  }
`;
