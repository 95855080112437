import { Footer, Header, LoadingSpinner, StatusAlert } from 'components';
import { AuthContext, RequestStatusContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { getMenuItems, getMenuItemsCancelado } from './menus';
import 'moment/locale/pt-br';
import moment from 'moment';
import { Signature as signatureService } from 'services/Cadastro/signature';
import useDeviceType from 'hooks/useDeviceType';

const Layout = ({ children }: any) => {
  const { deviceType } = useDeviceType();
  const { isAuthenticated, userData } = useContext(AuthContext);
  const { requestStack } = useContext(RequestStatusContext);
  const [menu, setMenu] = useState<any>();
  const [isLoading, setLoading] = useState(false);

  const storedStatus = localStorage?.getItem('SPC_StatusCadPos') || '';
  const documentType = localStorage.getItem('SPC_DocumentType') || "";

  const handleStatus = () => {
    if (storedStatus) {
      try {
        const status = JSON.parse(storedStatus);
        if (status) {
          setMenu((status == "ATIVO") ? getMenuItems(documentType, deviceType) : getMenuItemsCancelado(documentType, deviceType));
        }
      } catch (error) {
        console.error("Erro ao parsear o valor armazenado:", error);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      if (userData.cpf !== undefined) {
        signatureService.situacao('F', userData.cpf)
          .then((res: any) => {
            setMenu((res.data == "ATIVO") ? getMenuItems(documentType, deviceType) : getMenuItemsCancelado(documentType, deviceType));
            setLoading(false);
          });
      }
      if (userData.cnpj !== undefined) {
        signatureService.situacao('J', userData.cnpj)
          .then((res: any) => {
            setMenu((res.data == "ATIVO") ? getMenuItems(documentType, deviceType) : getMenuItemsCancelado(documentType, deviceType));
            setLoading(false);
          });
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    handleStatus()
  }, [storedStatus]);

  moment.locale('pt-br');
  return (
    <>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <Router>
        <div className='rootComponent'>
          <Header
            loggedIn={isAuthenticated}
            menu={menu}
            username={
              userData?.tipoConsumidor === 'PF'
                ? userData?.nome
                : userData?.nomeEmpresarial
            }
            documentRef={
              userData?.tipoConsumidor === 'PF'
                ? userData?.cpf
                : userData?.cnpj
            }
          />
          {children}
          {
            requestStack.map((status) => (
              <StatusAlert
                label={status.message}
                status={status.statusColor}
                showAlert
              />
            ))
          }
          <Footer />
        </div>
      </Router>
    </>);
};

export default Layout;
