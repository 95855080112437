export const formatDate = (e: any) => {
  const input = e.target;
  const len = input.value.length;

  if (e.keyCode !== 8 && (len === 2 || len === 5)) {
    input.value += '/';
  } else if (e.keyCode === 8 && (len === 4 || len === 7)) {
    input.value = input.value.slice(0, -1);
  }
};
