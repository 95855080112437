export const FilterIcon = ({ ...others }) => (

  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.30015 6.89472C5.85269 6.89472 7.11128 5.63613 7.11128 4.08359C7.11128 2.53105 5.85269 1.27246 4.30015 1.27246C2.74761 1.27246 1.48902 2.53105 1.48902 4.08359C1.48902 5.63613 2.74761 6.89472 4.30015 6.89472Z" stroke="currentColor" strokeWidth="1.40556" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.11127 4.08398H21.1669" stroke="currentColor" strokeWidth="1.40556" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.3558 15.3279C16.8032 15.3279 15.5447 16.5865 15.5447 18.1391C15.5447 19.6916 16.8032 20.9502 18.3558 20.9502C19.9083 20.9502 21.1669 19.6916 21.1669 18.1391C21.1669 16.5865 19.9083 15.3279 18.3558 15.3279Z" stroke="currentColor" strokeWidth="1.40556" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.5447 18.1396L1.48902 18.1396" stroke="currentColor" strokeWidth="1.40556" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.328 13.9221C12.8805 13.9221 14.1391 12.6635 14.1391 11.1109C14.1391 9.55839 12.8805 8.2998 11.328 8.2998C9.77542 8.2998 8.51684 9.55839 8.51684 11.1109C8.51684 12.6635 9.77542 13.9221 11.328 13.9221Z" stroke="currentColor" strokeWidth="1.40556" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.48902 11.1104H8.51684" stroke="currentColor" strokeWidth="1.40556" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1391 11.1104L21.1669 11.1104" stroke="currentColor" strokeWidth="1.40556" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
