import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const FooterTermContainer = styled.div<{ deviceType?: DeviceTypeProps, }>`
 width: 100%;
 display: flex;
 align-items: start;
 justify-content: center;
 flex-direction: column;
`;
