import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const DetailsContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme }) => `
    color: ${theme.color.neutral['586682']};
  `}
  margin: 0px 10px 20px;
  width: 90%;

  & h3 {
    font-weight: 700;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 2)}` : 'inherit'};
  };

  & h4 {
    font-weight: 600;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};;
    margin-bottom: 0;
  };

  & p {
    font-weight: 400;
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};;
    line-height: 28px;
    margin: 0;
  };

`;
