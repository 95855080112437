export const documentFormatter = (document: string) => {
  let docNumber = document;

  if ([10, 13].includes(docNumber.length)) {
    docNumber = '0' + docNumber;
  }

  if (docNumber.length === 11) {
    return docNumber.slice(0, 3) + '.' + docNumber.slice(3, 6) + '.' + docNumber.slice(6, 9) + '-' + docNumber.slice(-2);
  }
  if (docNumber.length === 14) {
    return docNumber.slice(0, 2) + '.' + docNumber.slice(2, 5) + '.' + docNumber.slice(5, 8) + '/' + docNumber.slice(8, 12) + '-' + docNumber.slice(-2);
  }
};
