import React from 'react'
import { DetailBlock } from 'pages/Cadastro/HistoricoPagamentos/components/OperationDetails/style'
import { documentFormatter } from 'utils/documentFormatter'
import { formatUnixTime } from 'utils/formatUnixTime'
import useDeviceType from 'hooks/useDeviceType'

const Energia = ({ details, payments, openParcels, modalityDictionary }: any) => {
  const { deviceType } = useDeviceType();
  return (
    <>
      <DetailBlock deviceType={{ type: deviceType }}>
        <h1>Dados da Fonte de Informação</h1>
        <p>
          <b>Nome da fonte: </b>
          <span>{details?.nomeComercialFonteOrigem ?? ''}</span>
        </p>
        <p>
          <b>CNPJ da fonte: </b>
          <span>{details?.cnpjFonteOrigemFormatado ?? (documentFormatter(details?.cnpjFonteOrigem?.toString() ?? ''))}</span>
        </p>
      </DetailBlock>
      <DetailBlock deviceType={{ type: deviceType }}>
        <h1>Dados do Contrato</h1>
        <p>
          <b>Natureza da Operação: </b>
         <span>Distribuidoras de Energia Elétrica</span>
        </p>
        <p>
          <b>Data da contratação: </b>
          <span>{formatUnixTime(details?.dataContratacao ?? '')}</span>
        </p>
        <p>
          <b>Data de Contratação Inicial: </b>
          <span>{formatUnixTime(details?.dataContratacao ?? '')}</span>
        </p>
        <p>
          <b>Número do contrato: </b>
          <span>{details?.numeroContrato}</span>
        </p>
        <p>
          <b>Data da última atualização: </b>
          <span>{formatUnixTime(details?.dataAlteracao)}</span>
        </p>
      </DetailBlock>
    </>
  )
}

export default Energia
