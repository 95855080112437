import styled, { css } from 'styled-components';

export const StyledCheckbox = styled.div`
  position: relative;
`;

export const RadioOptionsContainer = styled.div<{horizontal?: boolean}>`
  display: flex;
  flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
  gap: 14px;
`;

export const RadioOption = styled.label`
  ${({ theme }) => css`
    align-items: center;
    position: relative;
    cursor: pointer;
    display: flex;
    user-select: none;
    padding-left: 32px;
   
    input {
      width: 16px;
      height: 16px;
      -moz-appearance: none;

      left: 0;
      position: absolute;
      top: -2px;
      width: 0;

      :checked {
        + p {
          color: ${theme.color.blue['144695']};
        }

        ::before {
          background-color: ${theme.color.blue['144695']};
        }

        ::after {
          width: 10px;
          height: 5px;
          content: ' ';
          display: block;
          position: absolute;
          border: 2px solid ${theme.color.white['FFFFFF']};
          transform: rotate(135deg);
          border-bottom: none;
          border-left: none;
          left: 5px;
          top: 5px;
        }
      }

      ::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 16px !important;
        height: 16px !important;
        border-radius: 4px;
        border: 1px solid ${theme.color.neutral['586682']};
      }
    }
  `}
`;
