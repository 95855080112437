import {
  FilterContainer,
  MessageAlertContainer,
  PaymentCard,
  PaymentInfo,
  PaymentNumber,
  PaymentSubtypes,
  PaymentTypes,
  ShowMore,
  StyledPaymentHistory,
  SubtypeButton,
  TypeButton,
} from './styles';
import { ArrowLeftIcon } from 'components/Icon/ArrowLeftIcon';
import { ArrowDownIcon } from 'components/Icon/ArrowDownIcon';
import { useContext, useEffect, useState } from 'react';
import { ConfirmationBox, LoadingSpinner, Text } from 'components';
import { AuthContext, CadastroContext } from 'contexts';
import { PaymentHistoryFilter } from 'contexts/types';
import { Filter } from '../Filter';
import { OperationInfo } from '../..';
import { useNavigate } from 'react-router-dom';
import MessageAlert from 'components/MessageAlert';
import { Dropdown, DropdownItem } from 'components/Dropdown';
import useDeviceType from 'hooks/useDeviceType';

const types = [
  { label: 'Instituições Financeiras', value: 'operations' },
  { label: 'Telecomunicações', value: 'telecom' },
  { label: 'Energias', value: 'energy' },
];
const categories = [
  { label: 'Todos', value: null },
  { label: 'Cartão de crédito', value: 'CARTAO DE CREDITO' },
  { label: 'Consórcio', value: 'CONSORCIO' },
  { label: 'Crédito parcelado', value: 'CREDITO PARCELADO' },
  { label: 'Crédito rotativo', value: 'CREDITO ROTATIVO' },
];

const PaymentHistory = ({ changePage }: { changePage: (opertationInfo: OperationInfo) => void }) => {
  const { userData } = useContext(AuthContext);
  const { getPaymentHistory, paymentHistory, isLoading, setLoading } = useContext(CadastroContext);
  const [type, setType] = useState('operations');
  const [filters, setFilters] = useState<PaymentHistoryFilter>({
    nome: '',
    dataInicioFiltro: '',
    dataFimFiltro: '',
    documento: '',
    tipoCliente: '',
    apenasContestadas: false,
    tipoOperacao: null,
    maximoPorPagina: 4,
    numeroPagina: 1,
    permiteContestadas: true,
  });

  const [notFilter, setNotFilter] = useState<any>();

  const [loadingStatus, setLoagingStatus] = useState(Boolean);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState<DropdownItem | null>(null);
  const navigate = useNavigate();

  const { dataFimFiltro, dataInicioFiltro, nome, tipoOperacao } = filters;
  const isFilterActive = !!dataFimFiltro || !!dataInicioFiltro || !!nome || !!tipoOperacao;

  const resetNotFilter: any = () => {
    setNotFilter(false);
  };

  const { deviceType } = useDeviceType();


  useEffect(() => {
    setLoagingStatus(isLoading ?? false);
  }, [isLoading, filters]);

  useEffect(() => {
    if (filters.documento?.length > 0) {
      setLoading(true);
      getPaymentHistory(
        type,
        { ...filters, tipoOperacao: type === 'operations' ? filters.tipoOperacao : null },
      );
    }
  }, [type, filters]);

  useEffect(() => {
    if (userData) {
      setFilters({
        ...filters,
        documento: (userData.cpf !== "" && userData.cpf !== undefined) ? userData.cpf : userData.cnpj,
        tipoCliente: userData.tipoConsumidor,
      });
    }
  }, [userData]);

  const getModalFilters = (modalType: string, modalFilters: PaymentHistoryFilter) => {
    if (modalType !== '') {
      setType(modalType);
    }
    setFilters({ ...filters, ...modalFilters });
  };

  const handleContest = () => {
    navigate(`../realizar-contestacao?type=client`);
  };

  const handleDropdownSelect = (item: DropdownItem) => {
    setSelectedDropdownItem(item);
    setType(item.value);
  };

  return (
    <StyledPaymentHistory deviceType={{ type: deviceType }}>
      <div>
        <LoadingSpinner state={loadingStatus} />
      </div>
      {deviceType !== 'mobile' ? (
        <PaymentTypes>
          {types.map((item, index) => (
            <TypeButton
              key={index}
              selected={item.value === type}
              onClick={() => setType(item.value)}
            >
              {item.label}
            </TypeButton>
          ))}
        </PaymentTypes>
      ) : (
        <Dropdown items={types} selectedItem={selectedDropdownItem} onSelect={handleDropdownSelect} />
      )}


      <FilterContainer deviceType={{ type: deviceType }}>

        
          <PaymentSubtypes deviceType={{ type: deviceType }}>
            { type === 'operations' && categories.map((item, index) => (
              <SubtypeButton
                deviceType={{ type: deviceType }}
                key={index}
                selected={item.value === filters.tipoOperacao}
                onClick={() => {
                  setFilters({ ...filters, tipoOperacao: item.value })
                  setNotFilter(true)
                }}
              >
                {item.label}
              </SubtypeButton>),
            )}
          </PaymentSubtypes>
        <Filter sendFilters={getModalFilters} resetNotFilter={resetNotFilter} />

      </FilterContainer>
      {paymentHistory?.itens && paymentHistory.itens.length > 0 ? paymentHistory.itens.map((payment, index) => (
        <PaymentCard deviceType={{ type: deviceType }} key={index} onClick={() => changePage({
          id: payment.idOperacao,
          type: type,
          docType: payment.tipoDocumentoConsumidor,
          document: payment.numeroDocumentoConsumidor,
          source: payment.idFonte,
          contract: payment.numeroContrato,
        })}>
          <PaymentInfo>
            {payment.modalidadeTexto === "Telcos" ? "Operadoras de Telecomunicações" : payment.modalidadeTexto}
            <h4>{payment.nomeFonteOrigem}</h4>
          </PaymentInfo>
          <PaymentNumber deviceType={{ type: deviceType }}>
            <h4>Número do contrato: <b>{payment.numeroContrato}</b> </h4>
            <ArrowLeftIcon />
          </PaymentNumber>
        </PaymentCard>
      )) :
        (
          <MessageAlertContainer deviceType={{ type: deviceType }}>
            <MessageAlert data={paymentHistory.itens} filter={!notFilter && isFilterActive} />
          </MessageAlertContainer>
        )}
      {paymentHistory.numeroTotalItens > filters.maximoPorPagina &&
        <ShowMore onClick={() => setFilters({ ...filters, maximoPorPagina: filters.maximoPorPagina + 4 })}>
          Ver Mais
          <ArrowDownIcon fill='red' />
        </ShowMore>
      }

      {paymentHistory.itens.length == 0 ? "" :
        <ConfirmationBox
          message="Os dados do histórico de pagamentos exibidos acima estão corretos?"
          onDeny={handleContest}
        />
      }
    </StyledPaymentHistory>
  );
};

export { PaymentHistory };
