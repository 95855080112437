import styled from 'styled-components'

export const StyledLoadingSpinner = styled.div`
  background-color: white;
  opacity: 0.95;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  z-index: 99;
`

export const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
`;

export const LoadingMessage = styled.div`
  margin: 2rem 20px 0;
`;