import React from 'react'
import {
  AlertContainer,
} from './styles';
import { Text } from 'components';
import useDeviceType from 'hooks/useDeviceType';


const MessageAlert = ({ data, filter }: any) => {
  
  const { deviceType } = useDeviceType();

   if (!data || data.length === 0 && !filter) {
    return (
      <AlertContainer deviceType={{ type: deviceType }} >
        <Text size={1} lineHeight={1} weight={2}>
          Não há dados a serem exibidos.
        </Text>
      </AlertContainer>
    );
  }

  if (filter) {
    return (
      <AlertContainer deviceType={{ type: deviceType }}>
        <Text size={1} lineHeight={1} weight={2}>
          Não encontrado resultado para o filtro informado.
        </Text>
      </AlertContainer>
    );
  }

  return null;
}

export default MessageAlert;
