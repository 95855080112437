import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledContainer = styled.div`
  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    padding: 121px 148px;
    margin: 0 96px;
  `}
`;

export const BoxHighlight = styled.div`
  ${({ theme }) => `
    align-items: center;
    background: ${theme.color.white['F4F7FC']};
    border-radius: 25.53px;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 56px;
    padding: 32px;
  `}
`;

export const TextContent = styled.div`
  margin-left: 45px;
  `;


export const FilterContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  margin: 10px auto;
  
  ${({ deviceType }) => {
    if (deviceType?.type === "tablet" || deviceType?.type == 'laptop') {
      return `
    `
    }
    else if (deviceType?.type === "mobile") {
      return `
    margin: 20px 0px 0px 10px;
      `
    }
  }}

  `;


export const Title = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  text-align: left;
  
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      margin-left: 10px;
      `;
  }}

`;

export const Footer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 28px;
  margin: 24px 0;
  ${({ theme }) => css`
    background: ${theme.color.white['FFFFFF']};
    border-radius: 12px;
    border: 1px solid ${theme.color.lightGray['E5E5E5']};
  `}

  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      width: 65vw;
      margin-left: 8px;
      `;
  }}
`;

export const AlertContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme, deviceType }) => css`
    width: auto;
    padding: ${deviceType?.type === 'mobile' ? "10px" :"16px 8px"};
    border-radius: 16px;
    background: ${theme.color.lightGray['D5DDED']}3F;
    border: 1px solid ${theme.color.lightGray['BCBCBC']};
    display: flex;
    align-items: center;
    margin: 24px 0;
    justify-content: center;
  `}
`;

export const Container = styled.div<{ deviceType?: DeviceTypeProps }>`

  `

export const TextAreaContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display:flex;
  justify-content:center;
  `

export const HistoryContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  overflow: auto;
  max-height: 70vh;
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
    margin: 0px 15px;
      `;
  }}

`;

export const SearchContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  margin: 20px 0px;
  padding: 2rem;
  background: #F4F7FC;

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
    margin: 10px 0px;
    padding: 0.82rem;
      ;
      `;
  }}

  `;

export const StyledCardContainer = styled.div`
   padding: 2rem 2rem;
   margin-bottom: 1rem;
   border: 1px solid #E5E5E5;
   border-radius: 4px;
`;

export const StyledCard = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid '#BCBCBC';
`;

export const PaginateResponsive = styled.div<{ deviceType?: DeviceTypeProps }>`
border-radius: 2px;
border: 1px solid #BCBCBC;
text-align: center;

${({ deviceType }) => `
.pagination a {
  display: flex;
  height: 15px;
  width: 8px;
  align-items:center;
  justify-content:center;
}
`}

`;

export const CompaniesContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0px;
  ul {
    margin: 0 !important;
    padding: 0 !important;
  }

  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
        margin: 20px 0px;
        gap: 10px;
        flex-direction: column;

        & > button {
          width: 100%;
          margin-top: 20px;
        }
      `;
  }}
  ${({ deviceType }) => {
    if (deviceType?.type == 'tablet')
      return `
    gap: 10px;
    margin: 25px 0px;

      `;
  }}
  `;

