import { api } from './api';

export const score = {
  getPf: async (cpf: any) => await api()
    .get(`/cadastropositivo-consulta-service/consulta/score-positivo-cpf/?cpf=${cpf}`),  
  getPj: async (cnpj: any) => await api()
    .get(`/cadastropositivo-consulta-service/consulta/score-positivo-cnpj/?cnpj=${cnpj}`),

  getFactors: async (score: any) => {
    try {
      const res = await api().get(
        `/cadastropositivo-bloqueio-desbloqueio-score/motivo/${score}`,
      );

      return res.data;
    } catch (err) {
      return { error: 'something went wrong' };
    }
  },

  getImproveTips: async () => {
    try {
      const res = await api().get(
        `/cadastropositivo-bloqueio-desbloqueio-score/dicas/`,
      );

      return res.data;
    } catch (err) {
      return { error: 'something went wrong' };
    }
  },
};


