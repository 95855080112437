import { useContext, useEffect, useState } from "react";
import { LoadingMessage, LoadingWrapper, StyledLoadingSpinner } from './styles';
import ClipLoader from "react-spinners/ClipLoader";
import { Text } from 'components';
import { CadastroContext } from 'contexts';

const LoadingSpinner = (props: any) => {
  const { loading } = useContext(CadastroContext);
  const { state } = props
  const [loadingState, setLoadingState] = useState(true);

  useEffect(() => {
    if (loading) {
      setLoadingState(loading)
    }
    setLoadingState(state)
  });

  return (
    loadingState === true ? (
      <StyledLoadingSpinner>
        <LoadingWrapper>
          <ClipLoader
            color={'#1F3C75'}
            loading={loadingState}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <LoadingMessage>
            <Text size={1} weight={2} lineHeight={1}>
              Estamos carregando suas informações. Por favor, aguarde!
            </Text>
          </LoadingMessage>
        </LoadingWrapper>
      </StyledLoadingSpinner>
    ) : (<div></div>)
  );
};

export { LoadingSpinner };
