import { calculateFontSize } from 'components/Text/styles';
import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledHistoryItem = styled.div<{ hasAlpha?: boolean }>`
  ${({ theme, hasAlpha }) => css`
    padding: 32px 0;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.color.lightGray['E5E5E5']};
    }
    ${hasAlpha && `
      p {
        opacity: 0.5;
      }
    `}

  `};
`;

export const Header = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    text-align: center;
    
    ${({ deviceType }) => {
    if (deviceType?.type !== 'mobile')
      return `
        margin-right: 24px;
        margin-top: 24px;
        `;
    else {
      return `
          margin-right: 8px;
          `;
    }
  }}

    svg {
      font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 2)}` : 'inherit'};
      margin-right: 0;
    }
  }
`;

export const ExcludeButton = styled.button`
  ${({ theme }) => css`
    background: ${theme.color.white['FFFFFF']};
    border-radius: 6px;
    border: 1px solid ${theme.color.lightGray['E5E5E5']};
    font-size: 24px;
    height: 48px;
    width: 48px;
    cursor: pointer;
  `};
`;

export const Status = styled.div<{ status: string }>`
  ${({ theme, status }) => css`
    display: flex;
    align-items: center;
    & {
      :before {
        content: ' ';
        width: 12px;
        height: 12px;
        background: ${status === 'Ativa' ? theme.color.status.success :
      status === 'Excluída' ? theme.color.status.error : theme.color.status.disabled
    };
        display: block;
        margin-right: 8px;
        border-radius: 100%;
      }
    }
  `};
`;
