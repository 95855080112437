import styled, { css } from 'styled-components';
import type { SizeType, TextProps } from 'types';

export const calculateFontSize = (deviceType: string, size: SizeType) => {
  switch (deviceType) {
    case 'mobile':
    case 'mobile-landscape':
      switch (size) {
        case 0:
          return '14px';
        case 1:
          return '16px';
        case 2:
          return '18px';
        case 3:
          return '20px';
        case 4:
          return '22px';
        case 5:
          return '24px';
        case 10:
          return '32px';
        default:
          return `16px`;
      }
    case 'tablet':
      switch (size) {
        case 0:
          return '16px';
        case 1:
          return '18px';
        case 2:
          return '20px';
        case 3:
          return '22px';
        case 4:
          return '24px';
        case 5:
          return '30px';
        case 10:
          return '34px';
        default:
          return `16px`;
      }
    case 'laptop':
      switch (size) {
        case 0:
          return '16px';
        case 1:
          return '18px';
        case 2:
          return '20px';
        case 3:
          return '22px';
        case 4:
          return '24px';
        case 5:
          return '28px';
        case 10:
          return '36px';
        default:
          return `16px`;
      }
    case 'desktop':
      switch (size) {
        case 0:
          return '18px';
        case 1:
          return '20px';
        case 2:
          return '26px';
        case 3:
          return '30px';
        case 4:
          return '32px';
        case 5:
          return '36px';
        case 10:
          return '40px';
        default:
          return `24px`;
      }
    default:
      return `16px`;
  }
};


export const StyledText = styled.p<TextProps>`
  margin: 0;
  padding: 0;
  ${({ theme, color, size, lineHeight, weight, align, deviceType }) => css`
    ${color && `color: ${theme.font.color[color]}`};
    font-size: ${calculateFontSize(deviceType?.type ?? '', size ?? 1)};
    font-weight: ${theme.font.weight[weight ?? 0]};
    line-height: ${lineHeight ? `${theme.font.lineHeight[lineHeight]}px` : 1.5};
    text-align: ${align};
  `};
`;
