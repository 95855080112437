import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';
import { getScoreColor } from 'utils/getScoreColor';

export const StyledScoreRange = styled.div<{ deviceType?: DeviceTypeProps }>`
  position: relative;
  margin: 8px 0px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap; 
  justify-content:center;
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape')
    return `
    gap: 0px;
      justify-content:start;
      `;
  }}

  ${({ theme }) => `
    h4 {
      color: ${theme.color.darkGray['606060']};
    }
  `}
`;


export const StyledRangeItem = styled.div<{ isActive?: boolean, score: number, deviceType?: DeviceTypeProps }>`

${({ isActive, score, theme, deviceType }) => `
    position: relative;
    display: flex;
    flex-direction: ${deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape' ? 'row': 'column'};
    height:  ${deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape' || deviceType?.type === "tablet" ? "80px" : '80px'};
    width: ${deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape' ? '100%': deviceType?.type === "tablet" ? "200px" : ''};
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;

    > div {
      width: ${
        ((deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape') && !isActive) ? '100%' : deviceType?.type === 'mobile'  && isActive ? '35vw' : ''}; 
      border: 1px solid transparent;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      ${isActive ? 'background-color' : 'border-color'}: ${getScoreColor(score)};

     /*  &:first-child {
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: -18px;
          height: 18px;
          width: 1px;
          background-color: ${getScoreColor(score)};
        }
      } */
    }

    > p {
      margin: ${deviceType?.type === 'mobile' || deviceType?.type === 'mobile-landscape' ? 'auto': '0 0 10px 0 0 '};
    }
  `}
`;

export const Range = styled.div`
  position: relative;
  width: 190px;
  height: 48px;
`;

export const Percent = styled.div`
  position: relative;
  width: 120px;
  height: 40px;
`;
