/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from 'react'
import { Button } from "components/Button"
import { Selector } from "components/Selector"
import { Text } from "components/Text"
import { FieldArray, FormikErrors, FormikProvider, useFormik } from "formik"
import ReCAPTCHA from "react-google-recaptcha"
import { sentReport } from "services/SuaConsulta"
import { TIPOS_SOLICITACAO, TIPO_DADO } from ".."
import { Parametro, Solicitacao } from "../Models/solicitacao"
import { UserDetails } from "../Models/userDetails"
import * as S from './style'
import * as Yup from 'yup'
import useDeviceType from 'hooks/useDeviceType'

type InformacoesForm = {
  firstRequest: boolean
  infoOriginCheck: boolean
  infoOrigin: DinamicForm[]
  infoFinalidadeCheck: boolean
  infoFinalidadeConfirm: boolean
  infoFinalidade: DinamicForm[]
  infoShareCheck: boolean
  infoShare: DinamicForm[]
}

type DinamicForm = {
  dataType: string
  justification: string
}

const Informacoes = ({ detalheConsumidor, nome }: UserDetails) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const formSAERef = useRef<HTMLFormElement>(null)
  const [isFormReady, setIsFormReady] = useState(false)
  const [tokenRecapctha, setTokenRecaptcha] = useState('')
  const [formSAE, setFormSAE] = useState({
    retURL: '',
    name: '',
    email: '',
    cpf: '',
    phone: '',
    subject: '',
    description: '',
    type: '',
    motivo: '',
    submotivo: '',
    captcha_settings: '',
    recaptcha_response: '',
    orgId: ''
  })

  useEffect(() => {
    if (isFormReady && tokenRecapctha !== '') {
      formSAERef.current?.submit()
    }
  }, [isFormReady, formSAE, tokenRecapctha])



  const formik = useFormik<InformacoesForm>({
    initialValues: {
      firstRequest: false,
      infoOriginCheck: false,
      infoOrigin: [
        {
          dataType: '',
          justification: ''
        }
      ],
      infoFinalidadeCheck: false,
      infoFinalidadeConfirm: false,
      infoFinalidade: [
        {
          dataType: '',
          justification: ''
        }
      ],
      infoShareCheck: false,
      infoShare: [
        {
          dataType: '',
          justification: ''
        }
      ]
    },
    validationSchema: Yup.object().shape({
      infoOrigin: Yup.array().when('infoOriginCheck', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          dataType: Yup.string().required("Campo obrigatório."),
          justification: Yup.string().required("Campo obrigatório.").max(70, 'Limite máximo de 70 caracteres.')
        }))
      }),
      infoFinalidade: Yup.array().when('infoFinalidadeCheck', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          dataType: Yup.string().required("Campo obrigatório."),
          justification: Yup.string().required("Campo obrigatório.").max(70, 'Limite máximo de 70 caracteres.')
        }))
      }),
      infoShare: Yup.array().when('infoShareCheck', {
        is: true,
        then: Yup.array().of(Yup.object().shape({
          dataType: Yup.string().required("Campo obrigatório."),
          justification: Yup.string().required("Campo obrigatório.").max(70, 'Limite máximo de 70 caracteres.')
        }))
      }),
    }),
    onSubmit(values, formikHelpers) {

      recaptchaRef.current?.executeAsync().then(async (resp) => {
        const token = resp
        const solicitacao = solicitacaoFormFactory(values.firstRequest)
        const parametros: Parametro[] = []

        const infoOriginArr = values.infoOrigin
        infoOriginArr.length > 0 && infoOriginArr.forEach(origin => {
          const originDataType = origin.dataType
          const originRestOfTheWord = originDataType.slice(1).toLocaleLowerCase()
          const formattedDateType = originDataType.charAt(0).toUpperCase() + originRestOfTheWord

          const dataType = {
            campo: 'Dado Para qual dado de origem?',
            valor: formattedDateType
          }
          const justification = {
            campo: 'Explicação / Justificativa',
            valor: origin.justification
          }

          parametros.push(dataType)
          parametros.push(justification)
        })


        const infoFinalidadeArr = values.infoFinalidade
        infoFinalidadeArr.length > 0 && infoFinalidadeArr.forEach(finalidade => {
          const finalidadeDataType = finalidade.dataType
          const finalidadeRestOfTheWord = finalidadeDataType.slice(1).toLocaleLowerCase()
          const formattedDateType = finalidadeDataType.charAt(0).toUpperCase() + finalidadeRestOfTheWord

          const dataType = {
            campo: 'Dado Para qual dado de finalidade?',
            valor: formattedDateType
          }
          const justification = {
            campo: 'Explicação / Justificativa',
            valor: finalidade.justification
          }
          parametros.push(dataType)
          parametros.push(justification)
        })

        const infoShareArr = values.infoShare
        infoShareArr.length > 0 && infoShareArr.forEach(share => {

          const shareDataType = share.dataType
          const shareRestOfTheWord = shareDataType.slice(1).toLocaleLowerCase()
          const formattedDateType = shareDataType.charAt(0).toUpperCase() + shareRestOfTheWord

          const dataType = {
            campo: 'Dado Para qual dado de compartilhamento?',
            valor: formattedDateType
          }

          const justification = {
            campo: 'Explicação / Justificativa',
            valor: share.justification
          }

          parametros.push(dataType)
          parametros.push(justification)
        })

        solicitacao.parametros = parametros

        try {
          await sentReport(solicitacao)
          setIsFormReady(true)
          setTokenRecaptcha(token!)
          submitFormSAE(token!, parametros!)
        } catch (e) {
          console.log(e)
        }
      })
    },
  })

  const getOriginErrors = (index: number) => {
    if (formik?.errors?.infoOrigin && formik?.errors?.infoOrigin?.length > 0) {
      return formik?.errors?.infoOrigin[index] as FormikErrors<DinamicForm>
    }
    return {}
  }

  const getFinalidadeErrors = (index: number) => {
    if (formik?.errors?.infoFinalidade && formik?.errors?.infoFinalidade?.length > 0) {
      return formik?.errors?.infoFinalidade[index] as FormikErrors<DinamicForm>
    }
    return {}
  }

  const getShareErrors = (index: number) => {
    if (formik?.errors?.infoShare && formik?.errors?.infoShare?.length > 0) {
      return formik?.errors?.infoShare[index] as FormikErrors<DinamicForm>
    }
    return {}
  }

  const submitFormSAE = (token: string, parametros: Parametro[]) => {
    const description = formatDescription(parametros)
    const CAPTCHA_SETTINGS = {
      fallback: true,
      orgId: '00D1U000000FwcW',
      keyname: 'Captcha_V3',
      ts: new Date().getTime()

    }
    setFormSAE({
      retURL: `${window.location.href}?registroSAE=true`,
      name: nome,
      email: detalheConsumidor.email,
      cpf: detalheConsumidor.cpf,
      phone: detalheConsumidor.telefone,
      subject: `INFORMAÇÕES SOBRE ORIGEM, FINALIDADE OU COMPARTILHAMENTO - ${nome} - ${detalheConsumidor.cpf} -
      ${!formik.values.firstRequest ? 'Primeira solicitação' : ''}`,
      description,
      type: "Solicitação",
      motivo: 'INFORMAÇÕES SOBRE ORIGEM, FINALIDADE OU COMPARTILHAMENTO',
      captcha_settings: JSON.stringify(CAPTCHA_SETTINGS),
      recaptcha_response: token,
      orgId: '',
      submotivo: ''
    })
  }

  const isValidForm = () => {
    const { infoFinalidadeCheck, infoOriginCheck, infoShareCheck } = formik.values
    if (infoFinalidadeCheck || infoOriginCheck || infoShareCheck) {
      if (formik.isValid) {
        return true
      }
      return false
    }
    return false
  }

  const formatDescription = (parametros: Parametro[]) => {
    const format = parametros.map(parametro => `${parametro.campo} - ${parametro.valor}.`)
    return format.join('')
  }

  const solicitacaoFormFactory = (firstRequest: boolean) => {
    const solicitacaoForm: Solicitacao = {
      anexos: [],
      email: detalheConsumidor.email,
      idTipoSolicitacao: TIPOS_SOLICITACAO.INFO_ORIGEM_FINALIDADE_COMPARTILHA,
      primeiraSolicitacao: !firstRequest,
      resumoSolicitacao:
        // eslint-disable-next-line max-len
        `${TIPOS_SOLICITACAO.INFO_ORIGEM_FINALIDADE_COMPARTILHA} - ${nome.toUpperCase()} - ${detalheConsumidor.cpf} - ${!firstRequest ? 'Primeira solicitação' : 'solicitação'}`,
      parametros: []
    }
    return solicitacaoForm
  }
  const { deviceType } = useDeviceType();

  return (
    <S.ContainerText>
      <Text tag='h4' size={3} weight={4}>Solicitação para informações sobre origem, finalidade ou compartilhamento</Text>
      <Text color='text'>
        Informamos que o SPC BRASIL respeita a sua privacidade e a segurança dos seus dados pessoais.
        Portanto, todos os nossos processos de tratamento de dados pessoais estão em plena conformidade com a Lei nº. 13.709/2018
        (Lei Geral de Proteção de Dados Pessoais – LGPD). Para saber mais sobre nossas práticas de privacidade e proteção de dados pessoais,
        consulte aqui.
      </Text>
      <Text color='text'>
        Para que possamos atendê-lo, por gentileza, nos informe o que você deseja saber:
      </Text>
      <FormikProvider value={formik}>
        <S.FieldSet>
          <form onSubmit={formik.handleSubmit}>
            <S.Checks>
              <input type="checkbox" id='firstRequest' name='firstRequest'
                onChange={formik.handleChange} />
              <Text size={0} >Esta não é minha primeira solicitação.</Text>
            </S.Checks>
            <Text color='text'>
              Marque abaixo quais informações você deseja solicitar:
            </Text>

            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='infoOriginCheck' name='infoOriginCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Informação de Origem.</Text>
              </S.Checks>
              {
                formik.values.infoOriginCheck && (
                  <>
                    <Text size={0} weight={3}>Informação de origem</Text>
                    <FieldArray name="infoOrigin" render={(arrayHelpers) => (
                      <div>
                        {
                          formik.values.infoOrigin.map((info, index) => (
                            <S.DinamicForm key={index}>
                              <S.FormContainer deviceType={{ type: deviceType }}>
                                <S.FieldWrapper deviceType={{ type: deviceType }}>
                                  <Text size={0}>Para qual dado? (*)</Text>
                                  <Selector
                                    name={`infoOrigin[${index}].dataType`}
                                    options={[
                                      { label: 'Nome', value: TIPO_DADO.NOME },
                                      { label: 'Telefone', value: TIPO_DADO.TELEFONE },
                                      { label: 'Celular', value: TIPO_DADO.CELULAR },
                                      { label: 'Endereço', value: TIPO_DADO.ENDERECO },
                                      { label: 'Data de nascimento', value: TIPO_DADO.DATA_NASCIMENTO },
                                      { label: 'Email', value: TIPO_DADO.EMAIL },
                                      { label: 'CPF', value: TIPO_DADO.CPF },
                                    ]}
                                    value={formik.values.infoOrigin[index].dataType}
                                    onSelect={(e) => formik.setFieldValue(`infoOrigin.${index}.dataType`, e.value)}
                                  />
                                  <S.ErrorMessage>{getOriginErrors(index)?.dataType}</S.ErrorMessage>
                                </S.FieldWrapper>
                                <S.TextboxWrapper>
                                  <Text size={0}>Explicação / Justificativa (*)</Text>
                                  <S.TextAreaModal
                                    name={`infoOrigin[${index}].justification`}
                                    value={formik.values.infoOrigin[index].justification}
                                    onChange={formik.handleChange}
                                  />
                                  <S.ErrorMessage>{getOriginErrors(index)?.justification}</S.ErrorMessage>
                                </S.TextboxWrapper>
                              </S.FormContainer>
                              <S.DeleteWrapper>
                                <Button
                                  type="button"
                                  btnStatus='delete'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <S.IconDelete />
                                </Button>
                              </S.DeleteWrapper>
                            </S.DinamicForm>
                          ))

                        }
                        <S.AddFormWrapper deviceType={{ type: deviceType }}>
                          <Button
                            type="button"
                            btnStatus='primary'
                            onClick={() => arrayHelpers.push({
                              dataType: '',
                              justification: ''
                            })}
                          >
                            Mais campos {<S.IconAdd />}
                          </Button>
                        </S.AddFormWrapper>
                      </div>
                    )} />
                  </>
                )
              }

            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='infoFinalidadeCheck' name='infoFinalidadeCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Informação de Finalidade.</Text>
              </S.Checks>
              {
                formik.values.infoFinalidadeCheck && (
                  <>
                    <Text size={0} weight={3}>Informação de Finalidade</Text>
                    {
                      !formik.values.infoFinalidadeConfirm && (
                        <S.Notify>
                          <Text>
                            O SPC Brasil realiza o tratamento dos seus dados pessoais com base nas hipóteses de
                            tratamento previstas na legislação, objetivando a finalidade da proteção ao crédito,
                            serviços de marketing e prevenção à fraude.
                            Lembre-se que o SPC Brasil respeita a sua privacidade e a
                            segurança dos seus dados pessoais. Todos os nossos processos de
                            tratamento de dados pessoais estão em plena conformidade com a Lei nº. 13.709/2018
                            (Lei Geral de Proteção de Dados Pessoais – LGPD).
                          </Text>
                          <S.NotifyConfirm>
                            <Button
                              type="button"
                              btnStatus='primary'
                              onClick={() => formik.setFieldValue('infoFinalidadeConfirm', true)}
                            >
                              Gostaria de continuar com a solicitação?
                            </Button>
                          </S.NotifyConfirm>

                        </S.Notify>
                      )
                    }
                    {
                      formik.values.infoFinalidadeConfirm && (
                        <FieldArray name="infoFinalidade" render={(arrayHelpers) => (
                          <div>
                            {
                              formik.values.infoFinalidade.map((info, index) => (
                                <S.DinamicForm key={index}>
                                  <S.FormContainer>
                                    <S.FieldWrapper deviceType={{ type: deviceType }}>
                                      <Text size={0}>Para qual dado? (*)</Text>
                                      <Selector
                                        name={`infoFinalidade[${index}].dataType`}
                                        options={[
                                          { label: 'Nome', value: TIPO_DADO.NOME },
                                          { label: 'Telefone', value: TIPO_DADO.TELEFONE },
                                          { label: 'Celular', value: TIPO_DADO.CELULAR },
                                          { label: 'Endereço', value: TIPO_DADO.ENDERECO },
                                          { label: 'Data de nascimento', value: TIPO_DADO.DATA_NASCIMENTO },
                                          { label: 'Email', value: TIPO_DADO.EMAIL },
                                          { label: 'CPF', value: TIPO_DADO.CPF },
                                        ]}
                                        value={formik.values.infoFinalidade[index].dataType}
                                        onSelect={(e) => formik.setFieldValue(`infoFinalidade.${index}.dataType`, e.value)}
                                      />
                                      <S.ErrorMessage>{getFinalidadeErrors(index)?.dataType}</S.ErrorMessage>
                                    </S.FieldWrapper>
                                    <S.TextboxWrapper>
                                      <Text size={0}>Explicação / Justificativa (*)</Text>
                                      <S.TextAreaModal
                                        name={`infoFinalidade[${index}].justification`}
                                        value={formik.values.infoFinalidade[index].justification}
                                        onChange={formik.handleChange}
                                      />
                                      <S.ErrorMessage>{getFinalidadeErrors(index)?.justification}</S.ErrorMessage>
                                    </S.TextboxWrapper>
                                  </S.FormContainer>
                                  <S.DeleteWrapper>
                                    <Button
                                      type="button"
                                      btnStatus='delete'
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <S.IconDelete />
                                    </Button>
                                  </S.DeleteWrapper>
                                </S.DinamicForm>
                              ))

                            }
                            <S.AddFormWrapper deviceType={{ type: deviceType }}>
                              <Button
                                type="button"
                                btnStatus='primary'
                                onClick={() => arrayHelpers.push({
                                  dataType: '',
                                  justification: ''
                                })}
                              >
                                Mais campos {<S.IconAdd />}
                              </Button>
                            </S.AddFormWrapper>
                          </div>
                        )} />
                      )
                    }
                  </>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="checkbox" id='infoShareCheck' name='infoShareCheck'
                  onChange={formik.handleChange} />
                <Text size={0} >Informação de compartilhamento.</Text>
              </S.Checks>
              {
                formik.values.infoShareCheck && (
                  <>
                    <Text size={0} weight={3}>Informação de compartilhamento</Text>
                    <FieldArray name="infoShare" render={(arrayHelpers) => (
                      <div>
                        {
                          formik.values.infoShare.map((info, index) => (
                            <S.DinamicForm key={index}>
                              <S.FormContainer>
                                <S.FieldWrapper deviceType={{ type: deviceType }}>
                                  <Text size={0}>Para qual dado? (*)</Text>
                                  <Selector
                                    name={`infoShare[${index}].dataType`}
                                    options={[
                                      { label: 'Nome', value: TIPO_DADO.NOME },
                                      { label: 'Telefone', value: TIPO_DADO.TELEFONE },
                                      { label: 'Celular', value: TIPO_DADO.CELULAR },
                                      { label: 'Endereço', value: TIPO_DADO.ENDERECO },
                                      { label: 'Data de nascimento', value: TIPO_DADO.DATA_NASCIMENTO },
                                      { label: 'Email', value: TIPO_DADO.EMAIL },
                                      { label: 'CPF', value: TIPO_DADO.CPF },
                                    ]}
                                    value={formik.values.infoShare[index].dataType}
                                    onSelect={(e) => formik.setFieldValue(`infoShare.${index}.dataType`, e.value)}
                                  />
                                  <S.ErrorMessage>{getShareErrors(index)?.dataType}</S.ErrorMessage>
                                </S.FieldWrapper>
                                <S.TextboxWrapper>
                                  <Text size={0}>Explicação / Justificativa (*)</Text>
                                  <S.TextAreaModal
                                    name={`infoShare[${index}].justification`}
                                    value={formik.values.infoShare[index].justification}
                                    onChange={formik.handleChange}
                                  />
                                  <S.ErrorMessage>{getShareErrors(index)?.justification}</S.ErrorMessage>
                                </S.TextboxWrapper>
                              </S.FormContainer>
                              <S.DeleteWrapper>
                                <Button
                                  type="button"
                                  btnStatus='delete'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <S.IconDelete />
                                </Button>
                              </S.DeleteWrapper>
                            </S.DinamicForm>
                          ))

                        }
                        <S.AddFormWrapper deviceType={{ type: deviceType }}>
                          <Button
                            type="button"
                            btnStatus='primary'
                            onClick={() => arrayHelpers.push({
                              dataType: '',
                              justification: ''
                            })}
                          >
                            Mais campos {<S.IconAdd />}
                          </Button>
                        </S.AddFormWrapper>
                      </div>
                    )} />
                  </>
                )
              }
            </S.GroupField>
            <S.AddFormWrapper deviceType={{ type: deviceType }}>
              <S.Submit disabled={!isValidForm()} type='submit'>Enviar</S.Submit>
            </S.AddFormWrapper>
        </form>
        </S.FieldSet>
      </FormikProvider>
      <ReCAPTCHA
        sitekey="6LcoYpkiAAAAAN5JHzikMmBCXXkUd9-gMxu_2VAu"
        size='invisible'

        ref={recaptchaRef}
      />
      <form name="formSAE" ref={formSAERef} id="formSAE"
        method="POST" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8">
        <input type="hidden" id="00N1U00000USpvb" name="00N1U00000USpvb" title="Area" value="SAE - Consumidor" />
        <input type="hidden" id="00N1U00000USpw6" name="00N1U00000USpw6" value="LGPD (Atendimento ao titular)" />
        <input value={formSAE.motivo} type="hidden" id="00N1U00000USpw4" name="00N1U00000USpw4" />
        <input value={formSAE.submotivo} type="hidden" id="00N1U00000USpw9" name="00N1U00000USpw9" />
        <input type="hidden" name="orgid" value="00D1U000000FwcW" />
        <input value={formSAE.retURL} type="hidden" name="retURL" />
        <input value={formSAE.cpf} type="hidden" id="00N1U00000USpvk" name="00N1U00000USpvk" />
        <input value={formSAE.name} type="hidden" id="name" name="name" />
        <input value={formSAE.email} type="hidden" id="email" name="email" />
        <input value={formSAE.phone} type="hidden" id="phone" name="phone" />
        <input value={formSAE.subject} type="hidden" id="subject" name="subject" />
        <input value={formSAE.description} type="hidden" id="description" name="description" />
        <input value={formSAE.type} type="hidden" id="type" name="type" />
        <input value={formSAE.captcha_settings} type="hidden" name="captcha_settings" id="captcha_settings" />
        <input value={formSAE.recaptcha_response} type="hidden" name="g-recaptcha-response" id="g-recaptcha-response" />
        <input type="hidden" id="external" name="external" value="1" />
      </form>
    </S.ContainerText>
  )
}

export default Informacoes
