import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledCancelationForm = styled.div<{ deviceType?: DeviceTypeProps }>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px 6px;
  
  h1 {
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 3)}` : 'inherit'};
  }
  
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      button {
        width: 75vw !important;
      }
     

      h1 {
        font-size: 24px !important;
        font-weight: 600;
        margin: ${deviceType?.type === 'mobile' ? '12px 0' : '12px 0'};
      }


  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }

  button {
     width: 320px;
  }
  
  `;
  }}
  
  ${({ theme }) => `
    color: ${theme.color.darkGray['4E5157']};
  `}
`;

export const Paragraph = styled.div<{ deviceType?: DeviceTypeProps }>`
${({ deviceType }) => `
    margin-bottom: ${deviceType?.type === 'mobile' ? '10px' : '20px'};

    p {
    font-weight: 400;
    font-size: ${deviceType?.type === 'mobile' ? '15px' : '18px'};
    line-height: ${deviceType?.type === 'mobile' ? '20px' : '36px'};
    margin: 0;
  }
  `}
`;

export const ListCancelamento = styled.ul<{ deviceType?: DeviceTypeProps }>`
${({ deviceType }) => `

li {
    font-size: ${deviceType?.type === 'mobile' ? '15px' : '18px'};
    line-height: ${deviceType?.type === 'mobile' ? '20px' : '36px'};
    margin: 0;
  }
  `}
`;

export const TermContainer = styled.div`
  box-sizing: border-box;
  overflow: auto;
  margin-bottom: 16px;
  border-radius: 24px;
  padding: 16px;
  max-height: 50vh;
  
  p {
    margin-bottom: 30px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => `
    border: 1px solid ${theme.color.neutral['91A6B7']};
  `}
`;

export const CheckContainer = styled.div<{ deviceType?: DeviceTypeProps, whitespace?: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
  margin-bottom: 30px;
  gap: 5px;
  overflow-wrap: break-word;
  margin-top: 10px;
  b {
    cursor: pointer;
  }
  ${({ whitespace }) => `
  white-space:  ${whitespace ? "normal" : "nowrap"};
  `}

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile') {
      return `
        width: 95%;
      `;
    }
  }}
  input {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin-bottom: 6px;
    margin-top: 4px;
    ${({ theme }) => `
      border: 1px solid ${theme.color.darkGray['606060']};
    `}

  b {
    cursor: pointer;
  }

`;

export const ModalTerm = styled.div<{ deviceType?: DeviceTypeProps }>`
  max-width: 900px;
  height: 30vh;
  overflow-y: scroll;
  margin-bottom: 16px;
  padding: 10px;

  ${({ deviceType }) => {
    const styles: Record<string, string> = {
      mobile: `
        padding: 0 5px 0 0; 
        overflow-x: hidden;
        padding: 0 5px 0 0; 
        height: 60vh;
      `,
      'mobile-landscape': `
        overflow-x: hidden;
        height: 28vh;
        margin-bottom: 0;
      `,
      tablet: `
        padding: 0 5px 0 0; 
        overflow-x: hidden;
        height: 55vh;
        margin-bottom: 0;
      `,
      laptop: `
        height: 50vh;
      `,
    };

    return deviceType?.type && styles[deviceType.type] ? styles[deviceType.type] : '';
  }} 

  p {
    margin-bottom: 30px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9db;
    border-radius: 4px;
  }
`;



export const InputTerm = styled.div`
  width: 80vw;
`;

export const ConfirmContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  font-weight: 400;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
  line-height: 36px;
`;

export const CancelDateTime = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  align-items: center;
  margin: 14px 0;
  font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};

  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      flex-direction: column;
      `;
  }}

  b {
    font-weight: 500;
    margin-left: 3px;
    margin-right: 10px;
  }
`;

export const DateContainer = styled.div`
  display:flex;
  align-items: center;
`;

export const FooterContainer = styled.div`
  margin: 10px 0;
 
`;