import { useCallback, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts';
import { history as historicoService } from 'services/Cadastro';
import { authFactory } from 'factories';

const useGetSourceCompanies = () => {
  const { userData } = useContext(AuthContext);
  const [companies, setCompanies] = useState<any>([]);

  const getCompanyData = useCallback(async (name: any) => {
    const docType: any = userData?.tipoConsumidor
    const docNumber: any = (userData?.cpf !== "" && userData?.cpf !== undefined) ? userData?.cpf : userData?.cnpj 

    await historicoService.source.companies(docType, docNumber, name)
      .then((response) => {
        setCompanies(response.data)
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data);
        }
      });
  }, [userData]);

  useEffect(() => {
    const user = userData as typeof authFactory;

    const isUserValid = user != null
      && !!user.tipoConsumidor
      && (!!user.cpf || !!user.cnpj);

    if (isUserValid) {
      getCompanyData("");
    }
  }, [getCompanyData]);

  return { companies, getCompanyData }
};

export default useGetSourceCompanies
