import styled, { css } from 'styled-components'
import { animated } from 'react-spring'

export const AccordionTitleHeight = '3rem'

export const Accordion = styled(animated.div) <{ totalheight: string }>`
  ${({ totalheight }) => css`
    width: 100%;
    max-height: ${totalheight};
    cursor: pointer;
    overflow: hidden;
  `}
`

export const AccordionTitle = styled.div`
   ${({ theme }) => css`
    position: relative;
    background-color: ${theme.color.white['F4F7FC']};
    height: ${AccordionTitleHeight};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left:1rem;
  `}
`

export const AccordionIcon = styled(animated.div)`
      position: absolute;
      right: 2rem;
      top: 1rem;
      svg {
        color: #8094BC;
      }
`

export const AccordionContent = styled.div`
  margin-left: 16px;
  margin-top: 6px;
`
