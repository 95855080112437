import { useNavigate } from 'react-router-dom';

import { Button, Container, GaugeChart, Text } from 'components';
import { Breadcrumbs } from 'components/Header/partials/Breadcrumbs';
import { PageSkeletonProps } from 'types';
import { BackButton, BoxHighlight, ButtonScore, ContentPageBody, StyledPageBody, TextContent, Title } from './styles';
import { ArrowBackIcon } from 'components/Icon/ArrowBackIcon';
import useDeviceType from 'hooks/useDeviceType';
import * as DOMPurify from 'dompurify';

const PageSkeleton = ({ children, breadcrumb, title, highlight, score, paddingSize, backButton }: PageSkeletonProps) => {
  const navigate = useNavigate();
  const routeMatch = window.location.pathname !== '/home/entenda-seu-score';
  const { deviceType } = useDeviceType();


  return (
    <Container >
      {breadcrumb && <Breadcrumbs menu={breadcrumb.menuName} submenu={breadcrumb.submenuName} thirdLevel={breadcrumb.thirdLevel} />}
      <Container center={true}>
        <StyledPageBody hasScore={!!score} padding={paddingSize} deviceType={{ type: deviceType }}>
          <ContentPageBody deviceType={{ type: deviceType }}>
            {title && (
              <Title>
                <Text tag="h3" size={4} weight={3} color="title">
                  {backButton?.render ? (
                    <BackButton onClick={backButton.action} deviceType={{ type: deviceType }}>
                      <ArrowBackIcon />
                      {title}
                    </BackButton>
                  ) : (
                    <>{title}</>
                  )}
                </Text>
              </Title>
            )}

            {highlight && (
              <BoxHighlight reverse={highlight?.reverse} hasScore={!!score} deviceType={{ type: deviceType }}>
                {score ?
                  (<GaugeChart level={score} />) :
                  (<img src={highlight?.image} />)
                }
                <TextContent hasScore={!!score} deviceType={{ type: deviceType }}>
                  <Text tag="h4" size={(deviceType === 'mobile' && score) ? 2 : (score ? 3 : 1)}
                    weight={score ? 2 : 3} lineHeight={1} color={score ? 'primary' : 'text'}>{highlight?.title}</Text>
                  <Text
                    color="text"
                    size={1}
                    lineHeight={1}
                  >
                    <span dangerouslySetInnerHTML={{ __html: (highlight?.description && DOMPurify.sanitize(highlight?.description, { ADD_ATTR: ['target']})) ?? '' }}>{ }</span>
                  </Text>
                  {score && routeMatch &&
                    <ButtonScore deviceType={{ type: deviceType }}>
                      <Button onClick={() => navigate('/home/entenda-seu-score')}>Entenda seu score</Button>
                    </ButtonScore>
                  }
                </TextContent>
              </BoxHighlight>
            )}
            {children}
          </ContentPageBody>

        </StyledPageBody>
      </Container>
    </Container>
  );
};

export { PageSkeleton };
