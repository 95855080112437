import { useState } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: any) => {
  const storedAuth = localStorage.getItem('isAuthenticated');
  const initialAuthState = storedAuth ? JSON.parse(storedAuth) : false;

  const sessionRefreshToken = localStorage.getItem('SPC_SessionInfo');
  const refreshToken = sessionRefreshToken && JSON.parse(sessionRefreshToken).refresh_token;

  const [isAuthenticated, setAuthentication] = useState(initialAuthState);

  if (!isAuthenticated || !refreshToken) {
    localStorage.setItem('isAuthenticated', JSON.stringify(false));
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;
