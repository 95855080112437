import { FormField, Text } from 'components';
import { useEffect, useState } from 'react';
import { RadioProps } from 'types';
import { Option, OptionContainer, OptionLabel, StyledRadio } from './styles';

const Radio = ({ label, options, name, status, value, horizontal, onCheck }: RadioProps) => {
  const [radioValue, setRadioValue] = useState(value);

  const handleCheck = (value: any) => {
    setRadioValue(value);
    if (onCheck) onCheck(value);
  };

  useEffect(() => {
    setRadioValue(value);
  }, [value]);

  return (
    <FormField status={status} label={label}>
      <StyledRadio horizontal={horizontal}>
        {
          options.map((option, index) => (
            <OptionContainer key={index}>
              <OptionLabel htmlFor={`${name}_${index}`}>
                <input
                  type="radio"
                  id={`${name}_${index}`}
                  onChange={(e) => handleCheck(e.target.value)}
                  name={name} value={option.value}
                  checked={radioValue === option.value}
                />
                <Option>{option.label}</Option>
                <Text size={0}>{option.label}</Text>
              </OptionLabel>
            </OptionContainer>
          ))
        }
      </StyledRadio>
    </FormField>
  );
};

export { Radio };
