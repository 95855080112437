import { LinkCard } from 'components';
import { LineChartIcon, ThumbsUpIcon } from 'components/Icon';
import { CardContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from 'contexts';
import useDeviceType from 'hooks/useDeviceType';

export default function Inicio() {
  const { userData } = useContext(AuthContext)
  const navigate = useNavigate();
  const { deviceType } = useDeviceType();

  return (
    <CardContainer className='highlightSpace' deviceType={{ type: deviceType }}
      style={{ marginTop: userData.tipoConsumidor == "PJ" ? "20px" : "" }}>
      <LinkCard
        title="Cadastro Positivo"
        description="Veja detalhes sobre seu score e suas movimentações de crédito."
        icon={<LineChartIcon />}
        url='/home/cadastro-positivo'
        onClick={() => navigate('/home/cadastro-positivo')}
        alternative
      />
      <LinkCard
        title="Vantagens"
        description="Conheça as soluções e parcerias que o SPC Brasil tem para você"
        icon={<ThumbsUpIcon />}
        url='/vantagens'
        onClick={() => navigate('/vantagens')}
        alternative
      />
    </CardContainer>
  );
}
