import { ContainerProps } from 'types';
import { PageBody } from './PageBody';
import { StyledContainer } from './styles';
import useDeviceType from 'hooks/useDeviceType';


const Container = ({ children, fluid, center }: ContainerProps) => {
  const { deviceType } = useDeviceType();
  
  return <StyledContainer center={center} deviceType={{ type: deviceType }} fluid={fluid}>{children}</StyledContainer>;
};

export { Container, PageBody };
