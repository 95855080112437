import { AuthContext } from 'contexts';
import { useContext, useEffect } from 'react';
import { api } from 'services/Auth/api';

const AxiosInterceptor = () => {
  const { getToken } = useContext(AuthContext);

  useEffect(() => {
    const unregisterInterceptor = api.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const newAccessToken = await getToken();
            originalRequest.headers['Authorization'] = 'Bearer ' + newAccessToken;

            return api(originalRequest);
          } catch (err) {
            window.location.replace('/');
          }
        }

        return Promise.reject(error);
      }
    );

    const localData = localStorage.getItem('SPC_SessionInfo') || '{}';
    const sessionInfo = JSON.parse(localData);
    const expiresInMilliseconds = (sessionInfo.expires_in || 0) * 1000;
    const checkTokenIntervalTime = expiresInMilliseconds - (1000 * 60 * 3); // 3 minutos antes do token expirar
    const minimumIntervalTime = 1000 * 60; // 1 minuto

    if (checkTokenIntervalTime > minimumIntervalTime) {
      const checkTokenInterval = setInterval(async () => {
        const sessionRefreshToken: any | null = localStorage.getItem('SPC_SessionInfo');
        const refreshToken: any = JSON.parse(sessionRefreshToken).refresh_token;

        if (refreshToken) {
          await getToken();
        }
      }, checkTokenIntervalTime);

      return () => {
        // Limpe o interceptor quando o componente for desmontado
        api.interceptors.response.eject(unregisterInterceptor);
        // Limpe o intervalo de verificação do token quando o componente for desmontado
        clearInterval(checkTokenInterval);
      };
    }
  }, [getToken]);

  return null; // Retorne null, pois este componente não precisa renderizar nada
};

export default AxiosInterceptor;
