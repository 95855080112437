import styled, { css } from 'styled-components'
import { DeviceTypeProps } from 'types/Responsive.types'

export const ContentPersonalInfo = styled.div`
`

export const ContainerPersonalInfo = styled.div`
    margin-top: 1rem;
    padding-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%
`
export const PersonalInfo = styled('div')`
  ${() => css`
    display: flex;
    gap: 0.7rem;
  `}
`

export const ContainerRecords = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.6rem;
  margin-top: 0.3rem;
  width: 100%;

  h4{
    font-size: 18px;
  }

`
export const ContainerRecordsInformation = styled.section`
  ${({ theme }) => css`
    padding-bottom: 1.5rem;
    border-bottom: 1px solid ${theme.color.lightGray['EAEAEA']}
  `}
`

export const ContainerRequest = styled.section`
  ${({ theme }) => css`
    margin-top: 2rem;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid ${theme.color.lightGray['EAEAEA']};
    border-radius: 10px;
    gap: 12px;
  `}
`

export const ModalContentContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70vw;

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
      width: 100%;

      `;
  }}

  
`

export const ModalSuccess = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  h4 {
    text-align: center;
  }
`
