import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const ButtonContainer = styled.div<{ show: boolean }>`
  ${({ show }) => `
    display: ${show ? 'flex' : 'none'};
  `}
  justify-content: flex-end;
  margin-top: 45px;
`;

export const ConfirmMessage = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; 

  ${({ theme, deviceType }) => `
    color: ${theme.color.neutral['586682']};

  & img {
    width: ${deviceType?.type === "mobile" || deviceType?.type === "mobile-landscape" ? "60%" :  deviceType?.type === "tablet" ? "40%" : "30%"};
    height: auto;
    margin-bottom: 50px;
    margin-top: ${deviceType?.type === "tablet" ? "80px" : "30px"};
  }

  & h2 {
    font-weight: 700;
    margin: 0;
    font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 2)}` : 'inherit'};
}

  & h3 {
    font-weight: 400;
    line-height: 31px;
    font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
    margin: 16px 0;
  }
  
  & h4 {
    font-weight: 600;
    line-height: 21px;
    margin: 20px 0 0px 0;
    cursor: pointer;
    font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
    `}

    ${({ theme }) => `
      color: ${theme.color.blue['154695']};
    `}
  }
`;
