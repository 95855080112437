import { calculateFontSize } from 'components/Text/styles';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledLinkCard = styled(Link) <{ $alternative?: boolean, $inactive?: boolean, $isSidebar?: boolean, deviceType?: DeviceTypeProps }>`
  ${({ theme, $alternative, $inactive, $isSidebar, deviceType }) => css`
    text-decoration: none;
    margin: 5px 10px;
    align-items: center;
    border-radius: 16px;
    border: ${$isSidebar ? 'none' : `1px solid ${theme.color.lightGray['E5E5E5']}`};
    cursor: ${$inactive ? 'none' : 'pointer'};
    pointer-events: ${$inactive ? 'none' : 'auto'};
    display: flex;
    height: 140px;    
    justify-content:${deviceType?.type === "mobile" ||  deviceType?.type === "mobile-landscape" ? "center" : "space-between"};
    flex-direction :${deviceType?.type === "mobile" ||  deviceType?.type === "mobile-landscape" ? "column" : "row"};
    padding: ${deviceType?.type === "mobile" || deviceType?.type === "mobile-landscape" ? "16px 0" : deviceType?.type === "desktop" ? "24px" :  "14px"};
    position: relative;
    width: 320px;

    &:hover {
      background-color: ${theme.color.blue['007BFF']}16;
    }
    
    ${$alternative && `
      &:hover {
        background: linear-gradient(114.7deg, #007BFF -3.91%, #005ABA 103.48%);
        
        p, svg {
          color: ${theme.color.white['FFFFFF']};
        }
      }
      `}

      ${$isSidebar && `
        width: 200px;
        height: 40px;
        padding: 10px 10px;
       `}
      `}
      
      @media (max-width: 767px) {
        svg{
          width: 32px;
          height: 32px;
        }
      }

      `;

export const IconContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  ${({ theme, deviceType }) => `
  color: ${theme.color.blue['007BFF']};
  
  svg {
    width: ${deviceType?.type === "mobile" || deviceType?.type === "mobile-landscape" ? "36px" : "56px"};
    height: ${deviceType?.type === "mobile" || deviceType?.type === "mobile-landscape" ? "36px" : "56px"};
    }
    
  `}
`;

export const Content = styled.div<{ isSidebar?: boolean, deviceType?: DeviceTypeProps }>`
  margin: 0 20px;
  flex: 1;
  height: auto;
  
  p{
    font-size: ${({ deviceType }) => deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};
  }
  
  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile' || deviceType?.type === "mobile-landscape")
      return `
    align-i
      margin: 6px 12px;
      `;
  }}

  ${({ isSidebar }) => isSidebar && css`
    margin-left: 12px;

      & > :nth-child(2) {
        display: none;
    `}

  ${({ isSidebar }) => !isSidebar && css`
        display: flex;
        flex-direction: column;
        width:250px;
  `}
  }
`;

export const LinkCardContainer = styled.div<{ deviceType?: DeviceTypeProps, $isSidebar?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ $isSidebar }) => !$isSidebar ? 'column' : 'row'};

  ${({ deviceType, $isSidebar }) => {
    
    if (!$isSidebar) {
      if (!deviceType || (deviceType.type !== 'mobile'  && deviceType.type !== 'mobile-landscape')) {
        return `
          flex-direction: row;
          width: 100%;
          font-size: 16px;
        `;
      }
    }
    else{
      return `
          width: 90%;
      `
    }
  }}
`;

