import { Responsive, Text } from 'components';

import { LinkCardProps } from 'types';
import { Content, IconContainer, LinkCardContainer, StyledLinkCard } from './styles';
import useDeviceType from 'hooks/useDeviceType';


const LinkCard = ({ title, description, icon, url, alternative, inactive, onClick, isSidebar }: LinkCardProps) => {
  const { deviceType } = useDeviceType();

  return (
    <StyledLinkCard $alternative={alternative} $inactive={inactive} to={url}
      onClick={onClick} $isSidebar={isSidebar} deviceType={{ type: deviceType }}>
      <LinkCardContainer deviceType={{ type: deviceType }} $isSidebar={isSidebar}>
        <IconContainer deviceType={{ type: deviceType }}>
          {icon}
        </IconContainer>
        <Content isSidebar={isSidebar} deviceType={{ type: deviceType }}>
          <Text  weight={2} color='title'>{title}</Text>
          <Text color="text">{description}</Text>
        </Content>
      </LinkCardContainer>
    </StyledLinkCard>

  );
};

export { LinkCard };
