import { Text } from 'components';
import { ListIconProps } from 'types';
import { Content, IconContainer, StyledListIcon } from './styles';
import useDeviceType from 'hooks/useDeviceType';

const ListIcon = ({ title, description, icon, iconTop }: ListIconProps) => {
  const { deviceType } = useDeviceType();

  return (
    <div style={{ width: "auto" }}>
      {iconTop && (
        <IconContainer deviceType={{ type: deviceType }}>
          {icon}
        </IconContainer>
      )}
      <StyledListIcon>
        {!iconTop && (
          <IconContainer deviceType={{ type: deviceType }}>
            {icon}
          </IconContainer>
        )}
        <Content deviceType={{ type: deviceType }}>
          <Text size={1} weight={2} color="title">{title}</Text>
          <Text size={1}>
            <span dangerouslySetInnerHTML={{ __html: description ?? '' }} />
          </Text>
        </Content>
      </StyledListIcon>
    </div>
  );
};

export { ListIcon };
