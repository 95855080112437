import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const DetailBlock = styled.div<{ deviceType?: DeviceTypeProps, showPointer? : boolean }>`
${({ deviceType, showPointer }) => `

cursor: ${showPointer ? 'pointer': 'default'};
& h1 {
  font-weight: 700;
  font-size: ${ deviceType && deviceType.type ? calculateFontSize(deviceType.type, 1) : 'inherit' };
  margin-bottom: 10px;
}

& p {
  font-weight: 400;
  font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 1)}` : 'inherit'};
  word-break: break-all;
  word-wrap: break-word;
  width: ${deviceType?.type === 'mobile' ? "90%" : "auto"};

  & > span {
    display: inline-block;
  }

  & b {
    font-weight: 600;
    font-size: ${ deviceType && deviceType.type ? `${calculateFontSize(deviceType.type, 0)}` : 'inherit'};

  }
}

  `
  }
  ${({ theme }) => `
    border-bottom: 1.4px solid ${theme.color.lightGray['EAEAEA']};
    color: ${theme.color.neutral['586682']};
  `}
`;

export const HistoryContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  margin: 0px 8px;

  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      margin: 0px;

      `;
  }}
`;


export const PaymentTable = styled.table`
`;

export const PaymentTableContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  max-width: 100%;

  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
      max-width: 80vw;
      `;
  }}
  overflow-x: auto;
`;

export const TableRow = styled.tr<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  font-size: 16px;
  text-align: center;
  align-items: center;
  
  & th {
    font-weight: 500;
    margin-bottom: 30px;
  }
  
  gap: 10px;
  & th, & td {
    
    ${({ deviceType }) => {
    if (deviceType?.type === 'laptop')
      return `
      width: 130px;
    `;
    else if (deviceType?.type === 'desktop')
      return `
      width: 180px;
  `;
  else if (deviceType?.type === 'tablet')
  return `
      font-size: 12px;
      width: 90px;
        `;
  }}
  
  }
`;

export const TableCell = styled.td<{ color?: string, bold?: boolean, lineText?: boolean }>`
  margin-bottom: 40px;
  
  ${({ theme, color, bold, lineText }) => `
    word-wrap: ${lineText ? "" : "break-word"};
    font-weight: ${bold ? '500' : '400'};
    color: ${color ? color : theme.color.neutral['586682']}
  `}
`;
