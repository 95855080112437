import { Text } from 'components/Text';
import { calculateFontSize } from 'components/Text/styles';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';
export const StyledContainer = styled.div`
  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    padding: 96px 148px;
  `}
`;

export const BoxHighlight = styled.div`
  ${({ theme }) => `
    align-items: center;
    background: ${theme.color.white['F4F7FC']};
    border-radius: 25.53px;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 56px;
    padding: 32px;
  `}
`;

export const TextContent = styled.div`
  margin-left: 45px;
`;

export const StyledKeyValue = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  height: 36px;

  p {
    margin-right: 8px;
  }
`;

export const QueryContainer = styled.div`
  margin-top: 26px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h4{
    font-size: 20px;
  }
`;

export const Title = styled(Text)`
  ${({ theme }) => `
    width: 100%;
    color: ${theme.color.darkGray['606060']};
  `}
`;

export const ActionContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: start
  justify-content: start;
  width: 100%;

  > a {
    width: auto;
    height: 112px;
    margin: 24px 0 0 0;
    }
  }

`;

export const TitleContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
`
export const SearchField = styled.div<{ deviceType?: DeviceTypeProps }>`

${({ deviceType }) => {
  if (deviceType?.type == 'mobile')
  return `
    width: 100%;
    `;
}}

  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  height: 60px;
  border-radius: 6.38267px;
  right: 0;
  top: 0;
  z-index: 1;
  
  ${({ theme }) => `
    border: 1.59567px solid ${theme.color.lightGray['E5E5E5']};
    `}
    
    input {
    border: none;
    outline: none;
    margin-left: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    ${({ theme }) => `
      color: ${theme.color.darkGray['797979']};
    `}
  }
`;
