/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useRef, useState } from 'react'
import { Text } from 'components/Text'
import { FormikProvider, useFormik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { TIPOS_SOLICITACAO } from '..'
import { Parametro, Solicitacao } from '../Models/solicitacao'
import { UserDetails } from '../Models/userDetails'
import * as S from './style'
import { sentReport } from 'services/SuaConsulta'
import * as Yup from 'yup'
import useDeviceType from 'hooks/useDeviceType'

type OutrasSolicitacoesForm = {
  justification: string
  reportChecked: TIPO_REPORT | null
}

const enum TIPO_REPORT {
  BLOQUEIO = 'BLOQUEIO',
  PORTABILIDADE = 'PORTABILIDADE',
  DECLARACAO = 'DECLARACAO',
  RELATORIO = 'RELATORIO',
  CONTROLADOR = 'CONTROLADOR'
}

const OutrasSolicitacoes = ({ detalheConsumidor, nome }: UserDetails) => {

  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const formSAERef = useRef<HTMLFormElement>(null)
  const [isFormReady, setIsFormReady] = useState(false)
  const [tokenRecapctha, setTokenRecaptcha] = useState('')
  const [formSAE, setFormSAE] = useState({
    retURL: '',
    name: '',
    email: '',
    cpf: '',
    phone: '',
    subject: '',
    description: '',
    type: '',
    motivo: '',
    submotivo: '',
    captcha_settings: '',
    recaptcha_response: '',
    orgId: ''
  })


  const formik = useFormik<OutrasSolicitacoesForm>({
    initialValues: {
      justification: '',
      reportChecked: null
    },
    validationSchema: Yup.object().shape({
      reportChecked: Yup.string().required('Campo obrigatório'),
      justification: Yup.string().when('reportChecked', (reportChecked) => {
        if (reportChecked === TIPO_REPORT.PORTABILIDADE || reportChecked === TIPO_REPORT.BLOQUEIO) {
          return Yup.string().required('Campo obrigatório.').max(70, 'Limite máximo de 70 caracteres.')
        }
        return Yup.string().max(70, 'Limite máximo de 70 caracteres.')
      })
    }),
    onSubmit(values, formikHelpers) {

      recaptchaRef.current?.executeAsync().then(async (resp) => {
        const token = resp
        const solicitacao = solicitacaoFormFactory()

        const parametros: Parametro[] = []

        const tipoSolicitacao = getTipoSolicitacao(values.reportChecked!)
        const valueParameter = setParameterString(values.reportChecked!, tipoSolicitacao, values.justification)
        const parameter = {
          campo: 'Solicitação de outras informações',
          valor: valueParameter
        }

        parametros.push(parameter)
        solicitacao.parametros = parametros

        try {
          await sentReport(solicitacao)
          setIsFormReady(true)
          setTokenRecaptcha(token!)
          submitFormSAE(token!, parametros!)
        } catch (e) {
          console.log(e)
        }
      })
    },
  })

  const submitFormSAE = (token: string, parametros: Parametro[]) => {
    const description = formatDescription(parametros)
    const CAPTCHA_SETTINGS = {
      fallback: true,
      orgId: '00D1U000000FwcW',
      keyname: 'Captcha_V3',
      ts: new Date().getTime()

    }

    setFormSAE({
      retURL: `${window.location.href}?registroSAE=true`,
      name: nome,
      email: detalheConsumidor.email,
      cpf: detalheConsumidor.cpf,
      phone: detalheConsumidor.telefone,
      subject: `OUTRAS_SOLICITACAO  - ${nome} - ${detalheConsumidor.cpf} - Primeira solicitação`,
      description,
      type: "Solicitação",
      motivo: 'OUTRAS_SOLICITACAO ',
      captcha_settings: JSON.stringify(CAPTCHA_SETTINGS),
      recaptcha_response: token,
      orgId: '',
      submotivo: ''
    })
  }
  const formatDescription = (parametros: Parametro[]) => {
    const format = parametros.map(parametro => `${parametro.campo} - ${parametro.valor}.`)
    return format.join('')
  }
  const setParameterString = (tipoSolicitacao: TIPO_REPORT, valueSolicitacao: string, justification: string) => {
    let valueParameter = valueSolicitacao
    if (tipoSolicitacao === TIPO_REPORT.BLOQUEIO || tipoSolicitacao === TIPO_REPORT.PORTABILIDADE) {
      valueParameter = `${valueParameter} - ${justification}`
    }
    return valueParameter
  }
  const getTipoSolicitacao = (tipoSolicitacao: TIPO_REPORT) => {
    const getValue = {
      [TIPO_REPORT.BLOQUEIO]: () => 'bloqueio',
      [TIPO_REPORT.CONTROLADOR]: () => 'informacoes',
      [TIPO_REPORT.DECLARACAO]: () => 'completo',
      [TIPO_REPORT.PORTABILIDADE]: () => 'portabilidade',
      [TIPO_REPORT.RELATORIO]: () => 'simplificado'
    }
    return getValue[tipoSolicitacao]()
  }

  useEffect(() => {
    if (isFormReady && tokenRecapctha !== '') {
      formSAERef.current?.submit()
    }
  }, [isFormReady, formSAE, tokenRecapctha])

  const typeReportMarked = (typeReport: TIPO_REPORT) => {
    formik.setFieldValue('reportChecked', typeReport)
  }

  const solicitacaoFormFactory = () => {
    const solicitacaoForm: Solicitacao = {
      anexos: [],
      email: detalheConsumidor.email,
      idTipoSolicitacao: TIPOS_SOLICITACAO.OUTRAS_SOLICITACAO,
      primeiraSolicitacao: true,
      resumoSolicitacao:
        // eslint-disable-next-line max-len
        `${TIPOS_SOLICITACAO.OUTRAS_SOLICITACAO} - ${nome.toUpperCase()} - ${detalheConsumidor.cpf} - Primeira solicitação`,
      parametros: []
    }
    return solicitacaoForm
  }
  const { deviceType } = useDeviceType();

  return (
    <S.ContainerText>
      <Text tag='h4' size={3} weight={4}>Não achou o que procurava?</Text>
      <Text color='text'>
        Informamos que o SPC BRASIL respeita a sua privacidade e a
        segurança dos seus dados pessoais.
        Portanto, todos os nossos processos de tratamento de dados pessoais
        estão em plena conformidade com a Lei nº. 13.709/2018
        (Lei Geral de Proteção de Dados Pessoais – LGPD).
        Tire suas dúvidas sobre nossas práticas de privacidade e sobre a
        Lei Geral de Proteção de dados pessoais consultando aqui.
      </Text>
      <br />
      <Text color='text'>
        Ou, caso não tenha encontrado uma resposta, a gente te ajuda: (*)
      </Text>
      <FormikProvider value={formik}>
        <S.FieldSet>
          <form onSubmit={formik.handleSubmit}>
            <S.GroupField>
              <S.Checks>
                <input type="radio" name='reportType'
                  value="Relatório Simplificado" onChange={() => typeReportMarked(TIPO_REPORT.RELATORIO)} />
                <Text size={0} >Relatório Simplificado</Text>
              </S.Checks>
              {
                formik.values.reportChecked === TIPO_REPORT.RELATORIO && (
                  <S.Notify>
                    <Text color='text'>
                      Informamos que no Portal do Consumidor
                      (www.spcbrasil.org.br/lgpd.) você poderá acessar de forma imediata e
                      gratuita o seu CPF e outros dados cadastrais,
                      assim como terá acesso a informações de inadimplência
                      registradas na base de dados do SPC Brasil, caso existam.
                      Nesta mesma oportunidade você poderá acessar a pontuação do seu Score de
                      crédito e informações relacionadas ao seu Cadastro Positivo,
                      caso ele esteja ativo no SPC Brasil.
                    </Text>
                    <br />
                    <hr />
                    <Text color='text'>Caso queira continuar com a solicitação, clique no botão <strong>"Enviar solicitação"</strong></Text>
                  </S.Notify>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="radio" name='reportType'
                  value="Declaração Completa" onChange={() => typeReportMarked(TIPO_REPORT.DECLARACAO)} />
                <Text size={0} >Declaração Completa</Text>
              </S.Checks>
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="radio" name='reportType'
                  value="Informações sobre o Controlador" onChange={() => typeReportMarked(TIPO_REPORT.CONTROLADOR)} />
                <Text size={0} >Informações sobre o Controlador</Text>
              </S.Checks>
              {
                formik.values.reportChecked === TIPO_REPORT.CONTROLADOR && (
                  <S.Notify>
                    <Text color='text'>
                      O SPC Brasil é um órgão de proteção ao crédito que oferece soluções a
                      consumidores e empresários com o objetivo de facilitar a realização de
                      negócios por meio de informações confiáveis. O SPC Brasil é o controlador
                      de todos os dados armazenados em nossa base, e mantemos um canal aberto de comunicação,
                      onde os titulares podem solicitar seus direitos, através da página LGPD, no Portal do Consumidor.
                    </Text>
                    <br />
                    <hr />
                    <Text color='text'>Caso queira continuar com a solicitação, clique no botão <strong>"Enviar solicitação"</strong></Text>
                  </S.Notify>
                )
              }
            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="radio" name='reportType'
                  value="Bloqueio de uso de dados para fins de marketing" onChange={() => typeReportMarked(TIPO_REPORT.BLOQUEIO)} />
                <Text size={0} >Bloqueio de uso de dados para fins de marketing</Text>
              </S.Checks>
              {
                formik.values.reportChecked === TIPO_REPORT.BLOQUEIO && (
                  <S.FormContainer>
                    <S.TextboxWrapper>
                      <Text size={0}>Explicação / Justificativa (*)</Text>
                      <S.TextAreaModal 
                        name={`justification`}

                        onChange={formik.handleChange}
                      />
                      <S.ErrorMessage>{formik.errors.justification}</S.ErrorMessage>
                    </S.TextboxWrapper>
                  </S.FormContainer>
                )
              }

            </S.GroupField>
            <S.GroupField>
              <S.Checks>
                <input type="radio" name='reportType'
                  value="Portabilidade dos dados a outro fornecedor de serviço ou produto"
                  onChange={() => typeReportMarked(TIPO_REPORT.PORTABILIDADE)} />
                <Text size={0} >Portabilidade dos dados a outro fornecedor de serviço ou produto</Text>
              </S.Checks>
              {
                formik.values.reportChecked === TIPO_REPORT.PORTABILIDADE && (
                  <S.FormContainer>
                    <S.TextboxWrapper>
                      <Text size={0}>Explicação / Justificativa (*)</Text>
                      <S.TextAreaModal
                        name={`justification`}

                        onChange={formik.handleChange}
                      />
                      <S.ErrorMessage>{formik.errors.justification}</S.ErrorMessage>
                    </S.TextboxWrapper>
                  </S.FormContainer>
                )
              }

            </S.GroupField>
            <S.AddFormWrapper deviceType={{ type: deviceType }}>
              <S.Submit disabled={!(formik.isValid && formik.dirty)
                || formik.isSubmitting} type='submit'>Enviar</S.Submit>
            </S.AddFormWrapper>
          </form>
        </S.FieldSet>
      </FormikProvider>
      <ReCAPTCHA
        sitekey="6LcoYpkiAAAAAN5JHzikMmBCXXkUd9-gMxu_2VAu"
        size='invisible'

        ref={recaptchaRef}
      />
      <form name="formSAE" ref={formSAERef} id="formSAE"
        method="POST" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8">
        <input type="hidden" id="00N1U00000USpvb" name="00N1U00000USpvb" title="Area" value="SAE - Consumidor" />
        <input type="hidden" id="00N1U00000USpw6" name="00N1U00000USpw6" value="LGPD (Atendimento ao titular)" />
        <input value={formSAE.motivo} type="hidden" id="00N1U00000USpw4" name="00N1U00000USpw4" />
        <input value={formSAE.submotivo} type="hidden" id="00N1U00000USpw9" name="00N1U00000USpw9" />
        <input type="hidden" name="orgid" value="00D1U000000FwcW" />
        <input value={formSAE.retURL} type="hidden" name="retURL" />
        <input value={formSAE.cpf} type="hidden" id="00N1U00000USpvk" name="00N1U00000USpvk" />
        <input value={formSAE.name} type="hidden" id="name" name="name" />
        <input value={formSAE.email} type="hidden" id="email" name="email" />
        <input value={formSAE.phone} type="hidden" id="phone" name="phone" />
        <input value={formSAE.subject} type="hidden" id="subject" name="subject" />
        <input value={formSAE.description} type="hidden" id="description" name="description" />
        <input value={formSAE.type} type="hidden" id="type" name="type" />
        <input value={formSAE.captcha_settings} type="hidden" name="captcha_settings" id="captcha_settings" />
        <input value={formSAE.recaptcha_response} type="hidden" name="g-recaptcha-response" id="g-recaptcha-response" />
        <input type="hidden" id="external" name="external" value="1" />
      </form>
    </S.ContainerText>
  )
}

export default OutrasSolicitacoes
