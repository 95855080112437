import { LoadingSpinner, Modal, Text, TextArea } from 'components';
import { AuthContext, CadastroContext } from 'contexts';
import { useContext, useEffect, useState } from 'react';
import { HistoryItem } from '../HistoryItem';
import { HistoryContainer, TextAreaContainer } from '../../styles';
import useDeviceType from 'hooks/useDeviceType';


export const HistoryList = ({ historyList, onLoad }: any) => {
  const { removePermission } = useContext(CadastroContext);
  const { getPermissions, isLoading } = useContext(CadastroContext);
  const { userData } = useContext(AuthContext);
  const [modalData, setModalData] = useState({
    idPermissao: -1,
    justificativaExclusao: '',
  });
  const [modalOpen, setModalOpen] = useState(-1);
  const [reasonOpen, setReasonOpen] = useState(-1);
  const [fieldStatus, setFieldStatus] = useState({
    status: 'default',
    helperText: '',
  });
  const [isLoadingLocal, setLoadingLocal] = useState<boolean>(false);
  const history: any[] = historyList

  const sortOrder = ["Expirada", "Ativa", "Excluida"];

  const sortHistoryByStatus = history.sort((a, b) => {
    const statusA = sortOrder.indexOf(a.statusPermissao);
    const statusB = sortOrder.indexOf(b.statusPermissao);
    return statusB - statusA;
  });

  useEffect(() => {
    setFieldStatus({
      status: 'default',
      helperText: '',
    });
  }, [modalOpen]);

  useEffect(() => {
    setLoadingLocal(onLoad);
  }, [onLoad]);

  const handleModalToggle = () => {
    setReasonOpen(-1);
    setModalData({
      idPermissao: -1,
      justificativaExclusao: '',
    });
  };

  const handleSendForm = (modalForm: any) => {
    if (modalForm.justificativaExclusao == '' || modalData.justificativaExclusao == '') {
      setFieldStatus({ status: 'error', helperText: 'Justificativa para exclusão não informada' });
    } else {
      setLoadingLocal(true);
      Promise.resolve(removePermission(modalData)).then(() => {
        if (userData.cpf !== "" && userData.cpf !== undefined){
          getPermissions({ cpfConsumidor: userData.cpf ?? '' }, true);
        }else{
          getPermissions({ cnpjConsumidor: userData.cnpj ?? '' }, true);
        }
        setLoadingLocal(isLoading ?? false);
      }).catch(() => {
        setLoadingLocal(false);
      });
    }
  };

  const { deviceType } = useDeviceType();

  return (
    <>
      <div>
        <LoadingSpinner state={isLoadingLocal} />
      </div>
      <HistoryContainer deviceType={{ type: deviceType }}
>
        {
          sortHistoryByStatus?.map((item, index) => (
            <div key={index}>
              <HistoryItem
                key={item.id}
                legalName={item.nomeCompletoConsulente}
                cnpj={item.cnpjConsulente.formattedValue}
                releaseDate={item.dataPeriodoInicialPermissaoFormatada}
                expirationDate={item.dataPeriodoFinalPermissaoFormatada}
                status={item.statusPermissao}
                onDeleteHistory={() => setModalOpen(item.id)}
              />
              <Modal
                title={`Confirmação`}
                isOpen={item.id === modalOpen}
                onToggleModal={() => setModalOpen(-1)}
                primaryActionButton={{
                  action: () => setReasonOpen(item.id),
                  closeOnclick: true,
                }}
                secondaryActionButton={{
                  action: () => null,
                  closeOnclick: true,
                }}
              >
                <Text size={1}>
                  Deseja mesmo cancelar a liberação do seu histórico{' '}<br />
                  de pagamento para a empresa {item.nomeCompletoConsulente.toUpperCase()} -<br />
                  CNPJ: {item.cnpjConsulente.formattedValue}?
                </Text>
              </Modal>
              <Modal
                title={`Justificativa`}
                isOpen={item.id === reasonOpen}
                onToggleModal={handleModalToggle}
                primaryActionButton={{
                  action: () => handleSendForm(modalData),
                  closeOnclick: modalData.justificativaExclusao != '',
                }}
                secondaryActionButton={{
                  action: () => null,
                  closeOnclick: true,
                }}
              >
                <TextAreaContainer deviceType={{ type: deviceType }}>
                  <TextArea
                    status={fieldStatus.status}
                    helperText={fieldStatus.helperText}
                    label="Qual o motivo do cancelamento ?"
                    value={modalData.justificativaExclusao}
                    placeholder={'Insira o motivo do cancelamento'}
                    onFocus={() => setModalData({ ...modalData, idPermissao: item.id })}
                    onChange={(e) => {
                      setFieldStatus({
                        status: 'default',
                        helperText: '',
                      });
                      setModalData({ ...modalData, justificativaExclusao: e.target.value });
                    }}
                  />
                </TextAreaContainer>

              </Modal>
            </div>
          ))
        }
      </HistoryContainer>
    </>
  );
};
