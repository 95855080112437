import * as S from './styles';
import { AccordionProps } from 'types';
import { Text } from 'components/Text'
import { useState } from 'react'
import { useSpring } from 'react-spring';
import { BsFillCaretDownFill } from 'react-icons/bs'

const Accordion = ({ children, title, contentHeight }: AccordionProps) => {
  const [open, setOpen] = useState(false);
  const openAnimation = useSpring({
    config: { duration: 300 },
    from: { opacity: "0", maxHeight: S.AccordionTitleHeight },
    to: { opacity: "1", maxHeight: open ? contentHeight : S.AccordionTitleHeight },
  })

  const turnArrowAnimation = useSpring({
    config: { duration: 300 },
    from: { transform: 'rotate(0deg)', top: '1rem' },
    to: { transform: open ? 'rotate(180deg)' : 'rotate(0deg)', top: open ? '1rem' : '0.7rem' }
  })

  return (
    <S.Accordion totalheight={contentHeight} style={openAnimation}>
      <S.AccordionTitle onClick={() => setOpen(!open)}>
        <div><Text color='text' weight={1}>{title}</Text> </div>
        <S.AccordionIcon style={turnArrowAnimation}><BsFillCaretDownFill /> </S.AccordionIcon>
      </S.AccordionTitle>
      <S.AccordionContent>
        {children}
      </S.AccordionContent>
    </S.Accordion>
  );
};

export { Accordion };
