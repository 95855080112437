import { Text } from 'components/Text';
import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';
export const StyledContainer = styled.div`
  ${({ theme }) => `
    background: ${theme.color.white['FFFFFF']};
    padding: 96px 148px;
  `}
`;

export const BoxHighlight = styled.div`
  ${({ theme }) => `
    align-items: center;
    background: ${theme.color.white['F4F7FC']};
    border-radius: 25.53px;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 56px;
    padding: 32px;
  `}
`;

export const TextContent = styled.div`
  margin-left: 45px;
`;

export const StyledKeyValue = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  height: 36px;

  p {
    margin-right: 8px;
  }
`;

export const QueryContainer = styled.div`
  margin-top: 32px;
  position: relative;
  margin-bottom: 56px;
`;

export const Title = styled(Text)`
  ${({ theme }) => `
    color: ${theme.color.darkGray['606060']};
  `}
`;

export const ActionContainer = styled.div`
  margin-top: 100px;

  > a {
    width: 100%;
    margin-top: 24px;
    height: 112px;
  }
`;

export const SearchField = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  padding-left: 24px;
  width: 330px;
  height: 65px;
  border-radius: 6.38267px;
  right: 0;
  top: 0;
  z-index: 1;

  ${({ theme }) => `
    border: 1.59567px solid ${theme.color.lightGray['E5E5E5']};
  `}

  input {
    border: none;
    outline: none;
    margin-left: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    ${({ theme }) => `
      color: ${theme.color.darkGray['797979']};
    `}
  }
`;


export const MessageAlertContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  width: 100%;
  display: flex;
  justifyContent: center;

  ${({ deviceType }) => {
    if (deviceType?.type === 'mobile')
      return `
      width: 75vw;
      `;
  }}


`;