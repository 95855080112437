import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledForm = styled.form<{ deviceType?: DeviceTypeProps }>`
  gap: 10px;
  display: flex;
  flex-direction: column;
  padding: 22px 0;
  
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
      return `
    padding: 20px 0;
    `;
  }}

`;

export const DateRange = styled.div<{ deviceType?: DeviceTypeProps }>`
  display: flex;
  width: 300px;
  gap: 24px;
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
    return `
    gap: 0;
      width: 100%;
      flex-direction: column;
    `;
  }}

`;

export const TermContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
   overflow: auto;
   ${({ deviceType }) => {
     if (deviceType?.type === 'mobile')
     return `
      width: auto;
      `;
  }}

   p {
    margin-bottom: 30px;
   }
`;
