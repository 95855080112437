import React, { useState } from 'react';
import { Text } from 'components';
import { DropdownButton, DropdownContainer, DropdownList, DropdownListItem } from './styles';
import { FaChevronDown } from "react-icons/fa";

export interface DropdownItem {
  label: string;
  value: string;
}

interface DropdownProps {
  items: DropdownItem[];
  selectedItem: DropdownItem | null;
  onSelect: (item: DropdownItem) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ items, onSelect }) => {
  const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleItemClick = (item: DropdownItem) => {
    setSelectedItem(item);
    setIsOpen(false);
    onSelect(item); 
  };

  return (
    <DropdownContainer>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        {selectedItem ? selectedItem.label : items[0].label} <FaChevronDown />
      </DropdownButton>
      {isOpen && (
        <DropdownList>
          {items.map((item, index) => (
            <DropdownListItem key={index} onClick={() => handleItemClick(item)}>
              <Text size={1} >{item.label}</Text>
            </DropdownListItem>
          ))}
        </DropdownList>
      )}
    </DropdownContainer>
  );
};

export { Dropdown };
