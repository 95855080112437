import { PageSkeleton } from 'components';
import { useEffect, useState } from 'react';
import { OperationDetails } from './components/OperationDetails';
import { PaymentHistory } from './components/PaymentHistory';

export interface OperationInfo {
  id: string
  type: string
  docType: string
  document: number
  source: number
  contract: string
}

const operationFactory: OperationInfo = {
  id: '',
  type: '',
  docType: '',
  document: 0,
  source: 0,
  contract: ''
};

const pageLayout = [
  {
    title: 'Histórico de Pagamentos',
    highlight: {
      title: `Nesta página você encontrará as informações do seu Cadastro Positivo, seu histórico de pagamentos.
      `,
      description: `Acompanhe as informações recebidas de Bancos, Operadoras de Telefonia, Distribuidoras de Energia e demais empresas.`,
      image: require('../../../assets/images/historico-pagamentos.png'),
      reverse: true,
    },
    backButton: false,
  },
  {
    title: 'Detalhes da operação',
    highlight: {
      title: `Nesta página, encontrará todos os detalhes \nda operação.`,
      description: `Para confirmar os detalhes das informações, basta clicar
      em <strong>Sim</strong>. Caso \nidentifique qualquer divergência, basta clicar em <strong>Não</strong> para contestar.`,
      image: require('../../../assets/images/detalhes-operacao.png'),
      reverse: true,
    },
    backButton: true,
  },
];

export default function Historico() {
  const [page, setPage] = useState(0);
  const [operation, setOperation] = useState<OperationInfo>(operationFactory);

  useEffect(() => {
    if (page === 0) setOperation(operationFactory);
  }, [page]);

  const handlePageChange = (opertationInfo: OperationInfo) => {
    setPage(1);
    setOperation(opertationInfo);
  };

  return (
    <PageSkeleton
      title={pageLayout[page].title}
      highlight={pageLayout[page].highlight}
      paddingSize='sm'
      backButton={{
        render: pageLayout[page].backButton,
        action: () => setPage(0),
      }}
      breadcrumb={{
        menuName: 'Início',
        submenuName: 'Cadastro Positivo',
        thirdLevel: 'Histórico de Pagamentos',
      }}
    >
      {page === 0 ? <PaymentHistory changePage={handlePageChange} /> : <OperationDetails info={operation} />}
    </PageSkeleton>
  );
}
