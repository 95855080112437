import { FiChevronRight } from 'react-icons/fi';
import { Label, StyledStatusButton } from './styles';

type StatusButtonProps = {
  status: string
  label: string
}

const StatusButton = ({ label, status = 'success' }: StatusButtonProps) => {
  return (
    <StyledStatusButton>
      <Label size={1} weight={1} status={status}>
        <b>&#8226;</b>{label}
      </Label>
      <FiChevronRight />
    </StyledStatusButton>
  );
};

export { StatusButton };
