import { AuthContext } from 'contexts';
import Comunicacao from 'pages/Cadastro/Comunicacao';
import HistoricoDeConsulta from 'pages/Cadastro/HistoricoConsulta';
import Liberacao from 'pages/Cadastro/Liberacao';
import HistoricoPagamentos from 'pages/Cadastro/HistoricoPagamentos';
import Contestacao from 'pages/Cadastro/Contestacao';
import HomeLayout from 'pages/Home';
import CadastroPositivo from 'pages/Home/CadastroPositivo';
import ScoreInicio from 'pages/Home/Inicio';
import EntendaSeuScore from 'pages/Home/EntendaSeuScore';
import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Cadastro from 'pages/Cadastro';
import { ContestForm } from 'pages/Cadastro/HistoricoPagamentos/components/ContestForm';
import { Cancelamento } from 'pages/Cadastro/Cancelamento';
import { Abertura } from 'pages/Cadastro/Abertura';
import { BloqueioDesbloqueio } from 'pages/Cadastro/BloqueioDesbloqueio';
import Vantagens from 'pages/Vantagens';
import Protocolos from 'pages/Cadastro/Protocolos';
import SuaConsulta from 'pages/SuaConsulta';
import LiberarHistoricoPagamento from 'pages/Cadastro/Liberacao/LiberarHistoricoPagamento';
import Login from 'pages/Login';
import Signin from 'pages/Login/Signin';
import RecoverPassword from 'pages/Login/RecoverPassword';
import SignupPf from 'pages/Login/SignupPf';
import SignupPj from 'pages/Login/SignupPj';
import PrivateRoute from 'pages/_components/PrivateRoute';

export default function AppRoutes() {
  const { isAuthenticated } = useContext(AuthContext)

  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/cadastro-pf" element={<SignupPf />} />
      <Route path="/cadastro-pj" element={<SignupPj />} />
      <Route path="/entrar" element={<Signin />} />
      <Route path="/recadastro-senha-valido/:hash" element={<RecoverPassword />} />

      <Route path="/home" element={isAuthenticated ? <HomeLayout /> : <Navigate to="/" replace />} >
        <Route path="score" element={<PrivateRoute><ScoreInicio /></PrivateRoute>} />
        <Route path="cadastro-positivo" element={<PrivateRoute><CadastroPositivo /></PrivateRoute>} />
        <Route path="entenda-seu-score" element={<PrivateRoute><EntendaSeuScore /></PrivateRoute>} />
      </Route>


      <Route path="/cadastro-positivo" element={<Cadastro />}>
        <Route path="dados-comunicacao" element={<PrivateRoute><Comunicacao /></PrivateRoute>} />
        <Route path="historico-consulta" element={<PrivateRoute><HistoricoDeConsulta /></PrivateRoute>} />
        <Route path="liberacao-historico" element={<PrivateRoute><Liberacao /></PrivateRoute>} />
        <Route path="liberar-historico-pagamento" element={<PrivateRoute><LiberarHistoricoPagamento /></PrivateRoute>} />
        <Route path="historico-pagamentos" element={<PrivateRoute><HistoricoPagamentos /></PrivateRoute>} />
        <Route path="realizar-contestacao" element={<PrivateRoute><ContestForm /></PrivateRoute>} />
        <Route path="contestacao" element={<PrivateRoute><Contestacao /></PrivateRoute>} />
        <Route path="cancelamento" element={<PrivateRoute><Cancelamento /></PrivateRoute>} />
        <Route path="abertura" element={<PrivateRoute><Abertura /></PrivateRoute>} />
        <Route path="bloqueio-desbolqueio-score" element={<PrivateRoute><BloqueioDesbloqueio /></PrivateRoute>} />
        <Route path="protocolos" element={<PrivateRoute><Protocolos /></PrivateRoute>} />
      </Route>

      <Route path="vantagens" element={<PrivateRoute><Vantagens /></PrivateRoute>} />
      <Route path="sua-consulta" element={<PrivateRoute><SuaConsulta /></PrivateRoute>} />

      <Route
        path="*"
        element={isAuthenticated == true ?
          (<Navigate to="/" replace />) : (<div>page not found</div>)}
      />
    </Routes>
  );
}
