import { LinkCard, ListIcon, Modal, ScoreChangeMotives, ScoreRange, Text } from 'components';
import {
  KeyIcon,
  PaymentHistoryIcon,
  QueryHistoryIcon,
  ThumbsUpIcon,
  UnlockIcon,
} from 'components/Icon';
import { TermContainer } from 'pages/Cadastro/Liberacao/components/ModalLiberacao/styles';
import { useContext, useEffect, useState } from 'react';
import { AboutLink, AboutParagraph, AboutTopic, CardContainer, ContentBlock, TopicDescription } from './styles';
import { AuthContext } from 'contexts';

import { score as scoreService } from 'services/Cadastro';
import { CadastroContext } from 'contexts';
import useDeviceType from 'hooks/useDeviceType';
import * as DOMPurify from 'dompurify';

export default function EntendaSeuScore() {
  const [userScore, setUserScore] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [improveTips, setImproveTips] = useState([]);
  const { score } = useContext(CadastroContext);
  const { statusCadPos, userData } = useContext(AuthContext);
  const [status, setStatus] = useState<string>();
  const [documentType, setDocumentType] = useState<string>();

  useEffect(() => {
    setStatus(localStorage.getItem('SPC_StatusCadPos') || '');
  }, [statusCadPos]);

  useEffect(() => {
    setDocumentType(localStorage.getItem('SPC_DocumentType') || '');
  }, [userData]);

  const detailsByScore: any = {
    low: {
      description: `Seu score está baixo. Você pode melhorar a sua pontuação.
        Um Score baixo pode gerar dificuldades para conseguir crédito ou negociar condições especiais com as empresas.
        Dica especial pra você: acesse o Blog SPC Brasil e conte com as nossas dicas para ter uma vida financeira saudável,
        evitar novas dívidas, atrasos e restrições em seu nome. <a href="https://www.spcbrasil.org.br/blog" target="_blank">Clique aqui</a>`,
    },
    medium: {
      description: `Seu score está regular. Você pode melhorar a sua pontuação.
        Consulte as nossas dicas, evite atrasos e restrições em seu nome, procure manter sua vida financeira em ordem e
        seus dados sempre atualizados. Assim você terá mais fatores positivos para o aumento da sua nota e, com isso,
        poderá ter acesso a crédito com maior facilidade ou negociar melhores condições com as empresas.
        Conte com o Blog SPC Brasil para conferir dicas especiais! <a href="https://www.spcbrasil.org.br/blog" target="_blank">Clique aqui</a>`,
    },
    good: {
      description: `Seu score está bom! Parabéns!
        Sua pontuação de Score está boa e pode te ajudar a conseguir vantagens como taxas de juros, condições de
        financiamento e negociações melhores. Continue mantendo sua vida financeira organizada, pagamentos em dia e
        dados cadastrais atualizados. Quer mais dicas para turbinar sua pontuação? Acesse o Blog SPC Brasil e aprenda
        com o maior banco de dados da América Latina! <a href="https://www.spcbrasil.org.br/blog" target="_blank">Clique aqui</a>`,
    },
    veryGood: {
      description: `Seu score está excelente! Você cuida mesmo das finanças, fantástico!
        Continue acompanhando suas informações, mantendo os dados cadastrais atualizados e pagamentos em dia para manter essa pontuação.
        E lembre-se sempre: precisou de uma dica sobre saúde financeira, Score, crédito e outros assuntos?
        Acesse o Blog SPC Brasil e conte com informações confiáveis! <a href="https://www.spcbrasil.org.br/blog" target="_blank">Clique aqui</a>`,
    },
  };

  const getDetailsByScore = (score: any) => {
    if (score >= 0 && score < 400) {
      return detailsByScore?.low.description
    }
    else if (score >= 400 && score < 600) {
      return detailsByScore?.medium.description
    }
    else if (score >= 600 && score < 800) {
      return detailsByScore?.good.description
    }
    else if (score >= 800 && score < 1001) {
      return detailsByScore?.veryGood.description
    }
  }

  const getImproveTips = () => {
    const data: any = scoreService.getImproveTips().then((result) => {
      setImproveTips(result);
    });
  };

  useEffect(() => {
    getImproveTips();
    setUserScore(score);
  }, []);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const { deviceType } = useDeviceType();

  return (
    <>
      <ContentBlock>
        <CardContainer deviceType={{ type: deviceType }} isColumn={true}>
          <Text tag="h4" size={2} weight={3} lineHeight={1}>
            Sua faixa de crédito
          </Text>
          <ScoreRange score={userScore} />
          <Text
            color="text"
            size={1}
            lineHeight={1}
            tag="span"
          >
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getDetailsByScore(score), { ADD_ATTR: ['target']}) ?? '' }} />
          </Text>
        </CardContainer>
      </ContentBlock>
      <ContentBlock>
        <CardContainer deviceType={{ type: deviceType }}>
          <ScoreChangeMotives />
        </CardContainer>
      </ContentBlock>
      <ContentBlock>
        <CardContainer deviceType={{ type: deviceType }}>
          <Text tag="h4" size={2} weight={3} lineHeight={1}>
            Como melhorar seu score
          </Text>
        </CardContainer>
        <CardContainer deviceType={{ type: deviceType }}>
          {
            improveTips.map((tip: any) => (
              <ListIcon
                iconTop={deviceType === 'mobile' || deviceType === 'tablet' || deviceType === 'mobile-landscape'}
                title={tip.tituloDica}
                description={tip.descricao}
                icon={<ThumbsUpIcon />}
              />
            ))
          }
        </CardContainer>
      </ContentBlock>
      <ContentBlock className='sem-borda-bottom'>
        <CardContainer deviceType={{ type: deviceType }} isCenter={true}>
          <Text tag="h4" size={2} weight={3} lineHeight={1}>
            Outras opções
          </Text>
        </CardContainer>
        {(status?.includes('ATIVO'))
          ?
          (<CardContainer deviceType={{ type: deviceType }} isCenter={true}>
            <LinkCard
              title="Histórico de Pagamentos"
              description='Veja as informações consolidadas que compõem seu Cadastro Positivo'
              icon={<PaymentHistoryIcon />}
              url="/cadastro-positivo/historico-pagamentos"
              alternative
              onClick={() => null}
            />
            <LinkCard
              title="Liberação ao Histórico de Pagamentos"
              description='Autorize empresas à acessarem seu histórico de pagamentos.'
              icon={<UnlockIcon />}
              url="/cadastro-positivo/liberacao-historico"
              alternative
              onClick={() => null}
            />
            <LinkCard
              title="Histórico de Consulta"
              description={`Veja quem consultou seu ${documentType}.`}
              icon={<QueryHistoryIcon />}
              url="/cadastro-positivo/historico-consulta"
              alternative
              onClick={() => null}
            />
            <LinkCard
              title="Bloqueio e Desbloqueio do Score"
              description='Escolha se seu Score ficará acessível ou não para as empresas.'
              url="/cadastro-positivo/bloqueio-desbolqueio-score"
              icon={<KeyIcon />}
              alternative
              onClick={() => null}
            />
          </CardContainer>)
          : (<CardContainer >
            <LinkCard
              title="Histórico de Pagamentos"
              description='Veja as informações consolidadas que compõem seu Cadastro Positivo'
              icon={<PaymentHistoryIcon />}
              url=""
              alternative
              inactive
              onClick={() => null}
            />
            <LinkCard
              title="Liberação ao Histórico de Pagamentos"
              description='Autorize empresas à acessarem seu histórico de pagamentos.'
              icon={<UnlockIcon />}
              url=""
              inactive
              alternative
              onClick={() => null}
            />
            <LinkCard
              title="Histórico de Consulta"
              description={`Veja quem consultou seu ${documentType}.`}
              icon={<QueryHistoryIcon />}
              url=""
              inactive
              alternative
              onClick={() => null}
            />
            <LinkCard
              title="Bloqueio e Desbloqueio do Score"
              description='Escolha se seu Score ficará acessível ou não para as empresas.'
              url=""
              inactive
              icon={<KeyIcon />}
              alternative
              onClick={() => null}
            />
          </CardContainer>)
        }
      </ContentBlock>
      <AboutLink onClick={handleModal}>
        O que é o Cadastro Positivo?
      </AboutLink>
      <Modal
        title="O que é o Cadastro Positivo?"
        isOpen={showModal}
        onToggleModal={handleModal}
      >
        <TermContainer deviceType={{ type: deviceType }}>
          <AboutParagraph deviceType={{ type: deviceType }} >
            O Cadastro Positivo é um sucesso em vários países e, agora, veio revolucionar a
            análise de crédito no Brasil.
          </AboutParagraph>
          <AboutParagraph deviceType={{ type: deviceType }} >
            Ele reúne, de maneira segura, as informações de pagamento que você fez ou está
            fazendo. Esses dados ficam guardados num histórico que leva em conta toda a sua
            vida financeira recente.
          </AboutParagraph>
          <AboutParagraph deviceType={{ type: deviceType }} >
            Com isso, você poderá ter mais facilidade e menos burocracia para conseguir crédito
            ou fazer compras a prazo, entre outros benefícios e vantagens.
          </AboutParagraph>
          <AboutParagraph deviceType={{ type: deviceType }} >
            Com o Cadastro Positivo do SPC, você tem acesso gratuito às seguintes
            funcionalidades:
          </AboutParagraph>
          <AboutTopic deviceType={{ type: deviceType }}>
            &#8226; Histórico de pagamentos
          </AboutTopic>
          <TopicDescription deviceType={{ type: deviceType }}>
            Veja suas movimentações realizadas no crédito.
          </TopicDescription>
          <AboutTopic deviceType={{ type: deviceType }}>
            &#8226; Contestação
          </AboutTopic>
          <TopicDescription deviceType={{ type: deviceType }}>
            Veja as contestações realizadas de movimentações inadequadas do seu histórico de pagamentos.
          </TopicDescription>
          <AboutTopic deviceType={{ type: deviceType }}>
            &#8226; Liberação aos pagamentos
          </AboutTopic>
          <TopicDescription deviceType={{ type: deviceType }}>
            Autorize empresas à acessarem seu histórico de pagamentos.
          </TopicDescription>
          <AboutTopic deviceType={{ type: deviceType }}>
            &#8226; Histórico de consulta
          </AboutTopic>
          <TopicDescription deviceType={{ type: deviceType }}>
            Veja quem consultou seu {documentType}.
          </TopicDescription>
          <AboutTopic deviceType={{ type: deviceType }}>
            &#8226; Dados de comunicação
          </AboutTopic>
          <TopicDescription deviceType={{ type: deviceType }}>
            Veja os dados da comunicação de abertura do seu Cadastro Positivo.
          </TopicDescription>
          <AboutTopic deviceType={{ type: deviceType }}>
            &#8226; Cancelamento
          </AboutTopic>
          <TopicDescription deviceType={{ type: deviceType }}>
            Faça o cancelamento do seu Cadastro Positivo.
          </TopicDescription>
        </TermContainer>
      </Modal>
    </>
  );
}
