import styled from 'styled-components';
import { DeviceTypeProps } from 'types/Responsive.types';

export const StyledQueryInfoList = styled.div<{ deviceType?: DeviceTypeProps }>`
  position: relative;
  margin-bottom: 24px;
  width: 100%;
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
    return `
        margin: 0px 0px 0 -8px;
    `;
  }}

`;

export const DateContainer = styled.div<{ deviceType?: DeviceTypeProps }>`
  width: 56px;
  height: 63px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  
  ${({ deviceType }) => {
    if (deviceType?.type == 'mobile')
    return `
      margin: 0;
      margin-left: 10px;

      flex-direction: row;
      gap: 14px;
    `;
  }}
`;
