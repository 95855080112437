import React, { useContext, useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import {
  Content,
  DocumentLabel,
  ImageContainer,
  Input,
  InputContainer,
  Label,
  Link,
  StyledInlineErrorMessage,
  Submit,
  SubmitContainer,
  WelcomeContainer,
} from '../styles';
import { Container } from 'components/Container';
import { LoadingSpinner, Responsive } from 'components';
import { Text } from 'components/Text';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { AuthContext, RequestStatusContext } from 'contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { documentFormatter } from 'utils/documentFormatter';
import { Button } from 'components/Button';
import { InputPassword } from '../InputPassword/InputPassword';
import useDeviceType from 'hooks/useDeviceType';

type ErrorEnvioToken = {
  error: string;
  error_description: string;
  message: string;
  tempo_espera_total: string;
  tempo_liberacao_proxima_tentativa: string;
  tentativa_realizada: boolean;
};

const validationSchema = (step: number) => {
  if (step === 0) {
    return Yup.object().shape({
      password: Yup.string().required('Por favor, insira sua senha.'),
    });
  } else {
    return Yup.object().shape({
      otp: Yup.string()
        .required('Por favor, insira o token.')
        .length(6, 'O token deve ter 6 dígitos.'),
    });
  }
};

const Signin = () => {
  const { userData, setLogin, isAuthenticated, recoverPassword } =
    useContext(AuthContext);
  const [step, setStep] = useState(0);
  const [userEmail, setUserEmail] = useState();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [userDocument, setUserDocument] = useState(state.document);
  const [waitTime, setWaitTime] = useState(0);
  const { addRequest } = useContext(RequestStatusContext);
  const { deviceType } = useDeviceType();
  const [widthLeft, setWidthLeft] = useState<number | string>();
  const [widthRight, setWidthRight] = useState<number | string>();

  const userSignupPassword: any = state.password;
  const userSignupEmail: any = state.email;
  const userSignupLastStep: any = state.signupLastStep;

  const handleTokenWaitTime = (err: ErrorEnvioToken) => {
    if (err.tempo_liberacao_proxima_tentativa) {
      setWaitTime(Number(err.tempo_liberacao_proxima_tentativa));
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (waitTime > 0) {
        setWaitTime(waitTime - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [waitTime, step]);

  const maskEmail = (email: any) => {
    if (email) {
      const [username, domain] = email.split('@');
      const maskedUsername = username.replace(/.(?=.{4})/g, '*');
      return `${maskedUsername}@${domain}`;
    } else {
      ('');
    }
  };

  useEffect(() => {
    if (isAuthenticated == true) {
      navigate('/home/score');
    }
  }, [userData, isAuthenticated]);

  useEffect(() => {
    if (userData?.cpf !== '' || userData?.cnpj !== '') {
      setUserEmail(userData?.email);
    }
  }, [userData]);

  useEffect(() => {
    if (deviceType == 'laptop') {
      setWidthLeft('calc(50%)');
      setWidthRight('calc(50%)');
    }

    if (deviceType == 'tablet' || deviceType == 'mobile') {
      setWidthLeft('calc(100%)');
      setWidthRight('calc(85%)');
    }
  }, [deviceType]);

  useEffect(() => {
    {
      userSignupLastStep === true ? setStep(1) : '';
    }
    setWidthLeft('calc(50%)');
    setWidthRight('calc(50%)');
  }, []);

  useEffect(() => {
    if (state.document) {
      setUserDocument(state.document);
    }
  }, [state.document]);

  const initialValues = {
    document: userDocument,
    password: '',
    otp: '',
    grant_type: 'password',
    version: 'v2',
  };

  if (state?.signupLastStep === true) {
    initialValues.password = userSignupPassword;
  }

  const showLinkMsgSent = () => {
    Swal.fire({
      icon: 'success',
      text: 'O link para redefinir sua senha foi enviado para seu e-mail!',
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
    });
  };

  return (
    <>
      <div>
        <LoadingSpinner state={isLoading} />
      </div>
      <Container>
        <Content
          deviceType={{
            type: deviceType,
          }}
        >
          <WelcomeContainer deviceType={{ type: deviceType }}>

          <div >
            {step === 2 ? (
              <div>
                <Text tag="h4" size={3} weight={2} color="black ">
                  Enviamos um e-mail com o link de recuperação.
                </Text>
              </div>
            ) : (
              <div>
                <Text tag="h4" size={4} weight={2} color="black ">
                  Seja Bem-vindo ao seu
                  Portal do Consumidor
                </Text>
                <Text color="text" size={0}>
                  Nele você terá acesso ao seu Score de Crédito, Consulta
                  <br /> de CPF, Cadastro Positivo e muito mais.
                </Text>
              </div>
            )}


            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema(step)}
              onSubmit={(values, actions) => {
                const { password, otp } = values;
                if (step === 0) {
                  setLoading(true);
                  setLogin({
                    document: userDocument,
                    password: password,
                    version: 'v2',
                    grant_type: 'password',
                  })
                    .then((res: any) => {
                      setLoading(false);
                      setStep(1);
                    })
                    .catch((e: any) => {
                      setLoading(false);
                      handleTokenWaitTime(e.response.data as ErrorEnvioToken);
                      if (
                        !e.response.data.tempo_liberacao_proxima_tentativa ||
                        e.response.data?.tempo_liberacao_proxima_tentativa <=
                        0
                      ) {
                        actions.setFieldError(
                          'password',
                          JSON.stringify(e.response.data.message)
                        );
                      }
                    });
                  actions.setSubmitting(true);
                } else {
                  setLoading(true);
                  setLogin({
                    document: userDocument,
                    password:
                      state?.signupLastStep === true
                        ? userSignupPassword
                        : password,
                    version: 'v2',
                    otp: otp || '',
                    grant_type: 'password',
                  })
                    .then((res: any) => {
                      navigate('/home');
                      setLoading(false);
                    })
                    .catch((e: any) => {
                      setLoading(false);
                      actions.setFieldError(
                        'otp',
                        JSON.stringify(e.response.data.message)
                      );
                    });
                  actions.setSubmitting(true);
                  setLoading(false);
                }
                const timeOut = setTimeout(() => {
                  actions.setSubmitting(false);
                  clearTimeout(timeOut);
                }, 1000);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                isValidating,
                isValid,
              }) => {
                return (
                  <>
                    {step === 0 ? (
                      <>
                        <Form
                          name="contact"
                          method="post"
                          onSubmit={handleSubmit}
                        >
                           <InputContainer deviceType={{ type: deviceType }}>
                          <Label htmlFor="password">
                            <Text size={0} color='black'>
                            <AiOutlineArrowLeft
                              style={{ marginRight: '10px' }}
                              onClick={() => {
                                navigate('/');
                              }}
                            />
                              Insira a senha cadastrada para o documento inserido.
                            </Text>
                            <DocumentLabel>
                               <Text size={1} color='black'> <strong>{documentFormatter(userDocument)}</strong> </Text>
                            </DocumentLabel>
                            {/* <Input
                            type="password"
                            name="password"
                            placeholder="Digite sua senha"
                            valid={touched.password && !errors.password}
                            error={touched.password && errors.password}
                          /> */}
                            <InputPassword
                              name="password"
                              placeholder="Digite sua senha"
                              valid={touched.password && !errors.password}
                              error={touched.password && errors.password}
                            />

                          </Label>

                          {errors.password &&
                            touched.password &&
                            waitTime === 0 && (
                              <StyledInlineErrorMessage
                                dangerouslySetInnerHTML={{
                                  __html: errors.password,
                                }}
                              />
                            )}

                          {waitTime > 0 && (
                            <StyledInlineErrorMessage>
                              Tentativa de reenvio de token negada, tente
                              novamente em {waitTime} segundo
                              {waitTime > 1 ? 's' : ''}.
                            </StyledInlineErrorMessage>
                          )}

                          </InputContainer>

                          <SubmitContainer
                            deviceType={{ type: deviceType }}
                          >
                            <Submit
                              type="submit" 
                              disabled={
                                !isValid || isSubmitting || waitTime > 0
                              }
                            >
                              <Text size={0} color='buttonLabel'>{isSubmitting ? `Avançando...` : `Avançar`}</Text>
                            </Submit>
                            <Link
                              onClick={() => {
                                recoverPassword({
                                  document: userDocument,
                                })
                                  .then((res: any) => {
                                    showLinkMsgSent();
                                  })
                                  .catch((e: any) => {
                                    addRequest({
                                      statusCode: e?.response?.status,
                                      message: e?.response?.data?.message,
                                    });
                                    console.log('e', e.response.data.message);
                                  });
                                setStep(2);
                              }}
                            >
                             <Text size={0} color='black'> Recuperar senha </Text>
                            </Link>
                          </SubmitContainer>
                        </Form>
                      </>
                    ) : step === 1 ? (
                      <>
                        <Form
                          name="contact"
                          method="post"
                          onSubmit={handleSubmit}
                        >
                           <InputContainer deviceType={{ type: deviceType }}>
                          <Label htmlFor="otp">
                          <Text size={1} color='black'>
                            <div style={ { marginBottom: "10px" } }>
                            <AiOutlineArrowLeft
                              style={{ marginRight: '20px', marginTop: '20px' }}
                              onClick={() => {
                                setStep(0);
                              }}
                              />
                           Insira o código enviado para o e-mail cadastrado.
                              </div>
                            </Text>
                            <Text size={0} color='black' tag='p'>
                              O código foi enviado ao email{' '}
                              {maskEmail(
                                userSignupEmail ? userSignupEmail : userEmail
                              )}
                              . Caso não o encontre na caixa de entrada,{' '}
                              <b>verifique também a caixa de spam.</b>
                            </Text>
                            <br />
                            <Input
                              type="text"
                              name="otp"
                              autoCorrect="off"
                              maxLength={6}
                              placeholder="Digite o token"
                              valid={touched.otp && !errors.otp}
                              error={touched.otp && errors.otp}
                            />
                          </Label>

                          {errors.otp && touched.otp && (
                            <StyledInlineErrorMessage
                              dangerouslySetInnerHTML={{ __html: errors.otp }}
                            />
                          )}
                        </InputContainer>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                               marginTop: '1rem'
                            }}
                          >
                            <Submit                          
                              type="submit"
                              disabled={!isValid || isSubmitting}
                            >
                            <Text size={0} color='buttonLabel'> {isSubmitting ? `Avançando...` : `Avançar`} </Text>
                            </Submit>
                            <Link
                              onClick={() => {
                                setStep(3);
                              }}
                            >
                              <Text size={0} color='black'> Não encontrei o código</Text>
                            </Link>
                          </div>
                        </Form>
                      </>
                    ) : step === 2 ? (
                      <>
                        <p>
                          <AiOutlineArrowLeft
                            style={{ marginRight: '1rem' }}
                            onClick={() => {
                              setStep(0);
                            }}
                          />
                          Enviamos um link para o e-mail cadastrado.
                          <br />
                          Acesse-o para recuperar a sua senha.
                        </p>
                        <p>
                          Se você não encontrar na caixa de entrada, verifique
                          seu spam.
                        </p>
                        <div style={{ marginTop: '2rem' }}>
                          <Button
                            onClick={() => {
                              recoverPassword({
                                document: userDocument,
                              })
                                .then((res: any) => {
                                  showLinkMsgSent();
                                })
                                .catch((e: any) => {
                                  addRequest({
                                    statusCode: e?.response?.status,
                                    message: e?.response?.data?.message,
                                  });
                                  console.log('e', e.response.data.message);
                                });
                              // setStep(0)
                            }}
                          >
                            Reenviar e-mail
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>
                          <AiOutlineArrowLeft
                            style={{ marginRight: '1rem' }}
                            onClick={() => {
                              setStep(1);
                            }}
                          />
                          Código não encontrado.
                        </p>
                        <p>
                          O código anterior foi enviado ao email{' '}
                          {userData && maskEmail(userEmail)}.
                        </p>
                        <p>
                          Caso não o tenha encontrado após a verificação de
                          todas as caixas de entrada:
                        </p>
                        {waitTime > 0 && (
                          <StyledInlineErrorMessage>
                            Tentativa de reenvio de token negada, tente
                            novamente em {waitTime} segundo
                            {waitTime > 1 ? 's' : ''}.
                          </StyledInlineErrorMessage>
                        )}

                        <div style={{ marginTop: '2rem' }}>
                          <Button
                            disabled={waitTime !== 0}
                            onClick={() => {
                              setLogin({
                                document: userDocument,
                                password: values.password,
                                version: 'v2',
                                grant_type: 'password',
                              })
                                .then(() => {
                                  setStep(1);
                                })
                                .catch((err: any) =>
                                  handleTokenWaitTime(
                                    err.response.data as ErrorEnvioToken
                                  )
                                );
                            }}
                          >
                            Reenviar o token
                          </Button>
                        </div>
                      </>
                    )}
                  </>
                );
              }}
            </Formik>
          </div>
          </WelcomeContainer>
          <ImageContainer deviceType={{ type: deviceType }}>
            <img
              src={require('../../../assets/images/Group151.png')}
              alt="Group"
            />
          </ImageContainer>
        </Content>
      </Container>
    </>
  );
};

export default Signin;
