export const CadastroFactory = {
  contestation: [],
  history: [],
  paymentHistory: {
    itens: [],
    numeroTotalItens: 0,
  },
  companies: [],
  historyMsg: "",
  getPermissions: () => null,
  getPaymentHistory: () => null,
  getCompanies: () => null,
  getContestation: () => null,
  getScorePf: () => null,
  getScorePj: () => null,
  getCommunication: () => null,
  removePermission: () => null,
  includePermission: () => null,
  resetRequestStatus: () => null,
  communication: {
    dataComunicacao: 0,
    documentoConsumidor: 0,
    id: '',
    idFonte: 0,
    indicadorComunicacao: '',
    tipoDocumentoConsumidor: '',
    tipoMeioComunicacao: '',
    valorMeioComunicacao: '',
  },
  requestStatus: {
    status: 0,
    message: '',
  },
  setLoading: () => null,
};
