import { BsX } from 'react-icons/bs';
import styled, { css } from 'styled-components';

export const FilterBar = styled.div`
  margin: 18px 0;
  position: relative;
`;

export const InputGroup = styled.div`
${({ theme }) => css`
    justify-content: flex-start;
    margin: 24px 0;

    &:not(:last-child) {
      padding-bottom: 24px;

    }

    &:last-child {
      margin-bottom: 0;
    }
    > * {
      display: flex;
      gap: 32px;
      width: 100%;
    }

    padding-bottom: 10px;
    border-bottom: 1px solid ${theme.color.lightGray['D5DDED']};
  `}
`;

export const FilterDropdown = styled.div<{isOpen?: boolean}>`
  ${({ theme, isOpen }) => css`
    position: absolute;
    display: ${isOpen ? 'block' : 'none'};
    top: calc(100% + 14px);
    background: ${theme.color.white['FFFFFF']};
    border: 1px solid ${theme.color.lightGray['D5DDED']};
    width: 426px;
    border-radius: 8px;
    z-index: 15;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => `
    display: flex;
    justify-content: space-between;
    padding: 14px 10px;
    border-bottom: 1px solid ${theme.color.lightGray['D5DDED']};
    p {
      color: ${theme.color.blue['144695']};
    }
  `}
`;

export const FilterClose = styled(BsX)`
  ${({ theme }) => `
    width: 24px;
    height: 24px;
    color: ${theme.color.neutral['8094BC']};
    cursor: pointer;
  `}
`;

export const Content = styled.div`
  padding: 24px 10px;
`;

export const Footer = styled.div`
  ${({ theme }) => `
    padding: 14px 10px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid ${theme.color.lightGray['D5DDED']};

    button {
      height: 42px;
      padding: 6px 30px;
    }
  `}
`;
